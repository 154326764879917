import React from 'react';
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";


const OfficerDetailsComponent = (props) => {
  const dispatch = useDispatch();   


  let handleClick = (e,companyCode, companyName,ACN) =>{
    localStorage.setItem("companyCode", JSON.stringify(companyCode));
    localStorage.setItem("companyName", JSON.stringify(companyName));
    if(companyCode !== null){
      props.setSearchCompany([{ Code: companyCode, Name: companyName }]);
      props.recentSearch.push([{ Code: companyCode, Name: companyName }]);
      dispatch(userActions.postRecentCompanyList(props.userId,props.clientId,"",ACN))
      console.log("postRecentCompanyList from officerdetails",props.userId,props.clientId,ACN)
              localStorage.setItem(
                "recentCompanySearch",
                JSON.stringify(props.recentSearch)
              );
        dispatch(userActions.getCompanyProfileByCode(companyCode))
        dispatch(userActions.getRegdInformation(ACN));
      //  props.history.push("/CompanyMenuDetails/CompanySnapshot");
      props.history.push("/CompanyMenuDetails/details"); 
        props.setPopupShow(false);
      } 
      
  }
  const officerDetails = useSelector((state) => state.officerDetails);
 
  
  return (
    <div >
    
  <h5 className="mb-4"> Officer Detail</h5>
  <h5> {props.officerName}</h5>

  <div className="container">

        <table className="table table-hover table-striped">
        
        <tbody className="fs-6 fw-normal">
        {officerDetails &&officerDetails.items&& (
          officerDetails.items.map(
              (value) => 
          <tr>

            <td style={{ width: "200px" }}>
              {" "}
              <a href ={()=>false}
             
                className="text-decoration-none table-data hand-cursor" onClick={(e) => handleClick(e,value.CO_CODE,value.CO_NAME,value.ACN )}
                
              >
             
                {" "}
               { value.CO_NAME}
              </a>
            </td>
            <td style={{ width: "50px" }}>
              {" "}
           {value.POSITION_HELD}
            </td>
            <td style={{ width: "50px" }}>
              {" "}
              {moment(value.APPOINTED).format("DD/MM/YYYY")}
                      </td>
            
          </tr>
          )
          )
            }
        </tbody>
      </table>
    
    </div>
    </div>
  );
};

export default OfficerDetailsComponent;