import React, { useState, useEffect } from "react";
import "../MonitorsComponents/AnnualReviews.style.scss";
import Pagination from "../CommonPage/Pagination.component";
import MobileNumber from "../MobileNumberComponent/MobileNumber.Component";
import Popup from "../CommonPage/Popup.component";
import EmailId from "../EmailIdComponent/EmailId.Component";
import "../CommonPage/Action.scss";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import FullPageLoader from "../CommonPage/FullPageLoader";
import IndividualEntitiesOfficesPopup from "./IndividualEntitiesOfficesPopup.component";
import IndividualEntitiesSharesPopup from "./IndividualEntitiesSharesPopup.component";
import DidComponent from "../Did.Component/Did.Component";

const IndividualEntitiesComponent = (props) => {
  // phoneNumber,Emailid  Update
  const [popupShow, setPopupShow] = useState(0);
  const [popupDidErrorShow, setPopupDidErrorShow] = useState(0);
  const [rn, setRn] = useState("");
  const individualEntities = useSelector((state) => state.individualEntities);
  const handlePopupClose = () => setPopupShow(false);
  const handlePopupShow = (e, index) => {
    e.preventDefault();

    setPopupShow(index);
  };

  const [popupOfficeShow, setPopupOfficeShow] = useState(0);
  const handleOfficePopupClose = () => setPopupOfficeShow(false);
  const handleOfficePopupShow = (e, index, value) => {
    e.preventDefault();
    if (value === "Offices") {
      dispatch(userActions.getIndividualEntitiesOffices(index));
      value = "";
    }
    setPopupOfficeShow(index);
  };

  const [popupSharesShow, setPopupSharesShow] = useState(0);
  const handleSharesPopupClose = () => setPopupSharesShow(false);
  const handleSharesPopupShow = (e, index, value) => {
    e.preventDefault();
    if (value === "Shares") {
      dispatch(userActions.getIndividualEntitiesShares(index));

      value = "";
    }

    setPopupSharesShow(index);
  };

  const [popupMobileShow, setPopupMobileShow] = useState(0);
  const handleMobilePopupClose = () => setPopupMobileShow(false);
  const handleMobilePopupShow = (e, index) => {
    e.preventDefault();
    setPopupMobileShow(index);
  };

  let handleEmailPopupClick = (e, rn) => {
    e.preventDefault();
  // if (person.emailAddress) {
      dispatch(userActions.postIndividualEmailID(rn, person.emailAddress));
      setTimeout(() => {
        dispatch(userActions.getAllIndividualsEntities());
      }, 0);
      // props.history.push(props.history.location);
      handlePopupClose();
   // }
  };

  let handleMobilePopupClick = (e, rn) => {
    e.preventDefault();

   // if (person.mobileNumber) {
      dispatch(userActions.postIndividualPhoneNumber(rn, person.mobileNumber));
      setTimeout(() => {
        dispatch(userActions.getAllIndividualsEntities());
      }, 0);
      //props.history.push(props.history.location);
      handleMobilePopupClose();
   // }
  };

  const [popupDidShow, setPopupDidShow] = useState(0);
  const handleDidPopupClose = () => setPopupDidShow(false);
  const handleDidPopupShow = (e, index) => {
    e.preventDefault();
    setPopupDidShow(index);
  };

  let handleDidPopupClick = (e, rn) => {
    e.preventDefault();
  
    //if (person.didNumber) {
      dispatch(userActions.postIndividualEntitiesDid(rn, person.didNumber));
      setRn(rn)
      setPopupDidErrorShow(person.didNumber);
      setTimeout(() => {
        dispatch(userActions.getAllIndividualsEntities());
      }, 0);
    
      //props.history.push(props.history.location);
   
      handleDidPopupClose();
   // }
  };

  const [person, setPerson] = useState({
    mobileNumber: "",
    emailAddress: "",
    didNumber: "",
  });

  let handlePersonDetailsChange = (e) => {
    e.preventDefault();
    setPerson((person) => ({
      ...person,
      [e.target.name]: e.target.value,
    }));
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  let handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getAllIndividualsEntities());

    // eslint-disable-next-line
  }, []);
  

  // search
  const [searchInputIndividuals, setsearchInputIndividuals] = useState("");

  const individualEntitiesTotal =
    individualEntities && individualEntities.items
      ? individualEntities.items
        .sort((a, b) => (a.SURNAME > b.SURNAME || a.CODE > b.CODE ? 1 : -1))
          .filter(
            (filteredValue) =>
              filteredValue["NAME"] &&
              filteredValue["NAME"]
                .toLowerCase()
                .includes(searchInputIndividuals.toLowerCase())
          )
      : [];

  // console.log(posts);

  const indexOfLastPost = currentPage * postsPerPage;
  // console.log("indexOfLastPost: ", indexOfLastPost);

  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // console.log("indexOfFirstPost: ", indexOfFirstPost);

  const currentPosts = individualEntitiesTotal
    ? individualEntitiesTotal.slice(indexOfFirstPost, indexOfLastPost)
    : [];
  // refresh button
  function handleClickRefreshIndividualEntities(e) {
    e.preventDefault();
    setsearchInputIndividuals("");
  }

  //Profile Report

  //loading spinner
  const [isProfileReportLoaded, setIsProfileReportLoaded] = useState(false);

  function handleClickProfileReport(e, companyCode) {
    localStorage.setItem("companyCode", JSON.stringify(companyCode));
    dispatch(
      userActions.getIndividualEntitiesProfileReport(
        companyCode,
        setIsProfileReportLoaded
      )
    );
  }
  // Offices popup

  const individualEntitiesOffices = useSelector(
    (state) => state.individualEntitiesOffices
  );

  // shares popup
  const individualEntitiesShares = useSelector(
    (state) => state.individualEntitiesShares
  );

  const didUpdate = useSelector((state) => state.didUpdate);

  const handleErrorDidPopupClose = () => setPopupDidErrorShow(false);
  const handleErrorDidPopupShow = (e, index) => {
    e.preventDefault();
    setPopupDidErrorShow(index);
  };


  return (
    <div className="m-4 fontfamily">
    {console.log("individualEntities======",individualEntities)}
      <h2 className="panel-title">Individuals & Entities</h2>

      <nav className="navbar navbar-light bg-light navbar-expand-md ">
        <div className="container">
          <div
            className=" navbar-collapse d-flex flex-row-reverse "
            id="main-nav-collapse"
          >
            <ul className="navbar-nav">
              <div className=" col form-outline">
                <input
                  type="search"
                  id="CompaniesSearch"
                  className="form-control-sm "
                  size="50"
                  placeholder="Enter Name/ Company Name to Search"
                  aria-label="Search"
                  autoComplete={false}
                  value={searchInputIndividuals}
                  onChange={(e) => {
                    setsearchInputIndividuals(e.target.value);
                  }}
                />
              </div>
              &nbsp;
              <button
                className="btn-sm"
                onClick={(e) => handleClickRefreshIndividualEntities(e)}
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </ul>
          </div>
        </div>
      </nav>

      <div className="table-responsive-sm">
        <table className="table table-hover fs-6">
          <thead>
            <tr>
              <th className="text-wrap">Surname/Company Name</th>
              <th>Name</th>
             <th className="text-wrap">Email</th>
              <th className="text-wrap">SMS</th>
              <th className="text-wrap">DID</th>
              <th className="text-wrap"> Action </th>
            </tr>
          </thead>

          <tbody className=" fs-6">
            {
              individualEntities && currentPosts&& currentPosts.length ===0 ?
              
              <tr><td colSpan="10">No matching records found</td></tr>:
              individualEntities &&
              currentPosts &&
              currentPosts.sort().map((post) => (
                <tr>
                  <td>{post.SURNAME}</td>

                  <td>{post.NAME}</td>
              
                  <td className="fs-6">
                    <a
                      href={() => false}
                      onClick={(e) => handlePopupShow(e, post.RN)}
                      className="hand-cursor"
                    >
                    <i
                    data-toggle="tooltip" html= "true" 
                    data-placement="bottom" title="Add/Modify email"
                    class={
                     "bi bi-pencil-square text-dark px-2"
                    }
                  ></i>
                
                    </a>
                    {!post.EMAIL_ADDRESS?"Add/edit email" : post.EMAIL_ADDRESS.indexOf(';')!== -1? post.EMAIL_ADDRESS.split(';').join("\n")
                    :  post.EMAIL_ADDRESS}                  
                    {popupShow === post.RN && (
                      <Popup
                        show={popupShow === post.RN}
                        handleClose={handlePopupClose}
                        handleShow={(e) =>
                          handlePopupShow(e, post.EmailAddress)
                        }
                        body={
                          <EmailId
                            emailId={post.EMAIL_ADDRESS}
                            handlePersonDetailsChange={
                              handlePersonDetailsChange
                            }
                          />
                        }
                        btnName="Save"
                        handleClick={(e) => handleEmailPopupClick(e, post.RN)}
                      />
                    )}
                  </td>
                  <td className="fs-6">
                    <a
                      href={() => false}
                      className="hand-cursor"
                      onClick={(e) => handleMobilePopupShow(e, post.RN)}
                    >
                      <i
                        class={
                          post.SMS
                            ? "fa  fa-mobile fa-lg text-success"
                            : "fa  fa-mobile fa-lg text-danger"
                        }
                      ></i>{" "}
                    </a>
                    {popupMobileShow === post.RN && (
                      <Popup
                        show={popupMobileShow === post.RN}
                        handleClose={handleMobilePopupClose}
                        handleShow={handleMobilePopupShow}
                        body={
                          <MobileNumber
                            phone={post.SMS}
                            handlePersonDetailsChange={
                              handlePersonDetailsChange
                            }
                          />
                        }
                        btnName="Save"
                        handleClick={(e) => handleMobilePopupClick(e, post.RN)}
                      />
                    )}
                  </td>
                  <td >
                 
                  {post.INDIV_TYPE === "Person" ? (
                  
                      <a
                      className={
                        post.DID
                          ? "text-success text-decoration-none hand-cursor"
                          : " text-danger text-decoration-none hand-cursor"
                      }
                        href={() => false}
                        onClick={(e) => handleDidPopupShow(e, post.RN)}
                      >
                        DID
                      </a>
                      ) : (
                        "	n/a"
                      )}
                      {popupDidShow === post.RN && (
                        <Popup
                          show={popupDidShow === post.RN}
                          handleClose={handleDidPopupClose}
                          handleShow={(e) => handleDidPopupShow(e, post.RN)}
                          body={
                            <DidComponent
                          
                              did={post.DID}
                              handlePersonDetailsChange={
                                handlePersonDetailsChange
                              }
                            />
                          }
                          btnName="Save"
                          handleClick={(e) =>
                            handleDidPopupClick(e, post.RN)
                          }
                        
                          />
                      )}
                      
                  {didUpdate &&
                    didUpdate.error &&
                    didUpdate.error.indexOf("400") !== -1 ? (
                      <Popup
                        show={popupDidErrorShow}
                        heading={"Invalid DID " + popupDidErrorShow}
                        handleClose={handleErrorDidPopupClose}
                        handleShow={(e) =>
                          handleErrorDidPopupShow(e, post.DID)
                        }
                        body={<DidComponent
                          //did={post.DID}
                          handlePersonDetailsChange={
                            handlePersonDetailsChange
                          }
                        />
                        
                      }
                      btnName="Save"
                      handleClick={(e) =>
                        handleDidPopupClick(e, rn)
                      }
                      //  handleClick={handleErrorDidPopupClick}
                      />
                    ) : (
                      ""
                    )}
                 </td>
                  <td>
                    <a
                      href={() => false}
                      className="d-flex align-items-center text-white text-decoration-none "
                      id="dropdownUserDetail"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                    </a>
                    <ul className="dropdown-menu text-dark">
                      <li className="action ">
                        <a
                          className="dropdown-item"
                          href={() => false}
                          onClick={(e) =>
                            handleClickProfileReport(e, post.CODE)
                          }
                        >
                          Profile
                        </a>{" "}
                        <hr />
                      </li>
                      {isProfileReportLoaded ? <FullPageLoader /> : null}
                      <li className="action ">
                        <a
                          className="dropdown-item "
                          href={() => false}
                          onClick={(e) =>
                            handleOfficePopupShow(e, post.CODE, "Offices")
                          }
                        >
                          Offices
                        </a>
                        <hr />
                        {popupOfficeShow === post.CODE && (
                          <Popup
                            show={popupOfficeShow === post.CODE}
                            isLargeSize={true}
                            heading={"Offices"}
                            handleClose={handleOfficePopupClose}
                            handleShow={(e) =>
                              handleOfficePopupShow(e, post.CODE)
                            }
                            body={
                              <IndividualEntitiesOfficesPopup
                                individualEntitiesOffices={
                                  individualEntitiesOffices
                                }
                              />
                            }
                          />
                        )}
                      </li>
                      <li className="py-0 ">
                        <a
                          className="dropdown-item "
                          href={() => false}
                          onClick={(e) =>
                            handleSharesPopupShow(e, post.CODE, "Shares")
                          }
                        >
                          Shares
                        </a>

                        {popupSharesShow === post.CODE && (
                          <Popup
                            show={popupSharesShow === post.CODE}
                            isLargeSize={true}
                            heading={"Shares"}
                            handleClose={handleSharesPopupClose}
                            handleShow={(e) =>
                              handleSharesPopupShow(e, post.CODE)
                            }
                            body={
                              <IndividualEntitiesSharesPopup
                                individualEntitiesShares={
                                  individualEntitiesShares
                                }
                              />
                            }
                          />
                        )}
                      </li>
                    </ul>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <footer
        id="sticky-footer"
        class="footer d-flex justify-content-end footer-body py-5 "
      >
        <div class="container fs-6 ">
          <div class="row">
            <div class="col-md-6 ">
              Showing{" "}
              <input
                type="text"
                className="form-control-sm border border-default"
                onKeyPress={handleKeyPress}
                name="showRecord"
                value={postsPerPage}
                maxLength="3"
                size="3"
                onChange={(e) => setPostsPerPage(e.target.value)}
              ></input>
              {""}/
              {individualEntitiesTotal ? individualEntitiesTotal.length : 0}
            </div>
            <div class="col-md-6">
            {console.log('currentPage::::', currentPage)}
              {postsPerPage && (
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={
                    individualEntitiesTotal ? individualEntitiesTotal.length : 0
                  }
                  pageSize={postsPerPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              )}
            </div>
          </div>
        </div>
      </footer>
      <br/>
      <br/>
    </div>
  );
};
export default IndividualEntitiesComponent;




