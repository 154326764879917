import { userConstants } from "../constants/login.constants";

 function classListInfo(state = {}, action) {
    switch (action.type) {
        case userConstants.CLASSLIST_REQUEST:
            return {
                loading: true
            };
        case userConstants.CLASSLIST_SUCCESS:
            return {
                items: action.classListInfo
            };
        case userConstants.CLASSLIST_FAILURE:
            return {
                error: action.classListError
            };
        default:
            return state
    }
}
export default classListInfo;