import React from 'react';
import "../CompanyDetailsComponent/CompanyDetails.styles.scss";





const EmailHistoryComponent = (props) => {

  return (

    <div>
    <table className="table table-hover  table-xs fs-6">
    <thead>
   <tr>
        <th></th>
        <th>Sender </th>
        <th>Recipients</th>
        <th> Subject </th>
        <th>Date </th>
        <th> Delivered </th>
        <th>Read</th>
        </tr> </thead>
        <tbody>
        <tr><td colSpan="7">No matching records found</td></tr>
        </tbody>
</table>
  </div>

        
)
    
}
    export default EmailHistoryComponent;