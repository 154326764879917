import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

const IndividualEntitiesOfficesPopup = (props) => {
  const history = useHistory();

  let handleClick = (e, companyCode, companyName) => {
    localStorage.setItem("companyCode", JSON.stringify(companyCode));
    localStorage.setItem("companyName", JSON.stringify(companyName));
    history.push("/CompanyMenuDetails/details");
  };
  const Officesvalues =
    props.individualEntitiesOffices && props.individualEntitiesOffices.items;
  return (
    <div className="py-3">
    

      <h4 className="panel-title">CORPORATE REGISTER</h4>

      <table className="table table-hover  table-xs fs-6">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Position Held</th>
            <th>Alternate For</th>
            <th>Appointed</th>
            <th>Ceased</th>
          </tr>
        </thead>

        <tbody>
        {Officesvalues && Officesvalues.length === 0 ? (
          <tr>
            <td colSpan="5">No matching records found</td>
          </tr>
        ) : (
    

          Officesvalues &&
            Officesvalues.map((post) => (
              <tr>
                <td>
                  <a
                    href={() => false}
                    className="hand-cursor text-decoration-none"
                    onClick={(e) =>
                      handleClick(e, post.value.CO_CODE, post.key)
                    }
                  >
                    {post.key}
                  </a>
                </td>

                <td>
                  <span
                    className={
                      (post.value.CEASED)
                        ? "bg-danger  text-light "
                        : "  bg-success text-light"
                    }
                  >
                    {" "}
                    {post.value.POSITION_HELD}
                  </span>
                </td>
                <td></td>
                <td>{moment(post.value.APPOINTED).format("DD/MM/YYYY")}</td>
                <td>
                  {post.value.CEASED &&
                    moment(post.value.CEASED).format("DD/MM/YYYY")}
                </td>
              </tr>
            )
            ))}
          
        </tbody>
      </table>
    </div>
  );
};
export default IndividualEntitiesOfficesPopup;
