import React from "react";

import { useSelector } from "react-redux";

const ShareCapitalComponent = (props) => {
  const companyProfile = useSelector((state) => state.companyProfile);

  return (
    <div >
      <table className="table table-light">
        <thead className="fw-bold">
          <tr>
            <th>Class Code</th>
            <th>Total Shares</th>
            <th>Total Paid</th>
            <th>Total Unpaid</th>
          </tr>
        </thead>
     
            <tbody className="fs-6 fw-normal">
            {companyProfile.items &&
              companyProfile.items.sharecaps && companyProfile.items.sharecaps.length>0 ?  companyProfile.items.sharecaps.map((value, index) => (
              
              <tr>
          
                <td>{value.CLASS_CODE}</td>
                <td>{Intl.NumberFormat('en-US').format(value.ISSUED)}</td>
                <td>{value.PAID_UP.toLocaleString()}</td>
                <td>{Intl.NumberFormat('en-US').format(value.DUE_AND_PAYABLE ? value.DUE_AND_PAYABLE:"0")}</td>
              </tr>
              )):<tr><td colspan ={4}>No data exist in the share capital</td></tr>}
            </tbody>
       
      </table>
    </div>
  );
};
export default ShareCapitalComponent;
