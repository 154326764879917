import { userConstants } from "../constants/login.constants";

 function businessNameDetailList(state = {}, action) {
    switch (action.type) {
        case userConstants.BUSINESSNAMEDETAILLIST_REQUEST:
            return {
                loading: true
            };
        case userConstants.BUSINESSNAMEDETAILLIST_SUCCESS:
            return {
                items: action.businessNameDetailList
            };
        case userConstants.BUSINESSNAMEDETAILLIST_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export default businessNameDetailList;