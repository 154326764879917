import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import { useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import ToggleSwitch from "../CommonPage/ToggleSwitch.component";
import moment from "moment";


const UnitTrust = (props) => {
  const [selectedToggle, setSelectedToggle] = useState(false);
  const [companyCode, setCompanyCode] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const unitTrust = useSelector((state) => state.unitTrust);
  const selectedSearchValue = localStorage.getItem("inputSearchValue");
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("All");
  
  function handleChange(e) {
    setSelectedToggle(e);
    dispatch(
      userActions.getUnitTrustByCode(
        companyCode,
        selectedToggle ? "summary" : "showall"
      )
    );
  }
  useEffect(() => {
    
    if (selectedSearchValue !== null) {
      const companyCode = JSON.parse(selectedSearchValue).map((value) => {
        return value.Code;
      });
      if (companyCode) {
               setCompanyCode(companyCode);
        //dispatch(userActions.getCompanyProfileByCode(companyCode));
        //dispatch(userActions.getUnitTrustByCode(companyCode, "summary"));
      }
    }
    // eslint-disable-next-line
  }, []);

  function handleClickRegisterAction(e, rn) {
    dispatch(
      userActions.getUnitTrustUnitholdersReport(rn)
    );
  }
  
  function handleClickAAJournalAction(e, rn) {
    dispatch(
      userActions.getUnitTrustApplicationAllotmentJournalReport(rn)
    );
  }

  function handleClickUnitsTransferJournalAction(e, rn) {
    dispatch(
      userActions.getUnitTrustTransferJournalReport(rn)
    );
  }

  function handleClickUnitholdersSummaryAction(e, rn) {
    dispatch(
      userActions.getUnitTrustUnitholdersSummaryReport(rn)
    );
  }
  return (
     
    <form className="row row-cols-lg-auto g-1 ">
    <div className=" col form-outline">
            <input type="search" id="form1" class="form-control" placeholder="Search"
            aria-label="Search" value={searchInput} onChange={(e) =>{setSearchInput(e.target.value)}} autoComplete={false}/>
        </div>
        
      <div className="col">
      
        <select
          name="allform"
          id="allform"
          className="dropDownBox text-nowrap "
        >
        
          <option>Actions</option>
         
          <option >Register of Unitholders</option>
          <option >Units - Application and Allotment Journal</option>
          <option >Units Transfer Journal</option>
          <option> Unitholders Summary</option>
        
        </select>
      </div>

      <div className="col">
        <select
          name="allform"
          id="allform"
          className="dropDownBox text-nowrap "
          onChange={(e) => {
            setSearchInput("")
            setSelectedOption(e.target.value);
          }
        }
        >
          <option value="All" >Show All</option>
          <option value="T">Transfers</option>
          <option value="A">Allotments</option>
          <option value="R">Redemptions</option>
          <option value="E">Capital Return</option>
          <option value="B">Balance</option>
        </select>
      </div>
      <div className="col">
        <ToggleSwitch
          name="newsletter"
          checked={selectedToggle}
          onChange={handleChange}
        />
      </div>
      {
        
        unitTrust && 
        unitTrust.items &&
        unitTrust.items.map((trust) => (
            
          <table className="table">
            <thead>
              <tr>
                <th colspan="12">{trust.TRUST_NAME}</th>
              </tr>
              <tr>
         
                <th>UnitHolder Name</th>
                <th>Ben Name</th>
                <th>Class Code</th>
                <th>Total</th>
                <th>Total paid</th>
                <th>Trans</th>
                <th>Date</th>
                <th>FP</th>
                <th>BO</th>
                <th>Cert No.</th>
                <th>Action</th>
              </tr>
            </thead>
            {trust.UNITS.filter(filteredValue => (searchInput)?filteredValue.NAME.toLowerCase().includes(searchInput.toLowerCase()):(selectedOption && (selectedOption !=="All"))?filteredValue.TRAN_TYPE && filteredValue.TRAN_TYPE.toLowerCase().includes(selectedOption.toLowerCase()):filteredValue.NAME ).map((units) => (
              <tbody>
                <tr>
                
                  <td>{units.NAME}</td>
                  <td>{units.BEN_NAME}</td>
                  <td>{units.CLASS_CODE}</td>
                  <td>{units.SUM_TOT_SHARES}</td>
                  <td>
                    <CurrencyFormat
                      value={units.SUM_TRAN_PRICE}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td> {units.TRAN_TYPE} </td>

             

                  <td>{units.TRANS_DATE ? moment(units.TRANS_DATE).format(
                    "DD/MM/YYYY"
                  ) : ""}{" "} </td>
                  <td>
                    {units.FULLY_PAID ? (
                      <i className="bi bi-check-lg  text-success"></i>
                    ) : (
                      <i className="bi bi-x-lg text-danger "></i>
                    )}
                  </td>
                  <td>
                    {units.BEN_OWNER ? (
                      <i className="bi bi-check-lg  text-success"></i>
                    ) : (
                      <i className="bi bi-x-lg text-danger "></i>
                    )}
                  </td>
                  <td>{units.SCRIP_NO}</td>
                  <td>
                  <a
                    href={() => false}
                    className="d-flex align-items-center text-white text-decoration-none "
                    id="dropdownUserDetail"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                  </a>
                  {(selectedToggle)?
                  

                  <ul className="dropdown-menu text-dark ">
                    <li className="action">
                      <a className="dropdown-item " href={() => false} onClick={e=>handleClickRegisterAction(e, units.RN)}>
                      Register of Unitholders
                      </a>{" "}
                      <hr />
                    </li>

                    <li className="action ">
                      <a className="dropdown-item " href={() => false} onClick={e=>handleClickAAJournalAction(e, units.RN)}>
                      Units - Application and Allotment Journal
                      </a>
                      <hr />
                    </li>
                    <li className="action">
                      <a className="dropdown-item" href={() => false} onClick={e=>handleClickUnitsTransferJournalAction(e, units.RN)}>
                      Units Transfer Journal
                      </a>
                      <hr />
                    </li>
                    <li className="action ">
                      <a className="dropdown-item" href={() => false} onClick={e=>handleClickUnitholdersSummaryAction(e, units.RN)}>
                      Unitholders Summary
                      </a>
                  
                    </li>
                   
                  </ul>
                   :
                      <ul className="dropdown-menu text-dark ">
                      <li className="action ">
                      <a className="dropdown-item " href={() => false}>
                     PLEASE PRINT REPORT FROM SHOW ALL MODE
                      </a>
                                          </li>
                                 
                  </ul>
                    }
                </td>
                </tr>
              </tbody>
            ))}
          </table>
        ))}
    </form>
  );
};
export default UnitTrust;
