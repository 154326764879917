import React from "react";
import { useHistory } from "react-router-dom";
import "./MainScreen.style.scss";
import logo from "../../image/Prime-Logo-Coloured-Vertical.png";

import { useSelector } from "react-redux";
import * as constants from "../../properties/properties";

const MainScreen = (props) => {
  const users = useSelector((state) => state.users);
  const forgetPasswordEmail = useSelector((state) => state.forgetPasswordEmail);
  const history = useHistory();

  return (
    <div>
      <nav class="navbar navbar-light bg-light">
        <div className="container  d-flex justify-content-center">
          <div className="row  ">
            <table
              style={{
                fontSize: "14px",
                color: "#012040",
                lineHeight: "20px",
                fontWeight: "500",

              }}
            >
              <tr>
                <td className="text-center">
                  You have been redirected from portal.primecloud.com.au
                </td>
              </tr>
              <tr>
                <td className="text-center" style={{
                  fontWeight: "550",
                  fontStyle:'normal'
                }}>
                  {" "}
                  
                    We’ve changed the way we look but we’re still the same
                    service you know and trust!

                </td>
              </tr>
            </table>
          </div>
        </div>
      </nav>

      <div className="container py-4">
        <div className="text-center mx-auto d-block  d-flex justify-content-center py-4">
          <a
            target="_blank"
            rel="noreferrer"
            href={"https://www.primecc.com.au/"}
          >
            <img
              src={logo}
              alt="logo"
              className="mx-auto d-block img-thumbnail border-0"
              width="180"
              height="180"
            />
          </a>
        </div>
        <p
          className="text-center"
          style={{
            fontSize: "22px",
            fontWeight: "600",
            fontStyle: "normal",
            color: "#F99B1C",
          }}
        >
          Prime Compliance
        </p>
        {(history.location.pathname === "/" ||
          history.location.pathname === "/login") && (
          <p
            className="text-center"
            style={{
              fontSize: "16px",
              lineHeight: "19px",
              color: "#012040",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          >
            Login to your account
          </p>
        )}
        {history.location.pathname === "/forgetPassword" && (
          <p
            className="text-center"
            style={{
              fontSize: "16px",
              lineHeight: "19px",
              color: "#012040",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          >
            Reset your password
          </p>
        )}
        {users.error && (
          <div
            className="alert-message  mx-auto d-block d-flex justify-content-center  lh-sm"
            style={{ marginBottom: "-20px" }}
          >
            <div
              className="alert alert-danger alert-dismissible fade show text-center col-md-4  col-sm-12"
              role="alert"
            >
              {constants.INVALID_USER}

              <button
                type="button"
                className="btn-close mx-auto d-block float-right "
                style={{ fontSize: "12px" }}
                data-bs-dismiss="alert"
              ></button>
            </div>
          </div>
        )}

        {forgetPasswordEmail.error && (
          <div
            className="alert-message  mx-auto d-block d-flex justify-content-center  lh-sm"
            style={{ marginBottom: "-20px" }}
          >
            <div
              className="alert alert-danger alert-dismissible fade show text-center col-md-4  col-sm-12"
              role="alert"
            >
              {constants.INVALID_USERNAME}

              <button
                type="button"
                className="btn-close mx-auto d-block float-right "
                style={{ fontSize: "12px" }}
                data-bs-dismiss="alert"
              ></button>
            </div>
          </div>
        )}
        <div className=" rounded mx-auto d-block d-flex justify-content-center align-top col-sm-12">
          <div className="row g-1 col-md-4">
            <div className="login d-flex ">{props.content}</div>
          </div>
        </div>

        <div className="copyright text-center text-dark">
          <div
            style={{
              fontSize: "16px",
              lineHeight: "19px",
              color: "#A6A6A6",
              fontStyle: "normal",
              fontWeight: "400",
              letterspacing: "0.25px",
            }}
          >
            {" "}
            Copyright © 2022{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={"https://www.primecc.com.au/"}
              className="text-decoration-none "
              style={{
                fontSize: "16px",
                lineHeight: "19px",
                color: "#A6A6A6",
                fontStyle: "normal",
                fontWeight: "400",
                letterspacing: "0.25px",
                cursor: "hand",
              }}
            >
              Prime Company Compliance.{" "}
            </a>
            All rights reserved.
          </div>

          <a
            target="_blank"
            rel="noreferrer"
            href={
              "https://www.primecc.com.au/wp-content/uploads/2022/08/CCASA-Terms-of-Use-Final-Aug22.pdf"
            }
          >
            {" "}
            <u
              style={{
                fontSize: "16px",
                lineHeight: "19.36px",
                color: "#012040",
                fontStyle: "normal",
                fontWeight: "400",
                letterspacing: "0.25px",
              }}
            >
              Terms of Use
            </u>
          </a>
        </div>
      </div>
    </div>
  );
};
export default MainScreen;
