import React,{useState,} from 'react';
import Landingpage from '../LandingPagecomponent/LandingPage.Component'
import { withRouter } from "react-router-dom";
import FormsAndDocumentComponent from './formsAndDocumentComponent';
import CommonSearch from '../CommonPage/CommonSearch.component';


const FormsAndDocumentsLanding = (props) => {
    const [recentSearch, setRecentSearch] = useState([])
    const [searchCompany, setSearchCompany] = useState([]);

    return (
       
        <Landingpage    isMonitorCollapse={true} isFormsAndDocumentPage={true} 
        commonSearch={ <CommonSearch history={props.history} setRecentSearch={setRecentSearch} recentSearch={recentSearch} setSearchCompany={setSearchCompany} searchCompany={searchCompany}/>}
        content={
<FormsAndDocumentComponent/>
        }/>
        
    )
        
    }
    export default withRouter(FormsAndDocumentsLanding);