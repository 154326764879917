import React,{useState} from "react";
import Landingpage from "../LandingPagecomponent/LandingPage.Component";
import { withRouter } from "react-router-dom";
import CommonSearch from "../CommonPage/CommonSearch.component";
import UnitTrustRegisterComponent from "./UnitTrustRegisterComponent";

const UnitTrustRegisterLanding = (props) => {
  const [recentSearch, setRecentSearch] = useState([])
  const [searchCompany, setSearchCompany] = useState([]);
  return (
    <Landingpage
      isFileCollapse={true}
      isUnitTrustRegisterPage={true}
      commonSearch={ <CommonSearch history={props.history} setRecentSearch={setRecentSearch} recentSearch={recentSearch} setSearchCompany={setSearchCompany} searchCompany={searchCompany}/>}
      content={<UnitTrustRegisterComponent />}
    />
  );
};
export default withRouter(UnitTrustRegisterLanding);