import React, { useState } from "react";

const NotesComponent = (props) => {
  const [users, setUsers] = useState({
    notes: props.notes,
    companyName: props.companyName,
    departementNotes: props.departementNotes,
  });
// eslint-disable-next-line
  const [submitted, setSubmitted] = useState(false);

  let handleChange = (e) => {
    setUsers((users) => ({
      ...users,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div>
      <table className="table table-responsive table-borderless">
        <tbody>
          <tr>
            <div class="form-group">
              <label for="exampleFormControlTextarea1" className="fw-bold">
                Edit Notes [ {props.companyName}]
              </label>
              {props.departementNotes ? (
                <div class="alert alert-info" role="alert">
                  <p className="fw-bolder">Department Notes:</p>
                  <span className="fs-6"> {props.departementNotes}</span>
                </div>
              ) : (
                "	"
              )}
              
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                type="text"
                name="notes"
                onChange={(e) => {
                  handleChange(e);
                  props.handlePersonDetailsChange(e);
                }}
                className={
                  "form-control input-sm " +
                  (submitted && !users.notes ? "is-invalid" : "")
                }
                placeholder="Company Notes....."
                value= {users && users.notes}
                autoComplete="off"
              > </textarea>
              {submitted && !users.notes && (
                <div className="invalid-feedback">Add some notes</div>
              )}
            </div>
          </tr>
        </tbody>{" "}
      </table>
    </div>
  );
};

export default NotesComponent;
