import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

const PrintCompanyProfileComponent = (props) => {
  
  const handleDateChange = (e) => {
    try {
      const asDate = moment(e).format('DD/MM/yyyy');
      props.handleChange({target: { name: 'asDate', value: asDate }});  
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <h3 className="mb-4">Enter Values</h3>

      <table className="table table-responsive table-borderless">
        <tbody>
          <tr>
            <td>
              <strong>Enter from Date</strong>
            </td>
            <td>
              <DatePicker
                label="stuff"
                className="w-250"
                name="printDate"
                onChange={(e) => handleDateChange(e)}
                dateFormat="dd/MM/yyyy"
                placeholderText="Enter as at Date"
                value={props.printCompany.asDate}
              />
            </td>
          </tr>
          <tr>
            <td>
              <strong>Enter to Date</strong>
            </td>
            <td>
             <DatePicker
                label="stuff"
                className="w-250"
                name="printDate"
                onChange={(e) => handleDateChange(e)}
                dateFormat="dd/MM/yyyy"
                placeholderText="Enter as at Date"
                value={props.printCompany.asDate}
              />
            </td>
          </tr>
          <tr>
            <td className="fw-bold w-50 ">
              Show Documents Lodged List? 
            </td>{" "}
            <td>
              {" "}
              <label>
              <input
              type="radio"
              name="docLodge"
              checked={props.printCompany.docLodge === "Yes"}
              onClick={props.handleChange}
              value="Yes"
            />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
              <input
              type="radio"
              name="docLodge"
              checked={props.printCompany.docLodge === "No"}
              onClick={props.handleChange}
              value="No"
            />
                NO
              </label>
            </td>
          </tr>
          <tr>
            <td className="fw-bold w-50 ">
              Show Current Officeholders Only? 
            </td>{" "}
            <td>
              {" "}
              <label>
              <input
              type="radio"
              name="officeHolders"
              checked={props.printCompany.officeHolders === "Yes"}
              onClick={props.handleChange}
              value="Yes"
            />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
              <input
              type="radio"
              name="officeHolders"
              checked={props.printCompany.officeHolders === "No"}
              onClick={props.handleChange}
              value="No"
            />
                NO
              </label>
            </td>
          </tr>
        </tbody>{" "}
      </table>
    </div>
  );
};

export default PrintCompanyProfileComponent;
