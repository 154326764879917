import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "./redux/actions/user.actions";
import SignUp from "./pages/SignUpComponent/SignUp.Component";
import Login from "./pages/LoginPageComponent/LoginPage.Component.jsx";
import ForgetPassword from "./pages/ForgetPasswordComponent/ForgetPassword.Component";
import LandingPage from "./pages/LandingPagecomponent/LandingPage.Component";
import {
  Switch,
  Route,
  Router,
  useHistory,
  withRouter,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import CompanyMenuDetails from "./pages/CompanyMenuComponent/CompanyMenuDetails.Component";
import MonitorLanding from "./pages/MonitorsComponents/MonitorLanding.Component";
import OrderMonitorLanding from "./pages/OrderMonitorComponents/OrderMonitorLanding.Component";
import DigitalSignatureLanding from "./pages/DigitalSignatureComponent/DigitalSignatureLandingComponent";
import ASICDeptLandingComponent from "./pages/ASICDeptComponents/ASICDeptLanding.Component";
import CompaniesLandingComponent from "./pages/CompaniesComponents/CompaniesLanding.component";
import IndividualEntitiesLandingComponent from "./pages/Individual&EntitiesComponents/IndividualEntitiesLanding.component";
import JointHolderLandingComponent from "./pages/JointHoldersComponents/JointHolderLanding.component";
import BeneficialOwnerLandingComponent from "./pages/BeneficialOwnerComponent/BeneficialOwnerLanding.component";
import PrintCompanyProfileComponent from "./pages/PrintCompanyProfileComponents/PrintCompanyProfile.Component";
import formsAndDocumentsLandingComponent from "./pages/FormsandDocumentsComponent/formsAndDocumentsLanding.Component";
import ResetPassword from "./pages/ResetPasswordComponent/ResetPassword.Component.jsx";
import ResetPasswordInstruction from "./pages/ResetPasswordInstructionComponent/ResetPasswordInstruction.Component.jsx";
import PasswordChangeSuccess from "./pages/PasswordChangeSuccessComponent/PasswordChangeSuccess.Component.jsx";
import EOrderOrderRequestLanding from "./pages/ChangesAndOrderRequest/EorderOrderRequestLanding.Component";
import SignUpSuccess from "./pages/SignUpSuccessComponent/SignUpSuccessComponent.jsx";
import UnitTrustRegisterLanding from "./pages/UnitTrustRegisterComponent/UnitTrustRegisterLanding";
import DIDListComponentLanding from "./pages/DIDListComponent/DIDListComponentLanding";
import ChangeOrderStatus from "./pages/ChangesAndOrderRequest/ChangeOrderStatusComponent/ChangeOrderStatus.component";
import EbinderMainLandingComponent from "./pages/EbinderMainLandingComponent/EbinderMainLandingComponent";
import BusinessNamelandingComponent from "./pages/BusinessNameComponent/BusinessNamelanding.component";
import ProfileSettingLanding from "./pages/ProfileSettingComponent/ProfileSettingLanding";
import Cookies from "universal-cookie";
import { isLocalhost } from "./util/helpers.js";

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [recentSearch, setRecentSearch] = useState([]);
  const [searchToken, setSearchToken] = useState("");
  const [searchUserName, setSearchUserName] = useState("");
  const param = history.location.search && history.location.search.split("=");
  const token = param && param[1] && param[1].split("&");
  const loggedUserInfo = useSelector((state) => state.loggedUserInfo);
  const userId =
    loggedUserInfo && loggedUserInfo.items && loggedUserInfo.items.IDPrimeUser;
  const clientId =
    loggedUserInfo &&
    loggedUserInfo.items &&
    loggedUserInfo.items.IDClientProfile;
  const [searchCompany, setSearchCompany] = useState([]);

  const cookies = new Cookies();

  const isAppOnLocalhost = isLocalhost()
    ? ".localhost.com"
    : process.env.REACT_APP_DOMAIN;

  const cookieOptions = {
    domain: isAppOnLocalhost,
    path: "/",
  };

  // eslint-disable-next-line
  useEffect(() => {
    //  console.log(`In ${timer} seconds you will be automatically logged out`)
    setSearchToken(token && token[0]);
    setSearchUserName(param && param[2] && param[2]);
    if (searchToken) {
      localStorage.setItem("token", JSON.stringify(searchToken));
      cookies.set("token", JSON.stringify(searchToken), cookieOptions);
      localStorage.setItem("userName", searchUserName);
    }

    //localStorage.setItem("userName", userName);
  });
  useEffect(() => {
    dispatch(userActions.getLoggedUserInfo());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      return "show warning";
    }
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <Route path="/signUp" component={SignUp} />
        <Route path="/login" component={Login} />
        <Route path="/forgetPassword" component={ForgetPassword} />
        <Route path="/ResetSuccess" component={ResetPasswordInstruction} />
        <Route path="/Account/ResetPassword" component={ResetPassword} />
        <Route
          path="/PasswordChangeSuccess"
          component={PasswordChangeSuccess}
        />
        <Route path="/landingPage" component={LandingPage} />
        <Route path="/CompanyMenuDetails">
          <CompanyMenuDetails
            recentSearch={recentSearch}
            setRecentSearch={setRecentSearch}
            loggedUserInfo={loggedUserInfo}
            userId={userId}
            clientId={clientId}
            searchCompany={searchCompany}
            setSearchCompany={setSearchCompany}
          />
        </Route>
        <Route path="/CompaniesLanding">
          <CompaniesLandingComponent
            recentSearch={recentSearch}
            setRecentSearch={setRecentSearch}
            loggedUserInfo={loggedUserInfo}
            userId={userId}
            clientId={clientId}
            searchCompany={searchCompany}
            setSearchCompany={setSearchCompany}
          />{" "}
        </Route>
        <Route path="/BusinessNameLanding">
          <BusinessNamelandingComponent
            recentSearch={recentSearch}
            setRecentSearch={setRecentSearch}
            loggedUserInfo={loggedUserInfo}
            userId={userId}
            clientId={clientId}
            searchCompany={searchCompany}
            setSearchCompany={setSearchCompany}
          />{" "}
        </Route>
        <Route path="/EbinderMainLanding">
          <EbinderMainLandingComponent
            recentSearch={recentSearch}
            setRecentSearch={setRecentSearch}
            loggedUserInfo={loggedUserInfo}
            userId={userId}
            clientId={clientId}
            searchCompany={searchCompany}
            setSearchCompany={setSearchCompany}
          />{" "}
        </Route>
        <Route
          path="/IndividualEntitiesLanding"
          component={IndividualEntitiesLandingComponent}
        />
        <Route
          path="/jointHolderLanding"
          component={JointHolderLandingComponent}
        />
        <Route
          path="/BeneficialOwnerLanding"
          component={BeneficialOwnerLandingComponent}
        />
        <Route path="/MonitorLanding">
          <MonitorLanding
            recentSearch={recentSearch}
            setRecentSearch={setRecentSearch}
            loggedUserInfo={loggedUserInfo}
            userId={userId}
            clientId={clientId}
          />{" "}
        </Route>
        <Route path="/ASICDeptLanding" component={ASICDeptLandingComponent} />
        <Route path="/OrderMonitorLanding" component={OrderMonitorLanding} />
        <Route
          path="/DigitalSignatureLanding"
          component={DigitalSignatureLanding}
        />
        <Route
          path="/PrintCompanyProfile"
          component={PrintCompanyProfileComponent}
        />
        <Route
          path="/FormsAndDocumentsLanding"
          component={formsAndDocumentsLandingComponent}
        />
        <Route
          path="/EOrderOrderRequestLanding"
          component={EOrderOrderRequestLanding}
        />
        <Route path="/SignUpSuccess" component={SignUpSuccess} />
        <Route
          path="/UnitTrustRegisterLanding"
          component={UnitTrustRegisterLanding}
        />
        <Route path="/DIDListLanding" component={DIDListComponentLanding} />
        <Route path="/ChangeOrderStatus" component={ChangeOrderStatus} />
        <Route path="/ProfileSettings" component={ProfileSettingLanding} />

        {searchToken ? (
          <Route exact path="/">
            <CompanyMenuDetails
              recentSearch={recentSearch}
              setRecentSearch={setRecentSearch}
              searchToken={searchToken}
              userId={userId}
              clientId={clientId}
            />
          </Route>
        ) : (
          <Route exact path="/" component={Login} />
        )}
      </Switch>
    </Router>
  );
}

export default withRouter(App);
