import React, { useState, useEffect } from "react";
import AddOrRemoveAsicAgentListComponent from "./AddOrRemoveAsicAgentList.component";
import ASICDetailsUpdateComponent from "./ASICdetailsUpdate.component";
import DividendStatementOrderComponent from "./DividendStatementOrder.component";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import Popup from "../CommonPage/Popup.component";
import OrderSummary from "./OrderSummary";
import { withRouter} from "react-router-dom";
import ChangeOrderStatusComponent from "./ChangeOrderStatusComponent/ChangeOrderStatus.component";
import RemoveAsicAgentListComponent from "./RemoveAsicAgentList.component";

const EOrderOrderRequestComponent = (props) => {
  const companyCode = JSON.parse(localStorage.getItem("companyCode"));
  const inputSearchValue = JSON.parse(localStorage.getItem("inputSearchValue"));
  
  const [isChangeOrder, setIsChangeOrder] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);
  const [isOrderAlter, setIsOrderAlter] = useState(false);

  const [popupShow, setPopupShow] = useState(false);
  const handlePopupClose = () =>{setPopupShow(false);setIsOrderAlter(false)};
  const changeOrderRequest = useSelector((state) => state.changeOrderRequest);
  const handlePopupShow = (e, index) => {
    e.preventDefault();
    setPopupShow(true);
    
  };
  const [submitted, setSubmitted] = useState("AsicDetails");

  const companyProfile = useSelector((state) => state.companyProfile);
  const loggedUserInfo = useSelector((state) => state.loggedUserInfo);
  const classListInfo = useSelector((state) => state.classListInfo);
  
  const dispatch = useDispatch();


  

  const changedCompanyName =
    companyProfile &&
    companyProfile.items &&
    companyProfile.items.company.CO_NAME;
  const changedACN =
    companyProfile && companyProfile.items && companyProfile.items.company.ACN;
  const changedLocation =
    companyProfile &&
    companyProfile.items &&
    companyProfile.items.company.AGENT_ID;
  const [inputList, setInputList] = useState([
    {
      shareissueShareholderName: "",
      // by default this bellow to fields are true
      shareIssueFullyPaid: true,
      shareIssueBeneficiallyOwned: true,
      shareIssueBenName: "",
      shareholdersFullAddress: "",
      shareIssueClasscode: "",
      shareIssueAllotmentDate: new Date(),
      shareIssueTotalShares: "",
      shareIssuePaidPerShare: "",
      shareIssueTotalPaid: "",
      shareIssueUnpaidPerShare: "",
      shareIssueTotalUnpaid: "",
    },
  ]);


  const [shareCancellationInputList, setShareCancellationInputList] = useState([
    {
      // Share Cancellation(s)

      shareCancelDate: new Date(),
      shareCancelShareHolderName: "",
      shareCancellationFullyPaid: true,
      shareCancellationBeneficiallyOwned: true,
      shareCancellationBenName: "",
      shareCancellationClassCode: "",
      provisionDesc: "",
      provision: "",
      desc: "",
      shareCancellationTotalShares: "",
      shareCancellationPaidBy: "",
      shareCancellationPaidPerShare: "",
      shareCancellationTotalPaid: "",
      shareCancellationUnpaidPerShare: "",
      shareCancellationTotalUnpaid: "",
    },
  ]);

  const [shareTransferInputList, setShareTransferInputList] = useState([
    {
      transferFrom: "",
      transferTo: "",
      shareTransferclassCode: "",
      shareTrasferTotalShares: "",
      shareTransferPaidPerShare: "",
      shareTransferTotalPaid: "",
      shareTransferUnpaidPerShare: "",
      shareTransferTotalUnpaid: "",
      shareTransferTransferDate: new Date(),
      shareTransferAddress: "",
      transferFromFullyPaid: false,
      transferFromBeneficiallyOwned: false,
      //while loading itself bellow 2 condition is true in that form
      transfertoBeneficiallyOwned: true,
      transfertoFullyPaid: true,
    },
  ]);
  const [osChangeofAddressInputlist, setOSChangeofAddressInputlist] = useState([
    {
      ocaChangeDate: new Date(),
      officeholdersChangeFullAddress: "",
      changeFor: "",
      officeHolderName: "",
    },
  ]);

  const [osNameChangeInputlist, setOSNameChangeInputlist] = useState([
    {
      ohChangeDate: new Date(),
      changeForNameChange: "",
      fromName: "",
      toName: "",
    },
  ]);
  const [appointmentInputlist, setAppointmentInputlist] = useState([
    {
      appointedOfficerName: "",
      officerAddress: "",
      position1: "",
      position1ChangeDate: new Date(),
      position2: "",
      position2ChangeDate: new Date(),
      position3: "",
      position3ChangeDate: new Date(),
      alternateDirectorFor: "",
      dOB:"",
      placeOfBirth: "",
      stateofBrith: "",
      countryOfBirth: "Australia",
    },
  ]);
  const [resignInputlist, setResignInputlist] = useState([
    {
      resignationDate: new Date(),
      resignOfficerName: "",
      resignReason: "",
      ceasedDirector: false,
      ceasedsecretary: false,
      ceasedAltDirector: false,
      ceasedPublicOfficer: false,
    },
  ]);

  const [changeOrder, setChangeOrder] = useState({
    loginName:
      loggedUserInfo && loggedUserInfo.items && loggedUserInfo.items.FullName,
    loginEmail:
      loggedUserInfo && loggedUserInfo.items && loggedUserInfo.items.EmailID,
    additionalCC: "",
    companyName: changedCompanyName,
    location: "",
    companyAcn: "",
    //OrderSelection
    orderSelection: submitted,

    //Request Change to Address(es)

    requestChangeAddress: false,

    // Registered Office Address

    ROAddress: false,
    raChangeDate: new Date(),
    registeredFullAddress: "",
    companyOccupiesPremises: true,
    occupierCompanyName: "",

    //Principal Business Address

    businessAddress: false,
    baChangeDate: new Date(),
    businessFullAddress: "",

    //Officeholder(s)/Shareholder(s) - Change of Address

    changeOfAddress: false,
    osChangeofAddressInputlist: osChangeofAddressInputlist,

    //Request Change to Name(s)
    requestChangeNames: false,
    // Company Name Change
    companyNameChange: false,
    cnChangeDate: new Date(),
    companyNameChangeTo: "",
    // Officer Shareholders Name change
    officerNameChange: false,
    osNameChangeInputlist: osNameChangeInputlist,

    //Change to officeholder(s)
    changeOfficeholder: false,
    //Officeholder(s) - Appointment
    OfficeholderAppointment: false,
    appointmentInputlist: appointmentInputlist,

    //Officeholder(s) - Resignations
    OfficeholderResignations: false,
    resignInputlist: resignInputlist,

    // Request change to shareholdings
    changeToShareholdings: false,

    // Share Issue(s)
    shareIssue: false,
    inputList: inputList,

    // Share Cancellation(s)
    shareCancellation: false,
    shareCancellationInputList: shareCancellationInputList,

    //Share Transfer(s)

    shareTransfer: false,
    shareTransferInputList: shareTransferInputList,

    //Return of Capital

    returnCapital: false,
    returnCapitalInfo: "",

    //Additional Info
    additionalInfo: false,
    signerName: [],
    additionalMoreInfo: "",
    fileList: [],
    dataFileList:[],
    //   Dividend
    dividendClassCode: "",
    classCodeDetails: "",
    classCodeNo: "",
    booksCloseDate: "",
    financialYearEndDate: "",
    interimDividend: false,
    shareholdersResolutions: false,
    shortMeetingNotice: false,
    finalDividend: false,
    inputFrankedDollar: false,
    dividendPerShare: "",
    totalDividend: "",
    frankedAmountPercentage: "",
    unFrankedAmountPercentage: "",
    companyTaxRate: "",
    frankedAmount: "",
    unFrankedAmount: "",
  });

  let handleChange = (e, isDate, name) => {
    if (isDate) {
      setChangeOrder((changeOrder) => ({
        ...changeOrder,
        [name]: e,
      }));
    } else {
      setChangeOrder((changeOrder) => ({
        ...changeOrder,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      }));
    }
  };

  let handleComponentChange = (name, value) => {
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      [name]: value,
    }));
  };

  let handleClick = (e) => {
    console.log("submittedvalueradio",e.target.value)
    setSubmitted(
      e.target.type === "checkbox" ? e.target.checked : e.target.value
    );
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      orderSelection: e.target.value,
    }));
    handleReset();
   
  };
  let handleChangeOrder = (e) => {
  
 
    //Hema
    if(e.target.value ==="AsicAdd")
    {
      console.log("submittedsubmitted",e.target.value)
      setChangeOrder((changeOrder) => ({
        ...changeOrder,
        companyName: "",
        location: "",
        companyAcn: "",
      }));
console.log("submitted change order",changeOrder)
    }
  };

  let handleReset = () => {
    setInputList([
      {
        shareissueShareholderName: "",
        // by default this bellow to fields are true
        shareIssueFullyPaid: true,
        shareIssueBeneficiallyOwned: true,
        shareIssueBenName: "",
        shareholdersFullAddress: "",
        shareIssueClasscode: "",
        shareIssueAllotmentDate: new Date(),
        shareIssueTotalShares: "",
        shareIssuePaidPerShare: "",
        shareIssueTotalPaid: "",
        shareIssueUnpaidPerShare: "",
        shareIssueTotalUnpaid: "",
      },
    ])
  
  
    setShareCancellationInputList([
      {
        // Share Cancellation(s)
  
        shareCancelDate: new Date(),
        shareCancelShareHolderName: "",
        shareCancellationFullyPaid: true,
        shareCancellationBeneficiallyOwned: true,
        shareCancellationBenName: "",
        shareCancellationClassCode: "",
        provisionDesc: "",
        provision: "",
        desc: "",
        shareCancellationTotalShares: "",
        shareCancellationPaidBy: "",
        shareCancellationPaidPerShare: "",
        shareCancellationTotalPaid: "",
        shareCancellationUnpaidPerShare: "",
        shareCancellationTotalUnpaid: "",
      },
    ])
  
    setShareTransferInputList([
      {
        transferFrom: "",
        transferTo: "",
        shareTransferclassCode: "",
        shareTrasferTotalShares: "",
        shareTransferPaidPerShare: "",
        shareTransferTotalPaid: "",
        shareTransferUnpaidPerShare: "",
        shareTransferTotalUnpaid: "",
        shareTransferTransferDate: new Date(),
        shareTransferAddress: "",
        transferFromFullyPaid: false,
        transferFromBeneficiallyOwned: false,
        //while loading itself bellow 2 condition is true in that form
        transfertoBeneficiallyOwned: true,
        transfertoFullyPaid: true,
      },
    ])
    setOSNameChangeInputlist([
      
      {
        ocaChangeDate: new Date(),
        officeholdersChangeFullAddress: "",
        changeFor: "",
        officeHolderName: "",
      },
    ])
    setOSChangeofAddressInputlist([
      {
        ocaChangeDate: new Date(),
        officeholdersChangeFullAddress: "",
        changeFor: "",
        officeHolderName: "",
      },
    ])
  

    setAppointmentInputlist([
      {
        appointedOfficerName: "",
        officerAddress: "",
        position1: "",
        position1ChangeDate: new Date(),
        position2: "",
        position2ChangeDate: new Date(),
        position3: "",
        position3ChangeDate: new Date(),
        alternateDirectorFor: "",
        dOB: "",
        placeOfBirth: "",
        stateofBrith: "",
        countryOfBirth: "",
      },
    ])
    setResignInputlist([
      {
        resignationDate: new Date(),
        resignOfficerName: "",
        resignReason: "",
        ceasedDirector: false,
        ceasedsecretary: false,
        ceasedAltDirector: false,
        ceasedPublicOfficer: false,
      },
    ])
  
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      inputList: [],
    }));
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      shareCancellationInputList: [],
    }));
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      shareTransferInputList: [],
    }));
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      resignInputlist: [],
    }));
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      appointmentInputlist: [],
    }));
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      osNameChangeInputlist: [],
    }));
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      osChangeofAddressInputlist: [],
    }));
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      ROAddress: false,
      registeredFullAddress: "",
      companyOccupiesPremises: "",
      occupierCompanyName: "",
    }));
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      businessAddress: false,
      businessFullAddress: "",
    }));
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      companyNameChange: false,
      companyNameChangeTo: "",
    }));
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      returnCapital: false,
      returnCapitalInfo: "",
    }));
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      dividendClassCode: "",
    }));
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      additionalInfo: false,
      signerName: "",
      additionalMoreInfo: "",
      fileList: [],
    }));
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      //   Dividend
      dividendClassCode: "",
      classCodeDetails: "",
      classCodeNo: "",
      booksCloseDate: "",
      financialYearEndDate: "",
      interimDividend: false,
      shareholdersResolutions: false,
      shortMeetingNotice: false,
      finalDividend: false,
      inputFrankedDollar: false,
      dividendPerShare: "",
      totalDividend: "",
      frankedAmountPercentage: "",
      unFrankedAmountPercentage: "",
      companyTaxRate: "",
      frankedAmount: "",
      unFrankedAmount: "",
    }));
     
  }
  //const validate = companyCode !== null || (inputSearchValue !== null && inputSearchValue.length > 0 ) || (props.searchCompany  && props.searchCompany.length > 0 )
  const validate = companyCode !== null || (submitted === "AsicDetails"  ? inputSearchValue !== null && inputSearchValue.length > 0  &&
    ( changeOrder.requestChangeAddress || changeOrder.businessAddress ) : true) || 
    ( submitted === "AsicDetails" ? props.searchCompany  && props.searchCompany.length > 0  &&
      ( changeOrder.requestChangeAddress || changeOrder.requestChangeNames ||
    changeOrder.changeOfficeholder || changeOrder.changeToShareholdings)  :  true )

    const btnValidate = companyCode !== null || (submitted === "AsicDetails"  ? inputSearchValue !== null && inputSearchValue.length > 0 && changeOrder.additionalInfo &&
    ( changeOrder.requestChangeAddress || changeOrder.businessAddress ) : submitted !== "statementOrder") || 
    ( submitted === "AsicDetails" ? props.searchCompany  && props.searchCompany.length > 0  && changeOrder.additionalInfo &&
      ( changeOrder.requestChangeAddress || changeOrder.requestChangeNames ||
    changeOrder.changeOfficeholder || changeOrder.changeToShareholdings)  :  submitted !== "statementOrder" )
 //const emptyCheck= changeOrder.changeToShareholdings|| changeOrder.changeOfficeholder||changeOrder.requestChangeNames||changeOrder.requestChangeAddress
 //const validate = companyCode !== null || (inputSearchValue !== null && inputSearchValue.length > 0 && emptyCheck) || (props.searchCompany  && props.searchCompany.length > 0 && emptyCheck)

  useEffect(() => {
    dispatch(userActions.getLoggedUserInfo());
    // eslint-disable-next-line
  }, []);
  let handleSummarylPopupClick = (e) => {
    dispatch(userActions.postChangeOrderRequest(changeOrder, setIsChangeOrder, setIsSuccess));
  };

  useEffect(() => {
      setIsOrderAlter( changeOrderRequest && changeOrderRequest.items && changeOrderRequest.items.Desc !== "")
      if (isSuccess) {
        setIsOrderSubmitted(true);
        handlePopupClose();
      }
    // eslint-disable-next-line
  }, [isChangeOrder, isSuccess]);

  // handle click event of the Add button
  const handleAddClick = (e) => {
    setInputList([
      ...inputList,
      {shareIssueFullyPaid: true,
        shareIssueBeneficiallyOwned: true,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    ]);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      inputList: [
        ...inputList,
        {
          shareIssueFullyPaid: true,
        shareIssueBeneficiallyOwned: true,
          [e.target.name]:
            e.target.type === "checkbox" ? e.target.checked : e.target.value,
        },
      ],
    }));
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      inputList: list,
    }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = e.target.type === "checkbox" ? e.target.checked : value;
    setInputList(list);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      inputList: list,
    }));
  };

  // handle click event of the shareCancellation Add button
  const handleAddShareCancellationClick = (e) => {
    setShareCancellationInputList([
      ...shareCancellationInputList,
      {shareCancellationFullyPaid: [true],
        shareCancellationBeneficiallyOwned: [true],
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    ]);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      shareCancellationInputList: [
        ...shareCancellationInputList,
        {
            shareCancellationFullyPaid: [true],
            shareCancellationBeneficiallyOwned: [true],
          [e.target.name]:
            e.target.type === "checkbox" ? e.target.checked : e.target.value,
        },
      ],
    }));
  };

  const handleRemoveShareCancellationClick = (index) => {
    const list = [...shareCancellationInputList];
    list.splice(index, 1);
    setShareCancellationInputList(list);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      shareCancellationInputList: list,
    }));
  };

  const handleInputChangeShareCancellation = (e, index) => {
    const { name, value } = e.target;
    const list = [...shareCancellationInputList];
    list[index][name] = e.target.type === "checkbox" ? e.target.checked : value;
    setShareCancellationInputList(list);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      shareCancellationInputList: list,
    }));
  };

  // handle click event of the shareCancellation Add button
  const handleAddShareTransferClick = (e) => {
    setShareTransferInputList([
      ...shareTransferInputList,
      {  transfertoBeneficiallyOwned: true,
        transfertoFullyPaid: true,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    ]);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      shareTransferInputList: [
        ...shareTransferInputList,
        {
          transfertoBeneficiallyOwned: true,
        transfertoFullyPaid: true,
          [e.target.name]:
            e.target.type === "checkbox" ? e.target.checked : e.target.value,
        },
      ],
    }));
  };

  const handleRemoveShareTransferClick = (index) => {
    const list = [...shareTransferInputList];
    list.splice(index, 1);
    setShareTransferInputList(list);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      shareTransferInputList: list,
    }));
  };

  const handleInputChangeShareTransfer = (e, index) => {
    const { name, value } = e.target;
    const list = [...shareTransferInputList];
    list[index][name] = e.target.type === "checkbox" ? e.target.checked : value;
    setShareTransferInputList(list);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      shareTransferInputList: list,
    }));
  };

  // handle click event of the Officeholder(s)/Shareholder(s) - Change of Address Add button
  const handleChangeofAddressAddClick = (e) => {
    setOSChangeofAddressInputlist([
      ...osChangeofAddressInputlist,
      {
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    ]);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      osChangeofAddressInputlist: [
        ...osChangeofAddressInputlist,
        {
          [e.target.name]:
            e.target.type === "checkbox" ? e.target.checked : e.target.value,
        },
      ],
    }));
  };

  const handleChangeofAddressRemoveClick = (index) => {
    const list = [...osChangeofAddressInputlist];
    list.splice(index, 1);
    setOSChangeofAddressInputlist(list);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      osChangeofAddressInputlist: list,
    }));
  };

  const handleChangeofAddressInputChange = (e, index) => {
    if (e.target.name) {
      const { name, value } = e.target;
      const list = [...osChangeofAddressInputlist];
      list[index][name] =
        e.target.type === "checkbox" ? e.target.checked : value;
      setOSChangeofAddressInputlist(list);
      setChangeOrder((changeOrder) => ({
        ...changeOrder,
        osChangeofAddressInputlist: list,
      }));
    }
  };

  // handle click event of the .Officer Shareholders Name change  Add button
  const handleNameChangeAddClick = (e) => {
    setOSNameChangeInputlist([
      ...osNameChangeInputlist,
      {
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    ]);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      osNameChangeInputlist: [
        ...osNameChangeInputlist,
        {
          [e.target.name]:
            e.target.type === "checkbox" ? e.target.checked : e.target.value,
        },
      ],
    }));
  };

  const handleNameChangeRemoveClick = (index) => {
    const list = [...osNameChangeInputlist];
    list.splice(index, 1);
    setOSNameChangeInputlist(list);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      osNameChangeInputlist: list,
    }));
  };

  const handleNameChangeChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...osNameChangeInputlist];
    list[index][name] = e.target.type === "checkbox" ? e.target.checked : value;
    setOSNameChangeInputlist(list);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      osNameChangeInputlist: list,
    }));
  };

  // handle click event of the .fficeholder(s) - Resignations  Add button
  const handleResignAddClick = (e) => {
    setResignInputlist([
      ...resignInputlist,
      {
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    ]);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      resignInputlist: [
        ...resignInputlist,
        {
          [e.target.name]:
            e.target.type === "checkbox" ? e.target.checked : e.target.value,
        },
      ],
    }));
  };

  const handlResignRemoveClick = (index) => {
    const list = [...resignInputlist];
    list.splice(index, 1);
    setResignInputlist(list);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      resignInputlist: list,
    }));
  };

  const handleResignInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...resignInputlist];
    list[index][name] = e.target.type === "checkbox" ? e.target.checked : value;
    setResignInputlist(list);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      resignInputlist: list,
    }));
  };

  // handle click event of the .Officeholder(s) - Appointment  Add button
  const handleAppointmentAddClick = (e) => {
    setAppointmentInputlist([
      ...appointmentInputlist,
      {
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    ]);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      appointmentInputlist: [
        ...appointmentInputlist,
        {
          [e.target.name]:
            e.target.type === "checkbox" ? e.target.checked : e.target.value,
        },
      ],
    }));
  };

  const handlAppointmentRemoveClick = (index) => {
    const list = [...appointmentInputlist];
    list.splice(index, 1);
    setAppointmentInputlist(list);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      appointmentInputlist: list,
    }));
  };

  const handleAppointmentInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...appointmentInputlist];
    list[index][name] = e.target.type === "checkbox" ? e.target.checked : value;
    setAppointmentInputlist(list);
    setChangeOrder((changeOrder) => ({
      ...changeOrder,
      appointmentInputlist: list,
    }));
  };

  useEffect(() => {
    handleComponentChange("companyName", changedCompanyName);
    handleComponentChange("companyAcn", changedACN);
    handleComponentChange("location", changedLocation);
    // eslint-disable-next-line
  }, [changedCompanyName, changedACN, changedLocation]);

  return (
    <div className="py-3">
      {!isOrderSubmitted ? (
        <div>
          {console.log("orderdetails", changeOrder)}
          <h6 className="panel-title fw-bold">
            Order By Details           </h6>
          <hr />

          <form className="row g-3 fs-6  fw-normal ">
            <div className="col-md-6 px-4">
              <label for="loginName" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="loginName"
                name="loginName"
                value={changeOrder.loginName}
              />
            </div>
            <div className="col-md-6">
              <label for="loginEmail" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control "
                id="loginEmail"
                name="loginEmail"
                value={changeOrder.loginEmail}
              />
            </div>
            <div className="col-md-12 px-4">
              <label
                for="additionalCC"
                className="form-label"
                placeholder="Type Email Address and hit enter"
              >
                Additional CC to
              </label>
              <input
                type="text"
                className="form-control"
                id="additionalCC"
                name="additionalCC"
                value={changeOrder.additionalCC}
                onChange={(e) => handleChange(e, false, "")}
                autoComplete="off"
              />
            </div>

            <div className="col-12">
              <h6 className="panel-title">Order Selection</h6>
              <hr />
              <div className="form-check">
                <label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="orderSelection"
                    value="AsicDetails"
                    checked={submitted === "AsicDetails"}
                    onChange={(e) => {handleClick(e);handleChangeOrder(e)}}
                   
                  />
                  ASIC Agent Listed: Request update(s) to ASIC details
                </label>
              </div>
              <div className="form-check">
                <label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="orderSelection"
                    value="statementOrder"
                    checked={submitted === "statementOrder"}
                    onChange={(e) => {handleClick(e);handleChangeOrder(e)}}
                  />
                  Dividend Statement Order
                </label>
              </div>
              <div className="form-check">
                <label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="orderSelection"
                    value="AsicAdd"
                    checked={submitted === "AsicAdd"}
                    onChange={(e) => {handleClick(e);handleChangeOrder(e)}}
                  />
                  Request for Entity to be added to the ASIC Agent List
                </label>
              </div>
              <div className="form-check">
                <label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="orderSelection"
                    value="AsicRemove"
                    checked={submitted === "AsicRemove"}
                    onChange={(e) =>  {handleClick(e);handleChangeOrder(e)}}
                  />
                  Request for Entity to be removed the ASIC Agent List
                </label>
              </div>
            </div>
            {companyProfile && companyProfile.items && (
              <h6 className="panel-title">Company Selection</h6>
            )}

            {companyProfile && companyProfile.items && <hr />}
            {companyProfile && companyProfile.items && (
              <div className="col-md-6 px-4">
                <label for="inputCompanyName4" className="form-label">
                  Company Name
                </label>
                {submitted === "AsicAdd" ?
                <div>
                <input
                type="text"
                className=
                {
                  ! changeOrder.companyName ||
                  (changeOrder.companyName === "")
                    ? "form-control is-invalid"
                    : "form-control"
                }   
                id="companyName"
                name="companyName"
                value={changeOrder.companyName}
                onChange={(e) => handleChange(e, false, "")}
                 disabled={submitted=== "AsicAdd" ?  false : true}
                autoComplete="off"
              /> 
              {( changeOrder.companyName === "") && (
                
                <div className="text-danger py-3 px-3">
               
                Company Name Required
                </div>
              )}
                </div>
              : 
              <input
                  type="text"
                  className="form-control"
                  id="companyName"
                  name="companyName"
                  placeholder="Company Name"
                  value={changedCompanyName}
                  //value={changeOrder.companyName}
                  disabled={submitted=== "AsicAdd" ?  false : true}
                  onFocus={(e) => handleComponentChange(changedCompanyName)}
                />
            }
              </div>
            )}
            {companyProfile && companyProfile.items && (
              <div className="col-md-6">
                <label for="location" name="location" className="form-label">
                  Office Location / Department
                </label>
                {submitted === "AsicAdd" ?
                <input
                type="text"
                className="form-control"
                id="location"
                name="location"
                value={changeOrder.location}
                onChange={(e) => handleChange(e, false, "")}
                 disabled={submitted=== "AsicAdd" ?  false : true}
                autoComplete="off"
              />:
              <input
                  type="text"
                  className="form-control"
                  id="location"
                  name="location"
                  value={companyProfile.items.company.AGENT_ID}
                  disabled={submitted=== "AsicAdd" ?  false : true}
                />
             }
              </div>
            )}
            {companyProfile && companyProfile.items && (
              <div className="col-md-6 px-4">
                <label for="ACN" Name="companyAcn" className="form-label">
                  Company ACN
                </label>
                {submitted === "AsicAdd" ?
                <div>
                <input
                type="text"
                className=
                {
                  ! changeOrder.companyAcn ||
                  (changeOrder.companyAcn === "")
                    ? "form-control is-invalid"
                    : "form-control"
                }                
                
                id="companyAcn"
                name="companyAcn"
                value={changeOrder.companyAcn}
                onChange={(e) => handleChange(e, false, "")}
                 disabled={submitted=== "AsicAdd" ?  false : true}
                autoComplete="off"
              />
              {( changeOrder.companyAcn === "") && (
                
                <div className="text-danger py-3 px-3">
               
                Company ACN Required
                </div>
              )}
                </div>
                
               :  <input
              type="text"
              className="form-control"
              id="companyAcn"
              Name="companyAcn"
              value={companyProfile.items.company.ACN}
              disabled={submitted=== "AsicAdd" ?  false : true}
            />
          }
              </div>
            )}
            {companyProfile && companyProfile.items && (
              <div className="col-12">
                <h6 className="panel-title">Order Details</h6>
                <hr />
                {submitted === "AsicDetails" ? (
                  <ASICDetailsUpdateComponent
                    handleChange={handleChange}
                    asicDetails={changeOrder}
                    setAsicDetails={setChangeOrder}
                    companyProfile={companyProfile}
                    //passing multiple add information to share issue
                    handleAddClick={handleAddClick}
                    inputList={inputList}
                    handleRemoveClick={handleRemoveClick}
                    handleInputChange={handleInputChange}
                    setInputList={setInputList}
                    classListInfo={classListInfo}
                    //passing multiple add information to share Cancellation
                    shareCancellationInputList={shareCancellationInputList}
                    setShareCancellationInputList={
                      setShareCancellationInputList
                    }
                    handleAddShareCancellationClick={
                      handleAddShareCancellationClick
                    }
                    handleRemoveShareCancellationClick={
                      handleRemoveShareCancellationClick
                    }
                    handleInputChangeShareCancellation={
                      handleInputChangeShareCancellation
                    }
                    //passing Multiple add information to Share transfer
                    shareTransferInputList={shareTransferInputList}
                    setShareTransferInputList={setShareTransferInputList}
                    handleAddShareTransferClick={handleAddShareTransferClick}
                    handleRemoveShareTransferClick={
                      handleRemoveShareTransferClick
                    }
                    handleInputChangeShareTransfer={
                      handleInputChangeShareTransfer
                    }
                    // Passing multiple add information to Officeholder(s)/Shareholder(s) - Change of Address
                    osChangeofAddressInputlist={osChangeofAddressInputlist}
                    setOSChangeofAddressInputlist={
                      setOSChangeofAddressInputlist
                    }
                    handleChangeofAddressAddClick={
                      handleChangeofAddressAddClick
                    }
                    handleChangeofAddressRemoveClick={
                      handleChangeofAddressRemoveClick
                    }
                    handleChangeofAddressInputChange={
                      handleChangeofAddressInputChange
                    }
                    // Passing multiple add information to .Officer Shareholders Name change
                    osNameChangeInputlist={osNameChangeInputlist}
                    setOSNameChangeInputlist={setOSNameChangeInputlist}
                    handleNameChangeAddClick={handleNameChangeAddClick}
                    handleNameChangeRemoveClick={handleNameChangeRemoveClick}
                    handleNameChangeChange={handleNameChangeChange}
                    // Passing multiple add information to Officeholder(s) - Resignations
                    resignInputlist={resignInputlist}
                    setResignInputlist={setResignInputlist}
                    handleResignAddClick={handleResignAddClick}
                    handlResignRemoveClick={handlResignRemoveClick}
                    handleResignInputChange={handleResignInputChange}
                    // Passing multiple add information to Officeholder(s) - Appointment
                    appointmentInputlist={appointmentInputlist}
                    setAppointmentInputlist={setAppointmentInputlist}
                    handleAppointmentAddClick={handleAppointmentAddClick}
                    handlAppointmentRemoveClick={handlAppointmentRemoveClick}
                    handleAppointmentInputChange={handleAppointmentInputChange}
                    validate={validate}
                    searchCompany={props.searchCompany}
                    isCardHeader={true}
                  />
                ) : submitted === "statementOrder" ? (
                  <DividendStatementOrderComponent
                    handleChange={handleChange}
                    asicDetails={changeOrder}
                    setAsicDetails={setChangeOrder}
                    companyProfile={companyProfile}
                  />
                ) : submitted === "AsicAdd" ? (
                  <AddOrRemoveAsicAgentListComponent
                    handleChange={handleChange}
                    asicDetails={changeOrder}
                    setAsicDetails={setChangeOrder}
                    isCardHeader={true}
                    companyProfile={companyProfile}
                  />
                ) : submitted === "AsicRemove" ? (
                  <RemoveAsicAgentListComponent
                    handleChange={handleChange}
                    asicDetails={changeOrder}
                    setAsicDetails={setChangeOrder}
                    isCardHeader={true}
                    companyProfile={companyProfile}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
            <div className="col-12 py-3">
            <table><tr><td><button
            type="button"
            className={
              submitted && !validate ? "btn btn-secondary buttonCommon" : "buttonCommon fs-6"
            }
            onClick={(e) =>  submitted === "statementOrder" ? submitted && validate && handlePopupShow(e) :  submitted && validate && changeOrder.additionalInfo &&  changeOrder.signerName && changeOrder.signerName.length > 0 && changeOrder.signerName[0] !== "" && changeOrder.companyAcn && changeOrder.companyName && handlePopupShow(e)}
            disabled={  submitted === "statementOrder" ? !validate   :  !validate && !changeOrder.additionalInfo && (!changeOrder.signerName ||  (changeOrder.signerName && changeOrder.signerName[0] === "" && !changeOrder.companyAcn && !changeOrder.companyName) ) }
          >
            Submit
          </button>
          {(changeOrder.signerName &&  changeOrder.signerName[0] === "" && changeOrder.additionalInfo ) && (
            
            <div className="text-danger py-3 px-3">
          
       Additional Information SignerName required
            </div>
          )}
          {console.log("------" + props.searchCompany.length)}
          {console.log("------" + submitted && validate && !changeOrder.additionalInfo)}
        </td>  {props.searchCompany  && props.searchCompany.length === 0 && submitted !== "AsicAdd"  ?<div className="text-danger py-3 px-3">
        {"Company Name not selected! Please select the Company Name to proceed"}
        </div> : submitted && !validate  ?  (
          <div className="text-danger py-3 px-3">
          {"Please select order details to proceed"}
          </div>) :  submitted !== "statementOrder"  && !changeOrder.additionalInfo && (
            <div className="text-danger py-3 px-3">
               Additional info required to process the order.
            </div>
          )
        }
      </tr></table>
              
              {popupShow && (
                <Popup
                  show={popupShow}
                  handleClose={handlePopupClose}
                  handleShow={(e) => handlePopupShow(e)}
                  body={
                    <OrderSummary
                      changeOrder={changeOrder}
                      submitted={submitted}
                      companyName={
                        companyProfile &&
                        companyProfile.items &&
                        companyProfile.items.company &&
                        companyProfile.items.company.CO_NAME
                      }
                      companyAcn={
                        companyProfile &&
                        companyProfile.items &&
                        companyProfile.items.company &&
                        companyProfile.items.company.ACN
                      }
                      changeOrderRequest={changeOrderRequest}
                      isOrderAlter={isOrderAlter}
                      setIsOrderAlter={setIsOrderAlter}
                    />
                  }
                  btnName="Save"
                  handleClick={(e) => handleSummarylPopupClick(e)}
                  isPopupReportLoading={isChangeOrder}
                />
              )}
            </div>
          </form>
        </div>
      ) : (
        <ChangeOrderStatusComponent
          history={props.history}
          setIsOrderSubmitted={setIsOrderSubmitted}
          handleReset= {handleReset}
          changeOrderRequest={changeOrderRequest}
          isOrderAlter={isOrderAlter}
        />
      )}
    </div>
  );
};
export default withRouter(EOrderOrderRequestComponent);
