import React, { useCallback } from "react";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { Typeahead } from "react-bootstrap-typeahead";
 


const ShareCancellation = (props) => {

  const handleSearch = useCallback((call) => {
      // eslint-disable-next-line
    }, []);
  return (
    <div className="container ">
      <form>
        <hr />
        <div className="form-group row px-4 ">
          <div>
            <label
              htmlFor="shareCancelDate"
              className="col-form-label input-sm "
            >
              Cancel Date{" "}
              <i
                className="bi bi-info-circle px-1"
                data-toggle="tooltip"
                data-placement="top"
                title="ASIC late fees apply if form is not lodgged with in 28days of change date"
              ></i>
            </label>
            <DatePicker
              className="form-control input-sm w-25"
              name="shareCancelDate"
              selected={props.shareCancelElements.shareCancelDate}
              onChange={(e) =>{
                const list = [...props.shareCancellationInputList];
                list[props.shareCancelIndex]["shareCancelDate"] = e;
                props.setShareCancellationInputList(list);
                props.setAsicDetails((changeOrder) => ({
                  ...changeOrder,
                  shareCancellationInputList:list
                }));
              }
              }

              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              value={props.shareCancelElements.shareCancelDate}
            />
          </div>
          <div className="col-xs-3">
            <label for="shareCancelShareHolderName" className="form-label">
              Shareholder Name
              <i
                className="bi bi-info-circle px-1"
                data-toggle="tooltip"
                data-placement="top"
                title="Full legal Name as per Passport"
              ></i>
            </label>
          
            <div className="col-sm-12 col-lg-7 TypeAheadDropDown  w-100 ">
           
            <Typeahead
                className="w-100"
                  id="shareCancelShareHolderName"
                  labelKey={(option) =>
                    `${option.NAME}:  ${option.CLASS_CODE}:  ${option.CLASS_NO}:  FP=${option.FULLY_PAID ? "Yes" : "no"}:  BO=${option.BEN_OWNER ? "Yes" : "no"}:  Total Shares=${option.SUM_TOT_SHARES}`
                  }
                
                  options={
                    props.companyProfile &&
                    props.companyProfile.items &&
                    props.companyProfile.items.shareholders ? props.companyProfile.items.shareholders: []
                  }
                  isLoading={false}
                  onSearch={handleSearch}
                  onChange={(selected) => {
                    const shareCancellationValue = selected.map(item=> item.SUM_TOT_SHARES );
                    const SClassCode=selected.map(item=> item.CLASS_CODE );
                    const SPaidPershare=selected.map(item=> item.AMOUNT_PAID );
                    const STotalPaid=selected.map(item=> item.SUM_TRAN_PRICE );
                    /*props.setAsicDetails((value) => ({ ...value, shareCancelShareHolderName: selected,
                      shareCancellationFullyPaid:selected.map(item=> item.FULLY_PAID === "true" ? true:false), 
                      shareCancellationTotalShares: shareCancellationValue[0],
                      shareCancellationClassCode: SClassCode[0],
                      shareCancellationPaidPerShare:SPaidPershare[0],
                      shareCancellationTotalPaid:STotalPaid[0],
                    }));*/
                   
                    const list = [...props.shareCancellationInputList];
                    list[props.shareCancelIndex]["shareCancelShareHolderName"] = selected;
                    console.log("sharetransferselectedvalues", selected &&
                    selected[0])

                        list[props.shareCancelIndex]["shareCancellationFullyPaid"] =  selected.map(item=> item.FULLY_PAID ? true:false)
                        list[props.shareCancelIndex]["shareCancellationBeneficiallyOwned"] =  selected.map(item=> item.BEN_OWNER ? true:false)
                        console.log("cancellation",list[props.shareCancelIndex]["shareCancellationFullyPaid"])
                        list[props.shareCancelIndex]["shareCancellationTotalShares"] =  shareCancellationValue[0]
                        list[props.shareCancelIndex]["shareCancellationClassCode"] =   SClassCode[0]
                        list[props.shareCancelIndex]["shareCancellationPaidPerShare"] =  SPaidPershare[0]
                        list[props.shareCancelIndex]["shareCancellationTotalPaid"] = STotalPaid[0]
                       
                    
                    
                    props.setShareCancellationInputList(list);
                    props.setAsicDetails((changeOrder) => ({
                      ...changeOrder,
                      shareCancellationInputList:list
                    }));
                  }}
                  selected={props.shareCancelElements.shareCancelShareHolderName}
                  placeholder="Enter the ShareHolder Name to Search"
                  useCache={false}
                />     
       
      </div>


            <span className="text-secondary">
              For Joint holder, combine with 'AND' operator
            </span>
          </div>
          <div className="row py-2">
            <div className="col">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="shareCancellationFullyPaid"
                  name="shareCancellationFullyPaid"
                  onChange={(e) => {
                   
                    props.handleInputChangeShareCancellation(e,props.shareCancelIndex,true);
                  
                   }}
                   
                  checked={props.shareCancelElements.shareCancellationFullyPaid && props.shareCancelElements.shareCancellationFullyPaid[0]  }
                  value={props.shareCancelElements.shareCancellationFullyPaid  }
                />
              
                <label
                  className="form-check-label"
                  for="shareCancellationFullyPaid"
                >
                  Fully Paid
                </label>
              </div>
            </div>
            <div className="col">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="shareCancellationBeneficiallyOwned"
                  name="shareCancellationBeneficiallyOwned"
                  onChange={(e) => {
                   
                    props.handleInputChangeShareCancellation(e,props.shareCancelIndex,true);
                  
                   }}
                  checked={props.shareCancelElements.shareCancellationBeneficiallyOwned && props.shareCancelElements.shareCancellationBeneficiallyOwned[0] }
                  value={props.shareCancelElements.shareCancellationBeneficiallyOwned}
                />
                <label
                  className="form-check-label"
                  for="shareCancellationBeneficiallyOwned"
                >
                  Beneficially Owned
                </label>
              </div>
            </div>
          </div>
          {!props.shareCancelElements.shareCancellationBeneficiallyOwned && (
            <div className="col-xs-3">
              <label for="shareCancellationBenName" className="form-label">
                BEN NAME
              </label>
              <input
                type="text"
                className="form-control"
                id="shareCancellationBenName"
                name="shareCancellationBenName"
                value={props.shareCancelElements.shareCancellationBenName}
                onChange={(e) =>props.handleInputChangeShareCancellation(e,props.shareCancelIndex)}
         
                autoComplete='off' 
              />
            </div>
          )}
          <div className="col-xs-3">
            <div className="col-xs-3 py-2">
              <label for="shareCancellationClassCode" className="form-label">
                CLASS CODE
              </label>
              <input
                type="text"
                className="form-control"
                id="shareCancellationClassCode"
                name="shareCancellationClassCode"
                value={props.shareCancelElements.shareCancellationClassCode}
                onChange={(e) =>props.handleInputChangeShareCancellation(e,props.shareCancelIndex)}
                autoComplete='off' 
              />
            </div>
          </div>
          <div className="col-xs-3 py-3">
            <label
              htmlFor="provisionDesc"
              className="col-form-label input-sm  px-3"
            >
              Provision Desc
            </label>
            <select
              name="provisionDesc"
              id="provisionDesc"
              className=" dropDownBox w-50  "
             // onChange={(e) => props.handleChange(e, false, "")}
             onChange={(e) =>props.handleInputChangeShareCancellation(e,props.shareCancelIndex)}
            >
              <option Value="" selected>
                {" "}
              </option>
              <option Value="1">
                Redeemable preference shares,redeemedout of profits
              </option>
              <option Value="2">
                Redeemable preference shares,redeemedout of fresh issue of
                shares
              </option>
              <option Value="3">CapitalReduction Single Shareholder Co.</option>
              <option Value="4">
                CapitalReduction Multiple Shareholder Co.
              </option>
              <option Value="5">Share buy-back Minimum holdingbuy back</option>
              <option Value="6">Share buy-back Other buy-back type</option>
              <option Value="7">Forfeited Shares</option>
              <option Value="8">
                Shares returned to the company,under Section 651C.742(2),737 or
                738
              </option>
              <option Value="9">
                Shares returned to the company, underSection 1325A.(Court order)
              </option>
              <option Value="10">Other</option>
            </select>
          </div>
          <div className="col-xs-3 py-3">
            <label for="provision" className="form-label py-0">
              Provision (If other)
            </label>
            <input
              type="text"
              className="form-control"
              id="provision"
              name="provision"
              value={props.shareCancelElements.provision}
              onChange={(e) =>props.handleInputChangeShareCancellation(e,props.shareCancelIndex)}
              autoComplete='off' 
            />
          </div>
          <div className="col-xs-3 ">
            <label for="desc" className="form-label py-0">
              Desc (If other)
            </label>
            <input
              type="text"
              className="form-control"
              id="desc"
              name="desc"
              value={props.shareCancelElements.desc}
              onChange={(e) =>props.handleInputChangeShareCancellation(e,props.shareCancelIndex)}
            />
          </div>

          <div className="row py-2">
            <div className="col ">
              <label for="shareCancellationTotalShares" className="form-label ">
                Total Shares
              </label>
              <NumberFormat
                type="text"
                data-filter="[0-9]"
                name="shareCancellationTotalShares"
                id="shareCancellationTotalShares"
                className="form-control w-100"
                value={props.shareCancelElements.shareCancellationTotalShares}
                onChange={(e) =>props.handleInputChangeShareCancellation(e,props.shareCancelIndex)}
                thousandSeparator={true}
                decimalSeparator="."
                displayType="input"
                allowNegative={false}
                autoComplete='off' 
              />
            </div>
            <div className="col ">
              <label for="shareCancellationPaidBy" className="form-label py-0">
                Consideration Paid by Co.
              </label>
              <NumberFormat
                type="text"
                data-filter="[0-9]"
                name="shareCancellationPaidBy"
                className="form-control w-100"
                value={props.shareCancelElements.shareCancellationPaidBy}
                onChange={(e) =>props.handleInputChangeShareCancellation(e,props.shareCancelIndex)}
                placeholder="0.00"
                decimalScale={4}
                thousandSeparator={true}
                prefix="$"
                decimalSeparator="."
                displayType="input"
                allowNegative={false}
                autoComplete='off' 
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label
                htmlFor="shareCancellationPaidPerShare"
                className="col-form-label input-sm "
              >
                Paid per share
              </label>
              <NumberFormat
                type="text"
                data-filter="[0-9]"
                name="shareCancellationPaidPerShare"
                className="form-control w-100"
                value={props.shareCancelElements.shareCancellationPaidPerShare}
                onChange={(e) =>props.handleInputChangeShareCancellation(e,props.shareCancelIndex)}
                placeholder="0.00"
                decimalScale={4}
                thousandSeparator={true}
                prefix="$"
                decimalSeparator="."
                displayType="input"
                allowNegative={false}
                autoComplete='off' 
                />
            </div>
            <div className="col">
              <label
                htmlFor="shareCancellationTotalPaid"
                className="col-form-label input-sm "
              >
                Total Paid
              </label>
              <NumberFormat
                type="text"
                data-filter="[0-9]"
                name="shareCancellationTotalPaid"
                className="form-control w-100"
                value={props.shareCancelElements.shareCancellationTotalPaid}
                onChange={(e) =>props.handleInputChangeShareCancellation(e,props.shareCancelIndex)}
                placeholder="0.00"
                decimalScale={2}
                thousandSeparator={true}
                prefix="$"
                decimalSeparator="."
                displayType="input"
                allowNegative={false}
                autoComplete='off' 
              />
            </div>
          </div>
          {!props.shareCancelElements.shareCancellationFullyPaid && (
            <div className="row">
              <div className="col">
                <label
                  htmlFor="shareCancellationUnpaidPerShare"
                  className="col-form-label input-sm "
                >
                  UnPaid per share
                </label>
                <NumberFormat
                  type="text"
                  data-filter="[0-9]"
                  name="shareCancellationUnpaidPerShare"
                  className="form-control w-100"
                  id="shareCancellationUnpaidPerShare"
                  value={props.shareCancelElements.shareCancellationUnpaidPerShare}
                  onChange={(e) =>props.handleInputChangeShareCancellation(e,props.shareCancelIndex)}
                  placeholder="0.00"
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  allowNegative={false}
                  autoComplete='off' 
                />
              </div>
              <div className="col">
                <label
                  htmlFor="shareCancellationTotalUnPaid"
                  className="col-form-label input-sm "
                >
                  Total UnPaid
                </label>

                <NumberFormat
                  type="text"
                  data-filter="[0-9]"
                  name="shareCancellationTotalUnpaid"
                  className="form-control w-100"
                  id="shareCancellationTotalUnpaid"
                  value={props.shareCancelElements.shareCancellationTotalUnpaid}
                  onChange={(e) =>props.handleInputChangeShareCancellation(e,props.shareCancelIndex)}
                  placeholder="0.00"
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  allowNegative={false}
                  autoComplete='off' 
                />
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};
export default ShareCancellation;
