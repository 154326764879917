import { userConstants } from "../constants/login.constants";

 function forgetPasswordEmail(state = {}, action) {
    switch (action.type) {
        case userConstants.FORGETPASSWORDEMAIL_REQUEST:
            return {
                loading: true
            };
        case userConstants.FORGETPASSWORDEMAIL_SUCCESS:
            return {
                items: action.forgetPasswordEmail
            };
        case userConstants.FORGETPASSWORDEMAIL_FAILURE:
            return {
                error: action.errorForgetPasswordEmail
                
            };
        default:
            return state
    }
}
export default forgetPasswordEmail;