import React, { useState,useMemo } from "react";
import { useDispatch} from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import FullPageLoader from "../CommonPage/FullPageLoader";

const EBinderComplete= (props) => {
    const selectedSearchValue = localStorage.getItem("inputSearchValue");
  //loading spinner
  const [isEbinderLoaded, setIsEbinderLoaded] = useState(false);
  
  const dispatch = useDispatch();
// Creation date.
const creationDate =
  new Date().toLocaleTimeString() + " " + new Date().toLocaleDateString();
  function eBinderHandleClick(e, binderId) {
    if (props.selectedCompanyCode) {
      dispatch(
        userActions.getEbinderLink(
          props.selectedCompanyCode,
          binderId,
          setIsEbinderLoaded
        )
      );
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(
          userActions.getEbinderLink(
            props.selectedCompanyCode,
            binderId,
            setIsEbinderLoaded
          )
        )
      );
    }
  }
  const countFiles = (files) =>
  Object.keys(files).reduce((acc, cur) => acc + files[cur].length, 0);
// Memoized due to the reduction over a potentially large object.
let numPrimeDocs = useMemo(
  () => countFiles(props.totalSelected),
  [props.totalSelected]
);
 // Number of custom user documents.
 let numCustomDocs = props.customFileList &&  props.customFileList.length;
    return (
        <div className ="fs-6">
     <h4> <strong>Complete</strong></h4>  
     <p>your new e-Binder has been generated successfully   </p>
     {console.log("numPrimeDocs",numPrimeDocs)}

     <h2>Summary</h2>
     <table className="w-50">
       <tr>
         <td>Company</td>
         <td> {props.displaySelectedCompany &&
            props.displaySelectedCompany.Name}</td>
       </tr>

       <tr>
         <td>e-Binder ID</td>
         <td>
         <a
         href={() => false}
         target="_blank"
         rel="noreferrer"
         className="link-primary"
         style={{ cursor: "pointer" }}
         onClick={(e) => eBinderHandleClick(e,props.ebinderResponse && props.ebinderResponse.items && props.ebinderResponse.items.Id )}
       >
         eBinder-pdf (
         {creationDate}
         )
       </a>
         </td>  {isEbinderLoaded ? <FullPageLoader /> : null}
       </tr> 

       <tr>
         <td>Created At</td>
         <td>{creationDate}</td>
       </tr>

       <tr>
         <td>Prime Cloud Documents</td>
         <td>{numPrimeDocs}</td>
       </tr>

       <tr>
         <td>Custom User Documents</td>
         <td>{numCustomDocs}</td>
       </tr>

       <tr>
         <td>Included Registers</td>
         <td>
         {console.log('resigter::', props.register)}
         {props.register &&
            props.register.join(",")}</td>
       </tr>
     </table>



        </div>
    );
}

export default EBinderComplete;