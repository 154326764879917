import React from 'react';
import DatePicker from "react-datepicker";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const PrincipalBusinessAddressComponent = (props) => {
  

    
  return (
    <div className="container">
      <form>
        <div className="form-group row">
          <div>
            <label
              htmlFor="businessAddressChangeDate"
              className="col-form-label input-sm "
            >
              Change Date
              <i
                className="bi bi-info-circle px-1"
                data-toggle="tooltip"
                data-placement="top"
                title="ASIC late fees apply if form is not lodgged with in 28days of change date"
              ></i>
            </label>

            <DatePicker
            className="form-control input-sm w-25"
            name="baChangeDate"
            selected={props.asicDetails.baChangeDate}
            onChange={e=> props.handleChange(e, true, 'baChangeDate')}
            dateFormat="dd/MM/yyyy"
            placeholderText="DD/MM/YYYY"
            value={props.asicDetails.baChangeDate}
            
            />
          </div>
          <div className="col-xs-3">
            <label
              htmlFor="businessAddressFullAddress"
              className="col-form-label"
            >
              FULL ADDRESS
            </label>
            <GooglePlacesAutocomplete
            apiKey={'AIzaSyD8pHI8_Rviq7fmkxO5TBlJmaQl7kNHpE0'}
            
            selectProps={{
              placeholder:"Text...",
              isClearable: true,
              predefinedPlacesAlwaysVisible:true,
              value:  props.asicDetails.businessFullAddress,
              onChange: (val) => {
                props.setAsicDetails((businessAddress) => ({
                  ...businessAddress,
                  businessFullAddress:val
                }));
              }
            }}
          />
        
          </div>
        </div>
      </form>
    </div>
  );
};
export default PrincipalBusinessAddressComponent;
