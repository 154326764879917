import { userConstants } from "../constants/login.constants";

 function recentCompanyList(state = {}, action) {
    switch (action.type) {
        case userConstants.GETRECENTCOMPANYLIST_REQUEST:
            return {
                loading: true
            };
        case userConstants.GETRECENTCOMPANYLIST_SUCCESS:
            return {
                items: action.recentCompanyList
            };
        case userConstants.GETRECENTCOMPANYLIST_FAILURE:
            return {
                error: action.getRecentCompanyListError
            };
        default:
            return state
    }
}
export default recentCompanyList;