import { userConstants } from "../constants/login.constants";

 function departments(state = {}, action) {
    switch (action.type) {
        case userConstants.DEPARTMENTALL_REQUEST:
            return {
                loading: true
            };
        case userConstants.DEPARTMENTALL_SUCCESS:
            return {
                items: action.departements
            };
        case userConstants.DEPARTMENTALL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export default departments;