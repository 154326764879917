import { combineReducers } from 'redux';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { companyList } from './companyList.reducer';
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import companyProfile from'./companyProfile.reducer'
import unitTrust from './unitTrust.reducer';
import { userConstants } from '../constants/login.constants';
import departments from './departments.reducer';
import annualStatement from './annualStatement.reducer';
import asicDebtStatement from './AsicDebtStatement.reducer';
import companyProfileReport from'./companyProfileReport.reducer';
import membersReport from './membersReport.reducer';
import officerDetails from './officerDetails.reducer'
import shareHolderDetails from './shareHolderDetails.reducer';
import documentHistoryDocDetails from './documentHistoryByDocNo.reducer';
import documentHistoryAsicDocDetails from './documentHistoryByAsicDocNo.reducer';
import asicDocumentTracking from './AsicDocumentTracking.reducer';
import individualEntities from './individualEntities.reducer';
import jointHolders from './jointHolders.reducer';
import beneficialOwner from './beneficialOwner.reducer';
import orderMonitorStatement from'./orderMonitorStatement.reducer';
import digitalSignatureStatement from './digitalSignatureStatement.reducer';
import electronicLodgementService from './electronicLodgementService.reducer';
import individualElectronicLodgementService from './individualEletronicLodgementService.reducer';
import companiesDetailList from './companiesDetailList.reducer';
import viewCurrentAnnualStatementReport from './viewCurrentAnnualStatement.reducer';
import asicInvoiceReport from './asicInvoiceReport.reducer';
import asicInvoiceReportLinks from './asicInvoiceReportLinks.reducer'
import forgetPasswordEmail from './forgetPassword.reducer';
import resetPassword from './resetPassword.reducer';
import annualStatementMonthStatus from './annualStatementMonthStatus.reducer';
import directorIdReport from './directorIdReport.reducer';
import div7ALoanReport from './div7ALoanReport.reducer';
import form484Information from'./form484Information.reducer';
import regdInformation from './regdInformation.reducer';
import individualEntitiesShares from './individualEntitiesShares.reducer';
import individualEntitiesOffices from './individualEntitiesOffices.reducer';
import didUpdate from './didUpdate.reducer';
import countries from './countries.reducer';
import signUp from './signUp.reducer';
import unitTrustTable from './unitTrustTable.reducer';
import loggedUserInfo from './loggedUserInfo.reducer';
import classListInfo from './classListInfo.reducer';
import changeOrderRequest from './changeOrderRequest.reducer';
import recentCompanyList from './recentCompanyList.reducer';
import postRecentCompanyLIst from './postRecentCompanyLIst.reducer';
import ebinderPrimeFileList from './ebinderPrimeFileList.reducer';
import manageEbinderList from './manageEbinderList.reducer';
import eBinderLink from './eBinderLink.reducer';
import ebinderCustomFileList from './ebinderCustomFileList.reducer';
import ebinderCompanyList from './ebinderCompanyList.reducer';
import ebinderOrderRequest from './ebinderOrderRequest.reducer';
import changePassword from './changePassword.reducer';
import businessNameDetailList from './businessNameDetailList.reducer';
import businessNameWelcomeReport from './businessNameWelcomeReport.reducer';
import businessCertificateReport from './businessCertificateReport.reducer';
import businessNameSummary from './businessNameSummary.reducer';
import businessNameReport from './businessNameReport.reducer';
import businessNotes from './businessNotes.reducer';
import businessNotesUpdate from './businessNotesUpdate.reducer';
import directorIdCount from './directorIdCount.reducer';
import individualEntitiesWithPagination from './individualEntitiesWithPagination.reducer'

const persistConfig = {
   key:'root',
   storage
}
 
const rootReducer = combineReducers({
   users,
  companyList,
   companyProfile,
   departments,
  alert,
  unitTrust,
  annualStatement,
  annualStatementMonthStatus,
  asicDebtStatement,
  companyProfileReport,
  membersReport,
  officerDetails,
  shareHolderDetails,
  documentHistoryDocDetails,
  documentHistoryAsicDocDetails,
 asicDocumentTracking,
 individualEntities,
 jointHolders,
 beneficialOwner,
 orderMonitorStatement,
 digitalSignatureStatement,
 electronicLodgementService,
 individualElectronicLodgementService,
 companiesDetailList,
 viewCurrentAnnualStatementReport,
 asicInvoiceReport,
 asicInvoiceReportLinks,
 forgetPasswordEmail,
 resetPassword,
 directorIdReport,
 div7ALoanReport,
 form484Information,
 regdInformation,
 individualEntitiesShares,
 individualEntitiesOffices,
 didUpdate,
 countries,
 signUp,
 unitTrustTable,
 loggedUserInfo,
 classListInfo,
 changeOrderRequest,
 recentCompanyList,
 postRecentCompanyLIst,
 ebinderPrimeFileList,
 manageEbinderList,
 eBinderLink,
 ebinderCustomFileList,
 ebinderCompanyList,
 ebinderOrderRequest,
 changePassword,
 businessNameDetailList,
 businessNameWelcomeReport,
 businessCertificateReport,
 businessNameSummary,
 businessNameReport,
 businessNotesUpdate,
 businessNotes,
 directorIdCount,
 individualEntitiesWithPagination,
});

const resetReducer = (state, action) => {
   // when a logout action is dispatched it will reset redux state
   if (action.type === userConstants.LOGOUT) {
     state = undefined;
   }
 
   return rootReducer(state, action);
 };
 


export default persistReducer(persistConfig, resetReducer);