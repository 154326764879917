import React, {useEffect} from "react";
import moment from "moment";

const OrderSummary = (props) => {
  useEffect(() => {
    
    // eslint-disable-next-line
  }, []);
  
  return (
    <div>

      <h3 className="mb-4">Order Confirmation</h3>
  
      <h6>
        {" "}
        {props.submitted=== "AsicAdd" ? props.changeOrder.companyName : props.companyName} [{props.submitted=== "AsicAdd" ? props.changeOrder.companyAcn : props.companyAcn}]
      </h6>
      <table className="table table-responsive table-borderless">
     
        <tbody>
          {props.changeOrder.requestChangeAddress &&
            props.changeOrder.ROAddress && (
              <tr>
                <td>
                  <strong>
                    <u>Registered Office Address: </u>
                  </strong>{" "}
                  <br />
                  Date:{" "}
                  { props.changeOrder.raChangeDate ? (moment(props.changeOrder.raChangeDate).format("DD/MM/YYYY")):" "}
                  <br />
                  {props.changeOrder.registeredFullAddress.label}
                  <br />
                  Company Occupies Premises :{" "}
                  {props.changeOrder.companyOccupiesPremises ? "yes" : "no"}
                  <br />
                  {`${
                    !props.changeOrder.companyOccupiesPremises
                      ? ` Occupier's Company Name :  ${
                          props.changeOrder.occupierCompanyName &&
                          props.changeOrder.occupierCompanyName
                            ? props.changeOrder.occupierCompanyName
                            : ""
                        }`
                      : ""
                  }`}
                </td>
              </tr>
            )}
          {props.changeOrder.requestChangeAddress &&
            props.changeOrder.businessAddress && (
              <tr>
                <td>
                  <strong>
                    <u>Principal Business Address : </u>
                  </strong>
                  <br />
                  Date:{" "}
                  {moment(props.changeOrder.baChangeDate).format("DD/MM/YYYY")}
                  <br />
                  {props.changeOrder.businessFullAddress.label} <br />
                </td>
              </tr>
            )}
          {props.changeOrder.requestChangeAddress &&
            props.changeOrder.changeOfAddress &&props.changeOrder.osChangeofAddressInputlist && (props.changeOrder.osChangeofAddressInputlist.length >0) && (
              <tr>
                <td>
                  <strong>
                    <u>Officeholder(s)/Shareholder(s) - Change of Address : </u>
                  </strong>

                  <table>
                    {props.changeOrder &&
                      props.changeOrder.osChangeofAddressInputlist &&
                      props.changeOrder.osChangeofAddressInputlist.map(
                        (value) => (
                          <tbody>
                            <tr>
                              <td>
                                Name:
                                {value.changeFor === "Officer"
                                  ? value.officeHolderName &&
                                    value.officeHolderName.map(
                                      (value1) => value1.OFFICER_NAME
                                    )
                                  : value.changeFor === "Shareholder"
                                  ? value.officeHolderName &&
                                    value.officeHolderName.map(
                                      (value1) => value1.NAME
                                    )
                                  : value.officeHolderName &&
                                    value.officeHolderName[0]}
                              </td>
                            </tr>
                            <tr>
                              {" "}
                              <td>
                                Date:{" "}
                                {moment(value.ocaChangeDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>{" "}
                            </tr>
                            <tr>
                              <td> Mode: {value.changeFor}</td>
                            </tr>
                            <tr>
                              <td>{`Address:   ${
                                value.officeholdersChangeFullAddress &&
                                value.officeholdersChangeFullAddress.label
                                  ? value.officeholdersChangeFullAddress.label
                                  : ""
                              }`}</td>
                            </tr>
                            <tr>
                              <td>
                                <br />
                              </td>
                            </tr>
                          </tbody>
                        )
                      )}
                  </table>
                </td>
              </tr>
            )}
          {props.changeOrder.requestChangeNames &&
            props.changeOrder.companyNameChange && (
              <tr>
                <td>
                  <strong>
                    <u>Company Name Change : </u>
                  </strong>
                  <br />
                  Changed Name: {props.changeOrder.companyNameChangeTo}
                  <br />
                  Date:
                  {moment(props.changeOrder.cnChangeDate).format(
                    "DD/MM/YYYY"
                  )}{" "}
                  <br />
                </td>
              </tr>
            )}
          {props.changeOrder.requestChangeNames &&
            props.changeOrder.officerNameChange &&props.changeOrder.osNameChangeInputlist && (props.changeOrder.osNameChangeInputlist.length >0) && (
              <tr>
                <td>
                  <strong>
                    <u>Officer Shareholders Name change : </u>
                  </strong>
                  <table>
                    {props.changeOrder &&
                      props.changeOrder.osNameChangeInputlist &&
                      props.changeOrder.osNameChangeInputlist.map((value) => (
                        <tbody>
                          <tr>
                            <td>
                              Date:{" "}
                              {moment(value.ohChangeDate).format("DD/MM/YYYY")}
                            </td>{" "}
                          </tr>
                          <tr>
                            <td> Mode: {value.changeForNameChange}</td>
                          </tr>
                          <tr>
                            <td>
    
                              From Name:
                              {value.changeForNameChange === "Officer"
                                ? value.fromName &&
                                  value.fromName.map(
                                    (value1) => value1.OFFICER_NAME
                                  )
                                  : value.changeForNameChange === "Shareholder"
                                  ? value.fromName &&
                                    value.fromName.map(
                                      (value1) => value1.NAME
                                    )
                                  : value.fromName &&
                                    value.fromName[0]}
                              
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              To Name:{" "}
                              {value.toName && value.toName ? value.toName : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </table>
                </td>
              </tr>
            )}
          {props.changeOrder.changeOfficeholder &&
            props.changeOrder.OfficeholderAppointment  &&props.changeOrder.appointmentInputlist && (props.changeOrder.appointmentInputlist.length >0) && (
              <tr>
                <td>
                  <strong>
                    <u>Officeholder(s) - Appointment : </u>
                  </strong>
                    <table>
                    {props.changeOrder &&
                      props.changeOrder.appointmentInputlist &&
                      props.changeOrder.appointmentInputlist.map((value) => (
                        <tbody>
                          <tr>
                            <td>
                              {" "}
                              Officer Name :  {value.appointedOfficerName && 
                                value.appointedOfficerName.map((token)=>token.OFFICER_NAME ? token.OFFICER_NAME :  token)
                               }


                              {/* {props.changeOrder.appointedOfficerName && props.changeOrder.appointedOfficerName[0] &&
                                 props.changeOrder.appointedOfficerName[0].OFFICER_NAME?props.changeOrder.appointedOfficerName[0].OFFICER_NAME : props.changeOrder.appointedOfficerName[0] }
                            {value.appointedOfficerName &&
                                value.appointedOfficerName? value.appointedOfficerName: ""}
                                */} 
                            </td>
                          </tr>
                          <tr>
                          <td>{`Officer Address:  ${
                            value.officerAddress &&
                            value.officerAddress.label
                              ? value.officerAddress.label
                              : ""
                          }`}</td>
                        </tr>
                        {value.position1 && (   
                    <tr>                  
                    <td> position1 :  {value.position1} Date:   {moment(value.position1ChangeDate).format(
                      "DD/MM/YYYY"
                    )}</td>                 
                   </tr>
                            )    }
                            {value.position2 && (   
                            <tr>                  
                   <td> position2 :  {value.position2} Date:   {moment(value.position2ChangeDate).format(
                     "DD/MM/YYYY"
                   )}</td>                 
                  </tr>
                            )}
                            {value.position3 && (   
                  <tr>                  
                  <td> position3 :  {value.position3} Date:   {moment(value.position3ChangeDate).format(
                    "DD/MM/YYYY"
                  )}</td>                 
                 </tr>
                            )}
                 <tr> <td>  DOB :   {value.dOB?(moment(value.dOB).format(
                   "DD/MM/YYYY"
                 )):""} POB : {value.placeOfBirth} SOB : {value.stateofBrith} COB : {value.countryOfBirth}</td></tr>

<tr><td>Alt Director :  {value.alternateDirectorFor}
</td></tr>
                    <tr>
                    <td>
                      <br />
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>

                </td>
              </tr>
            )}
          {props.changeOrder.changeOfficeholder &&
            props.changeOrder.OfficeholderResignations &&props.changeOrder.resignInputlist && (props.changeOrder.resignInputlist.length >0) && (
              <tr>
                <td>
                  <strong>
                    <u>Officeholder(s) - Resignations : </u>
                  </strong>
                  <table>
                    {props.changeOrder &&
                      props.changeOrder.resignInputlist &&
                      props.changeOrder.resignInputlist.map((value) => (
                        <tbody>
                          <tr>
                            <td>
                              {" "}
                              Officer Name:
                              {value.resignOfficerName &&
                                value.resignOfficerName.map(
                                  (value1) => value1.OFFICER_NAME
                                )}
                            </td>
                          </tr>
                          
                          <tr>
                            <td >
                            Date:{" "} {value.resignationDate ? moment(value.resignationDate).format(
                              "DD/MM/YYYY"
                            ):""}
                            
                            </td>{" "}
                          </tr>
                          <tr>
                            <td> Resign Reason: {value.resignReason} </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              Position:
                              {value.ceasedDirector &&
                                value.ceasedDirector.map(
                                  (value1) => value1.POSITION_HELD
                                )}
                              {value.ceasedDirector && value.ceasedDirector[0]
                                ? " Director"
                                : ""}
                              {value.ceasedsecretary && value.ceasedsecretary[0]
                                ? " Secretary"
                                : ""}
                              {value.ceasedAltDirector &&
                              value.ceasedAltDirector[0]
                                ? " Alt Director"
                                : ""}
                              {value.ceasedPublicOfficer &&
                              value.ceasedPublicOfficer[0]
                                ? "  Public Officer"
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </table>
                </td>
              </tr>
            )}
         
          {props.changeOrder.changeToShareholdings &&
            props.changeOrder.shareIssue &&  props.changeOrder.inputList && (props.changeOrder.inputList.length >0) && (
              <tr>
                <td>
             
                  <strong>
                    <u> Share Issue(s) : </u>
           
                  </strong>
            
                  <table>
                    {props.changeOrder &&
                      props.changeOrder.inputList &&
                      props.changeOrder.inputList.map((value) => (
                        <tbody>
                          <tr>
                            <td>
                              Date:{" "}
                              {value.shareIssueAllotmentDate?moment(value.shareIssueAllotmentDate).format(
                                "DD/MM/YYYY")
                               : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>Name: {value.shareissueShareholderName}</td>
                          </tr>
                          <tr>
                            <td>
                              Fully Paid:{" "}
                              {value.shareIssueFullyPaid ? "yes" : "no"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Ben Owner:{" "}
                              {value.shareIssueBeneficiallyOwned ? "yes" : "no"}
                            </td>
                          </tr>
                          <tr>
                            <td>{`${
                              !value.shareIssueBeneficiallyOwned
                                ? ` Ben Name: ${
                                    value.shareIssueBenName &&
                                    value.shareIssueBenName
                                      ? value.shareIssueBenName
                                      : ""
                                  }`
                                : ""
                            }`}</td>
                          </tr>

                          <tr>
                            <td>{`Address:   ${
                              value.shareholdersFullAddress &&
                              value.shareholdersFullAddress.label
                                ? value.shareholdersFullAddress.label
                                : ""
                            }`}</td>
                          </tr>
                          <tr>
                            <td>
                            Class: 
                            {value.shareIssueClasscode &&
                              value.shareIssueClasscode.map(
                                (post) => post.CLASS_CODE
                              )}
                           
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {`TotalShares:   ${
                                value.shareIssueTotalShares &&
                                value.shareIssueTotalShares
                                  ? value.shareIssueTotalShares
                                  : ""
                              } `}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>{`PerSharesPaid:    ${
                              value.shareIssuePaidPerShare &&
                              value.shareIssuePaidPerShare
                                ? value.shareIssuePaidPerShare
                                : ""
                            }`}</td>
                          </tr>
                          <tr>
                            <td>{`TotalPaid:   ${
                              value.shareIssueTotalPaid &&
                              value.shareIssueTotalPaid
                                ? value.shareIssueTotalPaid
                                : ""
                            }`}</td>
                          </tr>
                          <tr>
                            <td>{`${
                              !value.shareIssueFullyPaid
                                ? `Total Unpaid:   ${
                                    value.shareIssueTotalUnpaid &&
                                    value.shareIssueTotalUnpaid
                                      ? value.shareIssueTotalUnpaid
                                      : ""
                                  }`
                                : ""
                            }`}</td>
                          </tr>
                          <tr>
                            <td>{`${
                              !value.shareIssueFullyPaid
                                ? ` Per Shares UnPaid: ${value.shareIssueUnpaidPerShare}`
                                : ""
                            }`}</td>
                          </tr>
                          <tr>
                            <td>
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </table>
                </td>
              </tr>
            )}
            
          {props.changeOrder.changeToShareholdings &&
            props.changeOrder.shareCancellation &&  props.changeOrder.shareCancellationInputList && (props.changeOrder.shareCancellationInputList.length >0) && (
              <tr>
                <td>
                  <strong>
                    <u> Share Cancellation(s) </u>
                  </strong>
                  <table>
                    {props.changeOrder &&
                      props.changeOrder.shareCancellationInputList &&
                      props.changeOrder.shareCancellationInputList.map(
                        (value) => (
                          <tbody>
                            <tr>
                              <td>
                                Date:{" "}
                                {moment(value.shareCancelDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>{" "}
                            </tr>
                            <tr>
                              <td>
                                Name:
                                {value.shareCancelShareHolderName &&
                                  value.shareCancelShareHolderName.map(
                                    (post) => post.NAME
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Fully Paid:
                                {value.shareCancellationFullyPaid
                                  ? "yes"
                                  : "no"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Ben Owner:
                                {value.shareCancellationBeneficiallyOwned
                                  ? "yes"
                                  : "no"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {!value.shareCancellationBeneficiallyOwned
                                  ? ` Ben Name: ${
                                      value.shareCancellationBenName &&
                                      value.shareCancellationBenName
                                        ? value.shareCancellationBenName
                                        : ""
                                    }`
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td>Class: {value.shareCancellationClassCode}</td>
                            </tr>
                            <tr>
                              <td>
                                Total Shares:{" "}
                                {value.shareCancellationTotalShares}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Per Shares Paid:
                                {value.shareCancellationPaidPerShare}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Total Paid: {value.shareCancellationTotalPaid}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {!value.shareCancellationFullyPaid
                                  ? `Total Unpaid: ${
                                      value.shareCancellationTotalUnpaid &&
                                      value.shareCancellationTotalUnpaid
                                        ? value.shareCancellationTotalUnpaid
                                        : ""
                                    }`
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                {!value.shareCancellationFullyPaid
                                  ? ` Per Shares UnPaid: ${
                                      value.shareCancellationUnpaidPerShare &&
                                      value.shareCancellationUnpaidPerShare
                                        ? value.shareCancellationUnpaidPerShare
                                        : ""
                                    }`
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <br />
                              </td>
                            </tr>
                          </tbody>
                        )
                      )}
                  </table>
                </td>
              </tr>
            )}
          {props.changeOrder.changeToShareholdings &&
            props.changeOrder.shareTransfer &&props.changeOrder.shareTransferInputList && (props.changeOrder.shareTransferInputList.length >0) && (
              <tr>
                <td>
                  <strong>
                    <u> Share Transfer(s) :</u>
                  </strong>
                  <table>                  
                    {props.changeOrder &&
                      props.changeOrder.shareTransferInputList &&
                      props.changeOrder.shareTransferInputList.map((value) => (
                        <tbody>
                          <tr>
                            <td>
                              Date:{" "}
                              {moment(value.shareTransferTransferDate).format(
                                "DD/MM/YYYY"
                              )}
                            </td>{" "}
                          </tr>
                          <tr>
                            <td> From Name:
                            {
                              value.transferFrom && value.transferFrom ?  value.transferFrom: ""

                            }
                                                        </td>
                          </tr>
                          <tr>
                            <td>
                              From Fully Paid:
                              {value.transferFromFullyPaid ? "YES" : "NO"}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              From Ben Owner:
                              {value.transferFromBeneficiallyOwned
                                ? "YES"
                                : "NO"}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              To Name:
                              {value.transferTo && value.transferTo
                                ? value.transferTo
                                : ""}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Fully Paid:
                              {value.transfertoFullyPaid ? "yes" : "no"}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              Ben Owner:
                              {value.transfertoBeneficiallyOwned ? "yes" : "no"}
                              {!value.transfertoBeneficiallyOwned
                                ? ` Ben Name: ${
                                    value.transfertoBenName &&
                                    value.transfertoBenName
                                      ? value.transfertoBenName
                                      : ""
                                  }`
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              Address:
                              {value.shareTransferAddress &&
                              value.shareTransferAddress.label
                                ? value.shareTransferAddress.label
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              Class:{" "}
                              {value.shareTransferclassCode &&
                              value.shareTransferclassCode
                                ? value.shareTransferclassCode
                                : ""}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              Total Shares:{" "}
                              {value.shareTrasferTotalShares &&
                              value.shareTrasferTotalShares
                                ? value.shareTrasferTotalShares
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              Per Shares Paid:{" "}
                              {value.shareTransferPaidPerShare &&
                              value.shareTransferPaidPerShare
                                ? value.shareTransferPaidPerShare
                                : ""}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              Total Paid:{" "}
                              {value.shareTransferTotalPaid &&
                              value.shareTransferTotalPaid
                                ? value.shareTransferTotalPaid
                                : ""}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              {!value.transfertoFullyPaid
                                ? `Total Unpaid: ${
                                    value.shareTransferTotalUnpaid &&
                                    value.shareTransferTotalUnpaid
                                      ? value.shareTransferTotalUnpaid
                                      : ""
                                  }`
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              {!value.transfertoFullyPaid
                                ? ` Per Shares UnPaid: ${
                                    value.shareTransferUnpaidPerShare &&
                                    value.shareTransferUnpaidPerShare
                                      ? value.shareTransferUnpaidPerShare
                                      : ""
                                  }`
                                : ""}{" "}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </table>
                </td>
              </tr>
            )}
          {props.changeOrder.changeToShareholdings &&
            props.changeOrder.returnCapital && (
              <tr>
                <td>
                  <strong>
                    <u>Return of Capital (Info) : </u>
                  </strong>
                  <br />

                  {props.changeOrder.returnCapitalInfo}
                </td>
              </tr>
            )}

         

          {props.changeOrder.dividendClassCode && (
            <tr>
              <td>
                <strong>
                  <u>Dividend : </u>
                </strong>
                <br />
                <table>
                
                {props.changeOrder && props.changeOrder.dividendClassCode  && (
                  <tbody>
                   <tr>
                   <td>  Class: {props.changeOrder.dividendClassCode}    </td>                                      
                </tr>
                <tr><td> Declaration/Books Close Date:{" "}
                {props.changeOrder.booksCloseDate ? moment(props.changeOrder.booksCloseDate).format("DD/MM/YYYY"):""}</td></tr>
                <tr><td>
                Financial Year End Date:{" "}
                {props.changeOrder.financialYearEndDate ? moment(props.changeOrder.financialYearEndDate).format(
                  "DD/MM/YYYY"
                ):""}</td></tr>
                <tr><td><br/></td></tr>
                
                <tr><td>Interim Dividend: {props.changeOrder.interimDividend ? "YES":"NO"}   </td></tr>
                <tr><td>Final Dividend: {props.changeOrder.finalDividend ? "YES":"NO"} </td></tr>
                <tr><td>Short Meeting Notice:{props.changeOrder.shortMeetingNotice ? "YES":"NO"} </td></tr>
                <tr><td>Include Shareholders Resolutions: {props.changeOrder.shareholdersResolutions ? "YES":"NO"} </td></tr>
                <tr><td>  Input Franked Dollar: {props.changeOrder.inputFrankedDollar ? "YES":"NO"} </td></tr>
                <tr><td><br/></td></tr>

                <tr><td>   Dividend Per Share: {props.changeOrder.dividendPerShare}</td></tr>
                <tr><td> Total Dividend $'s: {props.changeOrder.totalDividend}</td></tr>
                <tr><td><br/></td></tr>

                <tr><td>Franked Amount (%): {props.changeOrder.frankedAmountPercentage && props.changeOrder.frankedAmountPercentage.toFixed(4)}</td></tr>
                <tr><td> Unfranked Amount (%):{" "}
                {props.changeOrder.unFrankedAmountPercentage && props.changeOrder.unFrankedAmountPercentage.toFixed(4)}</td></tr>
                <tr><td>  Company Tax Rate (%): {props.changeOrder.companyTaxRate}</td></tr>
                <tr><td><br/></td></tr>
                <tr><td> Franked Amount ($): {props.changeOrder.frankedAmount}</td></tr>
                <tr><td>Unfranked Amount ($): {props.changeOrder.unFrankedAmount}</td></tr>
               
                <tr><td></td></tr>
                </tbody>

                )}
                </table>
                 
              </td>
            </tr>
          )}

          {
            props.changeOrder.additionalInfo && (
              <tr>
                <td>
                  <strong>
                    <u>Additional Info : </u>
                  </strong>
                  <br />
                  {props.changeOrder.additionalMoreInfo} <br />
                  Form Signer Name: {props.changeOrder.signerName && props.changeOrder.signerName[0] && props.changeOrder.signerName[0].OFFICER_NAME?props.changeOrder.signerName[0].OFFICER_NAME : props.changeOrder.signerName[0] }
                  <br />
                  {props.changeOrder.fileList !== null
                    ? ` Attachment(s) : ${
                        props.changeOrder.fileList &&
                        props.changeOrder.fileList.map(
                          (value) => value.meta.name
                        )
                      }`
                    : ""}
                </td>
              </tr>
            )}
        </tbody>{" "}
      </table>
      {props.isOrderAlter && <p><strong className="text-danger">{props.changeOrderRequest && props.changeOrderRequest.items && props.changeOrderRequest.items.Desc}</strong></p>}
    </div>
  );
};

export default OrderSummary;
