import { userConstants } from '../constants/login.constants';

function companyProfile(state = {}, action) {
    
    switch (action.type) {
        case userConstants.COMPANYSELECTED_REQUEST:
            return {
                loading: true
            };
        case userConstants.COMPANYSELECTED_SUCCESS:
            return {
                items: action.profiles
            };
        case userConstants.COMPANYSELECTED_FAILURE:
            return {
                error: action.errorCompanyProfile
            };
        default:
            return state
    }
}
export default companyProfile