import React from "react";
import DatePicker from "react-datepicker";

const CompanyNameChange = (props) => {
  return (
    <div className="container">
      <hr />
      <form>
        <div className="form-group row">
          <div>
            <label
              htmlFor="companyNameChangeDate"
              className="col-form-label input-sm "
            >
              Change Date
              <i
                className="bi bi-info-circle px-1"
                data-toggle="tooltip"
                data-placement="top"
                title="ASIC late fees apply if form is not lodgged with in 28days of change date"
              ></i>
            </label>

            <DatePicker
              className="form-control input-sm w-25"
              name="changeDate"
              selected={props.asicDetails.cnChangeDate}
              onChange={(e) => props.handleChange(e, true, "cnChangeDate")}
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              value={props.asicDetails.cnChangeDate}
            />
          </div>
          <div className="col-xs-3">
            <label htmlFor="companyNameChangeTo" className="col-form-label">
              Company Name Change to
            </label>
            <input
              type="text"
              name="companyNameChangeTo"
              className="form-control "
              placeholder="Text..."
              autoComplete="off"
              value={props.asicDetails.companyNameChangeTo}
              onChange={(e) => props.handleChange(e, false, "")}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
export default CompanyNameChange;
