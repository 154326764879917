export const userConstants = {

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    COMPANYALL_REQUEST: 'USERS_COMPANYALL_REQUEST',
    COMPANYALL_SUCCESS: 'USERS_COMPANYALL_SUCCESS',
    COMPANYALL_FAILURE: 'USERS_COMPANYALL_FAILURE',

    COMPANYSELECTED_REQUEST:'USERS_COMPANYSELECTED_REQUEST',
    COMPANYSELECTED_SUCCESS:'USERS_COMPANYSELECTED_SUCCESS',
    COMPANYSELECTED_FAILURE:'USERS_COMPANYSELECTED_FAILURE',


   DEPARTMENTALL_REQUEST:'USERS_DEPARTMENTALL_REQUEST',
   DEPARTMENTALL_SUCCESS:'USERS_DEPARTMENTALL_SUCCESS',
   DEPARTMENTALL_FAILURE:'USERS_DEPARTMENTALL_FAILURE',

   UNITTRUSTALL_REQUEST:'USERS_UNITTRUSTALL_REQUEST',
   UNITTRUSTALL_SUCCESS:'USERS_UNITTRUSTALL_SUCCESS',
   UNITTRUSTALL_FAILURE:'USERS_UNITTRUSTALL_FAILURE',

   ANNUALSTATEMENT_REQUEST:'USERS_ANNUALSTATEMENT_REQUEST',
   ANNUALSTATEMENT_SUCCESS:'USERS_ANNUALSTATEMENT_SUCCESS',
   ANNUALSTATEMENT_FAILURE:'USERS_ANNUALSTATEMENT_FAILURE',

   ANNUALSTATEMENTMONTHSTATUS_REQUEST:'USERS_ANNUALSTATEMENTMONTHSTATUS_REQUEST',
   ANNUALSTATEMENTMONTHSTATUS_SUCCESS:'USERS_ANNUALSTATEMENTMONTHSTATUS_SUCCESS',
   ANNUALSTATEMENTMONTHSTATUS_FAILURE:'USERS_ANNUALSTATEMENTMONTHSTATUS_FAILURE',

   ASICDEPTSTATEMENT_REQUEST:'USERS_ASICDEPTSTATEMENT_REQUEST',
   ASICDEPTSTATEMENT_SUCCESS:'USERS_ASICDEPTSTATEMENT_SUCCESS',
   ASICDEPTSTATEMENT_FAILURE:'USERS_ASICDEPTSTATEMENT_FAILURE',

   COMPANYPROFILEREPORT_REQUEST: 'USERS_COMPANYPROFILEREPORT_REQUEST',
   COMPANYPROFILEREPORT_SUCCESS: 'USERS_COMPANYPROFILEREPORT_SUCCESS',
   COMPANYPROFILEREPORT_FAILURE: 'USERS_COMPANYPROFILEREPORT_FAILURE',

   MEMBERSREPORT_REQUEST: 'USERS_MEMBERSREPORT_REQUEST',
   MEMBERSREPORT_SUCCESS: 'USERS_MEMBERSREPORT_SUCCESS',
   MEMBERSREPORT_FAILURE: 'USERS_MEMBERSREPORT_FAILURE',
   
   DIRECTORANDSECRETARIESREPORT_REQUEST: 'USERS_DIRECTORANDSECRETARIESREPORT_REQUEST',
   DIRECTORANDSECRETARIESREPORT_SUCCESS: 'USERS_DIRECTORANDSECRETARIESREPORT_SUCCESS',
   DIRECTORANDSECRETARIESREPORT_FAILURE: 'USERS_DIRECTORANDSECRETARIESREPORT_FAILURE',

   ALLOTMENTJOURNALREPORT_REQUEST: 'USERS_ALLOTMENTJOURNALREPORT_REQUEST',
   ALLOTMENTJOURNALREPORT_SUCCESS: 'USERS_ALLOTMENTJOURNALREPORT_SUCCESS',
   ALLOTMENTJOURNALREPORT_FAILURE: 'USERS_ALLOTMENTJOURNALREPORT_FAILURE',

   TRANSFERJOURNALREPORT_REQUEST: 'USERS_TRANSFERJOURNALREPORT_REQUEST',
   TRANSFERJOURNALREPORT_REQUEST_SUCCESS: 'USERS_TRANSFERJOURNALREPORT_SUCCESS',
   TRANSFERJOURNALREPORT_REQUEST_FAILURE: 'USERS_TRANSFERJOURNALREPORT_FAILURE',

   RESPONSIBLEMANAGERREPORT_REQUEST: 'USERS_RESPONSIBLEMANAGERREPORT_REQUEST',
   RESPONSIBLEMANAGERREPORT_SUCCESS: 'USERS_RESPONSIBLEMANAGERREPORT_SUCCESS',
   RESPONSIBLEMANAGERREPORT_FAILURE: 'USERS_RESPONSIBLEMANAGERREPORT_FAILURE',
   
   PUBLICOFFICERREPORT_REQUEST: 'USERS_PUBLICOFFICERREPORT_REQUEST',
   PUBLICOFFICERREPORT_SUCCESS: 'USERS_PUBLICOFFICERREPORT_SUCCESS',
   PUBLICOFFICERREPORT_FAILURE: 'USERS_PUBLICOFFICERREPORT_FAILURE',

   DIRECTORSSHAREHOLDINGSREPORT_REQUEST: 'USERS_DIRECTORSSHAREHOLDINGSREPORT_REQUEST',
   DIRECTORSSHAREHOLDINGSREPORT_SUCCESS: 'USERS_DIRECTORSSHAREHOLDINGSREPORT_SUCCESS',
   DIRECTORSSHAREHOLDINGSREPORT_FAILURE: 'USERS_DIRECTORSSHAREHOLDINGSREPORT_FAILURE',

   OFFICERDETAILSREPORT_REQUEST: 'USERS_OFFICERDETAILSREPORT_REQUEST',
   OFFICERDETAILSREPORT_SUCCESS: 'USERS_OFFICERDETAILSREPORT_SUCCESS',
   OFFICERDETAILSREPORT_FAILURE: 'USERS_OFFICERDETAILSREPORT_FAILURE',

   SHAREHOLDERDETAILSREPORT_REQUEST: 'USERS_SHAREHOLDERDETAILSREPORT_REQUEST',
   SHAREHOLDERDETAILSREPORT_SUCCESS: 'USERS_SHAREHOLDERDETAILSREPORT_SUCCESS',
   SHAREHOLDERDETAILSREPORT_FAILURE: 'USERS_SHAREHOLDERDETAILSREPORT_FAILURE',

   DOCUMENTHISTORYDOCREPORT_REQUEST: 'USERS_DOCUMENTHISTORYDOCREPORT_REQUEST',
   DOCUMENTHISTORYDOCREPORT_SUCCESS: 'USERS_DOCUMENTHISTORYDOCREPORT_SUCCESS',
   DOCUMENTHISTORYDOCREPORT_FAILURE: 'USERS_DOCUMENTHISTORYDOCREPORT_FAILURE',

   DOCUMENTHISTORYASICREPORT_REQUEST: 'USERS_DOCUMENTHISTORYASICREPORT_REQUEST',
   DOCUMENTHISTORYASICREPORT_SUCCESS: 'USERS_DOCUMENTHISTORYASICREPORT_SUCCESS',
   DOCUMENTHISTORYASICREPORT_FAILURE: 'USERS_DOCUMENTHISTORYASICREPORT_FAILURE',

   ASICDOCUMENTTRACKING_REQUEST: 'USERS_ASICDOCUMENTTRACKING_REQUEST',
   ASICDOCUMENTTRACKING_SUCCESS: 'USERS_ASICDOCUMENTTRACKING_SUCCESS',
   ASICDOCUMENTTRACKING_FAILURE: 'USERS_ASICDOCUMENTTRACKING_FAILURE',

   INDIVIDUALENTITIES_REQUEST: 'USERS_INDIVIDUALENTITIES_REQUEST',
   INDIVIDUALENTITIES_SUCCESS: 'USERS_INDIVIDUALENTITIES_SUCCESS',
   INDIVIDUALENTITIES_FAILURE: 'USERS_INDIVIDUALENTITIES_FAILURE',

   JOINTHOLDERS_REQUEST: 'USERS_JOINTHOLDERS_REQUEST',
   JOINTHOLDERS_SUCCESS: 'USERS_JOINTHOLDERS_SUCCESS',
  JOINTHOLDERS_FAILURE: 'USERS_JOINTHOLDERS_FAILURE',

  BENEFICIALOWNER_REQUEST: 'USERS_BENEFICIALOWNER_REQUEST',
  BENEFICIALOWNER_SUCCESS: 'USERS_BENEFICIALOWNER_SUCCESS',
  BENEFICIALOWNER_FAILURE: 'USERS_BENEFICIALOWNER_FAILURE',

  COMPANYREPORT_REQUEST: 'USERS_COMPANYREPORT_REQUEST',
  COMPANYREPORT_SUCCESS: 'USERS_COMPANYREPORT_SUCCESS',
  COMPANYREPORT_FAILURE: 'USERS_COMPANYREPORT_FAILURE',

  QUICKCOMPANYPROFILEREPORT_REQUEST: 'USERS_QUICKCOMPANYPROFILEREPORT_REQUEST',
  QUICKCOMPANYPROFILEREPORT_SUCCESS: 'USERS_QUICKCOMPANYPROFILEREPORT_SUCCESS',
  QUICKCOMPANYPROFILEREPORT_FAILURE: 'USERS_QUICKCOMPANYPROFILEREPORT_FAILURE',

  ASICCOMPANYDETAILREPORT_REQUEST: 'USERS_ASICCOMPANYDETAILREPORT_REQUEST',
 ASICCOMPANYDETAILREPORT_SUCCESS: 'USERS_ASICCOMPANYDETAILREPORT_SUCCESS',
  ASICCOMPANYDETAILREPORT_FAILURE: 'USERS_ASICCOMPANYDETAILREPORT_FAILURE',
 

ORDERMONITORSTATEMENT_REQUEST:'USERS_ORDERMONITORSTATEMENT_REQUEST',
ORDERMONITORSTATEMENT_SUCCESS:'USERS_ORDERMONITORSTATEMENT_SUCCESS',
ORDERMONITORSTATEMENT_FAILURE:'USERS_ORDERMONITORSTATEMENT_FAILURE',

DIGITALSIGNATURESTATEMENT_REQUEST:'USERS_DIGITALSIGNATURESTATEMENT_REQUEST',
DIGITALSIGNATURESTATEMENT_SUCCESS:'USERS_DIGITALSIGNATURESTATEMENT_SUCCESS',
DIGITALSIGNATURESTATEMENT_FAILURE:'USERS_DIGITALSIGNATURESTATEMENT_FAILURE',

ASICDEBTREPORT_REQUEST: 'USERS_ASICDEBTREPORT_REQUEST',
ASICDEBTREPORT_SUCCESS: 'USERS_ASICDEBTREPORT_SUCCESS',
ASICDEBTREPORT_FAILURE: 'USERS_ASICDEBTREPORT_FAILURE',

ELECTRONICLODGEMENTSERVICE_REQUEST:'USERS_ELECTRONICLODGEMENTSERVICE_REQUEST',
ELECTRONICLODGEMENTSERVICE_SUCCESS:'USERS_ELECTRONICLODGEMENTSERVICE_SUCCESS',
ELECTRONICLODGEMENTSERVICE_FAILURE:'USERS_ELECTRONICLODGEMENTSERVICE_FAILURE',

COMPANIESDEBTREPORT_REQUEST: 'USERS_COMPANIESDEBTREPORT_REQUEST',
COMPANIESDEBTREPORT_SUCCESS: 'USERS_COMPANIESDEBTREPORT_SUCCESS',
COMPANIESDEBTREPORT_FAILURE: 'USERS_COMPANIESDEBTREPORT_FAILURE',

INDIVIDUALELECTRONICLODGEMENTSERVICE_REQUEST:'USERS_INDIVIDUALELECTRONICLODGEMENTSERVICE_REQUEST',
INDIVIDUALELECTRONICLODGEMENTSERVICE_SUCCESS:'USERS_INDIVIDUALELECTRONICLODGEMENTSERVICE_SUCCESS',
INDIVIDUALELECTRONICLODGEMENTSERVICE_FAILURE:'USERS_INDIVIDUALELECTRONICLODGEMENTSERVICE_FAILURE',

COMPANIESDETAILLIST_REQUEST: 'USERS_COMPANIESDETAILLIST_REQUEST',
COMPANIESDETAILLIST_SUCCESS: 'USERS_COMPANIESDETAILLIST_SUCCESS',
COMPANIESDETAILLIST_FAILURE: 'USERS_COMPANIESDETAILLIST_FAILURE',

FORMSHISTORYREPORT_REQUEST: 'USERS_FORMSHISTORYREPORT_REQUEST',
FORMSHISTORYREPORT_SUCCESS: 'USERS_FORMSHISTORYREPORT_SUCCESS',
FORMSHISTORYREPORT_FAILURE: 'USERS_FORMSHISTORYREPORT_FAILURE',

DIRECTORIDREPORT_REQUEST: 'USERS_DIRECTORIDREPORT_REQUEST',
DIRECTORIDREPORT_SUCCESS: 'USERS_DIRECTORIDREPORT_SUCCESS',
DIRECTORIDREPORT_FAILURE: 'USERS_DIRECTORIDREPORT_FAILURE',


ASICINVOICEREPORT_REQUEST: 'USERS_ASICINVOICEREPORT_REQUEST',
ASICINVOICEREPORT_SUCCESS: 'USERS_ASICINVOICEREPORT_SUCCESS',
ASICINVOICEREPORT_FAILURE: 'USERS_ASICINVOICEREPORT_FAILURE',

ASICINVOICEREPORTLINK_REQUEST: 'USERS_ASICINVOICEREPORTLINK_REQUEST',
ASICINVOICEREPORTLINK_SUCCESS: 'USERS_ASICINVOICEREPORTLINK_SUCCESS',
ASICINVOICEREPORTLINK_FAILURE: 'USERS_ASICINVOICEREPORTLINK_FAILURE',

VIEWCURRENTANNUALSTATEMENTREPORT_REQUEST: 'USERS_VIEWCURRENTANNUALSTATEMENTREPORT_REQUEST',
VIEWCURRENTANNUALSTATEMENTREPORT_SUCCESS: 'USERS_VIEWCURRENTANNUALSTATEMENTREPORT_SUCCESS',
VIEWCURRENTANNUALSTATEMENTREPORT_FAILURE: 'USERS_VIEWCURRENTANNUALSTATEMENTREPORT_FAILURE',

UNITTRUSTUNITHOLDERSREPORT_REQUEST: 'USERS_UNITTRUSTUNITHOLDERSREPORT_REQUEST',
UNITTRUSTUNITHOLDERSREPORT_SUCCESS: 'USERS_UNITTRUSTUNITHOLDERSREPORT_SUCCESS',
UNITTRUSTUNITHOLDERSREPORT_FAILURE: 'USERS_UNITTRUSTUNITHOLDERSREPORT_FAILURE',

UNITTRUSTAPPLICATIONALLOTMENTJOURNALREPORT_REQUEST: 'USERS_UNITTRUSTAPPLICATIONALLOTMENTJOURNALREPORT_REQUEST',
UNITTRUSTAPPLICATIONALLOTMENTJOURNALREPORT_SUCCESS: 'USERS_UNITTRUSTAPPLICATIONALLOTMENTJOURNALREPORT_SUCCESS',
UNITTRUSTAPPLICATIONALLOTMENTJOURNALREPORT_FAILURE: 'USERS_UNITTRUSTAPPLICATIONALLOTMENTJOURNALREPORT_FAILURE',

UNITTRUSTTRANSFERJOURNALREPORT_REQUEST: 'USERS_UNITTRUSTTRANSFERJOURNALREPORT_REQUEST',
UNITTRUSTTRANSFERJOURNALREPORT_SUCCESS: 'USERS_UNITTRUSTTRANSFERJOURNALREPORT_SUCCESS',
UNITTRUSTTRANSFERJOURNALREPORT_FAILURE: 'USERS_UNITTRUSTTRANSFERJOURNALREPORT_FAILURE',

UNITTRUSTUNITHOLDERSSUMMARYREPORT_REQUEST: 'USERS_UNITTRUSTUNITHOLDERSSUMMARYREPORT_REQUEST',
UNITTRUSTUNITHOLDERSSUMMARYREPORT_SUCCESS: 'USERS_UNITTRUSTUNITHOLDERSSUMMARYREPORT_SUCCESS',
UNITTRUSTUNITHOLDERSSUMMARYREPORT_FAILURE: 'USERS_UNITTRUSTUNITHOLDERSSUMMARYREPORT_FAILURE',

COMPANYPHONENUMBERUPDATE_REQUEST: 'USERS_COMPANYPHONENUMBERUPDATE_REQUEST',
COMPANYPHONENUMBERUPDATE_SUCCESS: 'USERS_COMPANYPHONENUMBERUPDATE_SUCCESS',
COMPANYPHONENUMBERUPDATE_FAILURE: 'USERS_COMPANYPHONENUMBERUPDATE_FAILURE',

COMPANYEMAILIDUPDATE_REQUEST: 'USERS_COMPANYEMAILIDUPDATE_REQUEST',
COMPANYEMAILIDUPDATE_SUCCESS: 'USERS_COMPANYEMAILIDUPDATE_SUCCESS',
COMPANYEMAILIDUPDATE_FAILURE: 'USERS_COMPANYEMAILIDUPDATE_FAILURE',

INDIVIDUALPHONENUMBERUPDATE_REQUEST: 'USERS_INDIVIDUALPHONENUMBERUPDATE_REQUEST',
INDIVIDUALPHONENUMBERUPDATE_SUCCESS: 'USERS_INDIVIDUALPHONENUMBERUPDATE_SUCCESS',
INDIVIDUALPHONENUMBERUPDATE_FAILURE: 'USERS_INDIVIDUALPHONENUMBERUPDATE_FAILURE',

INDIVIDUALEMAILIDUPDATE_REQUEST: 'USERS_INDIVIDUALEMAILIDUPDATE_REQUEST',
INDIVIDUALEMAILIDUPDATE_SUCCESS: 'USERS_INDIVIDUALEMAILIDUPDATE_SUCCESS',
INDIVIDUALEMAILIDUPDATE_FAILURE: 'USERS_INDIVIDUALEMAILIDUPDATE_FAILURE',

FORGETPASSWORDEMAIL_REQUEST: 'USERS_FORGETPASSWORDEMAIL_REQUEST',
FORGETPASSWORDEMAIL_SUCCESS: 'USERS_FORGETPASSWORDEMAIL_SUCCESS',
FORGETPASSWORDEMAIL_FAILURE: 'USERS_FORGETPASSWORDEMAIL_FAILURE',

RESETPASSWORD_REQUEST: 'USERS_RESETPASSWORD_REQUEST',
RESETPASSWORD_SUCCESS: 'USERS_RESETPASSWORD_SUCCESS',
RESETPASSWORD_FAILURE: 'USERS_RESETPASSWORD_FAILURE',

DIV7ALOANREPORT_REQUEST: 'USERS_DIV7ALOANREPORT_REQUEST',
DIV7ALOANREPORT_SUCCESS: 'USERS_DIV7ALOANREPORT_SUCCESS',
DIV7ALOANREPORT_FAILURE: 'USERS_DIV7ALOANREPORT_FAILURE',

FORM484INFORMATION_REQUEST: 'USERS_FORM484INFORMATION_REQUEST',
FORM484INFORMATION_SUCCESS: 'USERS_FORM484INFORMATION_SUCCESS',
FORM484INFORMATION_FAILURE: 'USERS_FORM484INFORMATION_FAILURE',

REGDINFORMATION_REQUEST: 'USERS_REGDINFORMATION_REQUEST',
REGDINFORMATION_SUCCESS: 'USERS_REGDINFORMATION_SUCCESS',
REGDINFORMATION_FAILURE: 'USERS_REGDINFORMATION_FAILURE',

INDIVDUALENTITIESPROFILEREPORT_REQUEST: 'USERS_INDIVDUALENTITIESPROFILEREPORT_REQUEST',
INDIVDUALENTITIESPROFILEREPORT_SUCCESS: 'USERS_INDIVDUALENTITIESPROFILEREPORT_SUCCESS',
INDIVDUALENTITIESPROFILEREPORT_FAILURE: 'USERS_INDIVDUALENTITIESPROFILEREPORT_FAILURE',

INDIVIDUALENTITIESOFFICES_REQUEST: 'USERS_INDIVIDUALENTITIESOFFICES_REQUEST',
INDIVIDUALENTITIESOFFICES_SUCCESS: 'USERS_INDIVIDUALENTITIESOFFICES_SUCCESS',
INDIVIDUALENTITIESOFFICES_FAILURE: 'USERS_INDIVIDUALENTITIESOFFICES_FAILURE',

INDIVDUALENTITIESDID_REQUEST: 'USERS_INDIVDUALENTITIESDID_REQUEST',
INDIVDUALENTITIESDID_SUCCESS: 'USERS_INDIVDUALENTITIESDID_SUCCESS',
INDIVDUALENTITIESDID_FAILURE: 'USERS_INDIVDUALENTITIESDID_FAILURE',

INDIVDUALENTITIESSHARES_REQUEST: 'USERS_INDIVDUALENTITIESSHARES_REQUEST',
INDIVDUALENTITIESSHARES_SUCCESS: 'USERS_INDIVDUALENTITIESSHARES_SUCCESS',
INDIVDUALENTITIESSHARES_FAILURE: 'USERS_INDIVDUALENTITIESSHARES_FAILURE',

COMPANYNOTES_REQUEST: 'USERS_COMPANYNOTES_REQUEST',
COMPANYNOTES_SUCCESS: 'USERS_COMPANYNOTES_SUCCESS',
COMPANYNOTES_FAILURE: 'USERS_COMPANYNOTES_FAILURE',

COUNTRIESALL_REQUEST: 'USERS_COUNTRIESALL_REQUEST',
COUNTRIESALL_SUCCESS: 'USERS_COUNTRIESALL_SUCCESS',
COUNTRIESALL_FAILURE: 'USERS_COUNTRIESALL_FAILURE',

SIGNUP_REQUEST: 'USERS_SIGNUP_REQUEST',
SIGNUP_SUCCESS: 'USERS_SIGNUP_SUCCESS',
SIGNUP_FAILURE: 'USERS_SIGNUP_FAILURE',

UNITTRUSTTABLE_REQUEST: 'USERS_UNITTRUSTTABLE_REQUEST',
UNITTRUSTTABLE_SUCCESS: 'USERS_UNITTRUSTTABLE_SUCCESS',
UNITTRUSTTABLE_FAILURE: 'USERS_UNITTRUSTTABLE_FAILURE',

LOGINUSERINFO_REQUEST: 'USERS_LOGINUSERINFO_REQUEST',
LOGINUSERINFO_SUCCESS: 'USERS_LOGINUSERINFO_SUCCESS',
LOGINUSERINFO_FAILURE: 'USERS_LOGINUSERINFO_FAILURE',

CHANGEORDER_REQUEST: 'USERS_CHANGEORDER_REQUEST',
CHANGEORDER_SUCCESS: 'USERS_CHANGEORDER_SUCCESS',
CHANGEORDER_FAILURE: 'USERS_CHANGEORDER_FAILURE',

CLASSLIST_REQUEST: 'USERS_CLASSLIST_REQUEST',
CLASSLIST_SUCCESS: 'USERS_CLASSLIST_SUCCESS',
CLASSLIST_FAILURE: 'USERS_CLASSLIST_FAILURE',

COMPANYLISTSREPORT_REQUEST: 'USERS_COMPANYLISTSREPORT_REQUEST',
COMPANYLISTSREPORT_SUCCESS: 'USERS_COMPANYLISTSREPORT_SUCCESS',
COMPANYLISTSREPORT_FAILURE: 'USERS_COMPANYLISTSREPORT_FAILURE',

POSTRECENTCOMPANYLIST_REQUEST: 'USERS_POSTRECENTCOMPANYLIST_REQUEST',
POSTRECENTCOMPANYLIST_SUCCESS: 'USERS_POSTRECENTCOMPANYLIST_SUCCESS',
POSTRECENTCOMPANYLIST_FAILURE: 'USERS_POSTRECENTCOMPANYLIST_FAILURE',

GETRECENTCOMPANYLIST_REQUEST: 'USERS_GETRECENTCOMPANYLIST_REQUEST',
GETRECENTCOMPANYLIST_SUCCESS: 'USERS_GETRECENTCOMPANYLIST_SUCCESS',
GETRECENTCOMPANYLIST_FAILURE: 'USERS_GETRECENTCOMPANYLIST_FAILURE',

GETEBINDERPRIMEFILELIST_REQUEST: 'USERS_GETEBINDERPRIMEFILELIST_REQUEST',
GETEBINDERPRIMEFILELIST_SUCCESS: 'USERS_GETEBINDERPRIMEFILELIST_SUCCESS',
GETEBINDERPRIMEFILELIST_FAILURE: 'USERS_GETEBINDERPRIMEFILELIST_FAILURE',

MANAGEEBINDERLIST_REQUEST: 'USERS_MANAGEEBINDERLIST_REQUEST',
MANAGEEBINDERLIST_SUCCESS: 'USERS_MANAGEEBINDERLIST_SUCCESS',
MANAGEEBINDERLIST_FAILURE: 'USERS_MANAGEEBINDERLIST_FAILURE',

EBINDERLINKS_REQUEST: 'USERS_EBINDERLINKS_REQUEST',
EBINDERLINKS_SUCCESS: 'USERS_EBINDERLINKS_SUCCESS',
EBINDERLINKS_FAILURE: 'USERS_EBINDERLINKS_FAILURE',

EBINDERCUSTOMFILELIST_REQUEST: 'USERS_EBINDERCUSTOMFILELIST_REQUEST',
EBINDERCUSTOMFILELIST_SUCCESS: 'USERS_EBINDERCUSTOMFILELIST_SUCCESS',
EBINDERCUSTOMFILELIST_FAILURE: 'USERS_EBINDERCUSTOMFILELIST_FAILURE',

EBINDERCUSTOMFILEDELETE_REQUEST: 'USERS_EBINDERCUSTOMFILEDELETE_REQUEST',
EBINDERCUSTOMFILEDELETE_SUCCESS: 'USERS_EBINDERCUSTOMFILEDELETE_SUCCESS',
EBINDERCUSTOMFILEDELETE_FAILURE: 'USERS_EBINDERCUSTOMFILEDELETE_FAILURE',

EBINDERCUSTOMFILEADD_REQUEST: 'USERS_EBINDERCUSTOMFILEADD_REQUEST',
EBINDERCUSTOMFILEADD_SUCCESS: 'USERS_EBINDERCUSTOMFILEADD_SUCCESS',
EBINDERCUSTOMFILEADD_FAILURE: 'USERS_EBINDERCUSTOMFILEADD_FAILURE',

EBINDERCOMPANYLIST_REQUEST: 'USERS_EBINDERCOMPANYLIST_REQUEST',
EBINDERCOMPANYLIST_SUCCESS: 'USERS_EBINDERCOMPANYLIST_SUCCESS',
EBINDERCOMPANYLIST_FAILURE: 'USERS_EBINDERCOMPANYLIST_FAILURE',

EBINDERORDER_REQUEST: 'USERS_EBINDERORDER_REQUEST',
EBINDERORDER_SUCCESS: 'USERS_EBINDERORDER_SUCCESS',
EBINDERORDER_FAILURE: 'USERS_EBINDERORDER_FAILURE',

CHANGEPASSWORD_REQUEST: 'USERS_CHANGEPASSWORD_REQUEST',
CHANGEPASSWORD_SUCCESS: 'USERS_CHANGEPASSWORD_SUCCESS',
CHANGEPASSWORD_FAILURE: 'USERS_CHANGEPASSWORD_FAILURE',

BUSINESSNAMEDETAILLIST_REQUEST: 'USERS_BUSINESSNAMEDETAILLIST_REQUEST',
BUSINESSNAMEDETAILLIST_SUCCESS: 'USERS_BUSINESSNAMEDETAILLIST_SUCCESS',
BUSINESSNAMEDETAILLIST_FAILURE: 'USERS_BUSINESSNAMEDETAILLIST_FAILURE',

BUSINESSWELCOMEREPORT_REQUEST: 'USERS_BUSINESSWELCOMEREPORT_REQUEST',
BUSINESSWELCOMEREPORT_SUCCESS: 'USERS_BUSINESSWELCOMEREPORT_SUCCESS',
BUSINESSWELCOMEREPORT_FAILURE: 'USERS_BUSINESSWELCOMEREPORT_FAILURE',

BUSINESSCERTIFICATEREPORT_REQUEST: 'USERS_BUSINESSCERTIFICATEREPORT_REQUEST',
BUSINESSCERTIFICATEREPORT_SUCCESS: 'USERS_BUSINESSCERTIFICATEREPORT_SUCCESS',
BUSINESSCERTIFICATEREPORT_FAILURE: 'USERS_BUSINESSCERTIFICATEREPORT_FAILURE',

BUSINESSDETAILSUMMARY_REQUEST: 'USERS_BUSINESSDETAILSUMMARY_REQUEST',
BUSINESSDETAILSUMMARY_SUCCESS: 'USERS_BUSINESSDETAILSUMMARY_SUCCESS',
BUSINESSDETAILSUMMARY_FAILURE: 'USERS_BUSINESSDETAILSUMMARY_FAILURE',

BUSINESSNAMEREPORT_REQUEST: 'USERS_BUSINESSNAMEREPORT_REQUEST',
BUSINESSNAMEREPORT_SUCCESS: 'USERS_BUSINESSNAMEREPORT_SUCCESS',
BUSINESSNAMEREPORT_FAILURE: 'USERS_BUSINESSNAMEREPORT_FAILURE',

POSTBUSINESSNOTES_REQUEST: 'USERS_POSTBUSINESSNOTES_REQUEST',
POSTBUSINESSNOTES_SUCCESS: 'USERS_POSTBUSINESSNOTES_SUCCESS',
POSTBUSINESSNOTES_FAILURE: 'USERS_POSTBUSINESSNOTES_FAILURE',

GETBUSINESSNOTES_REQUEST: 'USERS_GETBUSINESSNOTES_REQUEST',
GETBUSINESSNOTES_SUCCESS: 'USERS_GETBUSINESSNOTES_SUCCESS',
GETBUSINESSNOTES_FAILURE: 'USERS_GETBUSINESSNOTES_FAILURE',

DIRECTORIDCOUNT_REQUEST: 'USERS_DIRECTORIDCOUNT_REQUEST',
DIRECTORIDCOUNT_SUCCESS: 'USERS_DIRECTORIDCOUNT_SUCCESS',
DIRECTORIDCOUNT_FAILURE: 'USERS_DIRECTORIDCOUNT_FAILURE',

EDGECOMPANYPROFILEREPORT_REQUEST: 'USERS_EDGECOMPANYPROFILEREPORT_REQUEST',
EDGECOMPANYPROFILEREPORT_SUCCESS: 'USERS_EDGECOMPANYPROFILEREPORT_SUCCESS',
EDGECOMPANYPROFILEREPORT_FAILURE: 'USERS_EDGECOMPANYPROFILEREPORT_FAILURE',
  
INDIVIDUALENTITIESWITHPAGINATION_REQUEST: 'USERS_INDIVIDUALENTITIESWITHPAGINATION_REQUEST',
INDIVIDUALENTITIESWITHPAGINATION_SUCCESS: 'USERS_INDIVIDUALENTITIESWITHPAGINATION_SUCCESS',
INDIVIDUALENTITIESWITHPAGINATION_FAILURE: 'USERS_INDIVIDUALENTITIESWITHPAGINATION_FAILURE',

};


