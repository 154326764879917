import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import moment from "moment";
import "font-awesome/css/font-awesome.min.css";
import "../MonitorsComponents/AnnualReviews.style.scss";
import Pagination from "../CommonPage/Pagination.component";
import "../CommonPage/Action.scss";

const ASICFormPortalComponent = (props) => {
  const individualElectronicLodgementService = useSelector(
    (state) => state.individualElectronicLodgementService
  );

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  let handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  // connect to API

  useEffect(() => {
    const selectedSearchValue = localStorage.getItem("inputSearchValue");
    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if (selectedCompanyCode !== null) {
      dispatch(
        userActions.getIndividualElectronicLodegementService(
          selectedCompanyCode
        )
      );
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(
          userActions.getIndividualElectronicLodegementService(value.Code)
        )
      );
    }
    // eslint-disable-next-line
  }, []);

  // search
  const [searchInput, setSearchInput] = useState("");
  const individualElectronicLodgementServiceTotalStatement =
    individualElectronicLodgementService &&
    individualElectronicLodgementService.items
      ? individualElectronicLodgementService.items.filter((filteredValue) =>
          filteredValue["CO_NAME"]
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        )
      : [];



  const indexOfLastPost = currentPage * postsPerPage;


  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const currentPosts = individualElectronicLodgementServiceTotalStatement
    ? individualElectronicLodgementServiceTotalStatement.slice(
        indexOfFirstPost,
        indexOfLastPost
      )
    : [];


  // refresh button
  function handleClickRefreshASICFormPortal(e) {
    e.preventDefault();
    setSearchInput("");
  }

  return (
    <div className="m-4 fontfamily">
      <h4 className="panel-title"> Electronic Lodgement Service </h4>
      <nav className="navbar navbar-light bg-light navbar-expand-md ">
        <div className="container">
          <h4>Corporate Register</h4>
          <div
            className=" navbar-collapse d-flex flex-row-reverse "
            id="main-nav-collapse"
          >
            <ul className="navbar-nav px-1">
              <div className=" col form-outline ">
                <input
                  type="search"
                  id="monitorsearch"
                  className="form-control-sm "
                  size="50"
                  placeholder="Search"
                  aria-label="Search"
                  autoComplete={false}
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                />
                &nbsp;
                <button
                  className="btn-sm"
                  onClick={(e) => handleClickRefreshASICFormPortal(e)}
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i>
                </button>
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <div className="table-responsive-sm">
        <table className="table table-hover fs-6">
          <thead>
            <tr>
              <th>Support</th>
              <th>Company Name</th>
              <th>Form</th>
              <th>Document Status</th>
              <th>Doc Track No.</th>
              <th className="text-wrap"> Date</th>
              <th className="text-wrap">Agent</th>
              <th className="text-wrap">Status</th>

              <th className="text-wrap"> Action </th>
            </tr>
          </thead>

          <tbody>
            {individualElectronicLodgementService &&
            currentPosts &&
            currentPosts.length === 0 ? (
              <tr>
                <td colSpan="10">No data available in table</td>
              </tr>
            ) : (
              individualElectronicLodgementService &&
              currentPosts &&
              currentPosts.map((post) => (
                <tr>
                  <td>
                    <i class="bi bi-file-lock-fill text-danger "></i>
                  </td>
                  <td>{post.CO_NAME} </td>

                  <td>{post.FORM_TYPE}</td>

                  <td>
                    {" "}
                    <button
                      className={
                        post.STATUS && post.STATUS === "PREPARED"
                          ? "btn btn-info btn-sm disabled btn-primary"
                          : post.STATUS === "VALIDATED "
                          ? "btn btn-info btn-sm disabled btn-success"
                          : post.STATUS === "TRANSMITTED - REJECTED"
                          ? "btn btn-info btn-sm disabled btn-danger"
                          : post.STATUS === "TRANSMITTED - AWAITING VALIDATION"
                          ? "btn btn-info btn-sm disabled btn-warning"
                          : "btn btn-info btn-sm disabled"
                      }
                    >
                      {post.STATUS}
                    </button>
                  </td>

                  <td>{post.DOC_CODE}</td>

                  <td>{moment(post.FORM_DATE).format("DD/MM/YYYY")}</td>
                  <td>{post.AGENT_CODE}</td>
                  <td>
                    <button
                      className={
                        post.DUE_DAYS &&
                        post.DUE_DAYS.trim().indexOf("DUE In") !== -1
                          ? "btn btn-info btn-sm disabled  btn-warning"
                          : post.DUE_DAYS.trim().indexOf("DUE -") !== -1
                          ? "btn btn-info btn-sm disabled btn-success"
                          : post.DUE_DAYS.trim().indexOf("OVERDUE By") !== -1
                          ? "btn btn-info btn-sm disabled  btn-danger"
                          : ""
                      }
                    >
                      {post.DUE_DAYS}
                    </button>
                  </td>

                  <td>
                    <a
                      href={() => false}
                      className="d-flex align-items-center text-white text-decoration-none "
                      id="dropdownUserDetail"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                    </a>
                    <ul className="dropdown-menu text-dark">
                      <li className="action ">
                        <a className="dropdown-item " href={() => false}>
                          View Transmit File
                        </a>{" "}
                        <hr />
                      </li>

                      <li className="action ">
                        <a className="dropdown-item " href={() => false}>
                          Tracking
                        </a>
                        <hr />
                      </li>
                      <li className="action ">
                        <a className="dropdown-item " href={() => false}>
                          Summary
                        </a>
                        <hr />
                      </li>
                      <li className="py-0 ">
                        <a className="dropdown-item " href={() => false}>
                          Log
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {individualElectronicLodgementServiceTotalStatement &&
        individualElectronicLodgementServiceTotalStatement.length >= 10 && (
          <footer
            id="sticky-footer"
            class="footer d-flex justify-content-end footer-body  "
          >
            <div class="container fs-6 ">
              <div class="row">
                <div class="col-md-6 ">
                  Showing{" "}
                  <input
                    type="text"
                    className="form-control-sm border border-default"
                    onKeyPress={handleKeyPress}
                    name="showRecord"
                    value={postsPerPage}
                    maxLength="3"
                    size="3"
                    onChange={(e) => setPostsPerPage(e.target.value)}
                  ></input>{" "}
                  /
                  {individualElectronicLodgementServiceTotalStatement
                    ? individualElectronicLodgementServiceTotalStatement.length
                    : 0}
                </div>
                <div class="col-md-6">
                  {postsPerPage && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={
                        individualElectronicLodgementServiceTotalStatement
                          ? individualElectronicLodgementServiceTotalStatement.length
                          : 0
                      }
                      pageSize={postsPerPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </footer>
        )}
        <br/>
        <br/>
    </div>
  );
};
export default ASICFormPortalComponent;
