import React, { useState } from "react";
import MainScreen from "../CommonPage/MainScreen.component";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Popup from "../CommonPage/Popup.component";

const ResetPassword = (props) => {
  const [loginUser, setLoginUser] = useState({
    // clientId: '',
    userName: "",
  });

  const [submitted, setSubmitted] = useState(false);
  let handleChange = (e) => {
    setLoginUser((users) => ({
      ...loginUser,
      [e.target.name]: e.target.value,
    }));
  };
  const dispatch = useDispatch();
  const resetPassword = useSelector((state) => state.resetPassword);
  const [popupResetErrorShow, setPopupResetErrorShow] = useState(false);
  const handleResetErrorPopupClose = () => setPopupResetErrorShow(false);
  const handleResetErrorPopupShow = (e) => {
    e.preventDefault();
    setPopupResetErrorShow(true);
  };

  let handleErrorPopupClick = (e) => {
    e.preventDefault();
    handleResetErrorPopupClose();
  };


  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    const uId=window.location.search.split("=");
 
    if(uId){
    dispatch(userActions.postResetPassword(loginUser.userName,loginUser.password,loginUser.confirmPassword,uId[1]));
    setPopupResetErrorShow(true)
   
  }
  }
  


  return (
    <MainScreen
      content={
        <div className="container  ">
          <form onSubmit={handleSubmit}>
            <div className="form-group ">
              <label htmlFor="userName" className="col-form-label">
                Username
              </label>
              <input
                type="text"
                name="userName"
                value={loginUser.userName}
                onChange={handleChange}
                className={
                  "form-control input-sm " +
                  (submitted && !loginUser.userName ? "is-invalid" : "")
                }
                placeholder="Please enter your username..."
                maxLength="30"
                size="25"
              />
              {submitted && !loginUser.userName && (
                <div className="invalid-feedback">Username Required</div>
              )}
            </div>
            <div className="form-group ">
            <label htmlFor="password" className="col-form-label">Password</label>
            <input type="password" name="password" value={loginUser.password} onChange={handleChange} className={'form-control input-sm ' + (submitted && !loginUser.password ? 'is-invalid' : '')} placeholder="password." maxLength="20" />
            {submitted && !loginUser.password && (
              <div className="invalid-feedback">Password Required</div>
            )}              
            </div>
        <div className="form-group ">
            <label htmlFor="password" className="col-form-label">Confirm Password</label>
            <input type="password" name="confirmPassword" value={loginUser.confirmPassword} onChange={handleChange} className={'form-control input-sm ' + (submitted && !loginUser.confirmPassword ? 'is-invalid' : '')} placeholder="Confirm Password." maxLength="20" />
            {submitted && !loginUser.confirmPassword && (
              <div className="invalid-feedback">ConfirmPassword Required</div>
            )}
        </div>
            <br />
            <div className="d-grid">
              <div className="col-auto col-md-12 text-center">
                <button className="buttonCommon" type="submit" id="reSet" onClick={(e) =>handleSubmit(e)}>
                  Reset
                </button>
                {popupResetErrorShow &&
                  resetPassword &&
                  resetPassword.error &&
                  resetPassword.error.indexOf("400") !== -1 ? (
                    <Popup
                      show={popupResetErrorShow}
                      handleClose={handleResetErrorPopupClose}
                      handleShow={(e) => handleResetErrorPopupShow(e)}
                      body={"  Reset username error"}
                      handleClick={handleErrorPopupClick}
                    />
                  ) : resetPassword && resetPassword.items ? (
                    props.history.push("/PasswordChangeSuccess")
                  ) : (
                    ""
                  )}
              </div>
            </div>
           
          </form>
        </div>
      }
    />
  );
};
export default ResetPassword;
