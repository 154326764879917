import React from "react";
import MainScreen from "../CommonPage/MainScreen.component";

const ResetPasswordInstruction = (props) => {
    
    return (
      <MainScreen
        content={
          <div className="container ">
            <form >
              <div className="form-group ">
              <h5 className="text-center"  style={{fontSize:'16px', color: '#012040', lineHeight: '20px', fontWeight: '400'}}>
              We’ve received your request!
                </h5>
              </div>
              <div className="container  d-flex justify-content-center">
              <div className="row ">
              
              <div   className= "text-center" style={{fontSize:'14px', color: '#012040', lineHeight: '20px', fontWeight: '400'}}>
                         
                           You will receive an email from us shortly with 
                              instructions on how to reset your password.
              </div>
              </div>
              
              </div>
           <br />
           <hr className="my-12" />
           <br />
           <div className="form-group">
           <div className="col-auto col-md-12 text-center ">
           <span style={{fontSize:'16px', lineHeight: '19.36 px', color: '#A6A6A6',fontStyle: 'normal', fontWeight: '400',letterspacing: '0.25px',}}> Already have an account?</span>
            
             <a
               href={() => false}
               style={{fontSize:'16px', lineHeight: '19.36 px', color: ' #012040',fontStyle: 'normal', fontWeight: '550',letterspacing: '0.25px',}}
              
               className="  hand-cursor  text-decoration-none"
               onClick={() => props.history.push("/login")}
             >
               {" "}
            Login
             </a>
           </div>
         </div>
             
            </form>
          </div>
        }
      />
    );
  };
  
export default ResetPasswordInstruction;