import React, { useState, useEffect } from "react";
import "../MonitorsComponents/AnnualReviews.style.scss";
import Pagination from "../CommonPage/Pagination.component";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";

const BenificialOwnerComponent = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  let handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getAllBeneficialOwner());
    

    // eslint-disable-next-line
  }, []);
  const beneficialOwner = useSelector((state) => state.beneficialOwner);
 
  // search
  const [searchInputBeneficialOwner, setsearchInputBeneficialOwner] =
    useState("");
  const beneficialOwnerTotal =
    beneficialOwner && beneficialOwner.items
      ? beneficialOwner.items.filter(
          (filteredValue) =>
            filteredValue["BEN_NAME"] &&
            filteredValue["BEN_NAME"]
              .toLowerCase()
              .includes(searchInputBeneficialOwner.toLowerCase())
        )
      : [];

  // console.log(posts);

  const indexOfLastPost = currentPage * postsPerPage;
  // console.log("indexOfLastPost: ", indexOfLastPost);

  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // console.log("indexOfFirstPost: ", indexOfFirstPost);

  const currentPosts = beneficialOwnerTotal
    ? beneficialOwnerTotal.slice(indexOfFirstPost, indexOfLastPost)
    : [];

  // refresh button
  function handleClickRefreshBeneficialOwner(e) {
    e.preventDefault();
    setsearchInputBeneficialOwner("");
  }

  return (
    <div className="m-4 fontfamily">
      <h2 className="panel-title">Beneficial Owners & Trusts </h2>

      <nav className="navbar navbar-light bg-light navbar-expand-md ">
        <div className="container">
          <div
            className=" navbar-collapse d-flex flex-row-reverse "
            id="main-nav-collapse"
          >
            <ul className="navbar-nav">
              <div className=" col form-outline">
                <input
                  type="search"
                  id="CompaniesSearch"
                  className="form-control-sm "
                  size="50"
                  placeholder="Search"
                  aria-label="Search"
                  autoComplete={false}
                  value={searchInputBeneficialOwner}
                  onChange={(e) => {
                    setsearchInputBeneficialOwner(e.target.value);
                  }}
                />
              </div>
              &nbsp;
              <button
                className="btn-sm"
                onClick={(e) => handleClickRefreshBeneficialOwner(e)}
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </ul>
          </div>
        </div>
      </nav>

      <div className="table-responsive-sm">
        <table className="table table-hover fs-6">
          <thead>
            <tr>
              <th className="text-wrap">Beneficial Owner Name</th>
              <th className="text-wrap w-25">TFN</th>

              <th> Action </th>
            </tr>
          </thead>

          <tbody className=" fs-6">
            {
              
                beneficialOwner && currentPosts&& currentPosts.length ===0 ?              
                <tr><td colSpan="3">No matching records found</td></tr>:
              beneficialOwner &&
              currentPosts &&
              currentPosts.map((post) => (
                <tr>
                  <td>{post.BEN_NAME} </td>
                  <td>{post.TFN} </td>

                  <td>
                    <a
                      href={() => false}
                      className="d-flex align-items-center text-white text-decoration-none "
                      id="dropdownUserDetail"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                    </a>
                    <ul className="dropdown-menu text-dark">
                      <li className="py-0 ">
                        <a className="dropdown-item " href={() => false}>
                          View
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {beneficialOwnerTotal && beneficialOwnerTotal.length >= 10 && (
        <footer
          id="sticky-footer"
          class="footer d-flex justify-content-end footer-body  "
        >
          <div class="container fs-6 ">
            <div class="row">
              <div class="col-md-6 ">
                Showing{" "}
                <input
                  type="text"
                  className="form-control-sm border border-default"
                  onKeyPress={handleKeyPress}
                  name="showRecord"
                  value={postsPerPage}
                  maxLength="3"
                  size="3"
                  onChange={(e) => setPostsPerPage(e.target.value)}
                ></input>
                {""}/{beneficialOwnerTotal ? beneficialOwnerTotal.length : 0}
              </div>
              <div class="col-md-6">
                {postsPerPage && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={
                      beneficialOwnerTotal ? beneficialOwnerTotal.length : 0
                    }
                    pageSize={postsPerPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </footer>
      )}
      <br/>
      <br/>
    </div>
  );
};
export default BenificialOwnerComponent;
