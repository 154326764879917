import { userConstants } from "../constants/login.constants";

 function businessNameSummary(state = {}, action) {
    switch (action.type) {
        case userConstants.BUSINESSDETAILSUMMARY_REQUEST:
            return {
                loading: true
            };
        case userConstants.BUSINESSDETAILSUMMARY_SUCCESS:
            return {
                items: action.businessNameSummary
            };
        case userConstants.BUSINESSDETAILSUMMARY_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export default businessNameSummary;