import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "100%",
    padding: "50px",
    fontSize: "20px",
  },
};

export const TimeoutWarningModal = ({ isOpen, onRequestClose, history }) => {
  const onLogOffCall = () => {
    // Implement your logout functionality here, e.g. clear the users login cache or hit your signout server
    history.push("/");
  };

  return (
    <div className="container">
      
        <Modal
          isOpen={isOpen}
          style={customStyles}
          contentLabel="Session Expired"
          ariaHideApp={false}
        >
          <h4>Session Timeout</h4>
          <div>
            You're being timed out due to inactivity. 
            you will be redirected to  the Login page.
          </div>
          <br />
          <div class="row">
          <div class="col">
          <button class="btn btn-primary form-control  btn-block" onClick={onLogOffCall}>Log off</button></div>
        
          </div>
        </Modal>
      
    </div>
  );
};
