import { userConstants } from "../constants/login.constants";

 function asicInvoiceReportLinks(state = {}, action) {
    switch (action.type) {
        case userConstants.ASICINVOICEREPORTLINK_REQUEST:
            return {
                loading: true
            };
        case userConstants.ASICINVOICEREPORTLINK_SUCCESS:
            return {
                items: action.asicInvoiceReportLinks
            };
        case userConstants.ASICINVOICEREPORTLINK_FAILURE:
            return {
                error: action.errorAsicInvoiceReportLink
            };
        default:
            return state
            
    }
}
export default asicInvoiceReportLinks;