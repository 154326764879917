import React, { useEffect,useState } from "react";
import MainScreen from "../CommonPage/MainScreen.component";
import { useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";



const ForgetPassword = (props) => {

  const [loginUser, setLoginUser] = useState({
    // clientId: '',
    userName: "",
  });

  const [submitted, setSubmitted] = useState(false);
  let handleChange = (e) => {
    setLoginUser((users) => ({
      ...loginUser,
      [e.target.name]: e.target.value,
    }));
  };

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    //   if (loginUser.clientId && loginUser.userName) {
    if (loginUser.userName) {
      console.log("Success");
    }
  }

  // Reset email call
  const dispatch = useDispatch();
  const forgetPasswordEmail = useSelector((state) => state.forgetPasswordEmail);
  
  // eslint-disable-next-line
  const [resetSubmitted, resetSetSubmitted] = useState(false);
  function resetHandleClick(e) {
    e.preventDefault();
    resetSetSubmitted(true);
    setSubmitted(true);
    //  console.log("(forgetPasswordEmail.error.indexOf",forgetPasswordEmail.error);
    dispatch(userActions.postForgetpasswordEmail(loginUser.userName));

   
  }

  
  useEffect(() => {
    console.log("forgetPasswordEmail && forgetPasswordEmail.items",forgetPasswordEmail && forgetPasswordEmail.items)
    if (forgetPasswordEmail && forgetPasswordEmail.items && forgetPasswordEmail.items === "success")
    {
            props.history.push("/ResetSuccess")
    } 
    // eslint-disable-next-line
  }, [forgetPasswordEmail]);
  return (
    <MainScreen
      content={
       
        <div className="container py-2 ">
      
        
        
        <p className="py-2"></p>
      
          <form onSubmit={handleSubmit}>
            <div className="form-group ">
              
              <input
                type="text"
                size="50"
                style={{fontSize:'16px', color: '#012040', lineHeight: '19px', fontWeight: '400'}}
                name="userName"
                value={loginUser.userName}
                onChange={handleChange}
                className={
                  "form-control form-control-lg " +
                  (submitted && !loginUser.userName ? "is-invalid" : "")
                }
                placeholder="Username"
             
              />
              {submitted && !loginUser.userName && (
                <div className="invalid-feedback "  style={{fontSize:'14px', color: '#CF0E0E', lineHeight: '19px', fontWeight: '400'}}>Username Required</div>
              )}
            </div>
            <br />
            <div className="d-grid">
              <div className="col-auto col-md-12 text-center">
                <button
                className={(loginUser.userName) === "" ? "buttonDisable  w-100" : "buttonActive w-100"}
              
                  type="submit"
                  id="sendLink"
                  onClick={(e) => resetHandleClick(e)}
                >
                 RESET PASSWORD
                </button>

              </div>
            </div>
            <br />
            <hr className="my-12" />
            <br />
            <div className="form-group">
            <div className="col-auto col-md-12 text-center ">
            <span style={{fontSize:'16px', lineHeight: '19.36 px', color: '#A6A6A6',fontStyle: 'normal', fontWeight: '400',letterspacing: '0.25px',}}> Already have an account?</span>
             
              <a
                href={() => false}
                style={{fontSize:'16px', lineHeight: '19.36 px', color: ' #012040',fontStyle: 'normal', fontWeight: '550',letterspacing: '0.25px',}}
               
                className=" hand-cursor  text-decoration-none"
                onClick={() => props.history.push("/login")}
              >
                {" "}
             Login
              </a>
            </div>
          </div>
            
          </form>
        </div>
       
      }
    />
  );
};
export default ForgetPassword;
