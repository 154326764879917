import { userConstants } from "../constants/login.constants";

 function unitTrust(state = {}, action) {
    switch (action.type) {
        case userConstants.UNITTRUSTALL_REQUEST:
            return {
                loading: true
            };
        case userConstants.UNITTRUSTALL_SUCCESS:
            return {
                items: action.unitTrust
            };
        case userConstants.UNITTRUSTALL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export default unitTrust;