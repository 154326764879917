import { userConstants } from "../constants/login.constants";

 function viewCurrentAnnualStatementReport(state = {}, action) {
    switch (action.type) {
        case userConstants.VIEWCURRENTANNUALSTATEMENTREPORT_REQUEST:
            return {
                loading: true
            };
        case userConstants.VIEWCURRENTANNUALSTATEMENTREPORT_SUCCESS:
            return {
                items: action.viewCurrentAnnualStatementReport
            };
        case userConstants.VIEWCURRENTANNUALSTATEMENTREPORT_FAILURE:
            return {
                error: action.errorViewCurrentAnnualStatement
            };
        default:
            return state
    }
}
export default viewCurrentAnnualStatementReport;