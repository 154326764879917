import React, { useState } from "react";
import FullPageLoader from "../CommonPage/FullPageLoader";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";

const CompanyListReport = (props) => {
  const dispatch = useDispatch();
  const [companyList, setCompanyList] = useState({
    email: "",
    dept: "",
    officer: "No",
  });

  //loading spinner
  const [isCompanyList, setIsCompanyList] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  let handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyList((companyList) => ({ ...companyList, [name]: value }));
  };

  const selectedSearchValue = localStorage.getItem("inputSearchValue");
  const inputSearchValue = JSON.parse(localStorage.getItem("inputSearchValue"));
  const companyCode = JSON.parse(localStorage.getItem("companyCode"));
  const validate = companyCode !== null || (inputSearchValue !== null && inputSearchValue.length > 0) || (props.searchCompany && props.searchCompany.length > 0);
  
  function handleClick(e) {
    setSubmitted(true);

    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
 
    if(props.searchCompany && props.searchCompany[0]) {
      dispatch(userActions.getCompanyListReport( props.searchCompany[0].Code,companyList,
        setIsCompanyList));
    } else if (selectedCompanyCode !== null) {
      dispatch(
        userActions.getCompanyListReport(
          selectedCompanyCode,
          companyList,
          setIsCompanyList
        )
      );
    } else if (selectedSearchValue !== null) {
      console.log("")
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(
          userActions.getCompanyListReport(
            value.Code,
            companyList,
            setIsCompanyList
          )
        )
      );
    }
  }

  return (
    <div class="container">
      <h3 className="mb-4">Enter Values</h3>

      <table className="table table-responsive table-borderless">
        <tbody>
          <tr>
            <td className="fw-bold w-50 ">
              Enter Dept Code(Blank for all departements)
            </td>

            <td colspan="2" className="w-75">
              <input
                type="text"
                name="dept"
                onChange={handleChange}
                value={companyList.dept}
                placeholder="Enter Dept Code /AgentID"
              />
            </td>
          </tr>

          <tr>
            <td className="fw-bold w-50 ">
              Show EmailAddresses((Blank for show All)):
            </td>{" "}
            <td colspan="2" className="w-75">
              <select
                name="email"
                id="email"
                className="dropDownBox w-50"
                onChange={(e) => {
                  setCompanyList(() => ({
                    ...companyList,
                    [e.target.name]: e.target.value,
                  }));
                }}
                required
              >
                <option value="" selected>
                  {" "}
                </option>
                <option value="show All">show All</option>
                <option value="Show No Emails Entered">
                  show No Emails Entered
                </option>
                <option value="Show Emails Entered">show Emails Entered</option>
              </select>
            </td>
          </tr>
          <tr>
            <td className="fw-bold w-50 ">Show Officers/Members</td>{" "}
            <td>
              {" "}
              <label>
                <input
                  type="radio"
                  name="officer"
                  checked={companyList.officer === "Yes"}
                  onClick={handleChange}
                  value="Yes"
                />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
                <input
                  type="radio"
                  name="officer"
                  checked={companyList.officer === "No"}
                  onClick={handleChange}
                  value="No"
                />
                NO
              </label>
            </td>
          </tr>

          <tr>
            <td>
              {" "}
              <button
                className={
                  submitted && !validate ? "btn btn-danger" : "btn btn-primary"
                }
                onClick={(e) => handleClick(e)}
              >
                Show Report
              </button>
              {isCompanyList ? <FullPageLoader /> : null}
              {submitted && !validate && (
                <div className="text-danger py-3">
                  Make sure Company Name selected / Report doesn't exist.
                </div>
              )}
            </td>
          </tr>
        </tbody>{" "}
      </table>
    </div>
  );
};

export default CompanyListReport;
