import axios from "axios";
import { encode as base64_encode } from "base-64";
import moment from "moment";
import Cookies from "universal-cookie";
import { isLocalhost } from "../../util/helpers";

export const userService = {
  login,
  logout,
  getAllCompany,
  getCompanyProfileByCode,
  getAllDepartement,
  getUnitTrustByCode,
  getAnnualStatement,
  getAnnualStatementMonthStatus,
  getAsicDebtStatement,
  getCompanyProfileReport,
  getMembersReport,
  getMembersReportDateRange,
  getDirectorsSecretariesReport,
  getAllotementJournalReport,
  getTransferJournalReport,
  getResponsibleManagerReport,
  getPublicOfficerReport,
  getDirectorsShareholdingsReport,
  getOfficerDetailsReport,
  getShareHolderDetailsReport,
  getDocumentHistoryDocReport,
  getDocumentHistoryAsicReport,
  getAsicDocumentTracking,
  getIndividualsEntities,
  getAllIndividualsEntities,
  getAllIndividualsEntitiesWithPagination,
  getAllJointHolders,
  getJointHolders,
  getAllBeneficialOwner,
  getBeneficialOwner,
  getCompanyReport,
  getQuickCompanyProfileReport,
  getAsicCompanyDetailReport,
  isUnitTrustEmpty,
  getOrderMonitorStatement,
  getDigitalSignatureStatement,
  getAsicDebtReport,
  getElectronicLodegementService,
  getCompaniesDebtReport,
  getIndividualElectronicLodegementService,
  getCompaniesDetailList,
  getFormsHistoryReport,
  getDirectorIdReport,
  getUnitTrustUnitholdersReport,
  getUnitTrustApplicationAllotmentJournalReport,
  getUnitTrustTransferJournalReport,
  getUnitTrustUnitholdersSummaryReport,
  getAsicInvoiceReport,
  getAsicInvoiceReportLinks,
  getViewCurrentAnnualStatementReport,
  postCompanyPhoneNumber,
  postCompanyEmailID,
  postIndividualPhoneNumber,
  postIndividualEmailID,
  postForgetpasswordEmail,
  postResetPassword,
  getDiv7ALoanReport,
  getForm484Information,
  getRegdInformation,
  getIndividualEntitiesProfileReport,
  getIndividualEntitiesOffices,
  postIndividualEntitiesDid,
  getIndividualEntitiesShares,
  postCompanyNotes,
  getAllCountries,
  postSignup,
  getUnitTrustTable,
  getLoggedUserInfo,
  postChangeOrderRequest,
  getClassList,
  getCompanyListReport,
  postRecentCompanyList,
  getRecentCompanyList,
  getEbinderPrimeFiles,
  getManageEbinderList,
  getEbinderLink,
  getEbinderCustomFileList,
  postEbinderCustomFileDelete,
  postEbinderCustomFileAdd,
  getEbinderCompanyList,
  postCreateEbinder,
  postChangePassword,
  getBusinessNameDetailList,
  getBusinessWelcomeReport,
  getBusinessCertificateReport,
  getBusinessNameSummary,
  getBusinessNameReport,
  postBusinessNotes,
  getBusinessNotes,
  getDirectorIdCount,
  getEdgeCompanyProfileReport,
};

const API_URL = process.env.REACT_APP_PRIME_API_URL;
export let unitTrustRecord = 0;
/*async function login(clientId, userName, password) {
  let encrypt = `${clientId}:${userName}:${password}`;
  const params = JSON.stringify({ clientId, userName });
  */

const cookies = new Cookies();

const isAppOnLocalhost = isLocalhost()
  ? ".localhost.com"
  : process.env.REACT_APP_DOMAIN;

const cookieOptions = {
  domain: isAppOnLocalhost,
  path: "/",
};

async function login(userName, password) {
  let encrypt = `${userName}:${password}`;
  const params = JSON.stringify({ userName });

  return await axios
    .post(`${API_URL}/api/Authenticate`, params, {
      headers: { Authorization: "Basic " + base64_encode(encrypt) },
    })
    .then((response) => {
      localStorage.setItem("token", JSON.stringify(response.headers.token));
      localStorage.setItem("userName", userName);
      localStorage.setItem("password", password);

      cookies.set(
        "token",
        JSON.stringify(response.headers.token),
        cookieOptions
      );
      cookies.set("userName", userName, cookieOptions);
      cookies.set("password", password, cookieOptions);

      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("token");
  localStorage.removeItem("password");
  localStorage.removeItem("userName");
  localStorage.removeItem("inputSearchValue");
  localStorage.removeItem("selectedSearchValue");
  localStorage.removeItem("companyName");
  localStorage.removeItem("companyCode");
  localStorage.removeItem("recentCompanySearch");

  cookies.remove("token", cookieOptions);
  cookies.remove("userName", cookieOptions);
  cookies.remove("password", cookieOptions);
  cookies.remove("fullName", cookieOptions);
  cookies.remove("roleId", cookieOptions);
}

async function getAllCompany(token) {
  console.log("token:::::::::::::::::::::::", token);
  return await axios
    .get(`${API_URL}/apiv2/RegCompany/GetCompanies?department=@all`, {
      headers: {
        token: token ? token : JSON.parse(localStorage.getItem("token")),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      localStorage.removeItem("inputSearchValue");
      localStorage.removeItem("selectedSearchValue");
      localStorage.removeItem("companyName");
      localStorage.removeItem("companyCode");
      localStorage.removeItem("recentCompanySearch");
    });
}
async function getCompanyProfileByCode(code) {
  return await axios
    .get(
      `${API_URL}/apiv2/RegCompany/GetCompanyDetail/${encodeURIComponent(code)}`,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      localStorage.removeItem("inputSearchValue");
      localStorage.removeItem("selectedSearchValue");
      localStorage.removeItem("companyName");
      localStorage.removeItem("companyCode");
      localStorage.removeItem("recentCompanySearch");
      return Promise.reject(error);
    });
}

async function getAllDepartement() {
  return await axios
    .get(`${API_URL}/apiv2/APIUser/GetDepartmentList`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data !== "No Department Found!" ? response.data : "";
    })
    .catch((error) => {
      // console.warn(error.response, "error");
      // if (error.response.status === 401) {
      //   logout();
      // }
      return Promise.reject(error);
    });
}
async function getUnitTrustByCode(companyCode, mode) {
  return await axios
    .get(
      `${API_URL}/apiv2/RegCompany/GetUnitTrust/${encodeURIComponent(companyCode)}/${mode}`,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      unitTrustRecord =
        response.data !== "No Unit Trust Found." ? response.data.length : "";
      return response.data !== "No Unit Trust Found." ? response.data : "";
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getAnnualStatement(currentYear, selectedMonth) {
  return await axios
    .get(
      `${API_URL}/apiv2/AnnualStatement/GetAnnualStatementMonthList_New/${currentYear}/${selectedMonth}`,
      //existing API accepts boolean value of current, previous year
      // `${API_URL}/apiv2/AnnualStatement/GetAnnualStatementMonthList/${currentYear}/${selectedMonth}`,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data.CompanyList;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAnnualStatementMonthStatus(currentYear) {
  return await axios
    .get(
      `${API_URL}/apiv2/AnnualStatement/GetMonthlyStatus_New/${currentYear}`,
      {
        //existing API  bellow accepts boolean value of current, previous year
        //`${API_URL}/apiv2/AnnualStatement/GetMonthlyStatus/${currentYear}`, {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAsicDebtStatement() {
  return await axios
    .get(`${API_URL}/apiv2/CompanyDebt/GetCompanyDebtList`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getCompanyProfileReport() {
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/QuickRegistryCompanyProfile/1APRIMEPROPRI01`,
      {
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/pdf",
        },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      //window.open(response.data)
      return fileURL;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMembersReport(members, companyCode) {
  const memberReportParams = `As At Date=${moment(members.printDate).format(
    "DD-MM-YY"
  )},Show Beneficial Owner Name=${
    members.benificialName
  },Show Holding Summmary OnlyQQQ=${
    members.holdingSummary
  },Show Zero BalancesQQQ=${members.zeroBalance},Show Address FieldsQQQ=${
    members.address
  },Show Foreign Currency (if any)QQQ=${
    members.foreignCurrency
  },Show Total Shares=${members.totalShares}`;

  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/corpregMembers/${companyCode}/${memberReportParams}`,
      {
        responseType: "arraybuffer",
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/pdf",
        },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMembersReportDateRange(members, companyCode) {
  //https://testserver.primecloud.com.au/apiv2/PrimeReports/ShowReport/corpregMembersDateRange/181CAPITALPTY01/From Date=01-05-2023,As At Date=30-05-2023,Show Beneficial Owner Name=Yes,Show Holding Summmary OnlyQQQ=No,Show Zero BalancesQQQ=Yes,Show Address FieldsQQQ=Yes,Show Foreign Currency (if any)QQQ=No,Show Total Shares=No

  const memberReportParams = `From Date=${moment(members.fromDate).format("DD-MM-YY")}
  ,As At Date=${moment(members.toDate).format("DD-MM-YY")}
  ,Show Beneficial Owner Name=${
    members.benificialName
  },Show Holding Summmary OnlyQQQ=${
    members.holdingSummary
  },Show Zero BalancesQQQ=${members.zeroBalance},Show Address FieldsQQQ=${
    members.address
  },Show Foreign Currency (if any)QQQ=${
    members.foreignCurrency
  },Show Total Shares=${members.totalShares}`;

  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/corpregMembersDateRange/${companyCode}/${memberReportParams}`,
      {
        responseType: "arraybuffer",
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/pdf",
        },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDirectorsSecretariesReport(officers, companyCode) {
  const directorsSecretariesReportParams = `As At Date=${moment(
    officers.dsPrintDate
  ).format("DD-MM-YYYY")},Current Officers=${officers.officer}`;

  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/directorsNsecretarie/${companyCode}/${directorsSecretariesReportParams}`,
      {
        responseType: "arraybuffer",
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/pdf",
        },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAllotementJournalReport(allotmentJournal, companyCode) {
  const allotmentJournalReportParams = `As At Date=${moment(
    allotmentJournal.ajPrintDate
  ).format("DD-MM-YYYY")}`;

  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/allotmentJournal/${companyCode}/${allotmentJournalReportParams}`,
      {
        responseType: "arraybuffer",
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/pdf",
        },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getTransferJournalReport(transferJournal, companyCode) {
  const transferJournalReportParams = `As At Date=${moment(
    transferJournal.tjPrintDate
  ).format("DD-MM-YYYY")}`;

  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/transferJournal/${companyCode}/${transferJournalReportParams}`,
      {
        responseType: "arraybuffer",
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/pdf",
        },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getResponsibleManagerReport(responsibleManager, companyCode) {
  const responsibleManagerReportParams = `As At Date=${moment(
    responsibleManager.rmPrintDate
  ).format("DD-MM-YYYY")},Current Officers=${responsibleManager.rofficer}`;

  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/responsibleManager/${companyCode}/${responsibleManagerReportParams}`,
      {
        responseType: "arraybuffer",
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/pdf",
        },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getPublicOfficerReport(publicOfficers, companyCode) {
  const PublicOfficerReportParams = `As At Date=${moment(
    publicOfficers.poPrintDate
  ).format("DD-MM-YYYY")},Current Officers=${publicOfficers.publicOfficer}`;

  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/publicOfficer/${companyCode}/${PublicOfficerReportParams}`,
      {
        responseType: "arraybuffer",
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/pdf",
        },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDirectorsShareholdingsReport(
  directorshareholdings,
  companyCode
) {
  const DirectorsShareholdingsReportParams = `As At Date=${moment(
    directorshareholdings.dsprintDate
  ).format("DD-MM-YYYY")}`;

  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/directorShareholding/${companyCode}/${DirectorsShareholdingsReportParams}`,
      {
        responseType: "arraybuffer",
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/pdf",
        },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getOfficerDetailsReport(officerCode) {
  return await axios
    .get(
      `${API_URL}/apiv2/RegCompany/GetShapShotOfficersDetail/${officerCode}`,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getShareHolderDetailsReport(code, holderType) {
  return await axios
    .get(
      `${API_URL}/apiv2/RegCompany/GetShapShotShareholdersDetail/${code}/${holderType}`,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDocumentHistoryDocReport(RN, formNo, docNo) {
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/GetDocumentPackage//${RN}/${formNo}/${docNo}`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((errorDocumentHistory) => {
      return Promise.reject(errorDocumentHistory);
    });
}
async function getDocumentHistoryAsicReport(asicDocNo) {
  return await axios
    .get(`${API_URL}/apiv2/PrimeReports/ShowReport/${asicDocNo}`, {
      responseType: "arraybuffer",
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((errorAsicDocumentHistory) => {
      return Promise.reject(errorAsicDocumentHistory);
    });
}
async function getAsicDocumentTracking(code) {
  return await axios
    .get(
      `${API_URL}/apiv2/Document/GetDocumentTracking/${encodeURIComponent(code)}`,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAllIndividualsEntities() {
  return getIndividualsEntities("all");
}

async function getIndividualsEntities(companyCode) {
  return await axios
    .get(
      `${API_URL}/apiv2/IndividualEntities/GetIndividualEntitiesList/${companyCode}`,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAllIndividualsEntitiesWithPagination(
  search,
  sortBy,
  sortOrder,
  countPerPage,
  pageNo
) {
  const individualEntitiesParams = `search=${search}&sortBy=${sortBy}&sortOrder=${sortOrder}&countPerPage=${countPerPage}&pageNo=${pageNo}`;
  return await axios
    .get(
      `${API_URL}/apiv2/IndividualEntities/GetIndividualEntities?${individualEntitiesParams}`,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getAllJointHolders() {
  return getJointHolders("all");
}

async function getJointHolders(code) {
  return await axios
    .get(`${API_URL}/apiv2/JointHolders/GetJointHoldersList/${code}`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAllBeneficialOwner() {
  return getBeneficialOwner("all");
}

async function getBeneficialOwner(code) {
  return await axios
    .get(`${API_URL}/apiv2/Benficialowner/GetBenficialOwnerList/${code}`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getCompanyReport(printCompany, companyCode) {
  const asAtDate = moment(printCompany.asDate, "DD/MM/yyyy").format(
    "DD-MM-yyyy"
  );
  const companyReportParams = `ShowDocumentsLodged List=${printCompany.docLodge},ShowCurrentOfficeholdersOnly=${printCompany.officeHolders},AsAtDate=${asAtDate}`;
  const apiURL = `${API_URL}/apiv2/PrimeReports/ShowReport/RegistryCompanyProfile/${companyCode}/${companyReportParams}`;

  return await axios
    .get(`${apiURL}`, {
      responseType: "arraybuffer",
      headers: {
        token: JSON.parse(localStorage.getItem("token")),
        "Content-Type": "application/pdf",
      },
    })
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      //console.log(`window.open('${fileURL}')`);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getQuickCompanyProfileReport(companyCode) {
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/QuickRegistryCompanyProfile/${companyCode}/Show Current Officeholders Only=Yes,Show Documents Lodged List=No/`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAsicCompanyDetailReport(asicCompanyDetail, companyCode) {
  const asicCompanyReportParams = `ShowGivenNameFields=${asicCompanyDetail.nameField}`;

  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/eSearchCompanyExtract/${companyCode}/${asicCompanyReportParams}`,
      {
        responseType: "arraybuffer",
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/pdf",
        },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function isUnitTrustEmpty() {
  return unitTrustRecord;
}

async function getOrderMonitorStatement() {
  return await axios
    .get(`${API_URL}/apiv2/Orders/GetOrdersList`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDigitalSignatureStatement() {
  return await axios
    .get(`${API_URL}/apiv2/Docusign/GetDocusignList`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAsicDebtReport(ACN) {
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/EDGEIndividualCompanyDebtReport/${ACN}/none`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getElectronicLodegementService() {
  return await axios
    .get(`${API_URL}/apiv2/ELSBatch/GetElsBatchList`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getCompaniesDebtReport(details) {
  const companiesDebtReportParams = `Show Zero BalancesQQQ=${details.zeroBalance},Show CreditBalance=${details.creditBalance},Show Alphabetical Order=${details.sortReport}`;
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/EDGECompanyDebtReport/all//${companiesDebtReportParams}`,
      {
        responseType: "arraybuffer",
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/pdf",
        },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getIndividualElectronicLodegementService(companyCode) {
  return await axios
    .get(`${API_URL}/apiv2/ELSBatch/GetCompanyElsBatchList/${companyCode}`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getCompaniesDetailList() {
  return await axios
    .get(`${API_URL}/apiv2/RegCompany/GetCompaniesDetailList`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getFormsHistoryReport(Code) {
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/printDocumentHistory/${Code}/none`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getDirectorIdReport(didList, typedoc) {
  const didListReportParams = `${didList.didType}`;
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/viewDirectorIDList/${didListReportParams}/none/${typedoc}`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], {
        type:
          typedoc === "pdf" ? "application/pdf" : " application/vnd.ms-excel",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getAsicInvoiceReport(companyCode, companyName) {
  return await axios
    .get(
      `${API_URL}/apiv2/AnnualStatement/GetAnnualStatementInvoice/${companyCode}/${companyName}`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((errorAsicInvoiceReport) => {
      return Promise.reject(errorAsicInvoiceReport);
    });
}
async function getAsicInvoiceReportLinks(companyCode) {
  return await axios
    .get(`${API_URL}/apiv2/CompanyDebt/GetLatestFiveInvoices/${companyCode}`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((errorAsicInvoiceReportLink) => {
      return Promise.reject(errorAsicInvoiceReportLink);
    });
}

async function getViewCurrentAnnualStatementReport(companyCode, companyName) {
  return await axios
    .get(
      `${API_URL}/apiv2/AnnualStatement/GetAnnualStatementPackage/${companyCode}/${companyName}`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((errorViewCurrentAnnualStatement) => {
      return Promise.reject(errorViewCurrentAnnualStatement);
    });
}

async function getUnitTrustUnitholdersReport(unitTrustRN) {
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/unitholdersRegister/${unitTrustRN}/none`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getUnitTrustApplicationAllotmentJournalReport(unitTrustRN) {
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/unitApplicationandAllotmentJournal/${unitTrustRN}/none`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getUnitTrustTransferJournalReport(unitTrustRN) {
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/unitsTransferJournal/${unitTrustRN}/none`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getUnitTrustUnitholdersSummaryReport(unitTrustRN) {
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/unitholdersSummary/${unitTrustRN}/none`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCompanyPhoneNumber(RN, mobileNumber) {
  const params = "";
  return await axios
    .post(
      `${API_URL}/apiv2/RegCompany/UpdateTelephoneNumber/${RN}/C/${mobileNumber}`,
      params,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCompanyEmailID(RN, emailId) {
  const params = "";
  return await axios
    .post(
      `${API_URL}/apiv2/RegCompany/UpdateEmailAddress/${RN}/C/${emailId}/`,
      params,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postIndividualPhoneNumber(RN, mobileNumber) {
  const params = "";
  return await axios
    .post(
      `${API_URL}/apiv2/RegCompany/UpdateTelephoneNumber/${RN}/I/${mobileNumber}`,
      params,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postIndividualEmailID(RN, emailId, detail) {
  const params = "";
  console.log("companyor individual", detail);
  const detailParam = detail ? detail : "I";
  console.log("companyor individual", detailParam);
  return await axios
    .post(
      `${API_URL}/apiv2/RegCompany/UpdateEmailAddress/${RN}/${detailParam}/${emailId}/`,
      params,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postForgetpasswordEmail(userName) {
  const params = "";
  return await axios
    .post(
      `${API_URL}/apiv2/Auth/ForgotPassword?username=${userName}`,
      params,
      {}
    )
    .then((response) => {
      return response.data;
    })
    .catch((errorForgetPasswordEmail) => {
      return Promise.reject(errorForgetPasswordEmail);
    });
}

async function postResetPassword(userName, passWord, confirmPassword, rCode) {
  return await axios
    .post(`${API_URL}/apiv2/Auth/ResetPassword`, {
      UserID: userName,
      Password: passWord,
      ConfirmPassword: confirmPassword,
      Code: rCode,
    })
    .then((response) => {
      return response.data;
    })
    .catch((errorResetPassword) => {
      return Promise.reject(errorResetPassword);
    });
}

async function getDiv7ALoanReport(companyCode, reportParam) {
  console.log("reportParam", reportParam);
  const param = {
    responseType: "arraybuffer",
    headers: { token: JSON.parse(localStorage.getItem("token")) },
  };
  const address =
    reportParam.associateAddress &&
    reportParam.associateAddress.replace(/,/g, "{comma}");
  const state = reportParam.stateLocation;
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/Div_7A_Loan_Agreement_PORTAL/${companyCode}/Enter Loan Balance (or leave Bank)=${reportParam.loanBalance},Associate Name=${reportParam.associateName},Associate Address=${address},State of Jurisdiction=${state}/`,
      param
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getForm484Information(rN, docNo) {
  return await axios
    .get(`${API_URL}/apiv2/Document/GetDocumentSummary/${rN}/${docNo}`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getRegdInformation(ACN) {
  return await axios
    .get(`${API_URL}/apiv2/RegCompany/GetCompanyASICStatus/${ACN}`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((errorRegdInformation) => {
      return Promise.reject(errorRegdInformation);
    });
}

async function getIndividualEntitiesProfileReport(code) {
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/IndividualandEntitiesProfile/${code}/Current Officers and Shareholders=Yes,Show Other Public Co. Directorships (if entered)=Yes/`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getIndividualEntitiesOffices(code) {
  return await axios
    .get(`${API_URL}/apiv2/IndividualEntities/GetOfficerCompany/${code}/`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getIndividualEntitiesShares(code) {
  return await axios
    .get(`${API_URL}/apiv2/IndividualEntities/GetShareholderCompany/${code}/`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postIndividualEntitiesDid(RN, did) {
  const params = "";
  const emptyDid = did && did.replace(/_/g, "");
  return await axios
    .post(
      `${API_URL}/apiv2/IndividualEntities/UpdateDID/${RN}/${emptyDid}`,
      params,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((errorDidUpdate) => {
      return Promise.reject(errorDidUpdate);
    });
}
async function postCompanyNotes(RN, notes) {
  return await axios
    .post(
      `${API_URL}/apiv2/RegCompany/SetCompanyNotes`,
      { identifier: RN, notes: notes },
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAllCountries() {
  return await axios
    .get(`https://restcountries.com/v3.1/all`)
    .then((response) => {
      return response.data ? response.data.map((value) => value.name) : "";
    })
    .catch((errorCountries) => {
      return Promise.reject(errorCountries);
    });
}
async function postSignup(firstName, lastName, phone, email, organisationName) {
  return await axios
    .post(`${API_URL}/apiv2/Auth/Register`, {
      FirstName: firstName,
      LastName: lastName,
      phone: phone,
      Email: email,
      OrganisationName: organisationName,
    })
    .then((response) => {
      return response.data;
    })
    .catch((errorSignup) => {
      return Promise.reject(errorSignup);
    });
}
async function getUnitTrustTable(departement) {
  return await axios
    .get(`${API_URL}/apiv2/RegCompany/GetUnitRegisterList/${departement}`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((unitTrustTableError) => {
      return Promise.reject(unitTrustTableError);
    });
}

async function getLoggedUserInfo() {
  return await axios
    .get(`${API_URL}/apiv2/APIUser/GetUserInfo`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      console.warn(response.data);
      cookies.set("fullName", response.data.FullName, cookieOptions);
      cookies.set("roleId", response.data.IDRole, cookieOptions);
      return response.data;
    })
    .catch((loggedUserInfoError) => {
      return Promise.reject(loggedUserInfoError);
    });
}

async function postChangeOrderRequest(changeOrder) {
  return await axios
    .post(
      `${API_URL}/apiv2/Orders/PostOnlineOrder`,
      {
        data: changeOrder,
      },
      {
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          contentType: "multipart/form-data",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((changeOrderError) => {
      return Promise.reject(changeOrderError);
    });
}

async function getClassList(code) {
  return await axios
    .get(`${API_URL}/apiv2/RegCompany/GetAllAssociatedClass/${code}`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((classlistError) => {
      return Promise.reject(classlistError);
    });
}
async function getCompanyListReport(companyCode, companyList) {
  const companyListReportParams = `AgentID=${companyList.dept},Show Email Addresses=${companyList.email},Show Officers{slash}MembersQQQ=${companyList.officer}`;

  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/CompanyEmailList/${companyCode}/${companyListReportParams}`,
      {
        responseType: "arraybuffer",
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/pdf",
        },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postRecentCompanyList(userName, clientId, deptCode, ACN) {
  const params = {
    USER_ID: userName,
    CLIENT_ID: clientId,
    DEPT_CODE: deptCode,
    CO_ACN: ACN,
  };
  return await axios
    .post(`${API_URL}/apiv2/RegCompany/PostRecentComanyHistory`, params, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((errorPostRecentCompanyList) => {
      return Promise.reject(errorPostRecentCompanyList);
    });
}

async function getRecentCompanyList(userName, clientId, deptCode) {
  return await axios
    .get(
      `${API_URL}/apiv2/RegCompany/GetRecentComanyHistory/${clientId}/${userName}/${deptCode}/`,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data !== "No Recent Search Found!" ? response.data : "";
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getEbinderPrimeFiles(Code) {
  return await axios
    .get(`${API_URL}/apiv2/portal/ebinders/company/${Code}/primefiles`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getManageEbinderList(Code) {
  const params = { companyCode: Code };
  console.log("params", params);
  return await axios
    .get(
      `${API_URL}/apiv2/portal/ebinders/company/binder-list?companycode=${Code}`,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getEbinderLink(Code, binderId) {
  return await axios
    .get(
      `${API_URL}/apiv2/portal/ebinders/company/binder?companycode=${Code}&binderId=${binderId}`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getEbinderCustomFileList(Code) {
  return await axios
    .get(`${API_URL}/apiv2/portal/ebinders/company/${Code}/customfiles`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postEbinderCustomFileDelete(Code, fileName) {
  return await axios
    .post(
      `${API_URL}/apiv2/portal/ebinders/company/${Code}/customfiles/delete`,
      { identifier: Code, filename: fileName },
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postEbinderCustomFileAdd(Code, formdata) {
  return await axios
    .post(
      `${API_URL}/apiv2/portal/ebinders/company/${Code}/customfiles`,
      formdata,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
        "Content-Type": "multipart/form-data",
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getEbinderCompanyList() {
  return await axios
    .get(`${API_URL}/apiv2/portal/ebinders/company?department=@all`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreateEbinder(
  Code,
  selectedPrimeFileList,
  companyRegister,
  customFileList
) {
  return await axios
    .post(
      `${API_URL}/apiv2/portal/ebinders/company/${Code}/create`,
      {
        registers: companyRegister,
        primeForms: selectedPrimeFileList,
        customFiles: customFileList,
      },
      {
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
          contentType: "multipart/form-data",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postChangePassword(
  clientId,
  userId,
  oldPassword,
  passWord,
  confirmPassword
) {
  const params = {
    ClientID: clientId,
    UserID: userId,
    OldPassword: oldPassword,
    Password: passWord,
    ConfirmPassword: confirmPassword,
  };
  return await axios
    .post(`${API_URL}/apiv2/APIUser/UpdatePassword`, params, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((errorChangePassword) => {
      return Promise.reject(errorChangePassword);
    });
}
async function getBusinessNameDetailList() {
  return await axios
    .get(
      `${API_URL}/apiv2/portal/businessName/businessNameTable?department=@all`,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getBusinessWelcomeReport(reportParam) {
  console.log("reportParam business ID", reportParam);
  const param = {
    responseType: "arraybuffer",
    headers: { token: JSON.parse(localStorage.getItem("token")) },
  };

  return await axios
    .get(
      `${API_URL}/apiv2/portal/businessName/welcomeletter?businessID=${reportParam}`,
      param
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getBusinessCertificateReport(reportParam) {
  console.log("reportParam businessCertificate ID", reportParam);
  const param = {
    responseType: "arraybuffer",
    headers: { token: JSON.parse(localStorage.getItem("token")) },
  };

  return await axios
    .get(
      `${API_URL}/apiv2/portal/businessName/businessCertificate?businessID=${reportParam}`,
      param
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getBusinessNameSummary(reportParam) {
  console.log("reportParam businessCertificate ID", reportParam);
  const param = {
    headers: { token: JSON.parse(localStorage.getItem("token")) },
  };
  return await axios
    .get(
      `${API_URL}/apiv2/portal/businessName/businessName?department=@all&rn=${reportParam}`,
      param
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getBusinessNameReport(reportParam) {
  console.log("reportParam business ID", reportParam);
  const param = {
    responseType: "arraybuffer",
    headers: { token: JSON.parse(localStorage.getItem("token")) },
  };

  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReportParam/BusinessNameReport/${reportParam}`,
      param
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postBusinessNotes(identifier, notes) {
  return await axios
    .post(
      `${API_URL}/apiv2/portal/BusinessName/BusinessNameNotes/`,
      { identifier: identifier, notes: notes },
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getBusinessNotes(identifier) {
  return await axios
    .get(
      `${API_URL}/apiv2/portal/BusinessName/BusinessNameNotes/${identifier}`,
      {
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDirectorIdCount() {
  return await axios
    .get(`${API_URL}/apiv2/IndividualEntities/GetDIDCount`, {
      headers: { token: JSON.parse(localStorage.getItem("token")) },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getEdgeCompanyProfileReport(companyCode) {
  return await axios
    .get(
      `${API_URL}/apiv2/PrimeReports/ShowReport/EdgeCompanyProfile/${companyCode}/none/pdf`,
      {
        responseType: "arraybuffer",
        headers: { token: JSON.parse(localStorage.getItem("token")) },
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
