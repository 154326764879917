import React from "react";

const ReturnCapitalInfoComponent = (props) => {
  return (
    <div className="container ">
      <form>
        <hr />

        <div className="form-group px-4">
          <label for="returnCapitalInfo"> Return of Capital Info</label>
          <textarea
            className="form-control"
            id="returnCapitalInfo"
            name="returnCapitalInfo"
            rows="3"
            placeholder="Text..."
            value={props.asicDetails.returnCapitalInfo}
            onChange={(e) => props.handleChange(e, false, "")}
           
          ></textarea>
        </div>
      </form>
    </div>
  );
};
export default ReturnCapitalInfoComponent;
