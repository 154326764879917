import React, { useState, useEffect } from "react";
import "../CorporateRegistersComponents/CorporateRegister.styles.scss";
import AddOrRemoveAsicAgentListComponent from "./AddOrRemoveAsicAgentList.component";
import "./checkboxaccordion.scss";
import CompanyNameChange from "./CompanyNameChangeComponent/CompanyNameChange.Component";
import OfficeHolderAppointementComponent from "./OfficeholderAppointmentcomponent/OfficeHolderAppointment.component";
import OfficeHoldersChangeofAddressComponent from "./OfficeHolderChangeofAddressComponent/OfficeHoldersChangeofAddress.component";
import OfficeHoldersResignation from "./OfficeHoldersResignationComponent/OfficeHoldersResignation.component";
import OfficerShareholdersNameChange from "./OfficerShareholdersNameChangeComponent/OfficerShareholdersNameChange.Component";
import PrincipalBusinessAddressComponent from "./PrincipalBusinessAddressComponent/principalBusinessAddress.component";
import RegisteredOfficeAddressComponent from "./RegisteredOfficeAddressComponent/RegisteredOfficeAddress.component";
import ReturnCapitalInfoComponent from "./ReturnCapitalInfoComponent/ReturnCapitalInfo.component";
import ShareCancellation from "./ShareCancellationComponent/ShareCancellation.component";
import ShareIssue from "./ShareIssueComponent/ShareIssue.component";
import ShareTransfer from "./ShareTransferComponent/ShareTransfer.component";

const ASICDetailsUpdateComponent = (props) => {
  const [officerShareHoldersData, setOfficerShareHoldersData] = useState({
    name: [],
  });

  const duplicatedOfficer =
    props.companyProfile &&
    props.companyProfile.items &&
    props.companyProfile.items.officers.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.OFFICER_NAME === thing.OFFICER_NAME)
    );
  const officerNames =
    duplicatedOfficer &&
    duplicatedOfficer.map((value) =>
      value.CEASED !== "" ? value.OFFICER_NAME : ""
    );

  useEffect(() => {
    setOfficerShareHoldersData((value) => ({
      ...value,
      name:
        officerNames && shareHolderNames
          ? officerNames.concat(shareHolderNames)
          : [],
    }));

    // eslint-disable-next-line
  }, []);

  const handleOfficerShareHoldersDataClick = (e) => {
    setOfficerShareHoldersData((value) => ({
      ...value,
      name:
        officerNames && shareHolderNames
          ? officerNames.concat(shareHolderNames)
          : [],
    }));
  };

  const shareHolderNames =
    props.companyProfile &&
    props.companyProfile.items &&
    props.companyProfile.items.shareholders &&
    props.companyProfile.items.shareholders.map(
      (value) =>
        `${value.NAME}:  ${value.CLASS_CODE}:  ${value.CLASS_NO}:  FP=${
          value.FULLY_PAID ? "Yes" : "no"
        }:  BO=${value.BEN_OWNER ? "Yes" : "no"}
 :  Total Shares=${value.SUM_TOT_SHARES} :${
          value.BEN_OWNER ? "Beneficially Held" : ""
        }`
    );

  return (
    <div>
    {console.log("companyOccupiesPremises from Eorder",props.companyOccupiesPremises)}
      <div className="accordion py-2" id="myAccordion">
        <div className="card ">
          <div className="card-header">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                id="gridCheck1"
                name="requestChangeAddress"
                value="requestChangeAddress"
                onChange={(e) => props.handleChange(e, false, "")}
              />
              <label className="form-check-label px-3" for="gridCheck1">
                Request Change to Address(es)
              </label>
            </div>
          </div>
          <div id="collapseOne" className="accordion-collapse collapse">
            <div className="accordion py-2" id="myAccordion1">
              <div className=" px-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse1"
                  id="gridCheckRegistered"
                  name="ROAddress"
                  value="ROAddress"
                  onChange={(e) => props.handleChange(e, false, "")}
                />
                <label className="form-check-label px-1" for="gridROAddress">
                  Registered Office Address
                </label>
                <div id="collapse1" className="accordion-collapse collapse">
                  <hr />
                  <div className="mx-auto px-5">
                    <RegisteredOfficeAddressComponent
                      handleChange={props.handleChange}
                      asicDetails={props.asicDetails}
                      setAsicDetails={props.setAsicDetails}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="collapseOne" className="accordion-collapse collapse">
            <div className="accordion py-2" id="myAccordion2">
              <div className=" px-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse2"
                  id="gridCheckRegistered"
                  name="businessAddress"
                  value="businessAddress"
                  onChange={(e) => props.handleChange(e, false, "")}
                />
                <label
                  className="form-check-label px-1"
                  for="gridBusinessAddress"
                >
                  Principal Business Address
                </label>
                <div id="collapse2" className="accordion-collapse collapse">
                  <hr />
                  <PrincipalBusinessAddressComponent
                    handleChange={props.handleChange}
                    asicDetails={props.asicDetails}
                    setAsicDetails={props.setAsicDetails}
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="collapseOne" className="accordion-collapse collapse">
            <div className="accordion py-2" id="myAccordion3">
              <div className=" px-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse3"
                  id="gridCheckRegistered"
                  name="changeOfAddress"
                  value="changeOfAddress"
                  onChange={(e) => {
                    props.handleChange(e, false, "");
                  }}
                />
                <label
                  className="form-check-label px-1"
                  for="gridChangeOfAddress"
                >
                  Officeholder(s)/Shareholder(s) - Change of Address
                </label>

                <div id="collapse3" className="accordion-collapse collapse">
                  {props.osChangeofAddressInputlist.map(
                    (changeofAddressElements, changeofAddressIndex) => (
                      <div>
                        {props.osChangeofAddressInputlist.length - 1 ===
                          changeofAddressIndex && (
                          <div className="btn-box d-flex flex-row-reverse ">
                            <i
                              className="bi bi-plus-circle-fill text-success mr10"
                              onClick={(e) =>
                                props.handleChangeofAddressAddClick(e)
                              }
                            ></i>
                          </div>
                        )}

                        {props.osChangeofAddressInputlist.length !== 1 && (
                          <div className="btn-box d-flex flex-row-reverse ">
                            <i
                              className="bi bi-dash-circle-fill text-danger mr10"
                              onClick={() =>
                                props.handleChangeofAddressRemoveClick(
                                  changeofAddressIndex
                                )
                              }
                            ></i>
                          </div>
                        )}
                        <OfficeHoldersChangeofAddressComponent
                          handleChange={props.handleChange}
                          asicDetails={props.asicDetails}
                          setAsicDetails={props.setAsicDetails}
                          osChangeofAddressInputlist={
                            props.osChangeofAddressInputlist
                          }
                          handleChangeofAddressInputChange={
                            props.handleChangeofAddressInputChange
                          }
                          changeofAddressElements={changeofAddressElements}
                          changeofAddressIndex={changeofAddressIndex}
                          setOSChangeofAddressInputlist={
                            props.setOSChangeofAddressInputlist
                          }
                          companyProfile={props.companyProfile}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion py-2" id="myAccordionRequestChangeName">
        <div className="card ">
          <div className="card-header">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                id="gridCheck5"
                Name="requestChangeNames"
                value="requestChangeNames"
                onChange={(e) => props.handleChange(e, false, "")}
              />
              <label className="form-check-label px-3" for="gridCheck5">
                Request Change to Name(s)
              </label>
            </div>
          </div>
          <div id="collapseFive" className="accordion-collapse collapse">
            <div className="accordion py-2" id="myAccordionCompanyNameChange">
              <div className="px-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseCompanyNameChange"
                  id="gridCheckCompanyNameChange"
                  Name="companyNameChange"
                  value="companyNameChange"
                  onChange={(e) => props.handleChange(e, false, "")}
                />
                <label
                  className="form-check-label px-3"
                  for="gridCheckCompanyNameChange"
                >
                  Company Name Change
                </label>
              </div>

              <div
                id="collapseCompanyNameChange"
                className="accordion-collapse collapse"
              >
                <CompanyNameChange
                  handleChange={props.handleChange}
                  asicDetails={props.asicDetails}
                  setAsicDetails={props.setAsicDetails}
                />
              </div>
            </div>

            <div className="accordion py-2" id="myAccordionOfficerNameChange">
              <div className="px-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOfficerNameChange"
                  id="gridCheckOfficerNameChange"
                  Name="officerNameChange"
                  value="officerNameChange"
                  onChange={(e) => props.handleChange(e, false, "")}
                />
                <label
                  className="form-check-label px-3"
                  for="gridCheckOfficerNameChange"
                >
                  Officer Shareholders Name change
                </label>
              </div>

              <div
                id="collapseOfficerNameChange"
                className="accordion-collapse collapse"
              >
                {props.osNameChangeInputlist.map(
                  (nameChangeElements, nameChangeIndex) => (
                    <div>
                      {props.osNameChangeInputlist.length - 1 ===
                        nameChangeIndex && (
                        <div className="btn-box d-flex flex-row-reverse ">
                          <i
                            className="bi bi-plus-circle-fill text-success mr10"
                            onClick={(e) => props.handleNameChangeAddClick(e)}
                          ></i>
                        </div>
                      )}

                      {props.osNameChangeInputlist.length !== 1 && (
                        <div className="btn-box d-flex flex-row-reverse ">
                          <i
                            className="bi bi-dash-circle-fill text-danger mr10"
                            onClick={() =>
                              props.handleNameChangeRemoveClick(nameChangeIndex)
                            }
                          ></i>
                        </div>
                      )}
                      <OfficerShareholdersNameChange
                        handleChange={props.handleChange}
                        asicDetails={props.asicDetails}
                        setAsicDetails={props.setAsicDetails}
                        osNameChangeInputlist={props.osNameChangeInputlist}
                        handleNameChangeChange={props.handleNameChangeChange}
                        nameChangeElements={nameChangeElements}
                        nameChangeIndex={nameChangeIndex}
                        setOSNameChangeInputlist={
                          props.setOSNameChangeInputlist
                        }
                        companyProfile={props.companyProfile}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion py-2" id="myAccordionChangeOfficer">
        <div className="card ">
          <div className="card-header">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                id="gridCheck1"
                Name="changeOfficeholder"
                value="changeOfficeholder"
                onChange={(e) => props.handleChange(e, false, "")}
              />
              <label className="form-check-label px-3" for="gridCheck2">
                Change to officeholder(s)
              </label>
            </div>
          </div>
          <div id="collapseTwo" className="accordion-collapse collapse">
            <div
              className="accordion py-2"
              id="myAccordionOfficeholderAppointment"
            >
              <div className="px-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOfficeholderAppointment"
                  id="gridCheckOfficeholderAppointment"
                  Name="OfficeholderAppointment"
                  value="OfficeholderAppointment"
                  onChange={(e) => props.handleChange(e, false, "")}
                />
                <label
                  className="form-check-label px-3"
                  for="gridCheckOfficeholderAppointment"
                >
                  Officeholder(s) - Appointment
                </label>
              </div>

              <div
                id="collapseOfficeholderAppointment"
                className="accordion-collapse collapse"
              >
                {props.appointmentInputlist.map(
                  (appointElements, appointIndex) => (
                    <div>
                      {props.appointmentInputlist.length - 1 ===
                        appointIndex && (
                        <div className="btn-box d-flex flex-row-reverse ">
                          <i
                            className="bi bi-plus-circle-fill text-success mr10"
                            onClick={(e) => props.handleAppointmentAddClick(e)}
                          ></i>
                        </div>
                      )}

                      {props.appointmentInputlist.length !== 1 && (
                        <div className="btn-box d-flex flex-row-reverse ">
                          <i
                            className="bi bi-dash-circle-fill text-danger mr10"
                            onClick={() =>
                              props.handlAppointmentRemoveClick(appointIndex)
                            }
                          ></i>
                        </div>
                      )}
                      <OfficeHolderAppointementComponent
                        handleChange={props.handleChange}
                        asicDetails={props.asicDetails}
                        setAsicDetails={props.setAsicDetails}
                        companyProfile={props.companyProfile}
                        appointmentInputlist={props.appointmentInputlist}
                        setAppointmentInputlist={props.setAppointmentInputlist}
                        handleAppointmentAddClick={
                          props.handleAppointmentAddClick
                        }
                        handlAppointmentRemoveClick={
                          props.handlAppointmentRemoveClick
                        }
                        handleAppointmentInputChange={
                          props.handleAppointmentInputChange
                        }
                        appointIndex={appointIndex}
                        appointElements={appointElements}
                      />
                    </div>
                  )
                )}
              </div>
              <div
                className="accordion py-2"
                id="myAccordionOfficeholderResignations"
              >
                <div className="px-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOfficeholderResignations"
                    id="gridCheckOfficeholderResignations"
                    Name="OfficeholderResignations"
                    value="OfficeholderResignations"
                    onChange={(e) => props.handleChange(e, false, "")}
                  />
                  <label
                    className="form-check-label px-3"
                    for="gridCheckOfficeholderResignations"
                  >
                    Officeholder(s) - Resignations
                  </label>
                </div>

                <div
                  id="collapseOfficeholderResignations"
                  className="accordion-collapse collapse"
                >
                  {props.resignInputlist.map((resignElements, resignIndex) => (
                    <div>
                      {props.resignInputlist.length - 1 === resignIndex && (
                        <div className="btn-box d-flex flex-row-reverse ">
                          <i
                            className="bi bi-plus-circle-fill text-success mr10"
                            onClick={(e) => props.handleResignAddClick(e)}
                          ></i>
                        </div>
                      )}

                      {props.resignInputlist.length !== 1 && (
                        <div className="btn-box d-flex flex-row-reverse ">
                          <i
                            className="bi bi-dash-circle-fill text-danger mr10"
                            onClick={() =>
                              props.handlResignRemoveClick(resignIndex)
                            }
                          ></i>
                        </div>
                      )}
                      <OfficeHoldersResignation
                        handleChange={props.handleChange}
                        asicDetails={props.asicDetails}
                        setAsicDetails={props.setAsicDetails}
                        companyProfile={props.companyProfile}
                        resignInputlist={props.resignInputlist}
                        setResignInputlist={props.setResignInputlist}
                        handleResignAddClick={props.handleResignAddClick}
                        handlResignRemoveClick={props.handlResignRemoveClick}
                        handleResignInputChange={props.handleResignInputChange}
                        resignIndex={resignIndex}
                        resignElements={resignElements}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion py-2" id="myAccordionChangeShareHolding">
        <div className="card ">
          <div className="card-header">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                id="gridCheck3"
                Name="changeToShareholdings"
                value="changeToShareholdings"
                onChange={(e) => {
                  props.handleChange(e, false, "");
                  handleOfficerShareHoldersDataClick(e);
                }}
              />
              <label className="form-check-label px-3" for="gridCheck3">
                Request change to shareholding(s)
              </label>
            </div>
          </div>
          <div id="collapseThree" className="accordion-collapse collapse">
            <div className="accordion py-2" id="myAccordionshareIssue">
              <div className="px-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  Name="shareIssue"
                  value="shareIssue"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseShareIssue"
                  id="gridCheckShareIssue"
                  onChange={(e) => props.handleChange(e, false, "")}
                />

                <label
                  className="form-check-label px-3"
                  for="gridCheckShareIssue"
                >
                  Share Issue(s)
                </label>
              </div>

              <div
                id="collapseShareIssue"
                className="accordion-collapse collapse"
              >
                {props.inputList.map((elements, index) => (
                  <div className="btn-box d-flex flex-row-reverse ">
                    {props.inputList.length - 1 === index && (
                      <div className="btn-box d-flex flex-row-reverse ">
                        <i
                          className="bi bi-plus-circle-fill text-success mr10"
                          onClick={(e) => props.handleAddClick(e)}
                        ></i>
                      </div>
                    )}

                    {props.inputList.length !== 1 && (
                      <div className="btn-box d-flex flex-row-reverse  px-2">
                        <i
                          className="bi bi-dash-circle-fill text-danger mr10"
                          onClick={() => props.handleRemoveClick(index)}
                        ></i>
                      </div>
                    )}

                    <ShareIssue
                      handleChange={props.handleChange}
                      asicDetails={props.asicDetails}
                      setAsicDetails={props.setAsicDetails}
                      inputList={props.inputList}
                      handleInputChange={props.handleInputChange}
                      elements={elements}
                      index={index}
                      setInputList={props.setInputList}
                      officerShareHoldersData={officerShareHoldersData}
                      companyProfile={props.companyProfile}
                      classListInfo={props.classListInfo}
                    />
                  </div>
                ))}
              </div>
            </div>
            {props.asicDetails.shareIssue && <hr />}
            <div className="accordion py-2" id="myAccordionShareCancellation">
              <div className="px-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseShareCancellation"
                  id="gridCheckShareCancellation"
                  Name="shareCancellation"
                  value="shareCancellation"
                  onChange={(e) => props.handleChange(e, false, "")}
                />
                <label
                  className="form-check-label px-3"
                  for="gridCheckShareCancellation"
                >
                  Share Cancellation(s)
                </label>
              </div>

              <div
                id="collapseShareCancellation"
                className="accordion-collapse collapse"
              >
                {props.shareCancellationInputList.map(
                  (shareCancelElements, shareCancelIndex) => (
                    <div>
                      {props.shareCancellationInputList.length - 1 ===
                        shareCancelIndex && (
                        <div className="btn-box d-flex flex-row-reverse ">
                          <i
                            className="bi bi-plus-circle-fill text-success mr10"
                            onClick={(e) =>
                              props.handleAddShareCancellationClick(e)
                            }
                          ></i>
                        </div>
                      )}

                      {props.shareCancellationInputList.length !== 1 && (
                        <div className="btn-box d-flex flex-row-reverse ">
                          <i
                            className="bi bi-dash-circle-fill text-danger mr10"
                            onClick={() =>
                              props.handleRemoveShareCancellationClick(
                                shareCancelIndex
                              )
                            }
                          ></i>
                        </div>
                      )}
                      <ShareCancellation
                        handleChange={props.handleChange}
                        asicDetails={props.asicDetails}
                        setAsicDetails={props.setAsicDetails}
                        shareCancellationInputList={
                          props.shareCancellationInputList
                        }
                        handleInputChangeShareCancellation={
                          props.handleInputChangeShareCancellation
                        }
                        shareCancelElements={shareCancelElements}
                        shareCancelIndex={shareCancelIndex}
                        setShareCancellationInputList={
                          props.setShareCancellationInputList
                        }
                        companyProfile={props.companyProfile}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            {props.asicDetails.shareCancellation && <hr />}
            <div className="accordion py-2" id="myAccordionShareTransfer">
              <div className="px-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseShareTransfer"
                  id="gridCheckShareTransfer"
                  Name="shareTransfer"
                  value="shareTransfer"
                  onChange={(e) => props.handleChange(e, false, "")}
                />
                <label
                  className="form-check-label px-3"
                  for="gridCheckShareTransfer"
                >
                  Share Transfer(s)
                </label>
              </div>

              <div
                id="collapseShareTransfer"
                className="accordion-collapse collapse"
              >
                {props.shareTransferInputList.map(
                  (shareTransferElements, shareTransferIndex) => (
                    <div>
                      {props.shareTransferInputList.length - 1 ===
                        shareTransferIndex && (
                        <div className="btn-box d-flex flex-row-reverse ">
                          <i
                            className="bi bi-plus-circle-fill text-success mr10"
                            onClick={(e) =>
                              props.handleAddShareTransferClick(e)
                            }
                          ></i>
                        </div>
                      )}

                      {props.shareTransferInputList.length !== 1 && (
                        <div className="btn-box d-flex flex-row-reverse ">
                          <i
                            className="bi bi-dash-circle-fill text-danger mr10"
                            onClick={() =>
                              props.handleRemoveShareTransferClick(
                                shareTransferElements
                              )
                            }
                          ></i>
                        </div>
                      )}

                      <ShareTransfer
                        handleChange={props.handleChange}
                        asicDetails={props.asicDetails}
                        setAsicDetails={props.setAsicDetails}
                        shareTransferInputList={props.shareTransferInputList}
                        handleInputChangeShareTransfer={
                          props.handleInputChangeShareTransfer
                        }
                        shareTransferElements={shareTransferElements}
                        shareTransferIndex={shareTransferIndex}
                        setShareTransferInputList={
                          props.setShareTransferInputList
                        }
                        companyProfile={props.companyProfile}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            {props.asicDetails.shareTransfer && <hr />}
            <div className="accordion py-2" id="myAccordionReturnCapital">
              <div className="px-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseReturnCapital"
                  id="gridCheckReturnCapital"
                  Name="returnCapital"
                  value="returnCapital"
                  onChange={(e) => props.handleChange(e, false, "")}
                />
                <label
                  className="form-check-label px-3"
                  for="gridCheckReturnCapital"
                >
                  Return of Capital
                </label>
              </div>

              <div
                id="collapseReturnCapital"
                className="accordion-collapse collapse"
              >
                <ReturnCapitalInfoComponent
                  handleChange={props.handleChange}
                  asicDetails={props.asicDetails}
                  setAsicDetails={props.setAsicDetails}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddOrRemoveAsicAgentListComponent
        handleChange={props.handleChange}
        asicDetails={props.asicDetails}
        setAsicDetails={props.setAsicDetails}
        companyProfile={props.companyProfile}
        isCardHeader={true}
      />
    </div>
  );
};
export default ASICDetailsUpdateComponent;
