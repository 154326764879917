import { userConstants } from "../constants/login.constants";

 function businessNotesUpdate(state = {}, action) {
    switch (action.type) {
        case userConstants.POSTBUSINESSNOTES_REQUEST:
            return {
                loading: true
            };
        case userConstants.POSTBUSINESSNOTES_SUCCESS:
            return {
                items: action.businessNotesUpdate
            };
        case userConstants.POSTBUSINESSNOTES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export default businessNotesUpdate;