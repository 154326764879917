import React from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";


const ShareHolderDetailsComponent = (props) => {
    const dispatch = useDispatch();   
 
let handleClick = (e,companyCode, companyName,ACN) =>{
    localStorage.setItem("companyCode", JSON.stringify(companyCode));
    localStorage.setItem("companyName", JSON.stringify(companyName));
    if(companyCode !== null){
      props.setSearchCompany([{ Code: companyCode, Name: companyName }]);
      props.recentSearch.push([{ Code: companyCode, Name: companyName }]);
      dispatch(userActions.postRecentCompanyList(props.userId,props.clientId,"",ACN))
      console.log("postRecentCompanyList from Shareholders",props.userId,props.clientId,ACN)
      localStorage.setItem(
        "recentCompanySearch",
        JSON.stringify(props.recentSearch)
      );
        dispatch(userActions.getCompanyProfileByCode(companyCode))
        dispatch(userActions.getRegdInformation(ACN));
       // history.push("/CompanyMenuDetails/CompanySnapshot");
       props.history.push("/CompanyMenuDetails/details"); 
        props.setPopupShareHolderShow(false);
      } 
  }
  
  const shareHolderDetails = useSelector((state) => state.shareHolderDetails);
  
  return (
    <div >
 
  <h5 className="mb-4"> Shareholder Detail</h5>
  <h5> {props.NAME}</h5>

  <div className="container">

        <table className="table table-hover table-striped">
                <tbody className="fs-6 fw-normal">
        {shareHolderDetails &&shareHolderDetails.items&& (
            shareHolderDetails.items.map(
              (value) => 
          <tr>

            <td style={{ width: "200px" }}>
              {" "}
              <a href={()=>false}
               // href="/CompanyMenuDetails/CompanySnapshot"
                className="text-decoration-none table-data hand-cursor " onClick={(e) => handleClick(e,value.CO_CODE,value.CO_NAME,value.ACN )}
              >
                {" "}
               { value.CO_NAME}
              </a>
            </td>
            <td style={{ width: "50px" }}>
              {" "}
           {value.CLASS_CODE}
            </td>
            <td style={{ width: "50px" }}>
            {" "}
         {value.TOT_SHARES}
          </td>
          <td style={{ width: "50px" }}>
          {value.BEN_OWNER ? "Yes" : "No"}
        </td>
        <td style={{ width: "50px" }}>
          {value.FULLY_PAID ? "FP" : ""}
        </td>
            
          </tr>
          )
          )
            }
        </tbody>
      </table>
    
    </div>
    </div>
  );
};

export default ShareHolderDetailsComponent;