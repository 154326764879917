
  import { userConstants } from "../constants/login.constants";

  function directorIdCount(state = {}, action) {
     switch (action.type) {
         case userConstants.DIRECTORIDCOUNT_REQUEST:
             return {
                 loading: true
             };
         case userConstants.DIRECTORIDCOUNT_SUCCESS:
             return {
                 items: action.directorIdCount
             };
         case userConstants.DIRECTORIDCOUNT_FAILURE:
             return {
                 error: action.error
             };
         default:
             return state
     }
 }
 export default directorIdCount;