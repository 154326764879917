import React,{ useState,useEffect } from 'react';
import ChangePasswordComponent from '../ChangePasswordComponent/ChangePasswordComponent';
import FullPageLoader from '../CommonPage/FullPageLoader';
import "../CommonPage/newStyles.scss"
import Popup from '../CommonPage/Popup.component';
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";

const ProfileSettingComponent = () => {
    const loggedUserInfo = useSelector((state) => state.loggedUserInfo);
    const changePassword= useSelector((state) => state.changePassword);
    // clearing popup data creating usestate for reducer.
    const [alertStatus, setAlertStatus] = useState(false);

    const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.getLoggedUserInfo());
    // eslint-disable-next-line
  }, []);
    //change password popup call
    const [showChangePassword, setShowChangePassword] = useState(false);
const handleChangePasswordClose = () => setShowChangePassword(false);
const handleChangePasswordShow = () => {setChangePwd((changePwd) =>({...changePwd, oldPassword:"", passWord: "",confirmPassword:""}));setAlertStatus("");setShowChangePassword(true);}

  //loading spinner
  const [isChangePasswordLoaded, setIsChangePasswordLoaded] = useState(false);

  const [changePwd, setChangePwd] = useState({
    clientId:loggedUserInfo && loggedUserInfo.items && loggedUserInfo.items.ClientID,
     userId:loggedUserInfo && loggedUserInfo.items && loggedUserInfo.items.UserName,
    oldPassword:"",
    passWord: "",
    confirmPassword:"",
  });
  let handlePersonalDetailsChange = (e) => {
    const { name, value } = e.target;
    setChangePwd((changePwd) => ({ ...changePwd, [name]: value }));
  };

  let changePasswordHandleClick = (e) => {
      
    e.preventDefault();
if( changePwd.passWord!==changePwd.confirmPassword)
{
    return ;
}
    console.log("hello",changePwd);
   dispatch(userActions.postChangePassword(changePwd.clientId,changePwd.userId, changePwd.oldPassword,
    changePwd.passWord,changePwd.confirmPassword));
    //setTimeout(() => {
        setAlertStatus(changePassword)
      //}, 1000);

  
}

  useEffect(() => {
    setAlertStatus(changePassword)
    // eslint-disable-next-line
  }, [changePassword]);

    return (
        
        
        <div className="container  ">
        <h4 className="panel-title ">Profile Settings</h4>
        <h5  className="fw-bold"> Account Login Details</h5>
        <form >
      
          <table  className="form-group fs-6">
          <tr><td >  <label htmlFor="name" className="col-form-label">
          Name
         </label></td></tr>
          <tr><td>
          <input className="form-control fs-6 fw-normal"
              type="text"
              size="50"
              name="name"
              value={ loggedUserInfo && loggedUserInfo.items && loggedUserInfo.items.FullName}
       readOnly={true}
            /></td></tr>
          <tr><td> <label htmlFor="userName" className="col-form-label">
          Username
          </label>
        </td></tr>
          <tr><td>  <input className=" form-control fs-6 fw-normal"
          type="text"
          size="50"
          name="userName"
          readOnly={true}
          value={ loggedUserInfo && loggedUserInfo.items && loggedUserInfo.items.UserName}     
        /></td></tr>
          <tr><td> <label htmlFor="email" className="col-form-label">
          Email
              </label>
              </td></tr>
          <tr><td><input className=" form-control fs-6 fw-normal"
          type="text"
          size="50"
          name="email"
          readOnly={true}
          value={ loggedUserInfo && loggedUserInfo.items && loggedUserInfo.items.EmailID} 
        /></td></tr>
          <tr><td>   <label htmlFor="password" className="col-form-label">
          Password
                </label>
                </td></tr>
                <tr><td> 
                <input type="button" className="buttonCommon w-100"
              value="Change password"
                id="sendLink"
                onClick={handleChangePasswordShow}/> 
               
              {showChangePassword && (
                <Popup
                  show={showChangePassword}
                  handleClose={handleChangePasswordClose}
                  handleShow={handleChangePasswordShow}
                  body={
                    <ChangePasswordComponent
                    changePwd={changePwd}
                   // changePassword={changePassword}
                    alertStatus ={alertStatus}
                    handlePersonDetailsChange={
                        handlePersonalDetailsChange
                      }
                    />
                  }
                  btnName="Save"
                  handleClick={changePasswordHandleClick}
                  isPopupReportLoading={isChangePasswordLoaded}
                />
              )}
              </td></tr>
              {isChangePasswordLoaded ? <FullPageLoader /> : null}</table>
            
            
          <br />
         
        
        </form>

        </div>
    );
};

export default ProfileSettingComponent;