import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";
import FullPageLoader from "../CommonPage/FullPageLoader";

const DirectorsAndSecretaries = (props) => {
  const dispatch = useDispatch();
  const [officers, setOfficers] = useState({
    dsPrintDate: new Date(),
    officer: "Yes",
  });
//loading spinner
const [isDirectorAndSecretariesLoaded, setIsDirectorAndSecretariesLoaded] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  let handleChange = (e, isDate) => {
    if (isDate) {
      setOfficers((Print) => ({ ...officers, dsPrintDate: e }));
    } else {
      const { name, value } = e.target;
      setOfficers((member) => ({ ...officers, [name]: value }));
    }
  };

  
  const companyCode = JSON.parse(localStorage.getItem("companyCode"));
  const inputSearchValue = JSON.parse(localStorage.getItem("inputSearchValue"));
  const validate = companyCode !== null || (inputSearchValue !== null && inputSearchValue.length > 0) || (props.searchCompany && props.searchCompany.length > 0);

  function handleClick(e) {
    setSubmitted(true);
    const selectedSearchValue = localStorage.getItem("inputSearchValue");

    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if(props.searchCompany && props.searchCompany[0]) {
      dispatch(userActions.getDirectorsSecretariesReport(officers, props.searchCompany[0].Code,setIsDirectorAndSecretariesLoaded));
    } else if (selectedCompanyCode !== null) {
      dispatch(userActions.getDirectorsSecretariesReport(officers, selectedCompanyCode,setIsDirectorAndSecretariesLoaded));
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(userActions.getDirectorsSecretariesReport(officers, value.Code,setIsDirectorAndSecretariesLoaded))
      );
    }
  }

  return (
    <div class="container">
      <h3 className="mb-4">Enter Values</h3>
      {console.log("props.searchCompany1111111111111111",props.searchCompany)}
      <table className="table table-responsive table-borderless">
        <tbody>
          <tr>
            <td className="fw-bold w-50 ">
              Enter as at Date (Click on Blank for Current Date)
        
            </td>

            <td colspan="2" className="w-75">
             
              <DatePicker
                className="w-50"
                name="printDate"
                selected={officers.dsPrintDate}
                onChange={(e) => handleChange(e, true)}
                dateFormat="dd/MM/yyyy"
                placeholderText="Enter as at Date"
                value={officers.dsPrintDate}
              />
            </td>
          </tr>
          <tr>
            <td className="fw-bold w-50 ">
              Enter Current Officers {" "}
            </td>{" "}
            <td>
              {" "}
              <label>
                <input
                  type="radio"
                  name="officer"
                  checked={officers.officer === "Yes"}
                  onClick={handleChange}
                  value="Yes"
                />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
                <input
                  type="radio"
                  name="officer"
                  checked={officers.officer === "No"}
                  onClick={handleChange}
                  value="No"
                />
                NO
              </label>
            </td>
          </tr>
          <tr>
            <td>
            {" "}
            <button
              className={
                submitted && !validate ? "btn btn-danger" : "btn btn-primary"
              }
              onClick={(e) => handleClick(e)}
            >
              Show Report 
            </button>
            {isDirectorAndSecretariesLoaded? <FullPageLoader /> :null}
            {submitted && !validate && (
              <div className="text-danger py-3">Company Name not selected.</div>
            )}
            </td>
          </tr>
        </tbody>{" "}
      </table>
    </div>
  );
};

export default DirectorsAndSecretaries;
