import React from "react";
import Landingpage from "../LandingPagecomponent/LandingPage.Component";
import { withRouter } from "react-router-dom";
import EOrderOrderRequestComponent from "./EorderOrderRequest.Component";


const EOrderOrderRequestLanding = (props) => {
  return (
    <Landingpage
    isEOrderOrderRequestCollapse={true} isEOrderOrderRequestPage={true}
      content={<EOrderOrderRequestComponent />}
    />
  );
};
export default withRouter(EOrderOrderRequestLanding);
