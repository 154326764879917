import React, { useState, useEffect } from "react";
import "../MonitorsComponents/AnnualReviews.style.scss";
import Pagination from "../CommonPage/Pagination.component";
import "../CommonPage/Action.scss";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import moment from "moment";
import "../CommonPage/colourCodeEntries.scss";


const OrderMonitor = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [filterOrders, setFilterOrders] = useState("");

  let handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  // connect to API
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.getOrderMonitorStatement());
    // eslint-disable-next-line
  }, []);
  const orderMonitorStatement = useSelector(
    (state) => state.orderMonitorStatement
  );

  // search
  const [searchInput, setSearchInput] = useState("");
  let orderMonitorTotalStatement =
    orderMonitorStatement && orderMonitorStatement.items
      ? orderMonitorStatement.items.sort((a, b) => (b.ORDER_ID - a.ORDER_ID)).filter((filteredValue) =>
          filteredValue["COMPANY_NAME"]
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        )
      : [];

  const indexOfLastPost = currentPage * postsPerPage;

  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  
   
let completedTotalStatuses =  orderMonitorTotalStatement && orderMonitorTotalStatement.filter((value) =>  value["STATUS"]  && value["STATUS"].toLowerCase().includes("Completed".toLowerCase()) )
let preparedTotalStatuses =  orderMonitorTotalStatement && orderMonitorTotalStatement.filter((value) =>  value["STATUS"]  && (value["STATUS"].toLowerCase().includes("Prepared".toLowerCase()) ||  value["STATUS"].toLowerCase().includes("Signed".toLowerCase()) ||  value["STATUS"].toLowerCase().includes("Lodged".toLowerCase())))
let requestedTotalStatuses =  orderMonitorTotalStatement && orderMonitorTotalStatement.filter((value) =>  (!value["STATUS"])|| ( value["STATUS"]  && (value["STATUS"].toLowerCase().includes("Requested".toLowerCase()) || value["STATUS"].toLowerCase().includes("Info Request".toLowerCase()) || value["STATUS"].toLowerCase().includes("Rejected".toLowerCase()))) )

  function handleClick(e, value) {
    e.preventDefault();
    if(value ==="empty") {
      setFilterOrders(orderMonitorTotalStatement && orderMonitorTotalStatement.filter((value) =>  (!value["STATUS"])|| (value["STATUS"]  && (value["STATUS"].toLowerCase().includes("Requested".toLowerCase()) || value["STATUS"].toLowerCase().includes("Info Request".toLowerCase()) ||  value["STATUS"].toLowerCase().includes("Rejected".toLowerCase())) )))
    } else if(value === 'Completed') {
          setFilterOrders(orderMonitorTotalStatement && orderMonitorTotalStatement.filter((value) =>  value["STATUS"]  && value["STATUS"].toLowerCase().includes("Completed".toLowerCase()) ))
    } else  if(value === 'Prepared') {
        setFilterOrders( orderMonitorTotalStatement && orderMonitorTotalStatement.filter((value) =>  value["STATUS"]  && (value["STATUS"].toLowerCase().includes("Prepared".toLowerCase()) ||  value["STATUS"].toLowerCase().includes("Signed".toLowerCase()) ||  value["STATUS"].toLowerCase().includes("Lodged".toLowerCase()))))
    } else if(value === 'Requested') {
      setFilterOrders(orderMonitorTotalStatement && orderMonitorTotalStatement.filter((value) =>  value["STATUS"]  && (value["STATUS"].toLowerCase().includes("Requested".toLowerCase()) || value["STATUS"].toLowerCase().includes("Info Request".toLowerCase()) || value["STATUS"].toLowerCase().includes("Rejected".toLowerCase())) ))
    } else {
      setFilterOrders(orderMonitorTotalStatement)
    }
  }  

  function handleChange(e) {
    setSearchInput(e.target.value);
    setFilterOrders(orderMonitorTotalStatement && orderMonitorTotalStatement.filter((value) =>  value["COMPANY_NAME"]  && value["COMPANY_NAME"].toLowerCase().includes(searchInput.toLowerCase()) ))
  }

  function handleStatusChange(e){
    e.preventDefault();
    if(e && e.target.value){
      setFilterOrders(orderMonitorTotalStatement && orderMonitorTotalStatement.filter((value) =>  value["STATUS"]  && value["STATUS"].toLowerCase().includes(e.target.value.toLowerCase()) ))  
    }
    else{
      setFilterOrders(orderMonitorTotalStatement)
    }
  }

  function handleRequestTypeChange(e){
    e.preventDefault();
    if(e && e.target.value){
      setFilterOrders(orderMonitorTotalStatement && orderMonitorTotalStatement.filter((value) =>  value["OPTIONS"]  && value["OPTIONS"].toLowerCase().includes(e.target.value.toLowerCase()) ))  
    }
    else{
      setFilterOrders(orderMonitorTotalStatement)
    }
  }

  let currentPosts = filterOrders
    ? filterOrders && filterOrders.slice(indexOfFirstPost, indexOfLastPost)
    : orderMonitorTotalStatement && orderMonitorTotalStatement.slice(indexOfFirstPost, indexOfLastPost);

      // refresh button
  function handleClickRefreshOrderMonitor(e) {
    e.preventDefault();
    setSearchInput("");
    
  }
function tooltipType(tooltipType){
return(
  tooltipType==="2"?	"ASIC Agent Listed: Request a Company Debt Report":
    tooltipType==="1"?"ASIC Agent Listed: Request update(s) to ASIC details":
    tooltipType==="3" ? "ASIC Agent Listed: Request a Company Debt Report" :
    tooltipType==="4" ? "Request a ASIC Company Extract":
    tooltipType==="5" ? "Incorporate a New Company" :
    tooltipType==="6" ? "Request for Entity to be added to the ASIC Agent List" :
    tooltipType==="7"  ? "Request updates to ASIC Details (Non ASIC Agent - AdHoc - Charges Apply)" :
    tooltipType==="8"  ? "Other request" :
    tooltipType==="9"  ? "Request for Entity to be removed the ASIC Agent List" :
    tooltipType==="10"  ? "Dividend" 
:
""
)

}


  return (
    <div className="m-4 fontfamily">
      <h2 className="panel-title">Order Monitor</h2>
      <nav className="navbar navbar-light bg-light navbar-expand-md ">
        <div className="container">
          <div
            className=" navbar-collapse justify-content-start"
            id="main-nav-collapse"
          >
            <ul className=" navbar-nav ">
              {" "}
              <li className="nav-item active nav-item list-inline-item">
                <button type="button" class="btn-outline-primary btn-sm px-2" onClick={e=>handleClick(e, '')}>
                  {orderMonitorTotalStatement && orderMonitorTotalStatement.length}
                </button>{" "}
                TotalOrders{" "}
              </li>
              <li className="nav-item active nav-item list-inline-item">
                {" "}
                <button type="button" class="btn btn-success btn-sm px-2" onClick={e=>handleClick(e, 'Completed')}>
                 {completedTotalStatuses.length}
                </button>{" "}
                Completed
              </li>
              <li className="nav-item active nav-item list-inline-item" onClick={e=>handleClick(e, 'Prepared')}>
                {" "}
                <button type="button" class="btn btn-warning btn-sm px-2">
                  {preparedTotalStatuses.length}
                </button>{" "}
                Prepared
              </li>
              <li className="nav-item active nav-item list-inline-item" onClick={e=>{handleClick(e, 'Requested');handleClick(e, "empty")}}>
                {" "}
                <button type="button" class="btn btn-danger btn-sm px-2">
                  {requestedTotalStatuses.length}
                </button>{" "}
                Requested
              </li>
            </ul>
          </div>{" "}
          <div className="d-flex ">
            <input
              type="search"
              id="ordermonitorsearch"
              className="form-control-sm "
              size="40"
              placeholder="Search"
              aria-label="Search"
              value={searchInput}
              onChange={(e) => {
                handleChange(e)
              }}
              autoComplete={false}
            />
            &nbsp; 
            <button className="btn-sm"  onClick={(e)=>handleClickRefreshOrderMonitor(e)}>
            <i class="fa fa-refresh" aria-hidden="true"></i>
            </button>
            <ul className="navbar-nav">
              <li className="nav-item active nav-item px-1 ">
                <select
                  name="requestType"
                  id="requestType"
                  className="dropDown text-nowrap"
                  onChange={e=>handleRequestTypeChange(e)}
                >
                 
                  <option value="">Show All Request Type</option>
                  <option value="1">
                    ASIC Agent Listed : Request update(s) to ASCI Details
                  </option>
                  <option value="2">ASIC Agent Listed: Request a Company Profile</option>
                  <option value="3">
                    ASIC Agent Listed : Request a Company Debt Report
                  </option>
                  <option value="4"> Request a ASCI Company Extract</option>
                  <option value="5">Incorporate a New Company</option>
                  <option value="6">
                    Request for Entity to be added to the ASIC Agent
                  </option>
                  <option value="9">
                    Request for Entity to be removed  ASIC Agent
                  </option>
                  <option value="7">Request updates to ASIC</option>
                  <option value="8">Other Request</option>
                </select>
              </li>
              <li className="nav-item active nav-item px-1">
                <select
                  name="status"
                  id="status"
                  className="dropDown text-nowrap " 
                  onChange={e=>handleStatusChange(e)}
                >
                  <option value="">Show All Status</option>
                  <option value="Unassigned">Unassigned</option>
                  <option value="Requested">Requested</option>
                  <option value="Info Request">Info Request</option>
                  <option value="Prepared">Prepared</option>
                  <option value="Sent">Sent</option>
                  <option value="Signed">Signed</option>
                  <option value="Lodged">Lodged</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Completed">Completed</option>
                </select>
              </li>
            
            </ul>
          </div>
        </div>
      </nav>

      <div className="table-responsive-sm">
        <table className="table table-hover fs-6">
          <thead>
            <tr>
              <th >Order No</th>
              <th >Company Name</th>
              <th  >Type</th>
              <th >Status</th>
              <th >Status date</th>
              <th >Ordered Date</th>
              <th >Order By</th>
              <th >Assign To</th>
              <th >Assign Date</th>
              <th  >Dept</th>
              {/* 
              <th > Action </th> */}
            </tr>
          </thead>
          <tbody className=" fs-6">
          {console.log("cureentpost::::::",filterOrders)}
            {
              
              currentPosts&& currentPosts.length ===0 ?              
            <tr><td colSpan="10">No matching records found</td></tr>:
              currentPosts.map((post) => (
                <tr
                  className={
                    post.STATUS === "Prepared" ||
                    post.STATUS === "Sent" ||
                    post.STATUS === "Signed" ||
                    post.STATUS === "Lodged"
                      ? "orangecolour  "
                      : post.STATUS === "Completed"
                      ? "greencolour "
                      : (post.STATUS === "Rejected" || post.STATUS === "Requested" || post.STATUS === "Info Request" || post.STATUS === null) ? "pinkcolour":""
                  }
                >
                  <td>{post.ORDER_ID} </td>

                  <td>{post.COMPANY_NAME} </td>

                  <td data-toggle="tooltip" html= "true" data-placement="bottom" title={tooltipType(post.OPTIONS)}> {tooltipType(post.OPTIONS).slice(0, 15).concat('...')}</td>
                  
               
                  <td>{post.STATUS}</td>

                  <td>
                    {post.UPDATED_ON
                      ? moment(post.UPDATED_ON).format("DD/MM/YYYY hh:mm A")
                      : ""}{" "}
                  </td>

                  <td>
                    {post.CREATED_ON
                      ? moment(post.CREATED_ON).format("DD/MM/YYYY hh:mm A")
                      : ""}{" "}
                  </td>

                  <td>{post.CREATED_BY}</td>
                  <td>{post.ASSIGNED_TO}</td>
                  <td>
                    {post.ASSIGNED_ON
                      ? moment(post.ASSIGNED_ON).format("DD/MM/YYYY hh:mm A")
                      : ""}{" "}
                  </td>
                  <td>{post.LOCATION}</td>
                  {/*
                  <td className="w-25">
                    <a
                      href={() => false}
                      className="d-flex align-items-center text-white text-decoration-none "
                      id="dropdownUserDetail"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                    </a>
                    <ul className="dropdown-menu text-dark">
                      <li className="action ">
                        <a className="dropdown-item " href={() => false}>
                          View Order Status
                        </a>{" "}
                        <hr />
                      </li>

                      <li className="action ">
                        <a className="dropdown-item " href={() => false}>
                          View Form And Documents
                        </a>
                        <hr />
                      </li>
                      <li className="action ">
                        <a className="dropdown-item " href={() => false}>
                          Email
                        </a>
                        <hr />
                      </li>
                      <li className="py-0 ">
                        <a className="dropdown-item " href={() => false}>
                          Sign And Authorise
                        </a>
                      </li>
                    </ul>
                  </td>
                */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {((filterOrders && filterOrders.length>=10) || (orderMonitorTotalStatement && orderMonitorTotalStatement.length >= 10)) && (
        <footer
          id="sticky-footer"
          class="footer d-flex justify-content-end footer-body  "
        >
          <div class="container fs-6 ">
            <div class="row">
              <div class="col-md-6 ">
                Showing{" "}
                <input
                  type="text"
                  className="form-control-sm border border-default"
                  onKeyPress={handleKeyPress}
                  name="showRecord"
                  value={postsPerPage}
                  maxLength="3"
                  size="3"
                  onChange={(e) => setPostsPerPage(e.target.value)}
                ></input>{" "}
                /
                {filterOrders
                  ? filterOrders.length
                  : orderMonitorTotalStatement && orderMonitorTotalStatement.length}
              </div>
              <div class="col-md-6">
                {postsPerPage && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={
                      filterOrders
                        ? filterOrders.length
                        : orderMonitorTotalStatement && orderMonitorTotalStatement.length
                    }
                    pageSize={postsPerPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </footer>
      )}
      <br/>
      <br/>
    </div>
  );
};
export default OrderMonitor;
