import React from "react";
import moment from "moment";

const BusinessDetailSummary = (props) => {
  const post = props.businessNameSummary && props.businessNameSummary.items;
  return (
    <div>
    
      <table className="w-100 justify-content-center">
        <tbody>
        {post && post.RN ?
          <tr>
            <td  >RN:</td>
            <td >{post && post.RN}</td>
          </tr> :""
        }
        {post && post.OwnerName ? 
          <tr>
            <td>Owner Name:</td>
            <td>{post && post.OwnerName ? post.OwnerName : " "}</td>
          </tr>
          : ""}

         {post && post.BusinessName ? <tr>
            <td>BusinessName:</td>
            <td>{post && post.BusinessName ? post.BusinessName : " "}</td>
          </tr>: ""}
          {post && post.EntityType ? <tr>
            <td>Entity Type:</td>
            <td>{post && post.EntityType ? post.EntityType : ""}</td>
          </tr> : ""}
          {post && post.EntityCode ?<tr>
            <td>Entity Code:</td>
            <td>{post && post.EntityCode ? post.EntityCode : " "}</td>
          </tr>: ""}
          {post && post.Trust ? <tr>
            <td>Trust:</td>
            <td>{post && post.Trust ? post.Trust : " "}</td>
          </tr>:""}
          {post && post.ABN?<tr>
            <td>ABN:</td>
            <td>{post && post.ABN ? post.ABN : " "}</td>
          </tr>: ""}
          {post && post.AsicHolderType ?<tr>
            <td>AsicHolderType:</td>
            <td>{post && post.AsicHolderType ? post.AsicHolderType : " "}</td>
          </tr>: ""}
          <tr>
            <td className="py-2"></td>
          </tr>
          {post && post.RegistrationDate? <tr>
            <td>Registration Date:</td>
            <td>
              {post && post.RegistrationDate
                ? moment(post.RegistrationDate).format("DD/MM/YYYY")
                : ""}{" "}
            </td>
          </tr>:""}
          {post && post.CancellationDate ? <tr>
            <td>Cancellation Date:</td>
            <td>
              {post && post.CancellationDate
                ? moment(post.CancellationDate).format("DD/MM/YYYY")
                : ""}{" "}
            </td>
          </tr>:""}
          {post && post.RenewalDate
            ?<tr>
            <td>Renewal Date:</td>
            <td>
              {post && post.RenewalDate
                ? moment(post.RenewalDate).format("DD/MM/YYYY")
                : ""}{" "}
            </td>
          </tr>:""}
          {post && post.RenewalYears ? <tr>
            <td>Renewal Years:</td>
            <td>{post && post.RenewalYears ? post.RenewalYears : " "}</td>
          </tr>: ""}

          <tr>
            <td className="py-2"></td>
          </tr>
          {post && post.SnCareOf ? 
          <tr>
            <td>Service Notice Care Of:</td>
            <td>{post && post.SnCareOf ? post.SnCareOf : " "}</td>
          </tr>: ""}
          {post && post.SnEmailAddress ?<tr>
            <td>Service Notice Email:</td>
            <td>{post && post.SnEmailAddress ? post.SnEmailAddress : " "}</td>
          </tr> : ""}
          {post && post.SnBuilding ? 
          <tr>
            <td>Service Notice Building:</td>
            <td>{post && post.SnBuilding ? post.SnBuilding : " "}</td>
          </tr>: ""}
          {post && post.SnStreet ? <tr>
            <td>Service Notice Street:</td>
            <td>{post && post.SnStreet ? post.SnStreet : " "}</td>
          </tr>: ""}
          {post && post.SnSuburb ?<tr>
            <td>Service Notice Suburb:</td>
            <td>{post && post.SnSuburb ? post.SnSuburb : " "}</td>
          </tr> : ""}
          {post && post.SnState ?<tr>
            <td>Service Notice State:</td>
            <td>{post && post.SnState ? post.SnState : " "}</td>
          </tr>: ""}
          {post && post.SnPostcode ?<tr>
            <td>Service Notice Postcode:</td>
            <td>{post && post.SnPostcode ? post.SnPostcode : " "}</td>
          </tr> : ""}
          <tr>
            <td className="py-2"></td>
          </tr>
          {post && post.BaCareOf ?<tr>
            <td>Business Care Of:</td>
            <td>{post && post.BaCareOf ? post.BaCareOf : " "}</td>
          </tr> : ""}
          {post && post.BaBuilding ?
          <tr>
            <td>Business Building:</td>
            <td>{post && post.BaBuilding ? post.BaBuilding : " "}</td>
          </tr>: ""}
          {post && post.BaStreet ?
          <tr>
            <td>Business Street:</td>
            <td>{post && post.BaStreet ? post.BaStreet : " "}</td>
          </tr>: ""}
          {post && post.BaSuburb ? <tr>
            <td>Business Suburb:</td>
            <td>{post && post.BaSuburb ? post.BaSuburb : " "}</td>
          </tr>: ""}
          {post && post.BaState ?<tr>
            <td>Business State:</td>
            <td>{post && post.BaState ? post.BaState : " "}</td>
          </tr>:" "}
          {post && post.BaPostcode ?<tr>
            <td>Business Postcode:</td>
            <td>{post && post.BaPostcode ? post.BaPostcode : " "}</td>
          </tr>:" "}
          {post && post.BaEmailAddress ?<tr>
            <td>Business Email:</td>
            <td>{post && post.BaEmailAddress ? post.BaEmailAddress : " "}</td>
          </tr>: ""}
          {post && post.ClientAccountCode ? <tr>
            <td>Client Account Code:</td>
            <td>{post && post.ClientAccountCode ? post.ClientAccountCode : " "}</td>
          </tr>: ""}
          {post && post.ClientBillingCode ? <tr>
            <td>Client Billing Code:</td>
            <td>
              {post && post.ClientBillingCode ? post.ClientBillingCode : " "}
            </td>
          </tr>: ""}
          {post && post.ClientCode ? <tr>
            <td>Client Code:</td>
            <td>{post && post.ClientCode ? post.ClientCode : " "}</td>
          </tr>: ""}
          {post && post.DepartmentCode ? <tr>
            <td>Department Code:</td>
            <td>{post && post.DepartmentCode ? post.DepartmentCode : " "}</td>
          </tr>: ""}
          {post && post.KeyContactName ? <tr>
            <td>Key Contact Name:</td>
            <td>{post && post.KeyContactName ? post.KeyContactName : " "}</td>
          </tr>: ""}
          {post && post.KeyContactEmail ?<tr>
            <td>Key Contact Email:</td>
            <td>{post && post.KeyContactEmail ? post.KeyContactEmail : " "}</td>
          </tr>: ""}
          {post && post.AsicKeyContactName ? <tr>
            <td>Asic Key Contact Name:</td>
            <td>
              {post && post.AsicKeyContactName ? post.AsicKeyContactName : " "}
            </td>
          </tr>: ""}
          {post && post.AsicKeyContactEmail
            ?<tr>
            <td>Asic Key Contact Email:</td>
            <td>
              {post && post.AsicKeyContactEmail
                ? post.AsicKeyContactEmail
                : " "}
            </td>
          </tr>: ""}
          {post && post.Partner ?
          <tr >
        
            <td>Partner:</td>
            <td>{post && post.Partner ? post.Partner : " "}</td>
         </tr>: "" }
         {post && post.Manager ? 
          <tr>
            <td>Manager:</td>
            <td>{post && post.Manager ? post.Manager : " "}</td>
          </tr>:""
        }
          <tr></tr>
          {post && post.DebtorCareOf ?
          <tr>
            <td>Debtor CareOf:</td>
            <td>{post && post.DebtorCareOf ? post.DebtorCareOf : " "}</td>
          </tr>: ""}
          {post && post.DebtorEmailAddress ?<tr>
            <td>Debtor Email:</td>
            <td>
              {post && post.DebtorEmailAddress ? post.DebtorEmailAddress : " "}
            </td>
          </tr>: " "}
          {post && post.DebtorNumber ?  <tr>
            <td>Debtor Contact Number:</td>
            <td>{post && post.DebtorNumber ? post.DebtorNumber : " "}</td>
          </tr>: ""}
          {post && post.SuccessorCareOf ? 
          <tr>
            <td>Successor CareOf:</td>
            <td>{post && post.SuccessorCareOf ? post.SuccessorCareOf : " "}</td>
          </tr>: ""}
          {post && post.SuccessorEmailAddress
            ?  <tr>
            <td>Successor Email:</td>
            <td>
              {post && post.SuccessorEmailAddress
                ? post.SuccessorEmailAddress
                : " "}
            </td>
          </tr>:""}
          {post && post.SuccessorNumber ?
          <tr>
            <td>Successor Contact Number:</td>
            <td>{post && post.SuccessorNumber ? post.SuccessorNumber : " "}</td>
          </tr>: ""
          }
        </tbody>
      </table>
    </div>
  );
};

export default BusinessDetailSummary;
