import React, { useState} from "react";
import {  withRouter } from "react-router-dom";
import Popup from "../CommonPage/Popup.component";
import EBinderCompanyComponent from "../EBinderCompanyComponent/EBinderCompanyComponent";


const EbinderGetStarted = (props) => {
  const [showManageEBinderPopup, setShowManageEBinderPopup] = useState(false);
  const handleClose = () => setShowManageEBinderPopup(false);
  const handleShow = () => {setShowManageEBinderPopup(true);props.setShowEBinderMangement(true); }

  return (
    <div className="fs-6">
    
    <div>
    <h4><b>Electronic Company Binders (e-Binders)</b></h4></div>
  <div><h5><strong>What is an e-Binder?</strong></h5>
    <p>    An e-Binder offers a solution for aggregating all relevant company documents, registers and files into one place.</p>
    <p> Examples of information which can be assembled into your e-Binder include:</p>
    <ul><li> Registered Company Information
   </li>
    <li> PrimeCloud Official Reports / Documents
   </li>
    <li> Register of Directors & Secretaries
   </li>
    <li> Application and Allotment Journal
    </li>
    <li>Transfer Journal
   </li>
    <li> Register of Members</li></ul>
   
         
        </div> 
        <div><h5> <strong>How does it work?</strong> </h5>
        <p>The e-Binder system will take you through the following process:</p>
        <ol>
          <li>Select the company for which you are creating an e-Binder.</li>
          <li>Select which Prime Cloud documents should be included.</li>
          <li>Select which Registers should be included.</li>
          <li>Review your selection.</li>
          <li>Wait for Prime Cloud to generate your binder.</li>
          <li>
            Access your e-Binder download link. The new e-bBinder will also be
            available in the 'previous binders' section.
          </li>
        </ol>
        <p></p>
</div>
<div>
<h5><strong> Previous e-Binders </strong> </h5>
<p>
  You can also review and download any previously generated e-binders.
</p>
<p>
  The e-Binders manager serves as a historical reference for record
  keeping purposes.
</p>
<button  onClick={ handleShow}  className={
 "buttonCommon fs-6"
}

> Manage e-Binders</button>
{console.log("props.showEBinderMangement1111",props.selectedCompanyCode)}
   </div>
 {showManageEBinderPopup && (
  <Popup
    show={showManageEBinderPopup}
    handleClose={handleClose}
    handleShow={handleShow}
    isLargeSize={true}
    body={
      <EBinderCompanyComponent
    displaySelectedCompany={props.displaySelectedCompany} 
    setDisplaySelectedCompany={props.setDisplaySelectedCompany} 
    primeFileDocuments={props.primeFileDocuments} setPrimeFileDocuments={props.setPrimeFileDocuments} 
    ebinderPrimeFileList={props.ebinderPrimeFileList} customFileList={props.customFileList} 
    setCustomFileList={props.setCustomFileList} selectedCompanyCode={props.selectedCompanyCode} 
    setSelectedCompanyCode={props.setSelectedCompanyCode}
    showEBinderMangement={props.showEBinderMangement}
    />

    }
  
  />
)}

 <div>
 <br/>
 <h5> <strong>Getting Started</strong> </h5>
        <p>Get started by clicking the <strong>' Next'</strong> button below.</p>

                 </div>
    </div>
  );
};
export default withRouter(EbinderGetStarted);