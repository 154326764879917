import React from "react";



const FullPageLoader=() =>
{
    return(
       
        <div class="d-flex align-items-center py-3">
        <strong>Loading...</strong>
        <div class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
      </div>
      
    )
}

export default FullPageLoader
