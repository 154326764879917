import React from 'react';
import Landingpage from '../LandingPagecomponent/LandingPage.Component'
import { withRouter } from "react-router-dom";
import CommonSearch from '../CommonPage/CommonSearch.component';
import EbinderLanding from '../EBinderComponent/EbinderLandingPage.component';


const EbinderMainLanding = (props) => {
   
   
    
    return (
       
        <Landingpage  isFileCollapse={true} isEbinderPage={true}
        commonSearch={ <CommonSearch history={props.history} setRecentSearch={props.setRecentSearch} recentSearch={props.recentSearch} setSearchCompany={props.setSearchCompany} searchCompany={props.searchCompany}/>} content={
<EbinderLanding setRecentSearch={props.setRecentSearch} recentSearch={props.recentSearch} loggedUserInfo={props.loggedUserInfo} userId={props.userId} clientId={props.clientId}  setSearchCompany={props.setSearchCompany} searchCompany={props.searchCompany}/>
            
        }/>
        
    )
        
    }
    export default withRouter(EbinderMainLanding);