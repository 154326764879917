import React, { useState, useEffect, useCallback } from "react";
import DatePicker from "react-datepicker";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import NumberFormat from "react-number-format";
import { Typeahead } from "react-bootstrap-typeahead";

const ShareTransfer = (props) => {
  useEffect(() => {
    handleNames();
    // eslint-disable-next-line
  }, []);

  function handleNames() {
    setOfficerShareHoldersData((value) => ({
      ...value,
      name:shareHolderNames,
      /*  officerNames &&
        shareHolderNames &&
        officerNames.concat(shareHolderNames),*/
    }));
  }
  const shareHolderNames =
    props.companyProfile &&
    props.companyProfile.items &&
    props.companyProfile.items.shareholders &&
    props.companyProfile.items.shareholders.map(
      (value) =>
        `${value.NAME}:  ${value.CLASS_CODE}:  ${value.CLASS_NO}:  FP=${
          value.FULLY_PAID ? "Yes" : "no"
        }:  BO=${value.BEN_OWNER ? "Yes" : "no"}
  :  Total Shares=${value.SUM_TOT_SHARES} : ${
          value.BEN_OWNER ? "Beneficially Held" : ""
        } 
  : Per Shares Paid= ${value.AMOUNT_PAID} : Total Paid=${
          value.SUM_TRAN_PRICE
        }  `
    );

  const duplicatedOfficer =
    props.companyProfile &&
    props.companyProfile.items &&
    props.companyProfile.items.officers.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.OFFICER_NAME === thing.OFFICER_NAME)
    );
// eslint-disable-next-line
  const officerNames =
    duplicatedOfficer &&
    duplicatedOfficer.map(
      (value) => `${value.CEASED !== "" ? value.OFFICER_NAME : ""}`
    );

  const [officerShareHoldersData, setOfficerShareHoldersData] = useState({
    name: [],
  });

  const handleSearch = useCallback((call) => {
    // eslint-disable-next-line
  }, []);
  /*    let handleClickTransfertoFullyPaid = (e) => {
    
            setTransfertoFullyPaid(e.target.checked);
        }
        const [transfertoBeneficiallyOwned, setTransfertoBeneficiallyOwned] = useState(true);
        
        let handleClickTransfertoBeneficiallyOwned = (e) => {
           
            setTransfertoBeneficiallyOwned(e.target.checked);
        }*/
  return (
    <div
      className="container "
      onClick={handleNames}
      onFocus={handleNames}
      onKeyPress={handleNames}
    >
      <form>
        <hr />
        <div className="form-group row px-4 ">
          <div className="col-xs-3">
            <label for="transferFrom" className="form-label">
              Tranfer From
              <i
                className="bi bi-info-circle px-1"
                data-toggle="tooltip"
                data-placement="top"
                title="Full legal Name as per Passport"
              ></i>
            </label>

            <div className="col-sm-12 col-lg-7 TypeAheadDropDown  w-100 ">
              <Typeahead
                id="transferFrom"
                labelKey={
                  officerShareHoldersData &&
                  officerShareHoldersData.name &&
                  officerShareHoldersData.name.map((value) => value.name)
                }
                options={
                  officerShareHoldersData && officerShareHoldersData.name
                    ? officerShareHoldersData.name
                    : []
                }
                isLoading={false}
                onSearch={handleSearch}
                onChange={(selected) => {
                  const splittedSelected =
                    selected[0] && selected[0].indexOf(":") !== -1
                      ? selected[0].split(":")
                      : selected[0];
                  const list = [...props.shareTransferInputList];
                  list[props.shareTransferIndex]["transferFrom"] = selected;
                  if (selected[0] && selected[0].indexOf(":") !== -1) {
                   const fullyPaid = splittedSelected && splittedSelected[3] && splittedSelected[3].trim()
                   const beneficiallyOwned =   splittedSelected && splittedSelected[4] && splittedSelected[4].trim();
                    list[props.shareTransferIndex]["shareTransferclassCode"] =
                      splittedSelected &&
                      splittedSelected[1] &&
                      splittedSelected[1].trim();
                      list[props.shareTransferIndex]["transferFromFullyPaid"] = fullyPaid  && fullyPaid.split('=')[1].toLowerCase() === 'yes'
                      list[props.shareTransferIndex]["transferFromBeneficiallyOwned"] = beneficiallyOwned  && beneficiallyOwned.split('=')[1].toLowerCase() === 'yes'
                    
                    list[props.shareTransferIndex]["shareTrasferTotalShares"] =
                      splittedSelected &&
                      splittedSelected[5] &&
                      splittedSelected[5].trim();
                    list[props.shareTransferIndex][
                      "shareTransferPaidPerShare"
                    ] =
                      splittedSelected &&
                      splittedSelected[7] &&
                      splittedSelected[7].trim();
                    list[props.shareTransferIndex]["shareTransferTotalPaid"] =
                      splittedSelected &&
                      splittedSelected[8] &&
                      splittedSelected[8].trim();
                  } else {
                    list[props.shareTransferIndex]["shareTransferclassCode"] =
                      "";
                    list[props.shareTransferIndex]["shareTrasferTotalShares"] =
                      "";
                    list[props.shareTransferIndex][
                      "shareTransferPaidPerShare"
                    ] = "";
                    list[props.shareTransferIndex]["shareTransferTotalPaid"] =
                      "";
                  }

                  props.setShareTransferInputList(list);
                  props.setAsicDetails((changeOrder) => ({
                    ...changeOrder,
                    shareTransferInputList: list,
                  }));
                }}
                /* onChange={(selected) => {
            const sc=selected.map(item=> item );
        const splitting = sc && sc[0] && sc[0].split(":")

            props.setAsicDetails((value) => ({ ...value, transferFrom: selected,
              shareTransferclassCode: splitting && splitting[1] && splitting[1],
          shareTrasferTotalShares:splitting && splitting[5] && splitting[5],
          shareTransferPaidPerShare:splitting && splitting[7] && splitting[7],
          shareTransferTotalPaid:splitting && splitting[8] && splitting[8],
             }));

          } } */

                selected={props.shareTransferElements.transferFrom}
                placeholder="Shareholder Name"
                useCache={false}
              />
            </div>
           
            <span className="text-secondary">
              For Joint holder, combine with 'AND' operator
            </span>
          </div>
       
          <div className="row py-2">
            <div className="col">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="transferFromFullyPaid"
                  name="transferFromFullyPaid"
                  onChange={(e) => {
                    props.handleInputChangeShareTransfer(
                      e,
                      props.shareTransferIndex
                    );
                  }}
                  checked={props.shareTransferElements.transferFromFullyPaid}
                  value={props.shareTransferElements.transferFromFullyPaid}
                />
              
                <label className="form-check-label" for="transferFromFullyPaid">
                  Transfer - Fully Paid
                </label>
              </div>
            </div>
            <div className="col">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="transferFromBeneficiallyOwned"
                  name="transferFromBeneficiallyOwned"
                  onChange={(e) => {
                    props.handleInputChangeShareTransfer(
                      e,
                      props.shareTransferIndex
                    );
                  }}
                  checked={
                    props.shareTransferElements.transferFromBeneficiallyOwned
                  }
                  value={
                    props.shareTransferElements.transferFromBeneficiallyOwned
                  }
                />
                <label
                  className="form-check-label"
                  for="transferFromBeneficiallyOwned"
                >
                  Transfer - Beneficially Owned
                </label>
              </div>
            </div>
          </div>
          <div className="col-xs-3">
            <label for="transferTo" className="form-label">
              Tranfer To
              <i
                className="bi bi-info-circle px-1"
                data-toggle="tooltip"
                data-placement="top"
                title="Full legal Name as per Passport"
              ></i>
            </label>
            <input
              type="text"
              className="form-control"
              id="transferTo"
              name="transferTo"
              placeholder="Shareholder Name"
              value={props.shareTransferElements.transferTo}
              onChange={(e) =>
                props.handleInputChangeShareTransfer(
                  e,
                  props.shareTransferIndex
                )
              }
            />
            <span className="text-secondary">
              For Joint holder, combine with 'AND' operator
            </span>
          </div>
          <div className="row py-2">
            <div className="col">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="transfertoFullyPaid"
                  name="transfertoFullyPaid"
                  onChange={(e) => {
                    props.handleInputChangeShareTransfer(
                      e,
                      props.shareTransferIndex
                    );
                  }}
                  checked={props.shareTransferElements.transfertoFullyPaid}
                  value={props.shareTransferElements.transfertoFullyPaid}
                />
                <label className="form-check-label" for="transfertoFullyPaid">
                  Fully Paid
                </label>
              </div>
            </div>
            <div className="col">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="transfertoBeneficiallyOwned"
                  name="transfertoBeneficiallyOwned"
                  checked={
                    props.shareTransferElements.transfertoBeneficiallyOwned
                  }
                  value={
                    props.shareTransferElements.transfertoBeneficiallyOwned
                  }
                  onChange={(e) => {
                    props.handleInputChangeShareTransfer(
                      e,
                      props.shareTransferIndex
                    );
                  }}
                />
                <label
                  className="form-check-label"
                  for="transfertoBeneficiallyOwned"
                >
                  Beneficially Owned
                </label>
              </div>
            </div>
          </div>
          {!props.shareTransferElements.transfertoBeneficiallyOwned && (
            <div className="col-xs-3">
              <label for="transfertoBenName" className="form-label">
                BEN NAME
              </label>
              <input
                type="text"
                className="form-control"
                id="transfertoBenName"
                name="transfertoBenName"
                value={props.shareTransferElements.transfertoBenName}
                onChange={(e) =>
                  props.handleInputChangeShareTransfer(
                    e,
                    props.shareTransferIndex
                  )
                }
                autoComplete="off"
              />
            </div>
          )}
          <div className="col-xs-3">
            <label htmlFor="shareTransferAddress" className="col-form-label">
              Shareholder Address
            </label>

            <GooglePlacesAutocomplete
              apiKey={"AIzaSyD8pHI8_Rviq7fmkxO5TBlJmaQl7kNHpE0"}
              selectProps={{
                placeholder: "Text...",
                isClearable: true,
                predefinedPlacesAlwaysVisible: true,
                value: props.shareTransferElements.shareTransferAddress,
                onChange: (val) => {
                  const list = [...props.shareTransferInputList];
                  list[props.shareTransferIndex]["shareTransferAddress"] = val;
                  props.setShareTransferInputList(list);
                  props.setAsicDetails((changeOrder) => ({
                    ...changeOrder,
                    shareTransferInputList: list,
                  }));
                },
              }}
            />

            <span className="text-secondary">
              Address required for new Shareholder
            </span>
            <div className="col-xs-3">
              <label for="shareTransferclassCode" className="form-label">
                CLASS CODE
              </label>
              <input
                type="text"
                className="form-control"
                id="shareTransferclassCode"
                name="shareTransferclassCode"
                value={props.shareTransferElements.shareTransferclassCode}
                onChange={(e) =>
                  props.handleInputChangeShareTransfer(
                    e,
                    props.shareTransferIndex
                  )
                }
                autoComplete="off"
              />
            </div>
            <div>
              <label
                htmlFor="shareTransferTransferDate"
                className="col-form-label input-sm "
              >
                Transfer Date{" "}
                <i
                  className="bi bi-info-circle px-1"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="ASIC late fees apply if form is not lodgged with in 28days of change date"
                ></i>
              </label>
              <DatePicker
                className="form-control input-sm w-25"
                name="shareTransferTransferDate"
                selected={props.shareTransferElements.shareTransferTransferDate}
                /*  onChange={e=> props.handleChange(e, true, 'shareTransferTransferDate')}*/
                onChange={(e) => {
                  const list = [...props.shareTransferInputList];
                  list[props.shareTransferIndex]["shareTransferTransferDate"] =
                    e;
                  props.setShareTransferInputList(list);
                  props.setAsicDetails((changeOrder) => ({
                    ...changeOrder,
                    shareTransferInputList: list,
                  }));
                }}
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
                value={props.shareTransferElements.shareTransferTransferDate}
              />
            </div>
          </div>
          <div className="col-xs-3">
            <label for="shareTrasferTotalShares" className="form-label">
              Total Shares
            </label>
            <NumberFormat
              type="text"
              data-filter="[0-9]"
              name="shareTrasferTotalShares"
              id="shareTrasferTotalShares"
              className="form-control w-100"
              placeholder="0"
              thousandSeparator={true}
              decimalSeparator="."
              displayType="input"
              allowNegative={false}
              value={props.shareTransferElements.shareTrasferTotalShares}
              onChange={(e) =>
                props.handleInputChangeShareTransfer(
                  e,
                  props.shareTransferIndex
                )
              }
              autoComplete="off"
            />
          </div>
          <div className="py-2">
            <hr />
          </div>
          <div className="row">
            <div className="col">
              <label
                htmlFor="shareTransferPaidPerShare"
                className="col-form-label input-sm "
              >
                Paid per share
              </label>
              <NumberFormat
                type="text"
                data-filter="[0-9]"
                name="shareTransferPaidPerShare"
                id="shareTransferPaidPerShare"
                className="form-control w-100"
                value={props.shareTransferElements.shareTransferPaidPerShare}
                onChange={(e) =>
                  props.handleInputChangeShareTransfer(
                    e,
                    props.shareTransferIndex
                  )
                }
                placeholder="0.00"
                decimalScale={4}
                thousandSeparator={true}
                prefix="$"
                decimalSeparator="."
                displayType="input"
                allowNegative={false}
                autoComplete="off"
              />
            </div>
            <div className="col">
              <label
                htmlFor="shareTransferTotalPaid"
                className="col-form-label input-sm "
              >
                Total Paid
              </label>
              <NumberFormat
                type="text"
                data-filter="[0-9]"
                name="shareTransferTotalPaid"
                className="form-control w-100"
                value={props.shareTransferElements.shareTransferTotalPaid}
                onChange={(e) =>
                  props.handleInputChangeShareTransfer(
                    e,
                    props.shareTransferIndex
                  )
                }
                autoComplete="off"
                placeholder="0.00"
                decimalScale={2}
                thousandSeparator={true}
                prefix="$"
                decimalSeparator="."
                displayType="input"
                allowNegative={false}
              />
            </div>
          </div>
          {!props.shareTransferElements.transfertoFullyPaid && (
            <div className="row">
              <div className="col">
                <label
                  htmlFor="shareTransferUnpaidPerShare"
                  className="col-form-label input-sm "
                >
                  UnPaid per share
                </label>
                <NumberFormat
                  type="text"
                  data-filter="[0-9]"
                  name="shareTransferUnpaidPerShare"
                  className="form-control w-100"
                  value={
                    props.shareTransferElements.shareTransferUnpaidPerShare
                  }
                  onChange={(e) =>
                    props.handleInputChangeShareTransfer(
                      e,
                      props.shareTransferIndex
                    )
                  }
                  placeholder="0.00"
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  allowNegative={false}
                  autoComplete="off"
                />
              </div>
              <div className="col">
                <label
                  htmlFor="shareTransferTotalUnPaid"
                  className="col-form-label input-sm "
                >
                  Total UnPaid
                </label>

                <NumberFormat
                  type="text"
                  data-filter="[0-9]"
                  name="shareTransferTotalUnpaid"
                  className="form-control w-100"
                  value={props.shareTransferElements.shareTransferTotalUnpaid}
                  onChange={(e) =>
                    props.handleInputChangeShareTransfer(
                      e,
                      props.shareTransferIndex
                    )
                  }
                  placeholder="0.00"
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  allowNegative={false}
                  autoComplete="off"
                />
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};
export default ShareTransfer;
