import React from 'react';
import './ToggleSwitch.style.scss'

const ToggleSwitch=({checked, onChange, name, yesLabel="Show All", noLabel="Summary", toggleWidth='120px'})=> {

    return (
      <div className="toggle-switch" style={{width:toggleWidth}}>
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          name={name}
          id={name}
          checked={checked}
          onChange={e => onChange(e.target.checked)}
        />
        <label className="toggle-switch-label" htmlFor={name} >
          <span className="toggle-switch-inner" data-yes={yesLabel} data-no={noLabel}/>
          <span className="toggle-switch-switch" />
        </label>
      </div>
    );
  
}

export default ToggleSwitch;