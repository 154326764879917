import React, { useState, useEffect } from "react";
import "./CompanySnapshot.style.scss";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import { useSelector } from "react-redux";
import moment from "moment";
import ShareCapitalComponent from "../CompanyDetailsComponent/ShareCapitalComponent";
import DocumentHistoryComponent from "../CompanySnapshotComponent/DocumentHistoryComponent";
import Popup from "../CommonPage/Popup.component";
import OfficerDetailsComponent from "../OfficerDetailsComponents/OfficerDetails.Component";
import ShareHolderDetailsComponent from "../ShareHolderDetailsComponent/ShareHolderDetails.component";
import { useHistory } from "react-router-dom";
import FullPageLoader from "../CommonPage/FullPageLoader";

const CompanySnapshotComponent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const companyProfile = useSelector((state) => state.companyProfile);
  const [popupShareHolderShow, setPopupShareHolderShow] = useState(0);
  const [popupShow, setPopupShow] = useState(0);
  const [popupSecretariesShow, setPopupSecretariesShow] = useState(0);

  const [selectedOfficerName, setSelectedOfficerName] = useState("");
  const [selectedShareholdersName, setSelectedShareholdersName] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setPopupShareHolderShow(false);
    setPopupShow(false)
    setPopupShareHolderShow(false)

      if(props.searchCompany && props.searchCompany[0]) {
  
        dispatch(userActions.getCompanyProfileByCode(props.searchCompany[0].Code));
     } 
    }, 0)
      // eslint-disable-next-line
  }, [props.searchCompany]);
  useEffect(() => {
    setPopupShareHolderShow(false);
    setPopupShow(false)
    setPopupShareHolderShow(false)
    const selectedSearchValue = localStorage.getItem("inputSearchValue");
    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    const selectedCompanyName = JSON.parse(localStorage.getItem("companyName"));
    console.log("(props.searchCompany Outside",props.searchCompany && props.searchCompany[0]);
    if(props.searchCompany && props.searchCompany[0]) {
      console.log("aaaaaaaaaaa");
      console.log("(props.searchCompany && props.searchCompany[0]",props.searchCompany && props.searchCompany[0]);
      dispatch(userActions.getCompanyProfileByCode(props.searchCompany[0].Code));
      
    } else if (selectedCompanyCode !== null) {
      console.log("bbbbbbb");
      props.setSearchCompany([
        { Code: selectedCompanyCode, Name: selectedCompanyName },
      ]);
      dispatch(userActions.getCompanyProfileByCode(selectedCompanyCode));
    } else if (selectedSearchValue !== null) {
      console.log("cccccccccccc");
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(userActions.getCompanyProfileByCode(value.Code))
      );
    }
    // eslint-disable-next-line
  }, []);



  const officerDetailHandleClose = () => setPopupShow(false);
  const secretariesDetailHandleClose = () => setPopupSecretariesShow(false);
  const shareHolderDetailHandleClose = () => setPopupShareHolderShow(false);

  const officerDetailHandleShow = (e, index, officerCode, officerName) => {
    e.preventDefault();

    setPopupShow(index);
    setSelectedOfficerName(officerName);

    dispatch(userActions.getOfficerDetailsReport(officerCode));
  };

  const secretariesDetailHandleShow = (e, index, officerCode, officerName) => {
    e.preventDefault();

    setPopupSecretariesShow(index);
    setSelectedOfficerName(officerName);
    dispatch(userActions.getOfficerDetailsReport(officerCode));
  };

  const shareHolderDetailHandleShow = (
    e,
    index,
    code,
    holderType,
    OfficerName
  ) => {
    e.preventDefault();
    setPopupShareHolderShow(index);
    setSelectedShareholdersName(OfficerName);
    dispatch(userActions.getShareHolderDetailsReport(code, holderType));
  };
  let officerDetailHandleClick = (e) => {
    e.preventDefault();
    officerDetailHandleClose();
    secretariesDetailHandleClose();
  };
  let shareHolderDetailHandleClick = (e) => {
    e.preventDefault();
    shareHolderDetailHandleClose();
  };

  // Print company Profile Report
  const printCompanyDispatch = useDispatch();
  // eslint-disable-next-line
  const [printCompanySubmitted, setPrintCompanySubmitted] = useState(false);
  //loading spinner
  const [isQuickCompanyLoaded, setIsQuickCompanyLoaded] = useState(false);
  function printCompanyHandleClick(e) {
    setPrintCompanySubmitted(true);
    const selectedSearchValue = localStorage.getItem("inputSearchValue");

    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if (selectedCompanyCode !== null) {
      printCompanyDispatch(
        userActions.getQuickCompanyProfileReport(
          selectedCompanyCode,
          setIsQuickCompanyLoaded
        )
      );
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        printCompanyDispatch(
          userActions.getQuickCompanyProfileReport(
            value.Code,
            setIsQuickCompanyLoaded
          )
        )
      );
    }
  }
  // Print ShareHolders
  // eslint-disable-next-line
  const [members, setMembers] = useState({
    printDate: new Date(),
    benificialName: "Yes",
    holdingSummary: "No",
    zeroBalance: "Yes",
    address: "Yes",
    foreignCurrency: "No",
    totalShares: "No",
  });
  const printShareHoldersDispatch = useDispatch();
  // eslint-disable-next-line
  const [printShareHoldersSubmitted, setPrintShareHoldersSubmitted] =
    useState(false);

  // loading spinner
  const [isMemberLoaded, setIsMemberLoaded] = useState(false);

  function printShareHoldersHandleClick(e) {
    setPrintShareHoldersSubmitted(true);
    const selectedSearchValue = localStorage.getItem("inputSearchValue");

    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if (selectedCompanyCode !== null) {
      printShareHoldersDispatch(
        userActions.getMembersReport(
          members,
          selectedCompanyCode,
          setIsMemberLoaded
        )
      );
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        printShareHoldersDispatch(
          userActions.getMembersReport(members, value.Code, setIsMemberLoaded)
        )
      );
    }
  }

  // Print F DIRECTORS & SECRETARIES
// eslint-disable-next-line
  const [officers, setOfficers] = useState({
    dsPrintDate: new Date(),
    officer: "Yes",
  });
  const printDirectorAndSecretariesDispatch = useDispatch();
// eslint-disable-next-line
  const [printDirectorAndSecretariesSubmitted,
    setPrintDirectorAndSecretariesSubmitted,
  ] = useState(false);

  //loading spinner
  const [isDirectorAndSecretariesLoaded, setIsDirectorAndSecretariesLoaded] =
    useState(false);

  function printDirectorAndSecretariesHandleClick(e) {
    setPrintDirectorAndSecretariesSubmitted(true);
    const selectedSearchValue = localStorage.getItem("inputSearchValue");

    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if (selectedCompanyCode !== null) {
      printDirectorAndSecretariesDispatch(
        userActions.getDirectorsSecretariesReport(
          officers,
          selectedCompanyCode,
          setIsDirectorAndSecretariesLoaded
        )
      );
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        printDirectorAndSecretariesDispatch(
          userActions.getDirectorsSecretariesReport(
            officers,
            value.Code,
            setIsDirectorAndSecretariesLoaded
          )
        )
      );
    }
  }
  return (
    <div className="tree ">
    {console.log('!~~~~~~~~~~~~~~~~~~~~', companyProfile)}
      {companyProfile && companyProfile.items ? (
        <ul>
          <li>
            <span>
              <div className="d-flex flex-row-reverse ">
                <a
                  className="text-decoration-none text-reset"
                  role="button"
                  href={() => false}
                  onClick={(e) => printCompanyHandleClick(e)}
                >
                  <span
                    className="p-0 border-0"
                    tabindex="0"
                    data-toggle="tooltip"
                    title="Company Profile"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                  </span>
                  <span className="p-1 border-0">
                    <p className="fs-6 fw-normal">Print</p>
                  </span>
                  <span className="p-0 border-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-printer-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                      <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                    </svg>
                  </span>
                </a>
                {isQuickCompanyLoaded ? <FullPageLoader /> : null}
              </div>
              <h5>{companyProfile.items.company.CO_NAME} </h5>
              <p className="fs-6 fw-normal">
                ACN:{companyProfile.items.company.ACN}
                <br />
                Inc Date :
                {companyProfile.items.company.DATE_INC
                  ? moment(companyProfile.items.company.DATE_INC).format(
                      "DD/MM/YYYY"
                    )
                  : ""}{" "}
              </p>{" "}
            </span>
            <div id="Web" className="collapse show">
              <ul>
                <li>
                  <span>
                    <h5> Addresses</h5>
                    <h6>Registered Office</h6>

                    <p className="fs-6 fw-normal">
                      {" "}
                      {` 
                      ${companyProfile.items.company.RO_CARE_OF !== null ? companyProfile.items.company.RO_CARE_OF: ""}
                      ${companyProfile.items.company.RO_BUILDING !== null ? companyProfile.items.company.RO_BUILDING:""}
                      ${companyProfile.items.company.RO_STREET},
                    ${companyProfile.items.company.RO_SUBURB},
                    ${companyProfile.items.company.RO_STATE}
                    ${companyProfile.items.company.RO_PCODE}`}{" "}
                    </p>
                    <h6>Principal Place of Business</h6>
                    <p className="fs-6 fw-normal">
                      {` 
                      ${companyProfile.items.company.PB_CARE_OF  !== null? companyProfile.items.company.PB_CARE_OF: ""}
                      ${companyProfile.items.company.PB_BUILDING !== null ? companyProfile.items.company.PB_BUILDING :""}
                      ${companyProfile.items.company.PB_STREET},
                  ${companyProfile.items.company.PB_SUBURB},
                  ${companyProfile.items.company.PB_STATE}
                  ${companyProfile.items.company.PB_PCODE}`}
                    </p>
                    <h6> Postal Address</h6>

                    <p className="fs-6 fw-normal">
                      {`${
                        companyProfile.items.company.POSTAL1
                          ? companyProfile.items.company.POSTAL1 + ","
                          : ""
                      }
                    ${
                      companyProfile.items.company.POSTAL2
                        ? companyProfile.items.company.POSTAL2 + ","
                        : ""
                    }
                    ${
                      companyProfile.items.company.POSTAL3
                        ? companyProfile.items.company.POSTAL3 + ","
                        : ""
                    }
                    ${
                      companyProfile.items.company.POST_STATE
                        ? companyProfile.items.company.POST_STATE + "  "
                        : ""
                    }
                    ${
                      companyProfile.items.company.POST_PCODE
                        ? companyProfile.items.company.POST_PCODE
                        : ""
                    }`}
                    </p>

                    <p className="fs-6 fw-normal">
                      {" "}
                      {!companyProfile.items.company.POSTAL1 &&
                        !companyProfile.items.company.POSTAL2 &&
                        !companyProfile.items.company.POSTAL3 &&
                        !companyProfile.items.company.POST_STATE &&
                        !companyProfile.items.company.POST_PCODE &&
                        "N/A"}{" "}
                    </p>
                  </span>
                </li>
                <li>
                  <span>
                    <div className="d-flex flex-row-reverse ">
                      <a
                        className="text-decoration-none text-reset"
                        role="button"
                        href={() => false}
                        onClick={(e) =>
                          printDirectorAndSecretariesHandleClick(e)
                        }
                      >
                        <span
                          className="p-0  border-0"
                          tabindex="0"
                          data-toggle="tooltip"
                          title="Register of Director and Secretaries"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-info-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                        </span>
                        <span className="p-1  border-0">
                          <p className="fs-6 fw-normal">Print</p>
                        </span>
                        <span className="p-0 border-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-printer-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                          </svg>
                        </span>
                      </a>{" "}
                      {isDirectorAndSecretariesLoaded ? (
                        <FullPageLoader />
                      ) : null}
                    </div>
                    <h5> Officers</h5>
                    <h6>DIRECTORS</h6>
                    {console.log('~~~~~~~~~~~~~~', popupShow)}
                    {companyProfile.items.officers.map(
                      (value) =>
                        value.POSITION_HELD === "DIRECTOR" &&
                        value.CEASED === null && (
                          <p className="fs-6 fw-normal">
                            <a
                              href="{()=> false}"
                              className="text-decoration-none table-data "
                              onClick={(e) =>{
                                officerDetailHandleShow(
                                  e,
                                  companyProfile.items.company.RN,
                                  value.CODE,
                                  value.OFFICER_NAME
                                )
                              }}
                            >
                              {value.OFFICER_NAME}
                            </a>
                            {popupShow === companyProfile.items.company.RN && (
                              <Popup
                                show={
                                  popupShow === companyProfile.items.company.RN
                                }
                                handleClose={officerDetailHandleClose}
                                handleShow={(e) =>
                                  officerDetailHandleShow(
                                    e,
                                    companyProfile.items.company.RN,
                                    value.CODE,
                                    value.OFFICER_NAME
                                  )
                                }
                                body={
                                  <OfficerDetailsComponent
                                    officerName={selectedOfficerName}
                                    history={history}
                                    setPopupShow={setPopupShow}
                                    setSearchCompany={props.setSearchCompany}
                                    recentSearch={props.recentSearch}
                                    loggedUserInfo={props.loggedUserInfo}
                                    userId={props.userId} clientId={props.clientId}
                                  />
                                }
                                dialogWidth="large"
                                handleClick={officerDetailHandleClick}
                              />
                            )}{" "}
                            {moment(value.APPOINTED).format("DD/MM/YYYY")}{" "}
                          </p>
                        )
                    )}
                    <h6>SECRETARIES</h6>
                    {companyProfile.items.officers.map(
                      (value) =>
                        value.POSITION_HELD === "SECRETARY" &&
                        value.CEASED === null && (
                          <p className="fs-6 fw-normal">
                            <a
                              href="{()=> false}"
                              className="text-decoration-none table-data"
                              onClick={(e) =>
                                secretariesDetailHandleShow(
                                  e,
                                  companyProfile.items.company.RN,
                                  value.CODE,
                                  value.OFFICER_NAME
                                )
                              }
                            >
                              {value.OFFICER_NAME}{" "}
                            </a>
                            {popupSecretariesShow ===
                              companyProfile.items.company.RN && (
                              <Popup
                                show={
                                  popupSecretariesShow ===
                                  companyProfile.items.company.RN
                                }
                                handleClose={secretariesDetailHandleClose}
                                handleShow={(e) =>
                                  secretariesDetailHandleShow(
                                    e,
                                    companyProfile.items.company.RN,
                                    value.CODE,
                                    value.OFFICER_NAME
                                  )
                                }
                                body={
                                  <OfficerDetailsComponent
                                    officerName={selectedOfficerName}
                                    history={history}
                                    setSearchCompany={props.setSearchCompany}
                                    setPopupShow={setPopupShow}
                                    recentSearch={props.recentSearch}
                                  />
                                }
                                handleClick={officerDetailHandleClick}
                              />
                            )}{" "}
                            {moment(value.APPOINTED).format("DD/MM/YYYY")}{" "}
                          </p>
                        )
                    )}
                  </span>
                </li>

                <li>
                  <span>
                    <div className="d-flex flex-row-reverse">
                      <a
                        className="text-decoration-none text-reset"
                        role="button"
                        href={() => false}
                        onClick={(e) => printShareHoldersHandleClick(e)}
                      >
                        <span
                          className="p-0 border-0"
                          tabindex="0"
                          data-toggle="tooltip"
                          title="Register of Members"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-info-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                        </span>
                        <span className="p-1  border-0">
                          <p className="fs-6 fw-normal">Print</p>
                        </span>
                        <span className="p-0  border-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-printer-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                          </svg>
                        </span>
                      </a>
                      {isMemberLoaded ? <FullPageLoader /> : null}
                    </div>
                    <h5> Shareholders</h5>
                    <div className="container">
                      <table className="table  table-light ">
                        <tbody className="fs-6 fw-normal">
                          {companyProfile.items.shareholders.map(
                            (value, index) => (
                              <tr>
                                <td style={{ width: "200px" }} key={index}>
                                  {" "}
                                  <a
                                    href="{()=> false}"
                                    className="text-decoration-none table-data "
                                    onClick={(e) =>
                                      shareHolderDetailHandleShow(
                                        e,
                                        value.CODE,
                                        value.CODE,
                                        value.HOLDERTYPE,
                                        value.NAME
                                      )
                                    }
                                  >
                                    {" "}
                                    {value.NAME}
                                    {value.BEN_NAME && (
                                      <p>[ {value.BEN_NAME}]</p>
                                    )}
                                  </a>
                                  {popupShareHolderShow === value.CODE && (
                                    <Popup
                                      show={popupShareHolderShow === value.CODE}
                                      handleClose={shareHolderDetailHandleClose}
                                      handleShow={(e) =>
                                        shareHolderDetailHandleShow(
                                          e,
                                          value.CODE,
                                          value.CODE,
                                          value.HOLDERTYPE,
                                          value.NAME
                                        )
                                      }
                                      body={
                                        <ShareHolderDetailsComponent
                                          NAME={selectedShareholdersName}
                                          history={history}
                                          setPopupShareHolderShow={
                                            setPopupShareHolderShow
                                          }
                                          setSearchCompany={
                                            props.setSearchCompany
                                          }
                                          recentSearch={props.recentSearch}
                                          loggedUserInfo={props.loggedUserInfo} userId={props.userId} clientId={props.clientId}
                                        />
                                      }
                                      handleClick={shareHolderDetailHandleClick}
                                    />
                                  )}
                                </td>
                                <td style={{ width: "50px" }}>
                                  {" "}
                                  {value.CLASS_CODE}
                                </td>
                                <td style={{ width: "50px" }}>
                                  {" "}
                                  {value.TOT_SHARES}
                                </td>
                                <td style={{ width: "50px" }}>
                                  {" "}
                                  {Intl.NumberFormat("en-US").format(
                                    value.SUM_TOT_SHARES
                                  )}
                                </td>
                                <td style={{ width: "50px" }}>
                                {value.BEN_OWNER ? "Yes" : "No"}
                                </td>
                                <td style={{ width: "50px" }}>
                                  {value.FULLY_PAID ? "FP" : ""}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </span>
                </li>

                <li>
                  <span>
                    <h5> Share Capital</h5>

                    <ShareCapitalComponent />
                  </span>
                </li>
                <li>
                  <span>
                    <h5> Document History</h5>

                    <div className="container">
                      <DocumentHistoryComponent />
                    </div>
                  </span>
                </li>

                <li>
                  <span>
                    <h5>ASIC FORMS Portal</h5>
                    <div className="container">
                      <table className="table table-light">
                        <thead className="fw-bold">
                          <tr>
                            <th>Form</th>
                            <th>Mode</th>
                            <th>Document Status</th>
                            <th>Doc No.</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        {companyProfile.items.documents.map(
                          (value, index) =>
                            value.STATUS === "Prepared" &&
                            value.ELS_TRANSMIT && (
                              <tbody className="fs-6 fw-normal">
                                <tr
                                  className={
                                    value.FORM_TYPE === "484"
                                      ? "table-danger"
                                      : ""
                                  }
                                >
                                  <td>{value.FORM_TYPE}</td>
                                  <td>{value.GROUP_DOC}</td>
                                  <td>{value.STATUS}</td>
                                  <td>{value.DOC_NO}</td>
                                  <td>
                                    {moment(value.FORM_DATE).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>{value.STATUS_DETAIL}</td>
                                </tr>
                              </tbody>
                            )
                        )}
                      </table>
                    </div>
                  </span>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      ) : companyProfile &&
        companyProfile.error &&
        companyProfile.error.indexOf("400") !== -1 ? (
        <span className="row d-flex justify-content-center text-center py-5 text-danger ">
          <strong>The company is not attached to your Department List. </strong>
        </span>
      ) : (
        ""
      )}
    </div>
  );
};
export default CompanySnapshotComponent;
