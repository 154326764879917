import React, { useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";
import FullPageLoader from "../CommonPage/FullPageLoader";

const PublicOfficerComponent = (props) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  //loading spinner
const [isPublicOfficerLoaded, setIsPublicOfficerLoaded] = useState(false);

 

  const [publicOfficers, setpublicOfficers] = useState({
    poPrintDate: new Date(),
    publicOfficer: "Yes",
  });

  let handleChange = (e, isDate) => {
    if (isDate) {
      setpublicOfficers((publicOfficers) => ({
        ...publicOfficers,
        poPrintDate: e,
      }));
    } else {
      const { name, value } = e.target;
      setpublicOfficers((publicOfficers) => ({
        ...publicOfficers,
        [name]: value,
      }));
    }
  };

  const companyCode = JSON.parse(localStorage.getItem("companyCode"));
  const inputSearchValue = JSON.parse(localStorage.getItem("inputSearchValue"));
  const validate = companyCode !== null || (inputSearchValue !== null && inputSearchValue.length > 0) || (props.searchCompany && props.searchCompany.length > 0);

  function handleClick(e) {
    setSubmitted(true);
    const selectedSearchValue = localStorage.getItem("inputSearchValue");

    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if(props.searchCompany && props.searchCompany[0]) {
      dispatch(userActions.getPublicOfficerReport(publicOfficers, props.searchCompany[0].Code,setIsPublicOfficerLoaded));
    } else if  (selectedCompanyCode !== null) {
      dispatch(userActions.getPublicOfficerReport(publicOfficers, selectedCompanyCode,setIsPublicOfficerLoaded));
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(userActions.getPublicOfficerReport(publicOfficers, value.Code,setIsPublicOfficerLoaded))
      );
    }
  }

  return (
    <div class="container">
      <h3 className="mb-4">Enter Values</h3>

      <table className="table table-responsive table-borderless">
        <tbody>
          <tr className="">
            <td className="fw-bold w-50 ">
              Enter as at Date (Click on Blank for Current Date)
              
            </td>

            <td colspan="2" className="w-25">
              
              <DatePicker
              className="w-50"
              name="printDate"
              selected={publicOfficers.poPrintDate}
              onChange={(e) => handleChange(e, true)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Enter as at Date"
              value={publicOfficers.poPrintDate}
            /> 
            </td>
          </tr>
          <tr>
          <td  className="fw-bold w-50 " >Enter Current Officers </td>
          <td >
         
            <label><input type="radio" name="publicOfficer"   checked={publicOfficers.publicOfficer === 'Yes'} onClick={handleChange} value="Yes"/>Yes</label>
            </td>
            <td className="w-75">
      
            <label><input type="radio" name="publicOfficer"  checked={publicOfficers.publicOfficer === 'No'} onClick={handleChange} value="No"/>NO</label>

          </td>
        </tr>
                  
          <tr>
            <th>
              {" "}
              <button
                className={
                  submitted && !validate ? "btn btn-danger" : "btn btn-primary"
                }
                onClick={(e) => handleClick(e)}
              >
                Show Report
              </button>
              {isPublicOfficerLoaded ? <FullPageLoader /> :null}
              {submitted && !validate && (
                <div className="text-danger py-3">
                Company Name not selected.
                </div>
              )}
            </th>
          </tr>
        </tbody>{" "}
      </table>
    </div>
  );
};

export default PublicOfficerComponent;
