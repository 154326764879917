import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import FullPageLoader from "../CommonPage/FullPageLoader";


const CorporateRegisterMembers = (props) => {

  function getLastMonthDate() {
    const today = new Date();
    const lastMonthToday = new Date(today.setMonth(today.getMonth() - 1));

    return lastMonthToday;
  }

  const dispatch = useDispatch();

  // loading spinner
  const [isMemberLoaded, setIsMemberLoaded] = useState(false);
  const [memberReportIsDateRange, setMemberReportIsDateRange] = useState(false);

  const [members, setMembers] = useState({
    printDate: new Date(),
    fromDate: getLastMonthDate(),
    toDate: new Date(),
    benificialName: "Yes",
    holdingSummary: "No",
    zeroBalance: "Yes",
    address: "Yes",
    foreignCurrency: "No",
    totalShares: "No",
  });

  const [submitted, setSubmitted] = useState(false);

  function setPrintDate(newDate) {
    setMembers({...members, printDate: newDate})
  }

  function setFromDate(newDate) {
    setMembers({...members, fromDate: newDate})
  }

  function setToDate(newDate) {
    setMembers({...members, toDate: newDate})
  }

  let handleChange = (e, isDate) => {
    console.log('handleChange()', e, isDate);
    if (isDate) {
      setMembers((member) => ({ ...members, printDate: e }));
    } else {
      const { name, value } = e.target;
      setMembers((member) => ({ ...members, [name]: value }));
    }
  };
  const companyCode = JSON.parse(localStorage.getItem("companyCode"));
  const inputSearchValue = JSON.parse(localStorage.getItem("inputSearchValue"));
  const validate = companyCode !== null || (inputSearchValue !== null && inputSearchValue.length > 0) || (props.searchCompany && props.searchCompany.length > 0);

  function handleClick(e) {
    setSubmitted(true);
    const selectedSearchValue = localStorage.getItem("inputSearchValue");
    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));

    // if user has chosen a date range, then process the date range report
    if(memberReportIsDateRange) {

      //not sure what this code does, so copy/paste argh!
      if(props.searchCompany && props.searchCompany[0]) {
        dispatch(userActions.getMembersReportDateRange(members, props.searchCompany[0].Code,setIsMemberLoaded));
      } else if (selectedCompanyCode !== null) {
        dispatch(userActions.getMembersReportDateRange(members, selectedCompanyCode,setIsMemberLoaded));
      } else if (selectedSearchValue !== null) {
        JSON.parse(selectedSearchValue).map((value) =>
          dispatch(userActions.getMembersReportDateRange(members, value.Code,setIsMemberLoaded))
        );
      }
    } else {
      //otherwise always process the "as at date" report

      //not sure what this code does, so copy/paste argh!
      if(props.searchCompany && props.searchCompany[0]) {
        dispatch(userActions.getMembersReport(members, props.searchCompany[0].Code,setIsMemberLoaded));
      } else if (selectedCompanyCode !== null) {
        dispatch(userActions.getMembersReport(members, selectedCompanyCode,setIsMemberLoaded));
      } else if (selectedSearchValue !== null) {
        JSON.parse(selectedSearchValue).map((value) =>
          dispatch(userActions.getMembersReport(members, value.Code,setIsMemberLoaded))
        );
      }
    }
  }

  function memberReportDateRangeControls() {
    if(memberReportIsDateRange) {
      return (
        <div>
          <div>
            <div>
              Enter start date:
            </div>
            <DatePicker
              className="w-50"
              name="printDate"
              selected={members.fromDate}
              onChange={(e) => setFromDate(e)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Enter start date"
              value={members.fromDate}
            />
          </div>
          <div>
            <div>
              Enter end date:
            </div>
            <DatePicker
              className="w-50"
              name="printDate"
              selected={members.toDate}
              onChange={(e) => setToDate(e)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Enter end date"
              value={members.toDate}
            />
          </div>
        </div>
      );
    } 
    
    return (
      <div>
        <div>
          As at Date:
        </div>
        <DatePicker
          className="w-50"
          name="printDate"
          selected={members.printDate}
          onChange={(e) => setPrintDate(e)}
          dateFormat="dd/MM/yyyy"
          placeholderText="Enter as at Date"
          value={members.printDate}
        />
      </div>
    )
  }

  return (
    <div class="container">
      <h3 className="mb-4">Enter Values</h3>
      <table className="table table-responsive table-borderless">
        <tbody>
          <tr>
            <td className="fw-bold w-50 ">
              {memberReportIsDateRange ? 
              <div>Enter date range:</div> 
              : 
              <div>Enter as at Date (Click on Blank for Current Date)</div>
              }
              <div>
                <button class="btn btn-secondary" onClick={() => setMemberReportIsDateRange(!memberReportIsDateRange) }>{!memberReportIsDateRange ? <>Change to date range</> : <>Change to as at Date</>}</button>
              </div>
            </td>
            <td colspan="2" className="w-50">
              { memberReportDateRangeControls() }
            </td>
          </tr>
          <tr>
            <td className="fw-bold w-50 ">
              Show Beneficial Owner Name{" "}
            </td>{" "}
            <td>
              {" "}
              <label>
                <input
                  type="radio"
                  name="benificialName"
                  checked={members.benificialName === "Yes"}
                  onClick={handleChange}
                  value="Yes"
                />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
                <input
                  type="radio"
                  name="benificialName"
                  checked={members.benificialName === "No"}
                  onClick={handleChange}
                  value="No"
                />
                NO
              </label>
            </td>
          </tr>
          <tr>
            <td className="fw-bold w-50 ">Show Holding Summary Only? </td>{" "}
            <td>
              {" "}
              <label>
                <input
                  type="radio"
                  name="holdingSummary"
                  checked={members.holdingSummary === "Yes"}
                  onClick={handleChange}
                  value="Yes"
                />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
                <input
                  type="radio"
                  name="holdingSummary"
                  checked={members.holdingSummary === "No"}
                  onClick={handleChange}
                  value="No"
                />
                NO
              </label>
            </td>
          </tr>
          <tr>
            <td className="fw-bold w-50 ">Show Zero Balances? </td>{" "}
            <td>
              <label>
                <input
                  type="radio"
                  name="zeroBalance"
                  checked={members.zeroBalance === "Yes"}
                  onClick={handleChange}
                  value="Yes"
                />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
                <input
                  type="radio"
                  name="zeroBalance"
                  checked={members.zeroBalance === "No"}
                  onClick={handleChange}
                  value="No"
                />
                NO
              </label>
            </td>
          </tr>
          <tr>
            <td className="fw-bold w-50 "> Show Address Fields? </td>{" "}
            <td>
              <label>
                <input
                  type="radio"
                  name="address"
                  checked={members.address === "Yes"}
                  onClick={handleChange}
                  value="Yes"
                />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
                <input
                  type="radio"
                  name="address"
                  checked={members.address === "No"}
                  onClick={handleChange}
                  value="No"
                />
                NO
              </label>
            </td>
          </tr>
          <tr>
            <td className="fw-bold w-50 ">Show Foreign Currency ( if any)? </td>{" "}
            <td>
              <label>
                <input
                  type="radio"
                  name="foreignCurrency"
                  checked={members.foreignCurrency === "Yes"}
                  onClick={handleChange}
                  value="Yes"
                />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
                <input
                  type="radio"
                  name="foreignCurrency"
                  checked={members.foreignCurrency === "No"}
                  onClick={handleChange}
                  value="No"
                />
                NO
              </label>
            </td>
          </tr>
          <tr>
            <td className="fw-bold w-50 ">Enter show Total Shares? </td>{" "}
            <td>
              <label>
                <input
                  type="radio"
                  name="totalShares"
                  checked={members.totalShares === "Yes"}
                  onClick={handleChange}
                  value="Yes"
                />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
                <input
                  type="radio"
                  name="totalShares"
                  checked={members.totalShares === "No"}
                  onClick={handleChange}
                  value="No"
                />
                NO
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <button
                className={
                  submitted && !validate ? "btn btn-danger" : "btn btn-primary"
                }
                onClick={(e) => handleClick(e)}
              >
                Show Report
              </button>
              {isMemberLoaded ? <FullPageLoader /> :null}
              {submitted && !validate && (
                <div className="text-danger py-3">Company Name not selected.</div>
              )}
            </td>
          </tr>
        </tbody>{" "}
      </table>
 
  
    </div>
  );
};

export default CorporateRegisterMembers;
