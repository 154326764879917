import React,{useState} from 'react';
import Landingpage from '../LandingPagecomponent/LandingPage.Component'
import { withRouter } from "react-router-dom";
import AnnualReviewsFilter from './AnnualReviewsFilter.Component';
import CommonSearch from '../CommonPage/CommonSearch.component';


const MonitorLanding = (props) => {

   
    const [searchCompany, setSearchCompany] = useState([]);
    
    
   
    return (
       
        <Landingpage  isMonitorCollapse={true} isMonitorPage={true} 
        commonSearch={ <CommonSearch history={props.history} setRecentSearch={props.setRecentSearch} recentSearch={props.recentSearch} setSearchCompany={setSearchCompany} searchCompany={searchCompany}/>}content={
         <AnnualReviewsFilter setRecentSearch={props.setRecentSearch} recentSearch={props.recentSearch} loggedUserInfo={props.loggedUserInfo} userId={props.userId} clientId={props.clientId} />
        }/>
        
    )
        
    }
    export default withRouter(MonitorLanding);