import { userConstants } from "../constants/login.constants";

 function form484Information(state = {}, action) {
    switch (action.type) {
        case userConstants.FORM484INFORMATION_REQUEST:
            return {
                loading: true
            };
        case userConstants.FORM484INFORMATION_SUCCESS:
            return {
                items: action.form484Information
            };
        case userConstants.FORM484INFORMATION_FAILURE:
            return {
                error: action.errorForm484Information
            };
        default:
            return state
    }
}
export default form484Information;