import React, { useState  } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";
import FullPageLoader from "../CommonPage/FullPageLoader";


const TransferJournalComponent = (props) => {
  const dispatch = useDispatch();
  // loading spinner
  const [isTransferLoaded, setIsTransferLoaded] = useState(false);
  const [submitted, setSubmitted] = useState(false);
    const [transferJournal, setTransferJournal] = useState({
      tjPrintDate: new Date()
  });
  
  let handleChange = (e, isDate) => {
    if(isDate){
        setTransferJournal(transferJournal => ({ ...transferJournal,tjPrintDate: e }))
    } 
   
  }

  const companyCode = JSON.parse(localStorage.getItem("companyCode"));
  const inputSearchValue = JSON.parse(localStorage.getItem("inputSearchValue"));
  const validate = companyCode !== null || (inputSearchValue !== null && inputSearchValue.length > 0) || (props.searchCompany && props.searchCompany.length > 0);

  function handleClick(e) {
    setSubmitted(true);
    const selectedSearchValue = localStorage.getItem("inputSearchValue");

    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if(props.searchCompany && props.searchCompany[0]) {
      dispatch(userActions.getTransferJournalReport(transferJournal,props.searchCompany[0].Code,setIsTransferLoaded));
    } else if  (selectedCompanyCode !== null) {
      dispatch(userActions.getTransferJournalReport(transferJournal,selectedCompanyCode,setIsTransferLoaded));
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(userActions.getTransferJournalReport(transferJournal,value.Code,setIsTransferLoaded))
      );
    }
  }
    return (
      <div class="container">
      <h3 className="mb-4">Enter Values</h3>
         
          <table className="table table-responsive table-borderless"><tbody>
          <tr>
          <td  className="fw-bold w-50" >Enter as at Date (Click on Blank for Current Date)</td>
       
            
          <td  colspan="2" className="w-75">
 <DatePicker
            className="w-50"
            name="printDate"
            selected={transferJournal.tjPrintDate}
            onChange={(e) => handleChange(e, true)}
            dateFormat="dd/MM/yyyy"
            placeholderText="Enter as at Date"
            value={transferJournal.tjPrintDate}
          />  
            </td>
          </tr>
          <tr>   <td>      {" "}
          <button
            className={
              submitted && !validate ? "btn btn-danger" : "btn btn-primary"
            }
            onClick={(e) => handleClick(e)}
          >
            Show Report 
          </button>
          {isTransferLoaded ? <FullPageLoader /> :null}
          {submitted && !validate && (
            <div className="text-danger py-3">Company Name not selected.</div>
          )}</td></tr>
       
       </tbody>   </table>
        
       
      </div>
    );
  };
  
  export default TransferJournalComponent;
  