import React, { useState } from "react";

import moment from "moment";

const EBinderPrimeCloudDocs = (props) => {



  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});

  const handleRow = (e, code) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(code);

    let obj = {};
    isRowExpanded ? (obj[code] = false) : (obj[code] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== code)
      : currentExpandedRows.concat(code);

    setExpandedRows(newExpandedRows);
  };

  function handleClickAll(e) {
    props.setPrimeFileDocuments((prevState) =>
      prevState.map((item) => ({
        ...item,
        primeDocCheckBox: true,
        documents: item.documents.map((value) =>
          Object.assign(value, { primeSubDocCheckBox: true })
        ),
      }))
    );
  }

  function handleClickNone(e) {
    props.setPrimeFileDocuments((prevState) =>
      prevState.map((item) => ({
        ...item,
        primeDocCheckBox: false,
        documents: item.documents.map((value) =>
          Object.assign(value, { primeSubDocCheckBox: false })
        ),
      }))
    );
   
  }

  function handleClickLatest(e) {
    props.setPrimeFileDocuments((prevState) =>
      prevState.map((item) => ({
        ...item,
        primeDocCheckBox: true,
        documents: item.documents.map((value, index) =>
          index === 0
            ? Object.assign(value, { primeSubDocCheckBox: true })
            : Object.assign(value, { primeSubDocCheckBox: false })
        ),
      }))
    );
  }

  function handleClickSelectedItems(e, type) {
    props.setPrimeFileDocuments((prevState) =>
      prevState.map((item) =>
        type === item.type
          ? {
              ...item,
              primeDocCheckBox: e.target.checked,
              documents: item.documents.map((value) =>
                Object.assign(value, { primeSubDocCheckBox: e.target.checked })
              ),
            }
          : {
              ...item,
            }
      )
    );
  }

  function handleClickSubSelectedItems(e, index, type, id) {
    props.setPrimeFileDocuments((prevState) =>
      prevState.map((item) =>
        type === item.type
          ? {
              ...item,
              documents: item.documents.map((value) =>
                value.ID === id
                  ? Object.assign(value, {
                      primeSubDocCheckBox: e.target.checked,
                    })
                  : Object.assign(value)
              ),
            }
          : {
              ...item,
            }
      )
    );

  }


  function handleClickDeselectMainItem(e, index, type) {
    setTimeout(() => {
      let value =
        props.primeFileDocuments &&
        props.primeFileDocuments[index].documents.filter(
          (item) => !item.primeSubDocCheckBox
        );
      console.log("uncheckboxCount===:::", value.length);

      props.setPrimeFileDocuments((prevState) =>
        prevState.map((item) =>
          type === item.type
            ? {
                ...item,
                primeDocCheckBox: value.length === 0,
              }
            : {
                ...item,
              }
        )
      );
    }, 0);

  }


  return (
    <div className="fs-6">
    {console.log('ebinderCompanyCode~~~~~3~~~~~~',props.primeFileDocuments)}
      <div className="px-3">
        <h4>
          <strong>Select Prime Documents</strong>
        </h4>
        <p>
          Please select files for inclusion from available Prime Cloud
          Documents.
        </p>
      </div>

      <div>
        <button onClick={(e) => handleClickAll(e)}> All</button>
        <button onClick={(e) => handleClickNone(e)}> None</button>
        <button onClick={(e) => handleClickLatest(e)}>Latest</button>
        <br />
        <br />
        {console.log("props.primeFileDocuments::::::", props.totalSelected)}
        {props.primeFileDocuments && props.primeFileDocuments.length < 0 && (
          <p>No Prime Documents on record</p>
        )}
        <div className="table-responsive-sm">
          {props.primeFileDocuments && props.primeFileDocuments.length > 0 && (
            <table className="table  table-bordered  w-50 fs-6">
              {props.primeFileDocuments &&
                props.primeFileDocuments.map((post, index) => (
                  <tbody>
                    <tr
                      key={
                        post.documents &&
                        post.documents.map((value) => value.ID)
                      }
                    >
                      <th scope="row">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="primeDocCheckBox"
                          onChange={(e) =>
                            handleClickSelectedItems(e, post.type)
                          }
                          checked={post.primeDocCheckBox}
                          value={post.primeDocCheckBox}
                        />
                      </th>
                      <td>
                        {expandState[post.type] ? (
                          <i
                            class="bi bi-caret-up-fill text-danger"
                            onClick={(e) => handleRow(e, post.type)}
                          ></i>
                        ) : (
                          <i
                            class="bi bi-caret-down-fill text-success"
                            onClick={(e) => handleRow(e, post.type)}
                          ></i>
                        )}
                      </td>

                      <td>{post.type}</td>
                      <td>{post.description}</td>
                      <td>
                        {props.totalSelected &&
                          props.totalSelected[index] &&
                          props.totalSelected[index].length > 0
                          ? `${props.totalSelected[index].length} selected`
                          : " "}
                      </td>
                    </tr>
                    {expandedRows.includes(post.type) && (
                      <tr>
                        <td colSpan="5 ">
                          <table className="table  fs-6 table-light table-borderless">
                            <tbody>
                              {post.documents &&
                                post.documents.map((value) => (
                                  <tr>
                                    <td className="w-25 text-center">
                                      {" "}
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="primeSubDocCheckBox"
                                        checked={value.primeSubDocCheckBox}
                                        value={value.primeSubDocCheckBox}
                                        onChange={(e) => {
                                          handleClickSubSelectedItems(
                                            e,
                                            index,
                                            post.type,
                                            value.ID
                                          );
                                          handleClickDeselectMainItem(
                                            e,
                                            index,
                                            post.type
                                          );
                                        }}
                                      />
                                    </td>
                                    <td>ID:{value.ID}</td>

                                    <td>
                                      Lodges:
                                      {value.LodgedAt !== -1 &&
                                        moment(value.LodgedAt).format(
                                          "DD/MMM/YYYY"
                                        )}
                                    </td>
                                    <td>ASIC:{value.ASIC_ID}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </tbody>
                ))}
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default EBinderPrimeCloudDocs;
