import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import { withRouter } from "react-router-dom";
import Pagination from "../CommonPage/Pagination.component";
import moment from "moment";
import EBinderManagement from "../EbinderMangementComponent/EbinderMangement.component";

const EbinderCompany = (props) => {
  const ebinderCompanyList =useSelector((state) => state.ebinderCompanyList);
  const companiesDetailList = useSelector((state) => state.companiesDetailList);


  const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line
  const [postsPerPage, setPostsPerPage] = useState(8);
  // eslint-disable-next-line
  let handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  // connect to API
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.getCompaniesDetailList());
    dispatch(userActions.getEbinderCompanyList());
    // eslint-disable-next-line
  }, []);

  // search
  const [searchInput, setSearchInput] = useState("");
  const ebinderCompanyListTotal =
  ebinderCompanyList && ebinderCompanyList.items
      ? ebinderCompanyList.items
          .sort((a, b) => (a.Name > b.Name ? 1 : -1))
          .filter((filteredValue) =>
            filteredValue["Name"]
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          )
      : [];

  // console.log(posts);

  const indexOfLastPost = currentPage * postsPerPage;
  // console.log("indexOfLastPost: ", indexOfLastPost);

  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // console.log("indexOfFirstPost: ", indexOfFirstPost);
  const currentPosts = ebinderCompanyListTotal
    ? ebinderCompanyListTotal.slice(indexOfFirstPost, indexOfLastPost)
    : [];

  // refresh button
  function handleClickRefresh(e) {
    e.preventDefault();
    setSearchInput("");
  }

  const companyCode = JSON.parse(localStorage.getItem("companyCode"));
  const inputSearchValue = JSON.parse(localStorage.getItem("inputSearchValue"));
    // eslint-disable-next-line
  const validate =
    companyCode !== null ||
    (inputSearchValue !== null && inputSearchValue.length > 0);

  function handleChange(e, post) {
    props.setSelectedCompanyCode(e.target.value);
    props.setDisplaySelectedCompany(post);

    console.log("displaySelectedCompany", post);
  }

 

  return (
    <div className="fs-6">
    {console.log("ebinderCompanyList",ebinderCompanyList)}
    {console.log("companiesDetailList",companiesDetailList)}
    companiesDetailList
    {console.log("ebinderCompanyListTotal",ebinderCompanyListTotal)}
      <div>
        <h4>
          <b>Select Company</b>
        </h4>
      </div>
      <nav className="navbar navbar-light bg-light navbar-expand-md ">
        <div className="container">
          <p>Please Select the company to proceed</p>
          <div
            className=" navbar-collapse d-flex flex-row-reverse "
            id="main-nav-collapse"
          >
            <ul className="navbar-nav">
              <div className=" col form-outline">
                <input
                  type="search"
                  id="CompaniesSearch"
                  className="form-control-sm "
                  size="50"
                  placeholder="Search Companies"
                  aria-label="Search"
                  autoComplete={true}
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                />
                &nbsp;
                <button
                  className="btn-sm"
                  onClick={(e) => handleClickRefresh(e)}
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i>
                </button>
              </div>
            </ul>
          </div>
        </div>
      </nav>
      <br/>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <table className="table table-bordered table-hover fs-6">
              <tbody className=" fs-6">
                {
                  ebinderCompanyList && currentPosts&& currentPosts.length ===0 ?              
              <tr><td >No matching records found</td></tr>:
              
                  ebinderCompanyList &&
                  currentPosts &&
                  currentPosts.map((post) => (
                    <tr>
                      <td>
                        <input
                          className="form-radio-input"
                          type="radio"
                          name="selectedCompany"
                          checked={post.Code === props.selectedCompanyCode}
                          onChange={(e) => handleChange(e, post)}
                          value={post.Code}
                        />
                      </td>
                      <td>
                   
                          {post.Name}
                      
                        <br />
                        ACN | {post.ACN}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div class="col-md-6 col-sm-6">
            {props.displaySelectedCompany && (
              <span className=" mt-5 justify-content-center">
                <h5 className=" mt-5 fw-bold">{props.displaySelectedCompany &&
                  props.displaySelectedCompany.Name}
                  </h5>
               
                {props.displaySelectedCompany &&
                props.displaySelectedCompany.ACN
                  ? `ACN:${props.displaySelectedCompany.ACN}`
                  : ""}
                <br />  

                {props.displaySelectedCompany &&
                props.displaySelectedCompany.DATE_INC === null
                  ? " Incorporated: N/A"
                  : props.displaySelectedCompany &&
                    props.displaySelectedCompany.DATE_INC
                  ? `Incorporated:  ${moment(
                      props.displaySelectedCompany.DATE_INC
                    ).format("DD/MM/YYYY")}`
                  : ""}
                <br />
                {props.displaySelectedCompany &&
                  props.displaySelectedCompany.Address
                    ? `Registered Office:  ${props.displaySelectedCompany.Address}`
                    : ""}
              </span>
            )}
           {  console.log('props.showEBinderMangement',props.showEBinderMangement)}
            {
          
              props.showEBinderMangement &&
         
            <EBinderManagement selectedCompanyCode={props.selectedCompanyCode} />
            }
          </div>
        </div>
      </div>

      {ebinderCompanyListTotal && ebinderCompanyListTotal.length >= 10 && (
        <footer
          id="sticky-footer"
          //class="footer d-flex  justify-content-end footer-body  "
          className="footer d-flex flex-row-reverse footer-body"
        >
          <div>
            {postsPerPage && (
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={
                  ebinderCompanyListTotal ? ebinderCompanyListTotal.length : 0
                }
                pageSize={postsPerPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </div>
        </footer>
      )}
    </div>
  );
};
export default withRouter(EbinderCompany);
