import React,{useState}from 'react';
import Landingpage from '../LandingPagecomponent/LandingPage.Component'
import { withRouter } from "react-router-dom";
import CommonSearch from '../CommonPage/CommonSearch.component';
import BusinessNameComponent from './BusinessNameComponent';


const BusinessNameLanding = (props) => {
   
    const [searchCompany, setSearchCompany] = useState([]);
    return (
       
        <Landingpage  isFileCollapse={true} isBusinessPage={true}
        commonSearch={ <CommonSearch history={props.history} setRecentSearch={props.setRecentSearch} recentSearch={props.recentSearch} setSearchCompany={setSearchCompany} searchCompany={searchCompany}/>} content={
     <BusinessNameComponent setRecentSearch={props.setRecentSearch} recentSearch={props.recentSearch} loggedUserInfo={props.loggedUserInfo} userId={props.userId} clientId={props.clientId}/>

        }/>
        
    )
        
    }
    export default withRouter(BusinessNameLanding);