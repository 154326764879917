import React from "react";

const ChangePasswordComponent = (props) => {
 

  return (
    <div  className="form-group">
    {props.alertStatus && props.alertStatus.items && (
      <div className="alert-message  mx-auto d-block d-flex justify-content-center">
      
        <div className="alert alert-success alert-dismissible fade show text-center" role="alert">
          {"Password has been updated successfully"}
      
           <button type="button" className="btn-close  " data-bs-dismiss="alert"></button>
         
        </div>
      </div>
    )}
    {props.alertStatus && props.alertStatus.error && (
      <div className="alert-message  mx-auto d-block d-flex justify-content-center">
      
        <div className="alert alert-danger alert-dismissible fade show text-center" role="alert">
          {"Invalid Old Password" }
      
           <button type="button" className="btn-close  " data-bs-dismiss="alert"></button>
         
        </div>
      </div>
    )}
      <h3 className="fs-6 ">< strong>Change password</strong></h3>
      {console.log("changePassword",props.alertStatus && props.alertStatus)}

      <table className="table table-responsive table-borderless fs-6">
        <tbody>
        <tr><td>  <label htmlFor="oldPassword" className="col-form-label">
      Old password
       </label></td><td>
        <input className="form-control fs-6 fw-normal "
        type="password"
                    name="oldPassword"
                    value={props.changePwd.oldPassword}
                onChange={(e) => {
                 
                  props.handlePersonDetailsChange(e);
                }}
                
                autoComplete="off"
          /></td></tr>
          <tr><td>  <label htmlFor="passWord" className="col-form-label">
          New password
         </label></td><td>
          <input  className={
            "form-control fs-6 fw-normal " +
            ( props.changePwd.confirmPassword && props.changePwd.passWord !== props.changePwd.confirmPassword ? "is-invalid" : "")
          }
          type="password"
           
              name="passWord"
              value={props.changePwd.passWord}
              onChange={(e) => {
              
                props.handlePersonDetailsChange(e);
              }}
            
              autoComplete="off" 
            /></td></tr>
            <tr><td>  <label htmlFor="confirmPassword" className="col-form-label">
         Retype new password
         </label></td><td>
          <input
          className={
            "form-control fs-6 fw-normal " +
            ( props.changePwd.confirmPassword && props.changePwd.passWord !== props.changePwd.confirmPassword ? "is-invalid" : "")
          }
          type="password"
            
              name="confirmPassword"
              value={props.changePwd.confirmPassword}
              onChange={(e) => {
               
                props.handlePersonDetailsChange(e);
              }}
            
              autoComplete="off"  
            />
            { props.changePwd.passWord !== props.changePwd.confirmPassword 
              && (
               <div className="invalid-feedback f-6">Your new password does not match</div>
             )}</td>
            </tr>
        </tbody>{" "}
      </table>
    </div>
  );
};

export default ChangePasswordComponent;
