import { userConstants } from "../constants/login.constants";

 function asicDebtStatement(state = {}, action) {
    switch (action.type) {
        case userConstants.ASICDEPTSTATEMENT_REQUEST:
            return {
                loading: true
            };
        case userConstants.ASICDEPTSTATEMENT_SUCCESS:
            return {
                items: action.asicDebtStatement
            };
        case userConstants.ASICDEPTSTATEMENT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export default asicDebtStatement;