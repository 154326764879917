import React, { useState, useEffect } from "react";
import "font-awesome/css/font-awesome.min.css";
import "../MonitorsComponents/AnnualReviews.style.scss";
import Pagination from "../CommonPage/Pagination.component";
import "../CommonPage/Action.scss";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import moment from "moment";
import "../CommonPage/colourCodeEntries.scss";

const DigitalSignature = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  let handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  // connect to API
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.getDigitalSignatureStatement());
    // eslint-disable-next-line
  }, []);
  const digitalSignatureStatement = useSelector(
    (state) => state.digitalSignatureStatement
  );

  // search
  const [searchInput, setSearchInput] = useState("");
  const digitalSignatureTotalStatement =
    digitalSignatureStatement && digitalSignatureStatement.items
      ? digitalSignatureStatement.items.filter((filteredValue) =>
          filteredValue["CO_NAME"]
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        )
      : [];

  // console.log(posts);

  const indexOfLastPost = currentPage * postsPerPage;
  // console.log("indexOfLastPost: ", indexOfLastPost);

  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // console.log("indexOfFirstPost: ", indexOfFirstPost);
  const currentPosts = digitalSignatureTotalStatement
    ? digitalSignatureTotalStatement.slice(indexOfFirstPost, indexOfLastPost)
    : [];

  // expandable table coding

  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});

  const handleRow = (e, code) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(code);

    let obj = {};
    isRowExpanded ? (obj[code] = false) : (obj[code] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== code)
      : currentExpandedRows.concat(code);

    setExpandedRows(newExpandedRows);
  };

  // refresh button
  function handleClickRefreshDigitalSignature(e) {
    e.preventDefault();
    setSearchInput("");
  }
  return (
    <div className="m-4 fontfamily">
      <h2 className="panel-title">Digital Signatures</h2>
      <nav className="navbar navbar-light bg-light navbar-expand-md ">
        <div className="container">
          <div
            className=" navbar-collapse d-flex flex-row-reverse "
            id="main-nav-collapse"
          >
            <ul className="navbar-nav">
              <div className=" col form-outline">
                <input
                  type="search"
                  id="digitalSignatureSearch"
                  className="form-control-sm "
                  size="50"
                  placeholder="Search"
                  aria-label="Search"
                  autoComplete={false}
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                />
                &nbsp;
                <button
                  className="btn-sm"
                  onClick={(e) => handleClickRefreshDigitalSignature(e)}
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i>
                </button>
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <div className="table-responsive-sm">
        <table className="table table-hover fs-6">
          <thead>
            <tr>
              <th></th>
              <th>Company Name</th>
              <th>Form</th>
              <th className="w-20">Title</th>
              <th>Doc No.</th>
              <th className="text-wrap">Form Date</th>
              <th className="text-wrap">Issue Date</th>
              <th className="text-wrap">Status</th>
              <th className="text-wrap">Main Form Signer</th>
              <th className="text-wrap">Esign Type</th>
           {/*  <th className="text-wrap"> Action </th>  */} 
            </tr>
          </thead>
          {
            digitalSignatureStatement &&currentPosts&& currentPosts.length ===0 ?
              
            <tr><td colSpan="10">No matching records found</td></tr>:
            
            digitalSignatureStatement &&
            currentPosts &&
            currentPosts.map((post) => (
              <tbody>
                <tr
                  key={post.DOC_CODE}
                  className={post.STATUS === "Completed" ? "greencolour" : ""}
                >
                  <td>
                    {expandState[post.DOC_CODE] ? (
                      <i
                        class="bi bi-caret-up-fill text-danger"
                        onClick={(e) => handleRow(e, post.DOC_CODE)}
                      ></i>
                    ) : (
                      <i
                        class="bi bi-caret-down-fill text-success"
                        onClick={(e) => handleRow(e, post.DOC_CODE)}
                      ></i>
                    )}
                  </td>

                  <td>{post.CO_NAME} </td>

                  <td>{post.FORM_TYPE}</td>

                  <td>{post.FORM_TITLE}</td>

                  <td>{post.DOC_CODE}</td>

                  <td>
                    {post.FORM_DATE
                      ? moment(post.FORM_DATE).format("DD/MM/YYYY ")
                      : ""}{" "}
                  </td>
                  <td>
                    {post.DOCUSIGN_SEND_DATE
                      ? moment(post.DOCUSIGN_SEND_DATE).format("DD/MM/YYYY ")
                      : ""}{" "}
                  </td>
                  <td>{post.STATUS}</td>
                  <td>{post.EMAIL_ADDRESS}</td>
                  <td>{post.ESIGNTYPE}</td>
                    {/* 
                  <td>
                    <a
                      href={() => false}
                      className="d-flex align-items-center text-white text-decoration-none "
                      id="dropdownUserDetail"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                    </a>
                    <ul className="dropdown-menu text-dark">
                      <li className="action ">
                        <a className="dropdown-item " href={() => false}>
                          Download/Print
                        </a>{" "}
                        <hr />
                      </li>

                      <li className="action ">
                        <a className="dropdown-item " href={() => false}>
                          Show File
                        </a>
                        <hr />
                      </li>
                      <li className="py-0 ">
                        <a className="dropdown-item " href={() => false}>
                          Open Folder
                        </a>
                      </li>
                    </ul>
                  </td>
                  */}
                </tr>
                {expandedRows.includes(post.DOC_CODE) && (
                  <tr>
                    <td colSpan="10">
                      <table className="table  fs-6 table-light table-borderless">
                        <tr>
                          Envelope:{post.ENVELOPEID}
                          <tr>
                            <td>Status:{post.STATUS} </td>
                          </tr>
                          <tr>
                            <td>Date:{post.DOCUSIGN_SEND_DATE} </td>
                          </tr>
                          <tr>
                            <td> Signer Name: </td>
                          </tr>
                          <tr>
                            <td> Signer:{post.EMAIL_ADDRESS}</td>
                          </tr>
                        </tr>
                      </table>
                    </td>
                  </tr>
                )}
              </tbody>
            ))}
        </table>
      </div>
      {digitalSignatureTotalStatement &&
        digitalSignatureTotalStatement.length >= 10 && (
          <footer
            id="sticky-footer"
            class="footer d-flex justify-content-end footer-body  "
          >
            <div class="container fs-6 ">
              <div class="row">
                <div class="col-md-6 ">
                  Showing{" "}
                  <input
                    type="text"
                    className="form-control-sm border border-default"
                    onKeyPress={handleKeyPress}
                    name="showRecord"
                    value={postsPerPage}
                    maxLength="3"
                    size="3"
                    onChange={(e) => setPostsPerPage(e.target.value)}
                  ></input>{" "}
                  /
                  {digitalSignatureTotalStatement
                    ? digitalSignatureTotalStatement.length
                    : 0}
                </div>
                <div class="col-md-6">
                  {postsPerPage && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={
                        digitalSignatureTotalStatement
                          ? digitalSignatureTotalStatement.length
                          : 0
                      }
                      pageSize={postsPerPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </footer>
        )}
        <br/>
        <br/>
    </div>
  );
};
export default DigitalSignature;
