import React,{useState} from 'react';
import Landingpage from '../LandingPagecomponent/LandingPage.Component'
import { withRouter } from "react-router-dom";
import DigitalSignature from './DigitalSignatureComponent';
import CommonSearch from '../CommonPage/CommonSearch.component';

const DigitalSignatureLanding = (props) => {
    const [recentSearch, setRecentSearch] = useState([])
    const [searchCompany, setSearchCompany] = useState([]);
    return (
       
        <Landingpage  isMonitorCollapse={true} isDigitalSignaturePage={true} 
       

commonSearch={ <CommonSearch history={props.history} setRecentSearch={setRecentSearch} recentSearch={recentSearch} setSearchCompany={setSearchCompany} searchCompany={searchCompany}/>}
        content={
           <DigitalSignature/>
        }/>
        
    )
        
    }
    export default withRouter(DigitalSignatureLanding);