import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import moment from "moment";
import "font-awesome/css/font-awesome.min.css";
import "../MonitorsComponents/AnnualReviews.style.scss";
import Pagination from "../CommonPage/Pagination.component";
import "../CommonPage/Action.scss";
import Form484InformationComponent from "../CommonPage/Form484Information.component";
import Popup from "../CommonPage/Popup.component";
import FullPageLoader from "../CommonPage/FullPageLoader";

const FormsAndDocumentComponent = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const[statusSort, setSortStatus] = useState("");

  let handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  // connect to API
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.getElectronicLodegementService());
    // eslint-disable-next-line
  }, []);
  const electronicLodgementService = useSelector(
    (state) => state.electronicLodgementService
  );

  function handleClickStatusSort(e) {
    e.preventDefault();
   if (statusSort === "asc") {

     setSortStatus("desc");
     
   } else {
     setSortStatus("asc");
   }
  }

  // search
  const [searchInput, setSearchInput] = useState("");
  const electronicLodgementServiceTotalStatement =
electronicLodgementService && electronicLodgementService.items
  ?(statusSort === "")? 
  electronicLodgementService.items
        .sort((a, b) => (a.CO_NAME > b.CO_NAME ? 1 : -1))
        .filter((filteredValue) =>
          filteredValue["CO_NAME"]
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        )
  :(statusSort === "asc")? 
  electronicLodgementService.items.sort((a, b) => a.DUE_DAYS.localeCompare(b.DUE_DAYS)).filter((filteredValue) =>
      filteredValue["CO_NAME"]
        .toLowerCase()
        .includes(searchInput.toLowerCase())
    ): electronicLodgementService.items.sort((a, b) =>   b.DUE_DAYS.localeCompare(a.DUE_DAYS) && b.DUE_DAYS_ONLY- a.DUE_DAYS_ONLY).filter((filteredValue) =>
    filteredValue["CO_NAME"]
      .toLowerCase()
      .includes(searchInput.toLowerCase())
  )
  : [];



      


  // console.log(posts);

  const indexOfLastPost = currentPage * postsPerPage;
  // console.log("indexOfLastPost: ", indexOfLastPost);

  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // console.log("indexOfFirstPost: ", indexOfFirstPost);
  const currentPosts = electronicLodgementServiceTotalStatement
    ? electronicLodgementServiceTotalStatement.slice(
        indexOfFirstPost,
        indexOfLastPost
      )
    : [];


// refresh button
function handleClickRefreshELS(e) {
  e.preventDefault();
  setSearchInput("");
}

// Form 484 information Popup.
const form484Information = useSelector(
  (state) => state.form484Information
);

 //loading spinner
 const [isForm484DocTrackLoaded, setIsForm484DocTrackLoaded] = useState(false);
 // eslint-disable-next-line
const [submitted, setSubmitted] = useState(false);
function form484DocTrackHandleClick(e,rN,docNo) {
  setSubmitted(true);
        dispatch(userActions.getForm484Information(rN,docNo,setIsForm484DocTrackLoaded));
        handle484DocTrackPopupShow(e, docNo);
 }

 const [popup484DocTrackShow, setPopup484DocTrackShow] = useState(0);
 const handle484DocTrackPopupClose = () => setPopup484DocTrackShow(false);
 const handle484DocTrackPopupShow = (e, index) => {
   e.preventDefault();
   setPopup484DocTrackShow(index);
 };
 let handle484DocTrackPopupClick = (e) => {
  e.preventDefault();
  handle484DocTrackPopupClose();
};
  return (
    <div className="m-4 fontfamily">
      <h4 className="panel-title"> Electronic Lodgement Service </h4>
      <nav className="navbar navbar-light bg-light navbar-expand-md ">
        <div className="container">
          <h4>Corporate Register</h4>
          <div
            className=" navbar-collapse d-flex flex-row-reverse "
            id="main-nav-collapse"
          >
            <ul className="navbar-nav px-1">
              <div className=" col form-outline ">
                
                <input
                  type="search"
                  id="monitorsearch"
                  className="form-control-sm "
                  size="50"
                  placeholder="Search"
                  aria-label="Search"
                  autoComplete={false}
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                />
                &nbsp; 
                <button className="btn-sm"  onClick={(e)=>handleClickRefreshELS(e)}>
              <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <div className="table-responsive-sm">
        <table className="table table-hover fs-6">
          <thead>
            <tr>
              <th>Support</th>
              <th>Company Name</th>
              <th>Form</th>
              <th>Document Status</th>
              <th>Doc Track No.</th>
              <th className="text-wrap"> Date</th>
              <th className="text-wrap">Agent</th>
              <th className="text-wrap" onClick={(e)=>handleClickStatusSort(e)}>Status {statusSort === "asc" ? <i class="bi bi-arrow-up-short"></i>: <i class="bi bi-arrow-down-short"></i>}</th>
              
                {/*    <th className="text-wrap"> Action </th> */}
              <th><i class="bi bi-info-circle-fill"></i></th>
            </tr>
          </thead>

          <tbody>
            {
              electronicLodgementService && currentPosts&& currentPosts.length ===0 ?              
              <tr><td colSpan="10">No matching records found</td></tr>:
              electronicLodgementService &&
              currentPosts &&
              currentPosts.map((post) => (
                <tr>
                  <td>
                    <i class="bi bi-file-lock-fill text-danger "></i>
                  </td>
                  <td>{post.CO_NAME} </td>

                  <td>{post.FORM_TYPE}</td>

                  <td>
                    {" "}
                    <button
                      className={
                        post.STATUS && post.STATUS === "PREPARED"
                          ? "btn btn-info btn-sm disabled btn-primary"
                          : post.STATUS === "VALIDATED "
                          ? "btn btn-info btn-sm disabled btn-success"
                          : post.STATUS === "TRANSMITTED - REJECTED"
                          ? "btn btn-info btn-sm disabled btn-danger"
                          : post.STATUS === "TRANSMITTED - AWAITING VALIDATION"
                          ? "btn btn-info btn-sm disabled btn-warning"
                          : "btn btn-info btn-sm disabled"
                      }
                    >
                      {post.STATUS}
                    </button>
                  </td>
                  <td>{post.DOC_CODE}</td>

                  <td>{moment(post.FORM_DATE).format("DD/MM/YYYY")}</td>
                  <td>{post.AGENT_CODE}</td>

                  <td>
                    <button
                      className={
                        post.DUE_DAYS &&
                        post.DUE_DAYS.trim().indexOf("DUE In") !== -1
                          ? "btn btn-info btn-sm disabled  btn-warning"
                          : post.DUE_DAYS.trim().indexOf("DUE -") !== -1
                          ? "btn btn-info btn-sm disabled btn-success"
                          : post.DUE_DAYS.trim().indexOf("OVERDUE By") !== -1
                          ? "btn btn-info btn-sm disabled  btn-danger"
                          : ""
                      }
                    >
                      {post.DUE_DAYS}
                    </button>
                  </td>
                    {/* 
                  <td>
                    <a
                      href={() => false}
                      className="d-flex align-items-center text-white text-decoration-none "
                      id="dropdownUserDetail"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                    </a>
                    <ul className="dropdown-menu text-dark">
                      <li className="action ">
                        <a className="dropdown-item " href={() => false}>
                          View Transmit File
                        </a>{" "}
                        <hr />
                      </li>

                      <li className="action ">
                        <a className="dropdown-item " href={() => false}>
                          Tracking
                        </a>
                        <hr />
                      </li>
                      <li className="action ">
                        <a className="dropdown-item " href={() => false}>
                          Summary
                        </a>
                        <hr />
                      </li>
                      <li className="py-0 ">
                        <a className="dropdown-item " href={() => false}>
                          Log
                        </a>
                      </li>
                    </ul>
                  </td>
                  */}
                  <td >{post.FORM_TYPE === "484" ?  <a                  
href={() => false }
role="button"
onClick={(e) =>form484DocTrackHandleClick(e,post.RN,post.DOC_CODE)}><i class="bi bi-info-circle-fill  hand-cursor text-primary"></i></a>: "" }</td>


{popup484DocTrackShow === post.DOC_CODE  ? (
    <Popup
      show={popup484DocTrackShow === post.DOC_CODE}
      heading={"Summary"} 
      handleClose={handle484DocTrackPopupClose}
      handleShow={(e) => handle484DocTrackPopupShow(e, post.DOC_CODE)}
      body={
        <Form484InformationComponent
        form484Information={form484Information}
                 />
      }
      handleClick={handle484DocTrackPopupClick}

     
    />
  )
  :  popup484DocTrackShow === post.DOC_CODE && isForm484DocTrackLoaded ? <FullPageLoader /> :null } 
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {electronicLodgementServiceTotalStatement &&
        electronicLodgementServiceTotalStatement.length >= 10 && (
          <footer
            id="sticky-footer"
            class="footer d-flex justify-content-end footer-body  "
          >
            <div class="container fs-6 ">
              <div class="row">
                <div class="col-md-6 ">
                  Showing{" "}
                  <input
                    type="text"
                    className="form-control-sm border border-default"
                    onKeyPress={handleKeyPress}
                    name="showRecord"
                    value={postsPerPage}
                    maxLength="3"
                    size="3"
                    onChange={(e) => setPostsPerPage(e.target.value)}
                  ></input>{" "}
                  /
                  {electronicLodgementServiceTotalStatement
                    ? electronicLodgementServiceTotalStatement.length
                    : 0}
                </div>
                <div class="col-md-6">
                  {postsPerPage && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={
                        electronicLodgementServiceTotalStatement
                          ? electronicLodgementServiceTotalStatement.length
                          : 0
                      }
                      pageSize={postsPerPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </footer>
        )}
        <br/>
        <br/>
    </div>
  );
};
export default FormsAndDocumentComponent;
