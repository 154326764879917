import React, { useCallback } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

const AdditionalInformationComponent = (props) => {
  // eslint-disable-next-line
  const API_URL = process.env.REACT_APP_PRIME_API_URL;
    const getUploadParams = ({ file, meta }) => {
      const body = new FormData()
      body.append('fileField', file)
      return { url: 'https://httpbin.org/post', body }
  };
  //const [fileList, setFileList] = useState([]);
  // const saveBase64AsFile = (base64, fileName) => {
  //   var link = document.createElement("a");

  //   link.setAttribute("href", base64);
  //   link.setAttribute("download", fileName);
  //   link.click();
  // };

  const handleControlledDropzonChangeStatus = (status, allFiles, xhr) => {
    setTimeout(() => {
      if (["done", "removed"].includes(status)) {
   
        props.setAsicDetails((data) => ({ ...data, fileList: [...allFiles] }));
        props.setAsicDetails((data) => ({ ...data, dataFileList: allFiles && allFiles.map((value)=>JSON.parse(JSON.stringify(value.xhr.response))) }));
       
      }
    }, 0);
  };

  const NoDropzoneLayout = ({
    previews,
    submitButton,
    input,
    files,
    dropzoneProps,
  }) => {
    const { ref, className, style } = dropzoneProps;
    return (
      <div ref={ref} className={className} style={style}>
        {previews}

        {input}

        {files.length > 0 && submitButton}
      </div>
    );
  };
  const handleSearch = useCallback((q) => {

    console.log("selected2",q)
      props.setAsicDetails((value) => ({ ...value, signerName: [q] }));
  
    // eslint-disable-next-line
  }, []);

  const handle = useCallback((q) => {

    console.log("selected2",q)
    // eslint-disable-next-line
  }, []);
  return (
    <div className="container">

      <div className="form-group ">
        <div className="row">
          <div className="col">
            <label htmlFor="signerName" className="col-form-label">
              Form Signatory
            </label>

            <AsyncTypeahead
            id="signerName"
            className={
              !props.asicDetails.signerName ||
              (props.asicDetails.signerName && props.asicDetails.signerName[0] === "")  ||
              (props.asicDetails.signerName && props.asicDetails.signerName.length===0)
                ? "form-control is-invalid"
                : ""
            }
            labelKey={(option) =>
                             `${option.CEASED!=="" ? option.OFFICER_NAME:""}`
                           
            }
        
            options={
              props.companyProfile &&
              props.companyProfile.items ?
              props.companyProfile.items.officers.filter(
                (thing, index, self) =>
                  index === self.findIndex((t) => t.OFFICER_NAME === thing.OFFICER_NAME)
              ):[]
            }
            isLoading={false}
            onSearch={handle}
            onInputChange={handleSearch}
            onChange={(selected) => {
             if (selected) {
              console.log("selected",selected)
                props.setAsicDetails((value) => ({ ...value, signerName: selected }));
             }
      
            }}
           // selected={props.asicDetails.signerName!=='undefined' && props.asicDetails.signerName}
            placeholder="Form SignerName"
            useCache={false}
         
          />
          { console.log("array length",props.asicDetails.signerName)}
          {console.log("props.asicDetails.signerName",props.asicDetails.signerName, props.asicDetails.signerName.length)}
          {( !props.asicDetails.signerName ||
            (props.asicDetails.signerName && props.asicDetails.signerName[0] === "") ||
            (props.asicDetails.signerName && props.asicDetails.signerName.length===0)            
            ) 
            && (
            
            <div className="text-danger py-3 px-3">
           
        SignerName required
            </div>
          )}

          </div>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="moreInfo " className="col-form-label">
          Additional Info{" "}
        </label>
        <div className="input-group ">
          <textarea
            className="form-control"
            name="additionalMoreInfo"
            id="additionalMoreInfo"
            rows="3"
            value={props.asicDetails.additionalMoreInfo}
            onChange={(e) => props.handleChange(e, false, "")}
          ></textarea>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="Attachments" className="col-form-label">
          Attachment(s){" "}
        </label>
        <hr />

        <label htmlFor="note" className="col-form-label">
          Note:{" "}
        </label>
        <Dropzone
          getUploadParams={getUploadParams}
          inputContent={"Upload files/documents"}
          inputWithFilesContent={"Add more"}
          LayoutComponent={NoDropzoneLayout}
          onChangeStatus={(file, status, allFiles,xhr) => {
            handleControlledDropzonChangeStatus(status, allFiles,xhr);
          }}
          styles={{ dropzone: { border: 0, overflow: "hidden" } }}
        />
      </div>
    </div>
  );
};
export default AdditionalInformationComponent;
