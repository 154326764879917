import React from "react";

const EBinderRegisters = (props) => {
  const handleClickSelectedRegister = (e) => {
    props.setCompanyRegister({
      ...props.companyRegister,
      [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value
    })
     
  };
  return (
    <div className="fs-6">
      <div>
        {console.log(props.companyRegister)}
        <h4>
          {" "}
          <strong>Select Company Registers</strong>
        </h4>
        <p>
          Select any company registers you would like to include in the binder.
        </p>
      </div>
      <div>
        <table>
          <tr>
            <td>
              {" "}
              <input
                class="form-check-input"
                type="checkbox"
                name="directors"
                value={props.companyRegister.directors}
                checked={props.companyRegister.directors}
                onChange={(e) => handleClickSelectedRegister(e)}
              />
            </td>
            <td>Directors & Secretaries</td>
          </tr>
          <tr>
            <td>
              {" "}
              <input
                class="form-check-input"
                type="checkbox"
                name="allotment"
                value={props.companyRegister.allotment}
                checked={props.companyRegister.allotment}
                onChange={(e) => handleClickSelectedRegister(e)}
              />
            </td>
            <td>Allotment Journal</td>
          </tr>
          <tr>
            <td>
              {" "}
              <input
                class="form-check-input"
                type="checkbox"
                name="transfer"
                value={props.companyRegister.transfer}
                checked={props.companyRegister.transfer}
                onChange={(e) => handleClickSelectedRegister(e)}
              />
            </td>
            <td>Transfer Journal</td>
          </tr>
          <tr>
            <td>
              {" "}
              <input
                class="form-check-input"
                type="checkbox"
                name="members"
                value={props.companyRegister.members}
                checked={props.companyRegister.members}
                onChange={(e) => handleClickSelectedRegister(e)}
              />
            </td>
            <td>Members</td>
          </tr>
        </table>
      </div>
      <br />
      <br />
    </div>
  );
};
export default EBinderRegisters;
