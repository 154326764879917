import React from 'react';

const EBinderDeleteCustomfileComponent = (props) => {
    return (
        <div>
       {`Are you sure you want to delete "${props.fileName}"?`}
        </div>
    );
};

export default EBinderDeleteCustomfileComponent;