import React from 'react';
import {
    withRouter
  } from "react-router-dom";

const ChangeOrderStatus= (props) => {

    let handleClick = (e) => {
   props.setIsOrderSubmitted(false);
   props.handleReset();
    };
    return (
        <div>
        <h6 className="panel-title fw-bold">Form Submitted</ h6 >
        {props.changeOrderRequest && props.changeOrderRequest.items && props.changeOrderRequest.items.OrderId >0 ?(
        <p class Name="fs-6  fw-normal text-danger"> Thankyou for your Order (ORDER NUMBER<strong> {props.changeOrderRequest && props.changeOrderRequest.items && props.changeOrderRequest.items.OrderId}</strong>)
        we will be in touch with you shortly</p>) : (
        <p class Name="fs-6  fw-normal text-danger">An unknown error occurred. Please contact support@primeconsulting.com.au if the error persists.</p>
        )
      }
        <button   onClick={(e) =>
          handleClick(e)
          }
          >Go Back</button>
            
        </div>
    );
};

export default withRouter(ChangeOrderStatus);