import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import "../MonitorsComponents/AnnualReviews.style.scss";
import "../CommonPage/Action.scss";
import Pagination from "../CommonPage/Pagination.component";
import "../CommonPage/Action.scss";
import moment from "moment";
import Popup from "../CommonPage/Popup.component";
import BusinessDetailSummary from "./BusinessDetailSummary";
import NotesComponent from "../Notes.Components/NotesComponent";


const BusinessNameComponent = (props) => {
  const businessNameDetailList = useSelector((state) => state.businessNameDetailList);
  const businessNameSummary = useSelector((state) => state.businessNameSummary);
  
  const [filterStatus, setFilterStatus] = useState("");
  const [startRenewalDate, setStartRenewalDate] = useState("");
  const [endRenewalDate, setEndRenewalDate] = useState("");
  const [status, setStatus] = useState("");
  
  const[businessSort, setBusinessSort] = useState("");
  const[ownerSort, setOwnerStatus] = useState("");
  const[renewalDateSort, setRenewalDateSort] = useState("");
  const[regDateSort, setRegDateSort] = useState("");
  const[cancelDateSort, setCancelDateSort] = useState("");
  const[statusSortByOwner, setSortStatusByOwner] = useState("");
  function handleClickStatusSort(e,name) {
    if(name === "BusinessName" && businessSort === "asc") {    setBusinessSort("desc");setSortStatusByOwner(name); } else { setBusinessSort("asc"); setSortStatusByOwner(name)}
    if(name === "RenewalDate" && renewalDateSort === "asc") {    setRenewalDateSort("desc");setSortStatusByOwner(name); } else { setRenewalDateSort("asc"); setSortStatusByOwner(name)}
    if(name === "RegistrationDate" && regDateSort === "asc") {    setRegDateSort("desc");setSortStatusByOwner(name); } else { setRegDateSort("asc"); setSortStatusByOwner(name)}
    if(name === "CancellationDate" && cancelDateSort === "asc") {    setCancelDateSort("desc");setSortStatusByOwner(name); } else { setCancelDateSort("asc"); setSortStatusByOwner(name)}
    if(name === "OwnerName" && ownerSort === "asc") {    setOwnerStatus("desc");setSortStatusByOwner(name); } else { setOwnerStatus("asc"); setSortStatusByOwner(name)}

  }

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  let handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

    // connect to API
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(userActions.getBusinessNameDetailList());
      // eslint-disable-next-line
    }, []);
  
    // search
  const [searchInput, setSearchInput] = useState("");

  

  // console.log(posts);

  const indexOfLastPost = currentPage * postsPerPage;
  // console.log("indexOfLastPost: ", indexOfLastPost);

  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // console.log("indexOfFirstPost: ", indexOfFirstPost);

  

  //const currentPosts = businessNameDetailListTotal    ?  businessNameDetailListTotal.slice(indexOfFirstPost, indexOfLastPost): [];
// refresh button
function handleClickRefresh(e) {
  e.preventDefault();
  setSearchInput("");
}
// Business Welcome Letter
function handleClick(e,ID) {
 
  if (ID !== null) {
 
    dispatch(userActions.getBusinessWelcomeReport(ID));
  } 
}
// Business name Report
function handleBusinessnameReportClick(e,ID) {
 
  if (ID !== null) {
 
    dispatch(userActions.getBusinessNameReport(ID));
  } 
}
// Business Certificate
function handleClickCertificate(e,BID) {
 
  if (BID !== null) {
 
    dispatch(userActions.getBusinessCertificateReport(BID));
  } 
}


// Business Details Summary Click
const [businessNameSummaryShow, setBusinessNameSummaryShow] = useState(false);
const handleBusinessNameSummaryClose = () => setBusinessNameSummaryShow(false);
const handleBusinessNameSummaryShow = (e,rn) => {handleSummaryClick(e,rn);setBusinessNameSummaryShow(rn)};

  let  handleSummaryClick = (e,rn) => {
    console.log("businessNameSummary",businessNameSummary)
    e.preventDefault();
    if (rn !== null) {
      dispatch(
        userActions.getBusinessNameSummary(
          rn
        )
      );
    } 
  };

  // status change filtter
  function handleRequestStatusChange(e){
    e.preventDefault();
    //if(e && e.target.value){
      
      //setFilterStatus(businessNameDetailListTotal && businessNameDetailListTotal.filter((value) =>  value["Status"]  && value["Status"].toLowerCase().includes(e.target.value.toLowerCase()) )) 
    //}
    //else{
      //setFilterStatus(businessNameDetailListTotal)
    //}
    setStatus(e.target.value);
  }
 
  function handleRequestReviewDateChange(e, isStartDate, isEndDate){
    if (isStartDate) {
      setStartRenewalDate(e.target.value); 
    } 
    if (isEndDate) {
      
      setEndRenewalDate(e.target.value)
      
    } 
  }

  function pad2(n) {
    return (n < 10 ? "0" : "") + n;
  }
  function DateToString(date) {
    var month = pad2(date.getMonth() + 1); //months (0-11)
    var day = pad2(date.getDate()); //day (1-31)
    var year = date.getFullYear();
  
    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }
  useEffect(() => {
    console.log("?????", status)
    //return businessNameDetailListTotal && 
    //businessNameDetailListTotal.filter((value) => value["Status"]  && value["Status"].toLowerCase().includes(status.toLowerCase()) )
   /* if(startRenewalDate  && endRenewalDate) {
    setFilterStatus(businessNameDetailListTotal && businessNameDetailListTotal.filter((value) =>  {
      var renewalDate = DateToString(new Date(value["RenewalDate"]));
      if (searchInput) {
           return( value["RenewalDate"] && startRenewalDate  && endRenewalDate ? 
           (value["BusinessName"]
           .toLowerCase()
           .includes(searchInput.toLowerCase())) &&
           DateToString(new Date(startRenewalDate))<=renewalDate &&  renewalDate <= DateToString(new Date(endRenewalDate)): value["RenewalDate"] )
      } else {
        return( value["RenewalDate"] && startRenewalDate  && endRenewalDate ? DateToString(new Date(startRenewalDate))<=renewalDate &&  renewalDate <= DateToString(new Date(endRenewalDate)): value["RenewalDate"] )
      }
    })) 
  }*/

      // eslint-disable-next-line
  }, [startRenewalDate, endRenewalDate, searchInput, status]);
  const businessNameDetailListTotal =
  businessNameDetailList && businessNameDetailList.items
    ?(statusSortByOwner === "OwnerName")? handleSort(ownerSort, statusSortByOwner) : 
    (statusSortByOwner === "RenewalDate")? handleSort(renewalDateSort, statusSortByOwner) : 
    (statusSortByOwner === "RegistrationDate")? handleSort(regDateSort, statusSortByOwner) : 
    (statusSortByOwner === "CancellationDate")? handleSort(cancelDateSort, statusSortByOwner) : 
    handleSort(businessSort, statusSortByOwner)
    : [];

  function handleSort(statusSort, statusSortByOwner) {
    if( statusSort === "desc") {
      return businessNameDetailList.items
      .sort((a, b) => 
      (statusSortByOwner === "OwnerName")? b.OwnerName.localeCompare(a.OwnerName):
      (statusSortByOwner === "RenewalDate")? b.RenewalDate.localeCompare(a.RenewalDate):
      (statusSortByOwner === "RegistrationDate")? b.RegistrationDate.localeCompare(a.RegistrationDate):
      (statusSortByOwner === "CancellationDate")? b.CancellationDate.localeCompare(a.CancellationDate)
      :b.BusinessName.localeCompare(a.BusinessName))
      .filter((filteredValue) =>
      filteredValue["Status"]  && filteredValue["Status"].toLowerCase().includes(status.toLowerCase()) &&
      filteredValue["BusinessName"]
        .toLowerCase()
        .includes(searchInput.toLowerCase())
       
    ).filter((filteredValue) =>
    (startRenewalDate  && endRenewalDate)? 
    DateToString(new Date(startRenewalDate))<= DateToString(new Date(filteredValue["RenewalDate"])) &&   DateToString(new Date(filteredValue["RenewalDate"])) <= DateToString(new Date(endRenewalDate))
    : filteredValue["RenewalDate"]
  )
    } else if( statusSort === "asc") {
      return businessNameDetailList.items
      .sort((a, b) => 
      (statusSortByOwner === "OwnerName")?  a.OwnerName.localeCompare(b.OwnerName):
      (statusSortByOwner === "RenewalDate")? a.RenewalDate.localeCompare(b.RenewalDate):
      (statusSortByOwner === "RegistrationDate")? a.RegistrationDate.localeCompare(b.RegistrationDate):
      (statusSortByOwner === "CancellationDate")? a.CancellationDate.localeCompare(b.CancellationDate):
      a.BusinessName.localeCompare(b.BusinessName))
      .filter((filteredValue) =>
      filteredValue["Status"]  && filteredValue["Status"].toLowerCase().includes(status.toLowerCase()) &&
      filteredValue["BusinessName"]
        .toLowerCase()
        .includes(searchInput.toLowerCase()) 
    ).filter((filteredValue) =>
    (startRenewalDate  && endRenewalDate)? 
    DateToString(new Date(startRenewalDate))<= DateToString(new Date(filteredValue["RenewalDate"])) &&   DateToString(new Date(filteredValue["RenewalDate"])) <= DateToString(new Date(endRenewalDate))
    : filteredValue["RenewalDate"]
  )
    } else {
      return businessNameDetailList.items
      .filter((filteredValue) =>
      filteredValue["Status"]  && filteredValue["Status"].toLowerCase().includes(status.toLowerCase()) &&
      filteredValue["BusinessName"]
        .toLowerCase()
        .includes(searchInput.toLowerCase())
    ).filter((filteredValue) =>
    (startRenewalDate  && endRenewalDate)? 
    DateToString(new Date(startRenewalDate))<= DateToString(new Date(filteredValue["RenewalDate"])) &&   DateToString(new Date(filteredValue["RenewalDate"])) <= DateToString(new Date(endRenewalDate))
    : filteredValue["RenewalDate"]
  )
    }
   
  }
  const currentPosts = businessNameDetailListTotal.slice(indexOfFirstPost, indexOfLastPost);


  let handleClickClearDate = (e, isDate) => {
    if (isDate) {
      setEndRenewalDate("")
      setStartRenewalDate("")
     
    }
  };

  //notes popup
  const [businessNotes, setBusinessNotes] = useState({
    notes: "",
    emailAddress: "",
  });
  let handleBusinessNotesChange = (e) => {
    e.preventDefault();
    setBusinessNotes((businessNotes) => ({
      ...businessNotes,
      [e.target.name]: e.target.value,
    }));
  };
const [popupBusinessNotesShow, setPopupBusinessNotesShow] = useState(0);
const handleBusinessPopupClose = () => setPopupBusinessNotesShow(false);
const handleBusinessPopupShow = (e, index) => {
  e.preventDefault();
  setPopupBusinessNotesShow(index);
};

let handleBusinessNotesPopupClick = (e, identifier) => {

  e.preventDefault();

    dispatch(userActions.postBusinessNotes(identifier, businessNotes.notes));
    setTimeout(() => {
      dispatch(userActions.getBusinessNameDetailList());
    }, 0);
    handleBusinessPopupClose();
   

};



  return (
    <div className="m-4 fontfamily">

      <h3 className="panel-title">Business Names</h3>
    
      <nav className="navbar navbar-light bg-light navbar-expand-md ">
        <div className="container">
          <div
            className=" navbar-collapse "
            id="main-nav-collapse"
          >
            <ul className="navbar-nav">
              <div className=" col form-outline">
                <input
                  type="search"
                  id="businessSearch"
                  className="form-control-sm "
                  size="50"
                  placeholder="Search"
                  aria-label="Search"
                  autoComplete={false}
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                />
                &nbsp;
                <button
                  className="btn-sm"
                  onClick={(e) => handleClickRefresh(e)}
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i>
                </button>
                &nbsp;
                &nbsp;
                <select
                name="status"
                defaultValue=""
                onChange={e=>handleRequestStatusChange(e)}
              >
                <option value="">Status</option>
                <option value="Green">Current</option>
                <option value="Orange">Renewal Required</option>
                <option value="Red">Overdue/Cancelled</option>
              
              </select>
              &nbsp;
              &nbsp;
              <label htmlFor="RenewalDate">Renewal Date:</label>
              <input
              type="date"
              name="startRenewalDate"
              onChange={e=>{ handleRequestReviewDateChange(e, true, false)}}
            value={startRenewalDate}
            />
           
            <span className="px-2">to</span>
           
            <input
              type="date"
              name="endRenewalDate"
              onChange={e=>{handleRequestReviewDateChange(e, false, true)}}
              value={endRenewalDate}
            />
            <span className="px-1"></span>
            <button
            className="btn-sm"
            onClick={(e) =>
              handleClickClearDate(e, true, endRenewalDate)
            }
          >
            <i class="fa fa-refresh" aria-hidden="true"></i>
          </button>
            
            
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <div className="table-responsive-sm">
        <table className="table table-hover  table-xs fs-6">
          <thead>
            <tr>
            <th >Status</th>
              <th  onClick={(e)=>handleClickStatusSort(e,"BusinessName")}>BusinessName {businessSort === "asc" ? <i class="bi bi-arrow-up-short"></i>: <i class="bi bi-arrow-down-short"></i>}</th>
              <th >ABN</th>
              <th onClick={(e)=>handleClickStatusSort(e,"OwnerName")}>Owner Name{ownerSort === "asc" ? <i class="bi bi-arrow-up-short"></i>: <i class="bi bi-arrow-down-short"></i>}</th>
              <th >Department</th>
              <th  onClick={(e)=>handleClickStatusSort(e,"RenewalDate")}>Renewal Date {renewalDateSort === "asc" ? <i class="bi bi-arrow-up-short"></i>: <i class="bi bi-arrow-down-short"></i>}</th>
              <th  onClick={(e)=>handleClickStatusSort(e,"RegistrationDate")}>Registration Date {regDateSort === "asc" ? <i class="bi bi-arrow-up-short"></i>: <i class="bi bi-arrow-down-short"></i>}</th>
              <th onClick={(e)=>handleClickStatusSort(e,"CancellationDate")}>
              Cancellation Date {cancelDateSort === "asc" ? <i class="bi bi-arrow-up-short"></i>: <i class="bi bi-arrow-down-short"></i>}</th>
              <th>Notes</th>
              <th> Action </th>
            </tr>
          </thead>

          <tbody>
          {
            businessNameDetailList && currentPosts&& currentPosts.length ===0 ?              
              <tr><td colSpan="10">No matching records found</td></tr>:
            businessNameDetailList&&
            currentPosts &&
            currentPosts.map((post) => (
                <tr  >
                  <td>
                  
                  <i
                  className={
                    post.Status === "Red"
                      ? "bi bi-circle-fill fa-xs text-danger"
                      : post.Status === "Green"
                      ? "bi bi-circle-fill fa-xs text-success"
                      : post.Status === "Orange"
                      ? "bi bi-circle-fill fa-xs text-warning"
                      : "bi bi-circle fa-xs"
                  }
                ></i>
                
                  </td>
                  <td >{post.BusinessName}</td>
                  <td className="text-nowrap">{post.ABN}</td>
                  <td  >{post.OwnerName}</td>
                  <td  className="text-nowrap">{post.DepartmentCode}</td>
                  <td>{post.RenewalDate &&
                    moment(post.RenewalDate).format("DD/MM/YYYY")}
            </td>
                  <td>{post.RegistrationDate &&
                    moment(post.RegistrationDate).format("DD/MM/YYYY")}</td>
                    <td>{post.CancellationDate &&
                      moment(post.CancellationDate).format("DD/MM/YYYY")}</td>
                    <td>        <a
                    href={() => false}
                    className="hand-cursor"
                   onClick={(e) => handleBusinessPopupShow(e, post.ID)}
                  >
                  <i
                   class={post.NOTES
                   ? "bi bi-chat-square-text  fa-lg text-success"
                   :"bi bi-chat-square-text  fa-lg text-danger"
               }        ></i>
                    </a>
                    {popupBusinessNotesShow === post.ID && (
                      <Popup
                        show={popupBusinessNotesShow === post.ID}
                        handleClose={handleBusinessPopupClose}
                        handleShow={handleBusinessPopupShow}
                        body={
                          <NotesComponent
                            notes={post.NOTES}
                            companyName={post.BusinessName}
                            handlePersonDetailsChange ={handleBusinessNotesChange}
                          />
                        }
                        btnName="Save"
                        handleClick={(e) => handleBusinessNotesPopupClick(e, post.ID)}
                      />
                    )}
                    </td>
                  <td>
                  <a
                    href={() => false}
                    className="d-flex align-items-center text-white text-decoration-none "
                    id="dropdownUserDetail"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                  </a>
                  <ul className="dropdown-menu text-dark">
                    <li className="action ">
                    <a className="dropdown-item " href={() => false}   
                    onClick={(e) => handleBusinessNameSummaryShow(e, post.RN)}
                    >
                    Business Name summary
                     </a>
                    
                    <hr />
                    </li>
                    {businessNameSummaryShow === post.RN && (
                      <Popup
                        show={businessNameSummaryShow === post.RN}
                        isLargeSize={true}
                        heading={"Business Name summary"}
                        handleClose={handleBusinessNameSummaryClose}
                        handleShow={(e) =>handleBusinessNameSummaryShow(e,post.RN)}
                        body={
                          <BusinessDetailSummary
                          businessNameSummary={
                           businessNameSummary
                         }
                          />
                        }
                       
                      
                      />
                    )}
                    <li className="action ">
                      <a
                        className="dropdown-item"
                        href={() => false}
                        onClick={(e) => handleClick(e, post.ID)}>
               Welcome Letter pdf
                      </a>
                    </li> <hr />
                    <li className="action ">
                    <a
                      className="dropdown-item"
                      href={() => false}
                      onClick={(e) => handleClickCertificate(e, post.ID)}>
                     
             Business Certificate pdf
                    </a>
                  </li><hr/>
                  <li className="py-0 ">
                      <a
                        className="dropdown-item"
                        href={() => false}
                        onClick={(e) => handleBusinessnameReportClick(e, post.ID)}>
               Business NameReport
                      </a>
                    </li>
                  </ul>
                </td>
                </tr>
                ))}
          </tbody>
        </table>
      </div>
      {((filterStatus && filterStatus.length>=10) || (businessNameDetailListTotal && businessNameDetailListTotal.length >= 10)) && (
     
        <footer
          id="sticky-footer"
          class="footer d-flex justify-content-end footer-body py-5  "
        >
          <div class="container fs-6 ">
            <div class="row">
              <div class="col-md-6 ">
                Showing{" "}
                <input
                  type="text"
                  className="form-control-sm border border-default"
                  onKeyPress={handleKeyPress}
                  name="showRecord"
                  value={postsPerPage}
                  maxLength="3"
                  size="3"
                  onChange={(e) => setPostsPerPage(e.target.value)}
                ></input>{" "}
                /
                {filterStatus
                  ? filterStatus.length
                  : businessNameDetailListTotal && businessNameDetailListTotal.length}
              
                
              </div>
              <div class="col-md-6">
                {postsPerPage && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={
                      filterStatus
                      ? filterStatus.length
                      : businessNameDetailListTotal && businessNameDetailListTotal.length
                  }
                   
                    pageSize={postsPerPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};

export default BusinessNameComponent;
