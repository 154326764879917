import React, { useState, useEffect } from 'react';
import MainScreen from '../CommonPage/MainScreen.component'
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";

const SignUp = (props) => {
    const dispatch = useDispatch();
    const [signUpUsers, setSignUpUsers] = useState({
        phonenumber: '',
        firstName: '',
        lastName: '',
        email:'',
        organisationName: '',
        terms: '',
        popup: ''
    });
  

    const charac = /^[0-9]*$/;
    useEffect(() => {
        //add whatever functions use new `signUpUsers` value here.
        console.log(signUpUsers)
    }, [signUpUsers]);

    
    const [submitted, setSubmitted] = useState(false);
    let handleKeyPress = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }

    let handleName = (e) => {
        if (charac.test(e.key)) {
            e.preventDefault();
        }
    }
    let handleChange = (e) => {
        setSignUpUsers(signUpUsers => ({ ...signUpUsers, [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value }))
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (signUpUsers.phonenumber && signUpUsers.phonenumber.length === 10 && signUpUsers.firstName && signUpUsers.lastName && signUpUsers.email && signUpUsers.organisationName && signUpUsers.terms) {
            console.log('Success')
            dispatch(userActions.postSignup(signUpUsers.firstName,signUpUsers.lastName,signUpUsers.phonenumber,signUpUsers.email,signUpUsers.OrganisationName));
            props.history.push("/SignUpSuccess")
        }
    }



    return (
        <MainScreen content={
            <div className="container ">
                    <h3 className="text-center"   style={{fontSize:'16px', lineHeight: '19px', color: '#012040',fontStyle: 'normal', fontWeight: '400',}}>Create an account and get a free 30 day trial</h3>
                    <form onSubmit={handleSubmit}>
                     
                        <div className="form-group ">
                        <div className="row">
                        <label htmlFor="firstName" className="col-form-label">Name</label>
                        </div>
                            <div className="row">
                                <div className="col">
                                    
                                    <input type="text" 
                                    autoComplete='off'
                                    style={{fontSize:'16px', color: '#012040', lineHeight: '19px', fontWeight: '400'}}
                                    name="firstName" onKeyPress={handleName} value={signUpUsers.firstName} onChange={handleChange} className={'form-control form-control-lg ' + (submitted && !signUpUsers.firstName ? 'is-invalid' : '')} placeholder="First Name" />
                                    {
                                        submitted && !signUpUsers.firstName &&
                                        
                                        <div className="invalid-feedback" style={{fontSize:'14px', color: '#CF0E0E', lineHeight: '19px', fontWeight: '400'}}>First Name  required</div>

                                    }
                                    </div>
                                <div className="col">
                                   <span></span>
                                    <input 
                                    autoComplete='off'
                                    style={{fontSize:'16px', color: '#012040', lineHeight: '19px', fontWeight: '400'}}
                                    type="text" name="lastName" value={signUpUsers.lastName} onKeyPress={handleName} onChange={handleChange} className={'form-control form-control-lg ' + (submitted && !signUpUsers.lastName ? 'is-invalid' : '')} placeholder="Last Name" />
                                    {
                                        submitted && !signUpUsers.lastName &&
                                        <div className="invalid-feedback" style={{fontSize:'14px', color: '#CF0E0E', lineHeight: '19px', fontWeight: '400'}}>Last Name required</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-group ">
                        <label htmlFor="organisationName" className="col-form-label">Organisation Name</label>
                        <input
                  
                        style={{fontSize:'16px', color: '#012040', lineHeight: '19px', fontWeight: '400'}}
                         type="text" name="organisationName" 
                         autoComplete='off'
                         value={signUpUsers.organisationName} onChange={handleChange} className={'form-control form-control-lg ' + (submitted && !signUpUsers.organisationName ? 'is-invalid' : '')} placeholder="Organisation Name" />
                        {
                            submitted && !signUpUsers.organisationName &&
                            <div className="invalid-feedback"
                            style={{fontSize:'14px', color: '#CF0E0E', lineHeight: '19px', fontWeight: '400'}}>Organisation name Required
                            </div>
                        }
                    </div>
                        <div className="form-group ">
                        <label htmlFor="email" className="col-form-label">Email</label>
                        <input type="text"
                                                style={{fontSize:'16px', color: '#012040', lineHeight: '19px', fontWeight: '400'}}
                                                autoComplete='off'
                                                name="email" value={signUpUsers.email} onChange={handleChange} 
                         className={'form-control form-control-lg ' + (submitted && !signUpUsers.email ? 'is-invalid' : '')} placeholder="Email" />
                        {
                            submitted && !signUpUsers.email &&
                            <div className="invalid-feedback"
                            style={{fontSize:'14px', color: '#CF0E0E', lineHeight: '19px', fontWeight: '400'}}>Email required
                            </div>
                        }
                    </div>
                        
                        <div className="form-group" >
                        <label htmlFor="Phone Number" className="col-form-label">Phone</label>
                        <div className="input-group " >
                           
                            
                            <input type="text" 
                            style={{fontSize:'16px', color: '#012040', lineHeight: '19px', fontWeight: '400'}}
                            autoComplete='off'
                            name="phonenumber" onKeyPress={handleKeyPress} value={signUpUsers.phonenumber} onChange={handleChange} className={'form-control form-control-lg ' + (submitted && ((!signUpUsers.phonenumber) || (signUpUsers.phonenumber && signUpUsers.phonenumber.length < 10)) ? 'is-invalid' : '')} placeholder="Phone " size="10" maxLength={10} />
                            {

                                submitted && ((!signUpUsers.phonenumber) || (signUpUsers.phonenumber && signUpUsers.phonenumber.length < 8)) &&
                                <div className="invalid-feedback "
                                style={{fontSize:'14px', color: '#CF0E0E', lineHeight: '19px', fontWeight: '400'}}>Phone required</div>
                            }
                        </div>
                    </div>

                        <div className="form-check p-4">
                            <input type="checkbox" id="termsAndCondition" name="terms" defaultChecked={signUpUsers.terms} checked={signUpUsers.terms} onChange={handleChange} className={'form-check-input ' + (submitted && !signUpUsers.terms ? 'is-invalid' : '')} />

                            
                            <p>  I agree to the  <a          
                            target="_blank"
                            rel="noreferrer"
                            href={
                              "https://www.primecc.com.au/wp-content/uploads/2022/08/CCASA-Terms-of-Use-Final-Aug22.pdf"
                            }
                          >
                          
                              {" "}
                              <u style={{fontSize:'16px', lineHeight: '19.36px', color: '#012040',fontStyle: 'normal', fontWeight: '400',letterspacing: '0.25px',}}>Terms of Use</u>
                            </a></p>    </div>
                        
                        <div className="d-grid">
                            <button 
                            className={(signUpUsers.phonenumber &&signUpUsers.firstName &&signUpUsers.lastName && signUpUsers.email && signUpUsers.organisationName && signUpUsers.terms) === "" ? "buttonDisable  w-100" : "buttonActive w-100"}
                             type="submit" id="signUp"
                            onClick={(e) =>handleSubmit(e)}>REGISTER</button>
                        </div>
                        <br />
                        <hr className="my-12" />
                      
                        <div className="form-group">
                        <div className="col-auto col-md-12 text-center ">
                        <span style={{fontSize:'16px', lineHeight: '19.36 px', color: '#A6A6A6',fontStyle: 'normal', fontWeight: '400',letterspacing: '0.25px',}}> Already have an account?</span>
                         
                          <a
                            href={() => false}
                            style={{fontSize:'16px', lineHeight: '19.36 px', color: ' #012040',fontStyle: 'normal', fontWeight: '550',letterspacing: '0.25px',}}
                           
                            className=" hand-cursor  text-decoration-none"
                            onClick={() => props.history.push("/login")}
                          >
                            {" "}
                         Login
                          </a>
                        </div>
                      </div>
                    
                    </form>
                    <br />
                  
            </div>
        }/>
    )
}
export default SignUp;