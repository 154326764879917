import { userConstants } from "../constants/login.constants";

 function unitTrustTable(state = {}, action) {
    switch (action.type) {
        case userConstants.UNITTRUSTTABLE_REQUEST:
            return {
                loading: true
            };
        case userConstants.UNITTRUSTTABLE_SUCCESS:
            return {
                items: action.unitTrustTable
            };
        case userConstants.UNITTRUSTTABLE_FAILURE:
            return {
                error: action.unitTrustTableError
            };
        default:
            return state
    }
}
export default unitTrustTable;