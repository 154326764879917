import { userService } from "../services/login.servcies";
import { userConstants } from "../constants/login.constants";

export const userActions = {
  login,
  logout,
  getAllCompany,
  getCompanyProfileByCode,
  getAllDepartement,
  getUnitTrustByCode,
  getAnnualStatement,
  getAnnualStatementMonthStatus,
  getAsicDebtStatement,
  getCompanyProfileReport,
  getMembersReport,
  getMembersReportDateRange,
  getDirectorsSecretariesReport,
  getAllotementJournalReport,
  getTransferJournalReport,
  getResponsibleManagerReport,
  getPublicOfficerReport,
  getDirectorsShareholdingsReport,
  getOfficerDetailsReport,
  getShareHolderDetailsReport,
  getDocumentHistoryDocReport,
  getDocumentHistoryAsicReport,
  getAsicDocumentTracking,
  getIndividualsEntities,
  getAllIndividualsEntities,
  getAllIndividualsEntitiesWithPagination,
  getAllJointHolders,
  getJointHolders,
  getAllBeneficialOwner,
  getBeneficialOwner,
  getCompanyReport,
  getQuickCompanyProfileReport,
  getAsicCompanyDetailReport,
  isUnitTrustEmpty,
  getOrderMonitorStatement,
  getDigitalSignatureStatement,
  getAsicDebtReport,
  getElectronicLodegementService,
  getCompaniesDebtReport,
  getIndividualElectronicLodegementService,
  getCompaniesDetailList,
  getFormsHistoryReport,
  getDirectorIdReport,
  getUnitTrustUnitholdersReport,
  getUnitTrustApplicationAllotmentJournalReport,
  getUnitTrustTransferJournalReport,
  getUnitTrustUnitholdersSummaryReport,
  getAsicInvoiceReport,
  getAsicInvoiceReportLinks,
  getViewCurrentAnnualStatementReport,
  postCompanyPhoneNumber,
  postCompanyEmailID,
  postIndividualPhoneNumber,
  postIndividualEmailID,
  postForgetpasswordEmail,
  postResetPassword,
  getDiv7ALoanReport,
  getForm484Information,
  getRegdInformation,
  getIndividualEntitiesProfileReport,
  getIndividualEntitiesOffices,
  postIndividualEntitiesDid,
  getIndividualEntitiesShares,
  postCompanyNotes,
  getAllCountries,
  postSignup,
  getUnitTrustTable,
  getLoggedUserInfo,
  postChangeOrderRequest,
  getClassList,
  getCompanyListReport,
  postRecentCompanyList,
  getRecentCompanyList,
  getEbinderPrimeFiles,
  getManageEbinderList,
  getEbinderLink,
  getEbinderCustomFileList,
  postEbinderCustomFileDelete,
  postEbinderCustomFileAdd,
  getEbinderCompanyList,
  postCreateEbinder,
  postChangePassword,
  getBusinessNameDetailList,
  getBusinessWelcomeReport,
  getBusinessCertificateReport,
  getBusinessNameSummary,
  getBusinessNameReport,
  postBusinessNotes,
  getBusinessNotes,
  getDirectorIdCount,
  getEdgeCompanyProfileReport,
};
/*function login(clientId, userName, password, from, history) {
  return (dispatch) => {
    dispatch(request({ userName }));
    userService.login(clientId, userName, password).then(
      (user) => {
        dispatch(success(user));
        console.log(localStorage.getItem("token"));
        history.push(from);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };*/

function login(userName, password, from, history) {
  return (dispatch) => {
    dispatch(request({ userName }));
    userService.login(userName, password).then(
      (user) => {
        dispatch(success(user));
        console.log(localStorage.getItem("token"));
        if (from) {
          history.push(from);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}
function logout() {
  return (dispatch) => {
    dispatch(success());
    userService.logout();
  };
  function success() {
    return { type: userConstants.LOGOUT };
  }
}

function getAllCompany(token) {
  return (dispatch) => {
    dispatch(request());
    userService.getAllCompany(token).then(
      (company) => {
        dispatch(success(company));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.COMPANYALL_REQUEST };
  }
  function success(company) {
    return { type: userConstants.COMPANYALL_SUCCESS, company };
  }
  function failure(error) {
    return { type: userConstants.COMPANYALL_FAILURE, error };
  }
}

function getCompanyProfileByCode(code) {
  return (dispatch) => {
    dispatch(request());
    userService.getCompanyProfileByCode(code).then(
      (profiles) => {
        dispatch(success(profiles));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.COMPANYSELECTED_REQUEST };
  }
  function success(profiles) {
    return { type: userConstants.COMPANYSELECTED_SUCCESS, profiles };
  }
  function failure(errorCompanyProfile) {
    return { type: userConstants.COMPANYSELECTED_FAILURE, errorCompanyProfile };
  }
}

function getAllDepartement() {
  return (dispatch) => {
    dispatch(request());
    userService.getAllDepartement().then(
      (departements) => {
        dispatch(success(departements));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.DEPARTMENTALL_REQUEST };
  }
  function success(departements) {
    return { type: userConstants.DEPARTMENTALL_SUCCESS, departements };
  }
  function failure(error) {
    return { type: userConstants.DEPARTMENTALL_FAILURE, error };
  }
}
function getUnitTrustByCode(companyCode, mode) {
  return (dispatch) => {
    dispatch(request());
    userService.getUnitTrustByCode(companyCode, mode).then(
      (unitTrust) => {
        dispatch(success(unitTrust));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.UNITTRUSTALL_REQUEST };
  }
  function success(unitTrust) {
    return { type: userConstants.UNITTRUSTALL_SUCCESS, unitTrust };
  }
  function failure(error) {
    return { type: userConstants.UNITTRUSTALL_FAILURE, error };
  }
}

function getAnnualStatement(currentYear, selectedMonth) {
  return (dispatch) => {
    dispatch(request());
    userService.getAnnualStatement(currentYear, selectedMonth).then(
      (annualStatement) => {
        dispatch(success(annualStatement));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.ANNUALSTATEMENT_REQUEST };
  }
  function success(annualStatement) {
    return { type: userConstants.ANNUALSTATEMENT_SUCCESS, annualStatement };
  }
  function failure(error) {
    return { type: userConstants.ANNUALSTATEMENT_FAILURE, error };
  }
}

function getAnnualStatementMonthStatus(currentYear) {
  return (dispatch) => {
    dispatch(request());
    userService.getAnnualStatementMonthStatus(currentYear).then(
      (annualStatementMonthStatus) => {
        dispatch(success(annualStatementMonthStatus));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.ANNUALSTATEMENTMONTHSTATUS_REQUEST };
  }
  function success(annualStatementMonthStatus) {
    return {
      type: userConstants.ANNUALSTATEMENTMONTHSTATUS_SUCCESS,
      annualStatementMonthStatus,
    };
  }
  function failure(error) {
    return { type: userConstants.ANNUALSTATEMENTMONTHSTATUS_FAILURE, error };
  }
}

function getAsicDebtStatement() {
  return (dispatch) => {
    dispatch(request());
    userService.getAsicDebtStatement().then(
      (asicDebtStatement) => {
        dispatch(success(asicDebtStatement));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.ASICDEPTSTATEMENT_REQUEST };
  }
  function success(asicDebtStatement) {
    return { type: userConstants.ASICDEPTSTATEMENT_SUCCESS, asicDebtStatement };
  }
  function failure(error) {
    return { type: userConstants.ASICDEPTSTATEMENT_FAILURE, error };
  }
}

function getCompanyProfileReport() {
  return (dispatch) => {
    dispatch(request());
    userService.getCompanyProfileReport().then(
      (companyProfileReport) => {
        dispatch(success(companyProfileReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.COMPANYPROFILEREPORT_REQUEST };
  }
  function success(companyProfileReport) {
    return {
      type: userConstants.COMPANYPROFILEREPORT_SUCCESS,
      companyProfileReport,
    };
  }
  function failure(error) {
    return { type: userConstants.COMPANYPROFILEREPORT_FAILURE, error };
  }
}

function getMembersReport(members, companyCode, setIsMemberLoaded) {
  return (dispatch) => {
    dispatch(request());
    userService.getMembersReport(members, companyCode).then(
      (membersReport) => {
        dispatch(success(membersReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsMemberLoaded(true);
    return { type: userConstants.MEMBERSREPORT_REQUEST };
  }
  function success(membersReport) {
    setIsMemberLoaded(false);
    return { type: userConstants.MEMBERSREPORT_SUCCESS, membersReport };
  }
  function failure(error) {
    setIsMemberLoaded(false);
    return { type: userConstants.MEMBERSREPORT_FAILURE, error };
  }
}

function getMembersReportDateRange(members, companyCode, setIsMemberLoaded) {
  return (dispatch) => {
    dispatch(request());
    userService.getMembersReportDateRange(members, companyCode).then(
      (membersReport) => {
        dispatch(success(membersReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsMemberLoaded(true);
    return { type: userConstants.MEMBERSREPORT_REQUEST };
  }
  function success(membersReport) {
    setIsMemberLoaded(false);
    return { type: userConstants.MEMBERSREPORT_SUCCESS, membersReport };
  }
  function failure(error) {
    setIsMemberLoaded(false);
    return { type: userConstants.MEMBERSREPORT_FAILURE, error };
  }
}

function getDirectorsSecretariesReport(
  officers,
  companyCode,
  setIsDirectorAndSecretariesLoaded
) {
  return (dispatch) => {
    dispatch(request());
    userService.getDirectorsSecretariesReport(officers, companyCode).then(
      (directorsSecretariesReport) => {
        dispatch(success(directorsSecretariesReport));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    setIsDirectorAndSecretariesLoaded(true);
    return { type: userConstants.DIRECTORANDSECRETARIESREPORT_REQUEST };
  }
  function success(directorsSecretariesReport) {
    setIsDirectorAndSecretariesLoaded(false);
    return {
      type: userConstants.DIRECTORANDSECRETARIESREPORT_SUCCESS,
      directorsSecretariesReport,
    };
  }
  function failure(error) {
    setIsDirectorAndSecretariesLoaded(false);
    return { type: userConstants.DIRECTORANDSECRETARIESREPORT_FAILURE, error };
  }
}

function getAllotementJournalReport(
  allotmentJournal,
  companyCode,
  setIsAllotmentJournalLoaded
) {
  return (dispatch) => {
    dispatch(request());
    userService.getAllotementJournalReport(allotmentJournal, companyCode).then(
      (allotementJournalReport) => {
        dispatch(success(allotementJournalReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsAllotmentJournalLoaded(true);
    return { type: userConstants.ALLOTMENTJOURNALREPORT_REQUEST };
  }
  function success(allotementJournalReport) {
    setIsAllotmentJournalLoaded(false);
    return {
      type: userConstants.ALLOTMENTJOURNALREPORT_SUCCESS,
      allotementJournalReport,
    };
  }
  function failure(error) {
    setIsAllotmentJournalLoaded(false);
    return { type: userConstants.ALLOTMENTJOURNALREPORT_FAILURE, error };
  }
}

function getTransferJournalReport(
  transferJournal,
  companyCode,
  setIsTransferLoaded
) {
  return (dispatch) => {
    dispatch(request());
    userService.getTransferJournalReport(transferJournal, companyCode).then(
      (transferJournalReport) => {
        dispatch(success(transferJournalReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsTransferLoaded(true);
    return { type: userConstants.TRANSFERJOURNALREPORT_REQUEST };
  }
  function success(transferJournalReport) {
    setIsTransferLoaded(false);
    return {
      type: userConstants.TRANSFERJOURNALREPORT_REQUEST_SUCCESS,
      transferJournalReport,
    };
  }
  function failure(error) {
    setIsTransferLoaded(false);
    return { type: userConstants.TRANSFERJOURNALREPORT_REQUEST_FAILURE, error };
  }
}
function getResponsibleManagerReport(
  responsibleManager,
  companyCode,
  setIsResponsibleManagerLoaded
) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getResponsibleManagerReport(responsibleManager, companyCode)
      .then(
        (responsibleManagerReport) => {
          dispatch(success(responsibleManagerReport));
        },
        (error) => {
          dispatch(failure(error && error.toString()));
        }
      );
  };
  function request() {
    setIsResponsibleManagerLoaded(true);
    return { type: userConstants.RESPONSIBLEMANAGERREPORT_REQUEST };
  }
  function success(responsibleManagerReport) {
    setIsResponsibleManagerLoaded(false);
    return {
      type: userConstants.RESPONSIBLEMANAGERREPORT_SUCCESS,
      responsibleManagerReport,
    };
  }
  function failure(error) {
    setIsResponsibleManagerLoaded(false);
    return { type: userConstants.RESPONSIBLEMANAGERREPORT_FAILURE, error };
  }
}

function getPublicOfficerReport(
  publicOfficers,
  companyCode,
  setIsPublicOfficerLoaded
) {
  return (dispatch) => {
    dispatch(request());
    userService.getPublicOfficerReport(publicOfficers, companyCode).then(
      (publicOfficersReport) => {
        dispatch(success(publicOfficersReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsPublicOfficerLoaded(true);
    return { type: userConstants.PUBLICOFFICERREPORT_REQUEST };
  }
  function success(publicOfficersReport) {
    setIsPublicOfficerLoaded(false);
    return {
      type: userConstants.PUBLICOFFICERREPORT_SUCCESS,
      publicOfficersReport,
    };
  }
  function failure(error) {
    setIsPublicOfficerLoaded(false);
    return { type: userConstants.PUBLICOFFICERREPORT_FAILURE, error };
  }
}

function getDirectorsShareholdingsReport(
  directorshareholdings,
  companyCode,
  setIsDirectorsLoaded
) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getDirectorsShareholdingsReport(directorshareholdings, companyCode)
      .then(
        (directorsShareholdingsReport) => {
          dispatch(success(directorsShareholdingsReport));
        },
        (error) => {
          dispatch(failure(error && error.toString()));
        }
      );
  };
  function request() {
    setIsDirectorsLoaded(true);
    return { type: userConstants.DIRECTORSSHAREHOLDINGSREPORT_REQUEST };
  }
  function success(directorsShareholdingsReport) {
    setIsDirectorsLoaded(false);
    return {
      type: userConstants.DIRECTORSSHAREHOLDINGSREPORT_SUCCESS,
      directorsShareholdingsReport,
    };
  }
  function failure(error) {
    setIsDirectorsLoaded(false);
    return { type: userConstants.DIRECTORSSHAREHOLDINGSREPORT_FAILURE, error };
  }
}

function getOfficerDetailsReport(officerCode) {
  return (dispatch) => {
    dispatch(request());
    userService.getOfficerDetailsReport(officerCode).then(
      (officerDetails) => {
        dispatch(success(officerDetails));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.OFFICERDETAILSREPORT_REQUEST };
  }
  function success(officerDetails) {
    return { type: userConstants.OFFICERDETAILSREPORT_SUCCESS, officerDetails };
  }
  function failure(error) {
    return { type: userConstants.OFFICERDETAILSREPORT_FAILURE, error };
  }
}

function getShareHolderDetailsReport(code, holderType) {
  return (dispatch) => {
    dispatch(request());
    userService.getShareHolderDetailsReport(code, holderType).then(
      (shareHolderDetails) => {
        dispatch(success(shareHolderDetails));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.SHAREHOLDERDETAILSREPORT_REQUEST };
  }
  function success(shareHolderDetails) {
    return {
      type: userConstants.SHAREHOLDERDETAILSREPORT_SUCCESS,
      shareHolderDetails,
    };
  }
  function failure(error) {
    return { type: userConstants.SHAREHOLDERDETAILSREPORT_FAILURE, error };
  }
}

function getDocumentHistoryDocReport(rN, formType, docNo, setIsDocLoaded) {
  return (dispatch) => {
    dispatch(request());

    userService
      .getDocumentHistoryDocReport(rN, formType, docNo, setIsDocLoaded)
      .then(
        (documentHistoryDocDetails) => {
          dispatch(success(documentHistoryDocDetails));
        },
        (errorDocumentHistory) => {
          dispatch(
            failure(errorDocumentHistory && errorDocumentHistory.toString())
          );
        }
      );
  };

  function request() {
    setIsDocLoaded(true);
    return { type: userConstants.SHAREHOLDERDETAILSREPORT_REQUEST };
  }
  function success(documentHistoryDocDetails) {
    setIsDocLoaded(false);
    return {
      type: userConstants.DOCUMENTHISTORYDOCREPORT_SUCCESS,
      documentHistoryDocDetails,
    };
  }
  function failure(errorDocumentHistory) {
    setIsDocLoaded(false);
    return {
      type: userConstants.DOCUMENTHISTORYDOCREPORT_FAILURE,
      errorDocumentHistory,
    };
  }
}

function getDocumentHistoryAsicReport(asicDocNo) {
  return (dispatch) => {
    dispatch(request());
    userService.getDocumentHistoryAsicReport(asicDocNo).then(
      (documentHistoryAsicDocDetails) => {
        dispatch(success(documentHistoryAsicDocDetails));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.DOCUMENTHISTORYASICREPORT_REQUEST };
  }
  function success(documentHistoryAsicDocDetails) {
    return {
      type: userConstants.DOCUMENTHISTORYASICREPORT_SUCCESS,
      documentHistoryAsicDocDetails,
    };
  }

  function failure(errorAsicDocumentHistory) {
    return {
      type: userConstants.DOCUMENTHISTORYASICREPORT_FAILURE,
      errorAsicDocumentHistory,
    };
  }
}

function getAsicDocumentTracking(code) {
  return (dispatch) => {
    dispatch(request());
    userService.getAsicDocumentTracking(code).then(
      (asicDocumentTracking) => {
        dispatch(success(asicDocumentTracking));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.ASICDOCUMENTTRACKING_REQUEST };
  }
  function success(asicDocumentTracking) {
    return {
      type: userConstants.ASICDOCUMENTTRACKING_SUCCESS,
      asicDocumentTracking,
    };
  }
  function failure(error) {
    return { type: userConstants.ASICDOCUMENTTRACKING_FAILURE, error };
  }
}

function getIndividualsEntities(companyCode) {
  return (dispatch) => {
    dispatch(request());
    userService.getIndividualsEntities(companyCode).then(
      (individualEntities) => {
        dispatch(success(individualEntities));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.INDIVIDUALENTITIES_REQUEST };
  }
  function success(individualEntities) {
    return {
      type: userConstants.INDIVIDUALENTITIES_SUCCESS,
      individualEntities,
    };
  }
  function failure(error) {
    return { type: userConstants.INDIVIDUALENTITIES_FAILURE, error };
  }
}

function getAllIndividualsEntities() {
  return (dispatch) => {
    dispatch(request());
    userService.getAllIndividualsEntities().then(
      (individualEntities) => {
        dispatch(success(individualEntities));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.INDIVIDUALENTITIES_REQUEST };
  }
  function success(individualEntities) {
    return {
      type: userConstants.INDIVIDUALENTITIES_SUCCESS,
      individualEntities,
    };
  }
  function failure(error) {
    return { type: userConstants.INDIVIDUALENTITIES_FAILURE, error };
  }
}

function getAllIndividualsEntitiesWithPagination(search,sortBy,sortOrder,countPerPage,pageNo) {
  return (dispatch) => {
    dispatch(request());
    userService.getAllIndividualsEntitiesWithPagination(search,sortBy,sortOrder,countPerPage,pageNo).then(
      (individualEntitiesWithPagination) => {
        dispatch(success(individualEntitiesWithPagination));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.INDIVIDUALENTITIESWITHPAGINATION_REQUEST };
  }
  function success(individualEntitiesWithPagination) {
    return {
      type: userConstants.INDIVIDUALENTITIESWITHPAGINATION_SUCCESS,
      individualEntitiesWithPagination,
    };
  }
  function failure(error) {
    return { type: userConstants.INDIVIDUALENTITIESWITHPAGINATION_FAILURE, error };
  }
}

function getJointHolders(Code) {
  return (dispatch) => {
    dispatch(request());
    userService.getJointHolders(Code).then(
      (jointHolders) => {
        dispatch(success(jointHolders));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.JOINTHOLDERS_REQUEST };
  }
  function success(jointHolders) {
    return { type: userConstants.JOINTHOLDERS_SUCCESS, jointHolders };
  }
  function failure(error) {
    return { type: userConstants.JOINTHOLDERS_FAILURE, error };
  }
}

function getAllJointHolders() {
  return (dispatch) => {
    dispatch(request());
    userService.getAllJointHolders().then(
      (jointHolders) => {
        dispatch(success(jointHolders));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.JOINTHOLDERS_REQUEST };
  }
  function success(jointHolders) {
    return { type: userConstants.JOINTHOLDERS_SUCCESS, jointHolders };
  }
  function failure(error) {
    return { type: userConstants.JOINTHOLDERS_FAILURE, error };
  }
}

function getBeneficialOwner(Code) {
  return (dispatch) => {
    dispatch(request());
    userService.getBeneficialOwner(Code).then(
      (beneficialOwner) => {
        dispatch(success(beneficialOwner));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.BENEFICIALOWNER_REQUEST };
  }
  function success(beneficialOwner) {
    return { type: userConstants.BENEFICIALOWNER_SUCCESS, beneficialOwner };
  }
  function failure(error) {
    return { type: userConstants.BENEFICIALOWNER_FAILURE, error };
  }
}

function getAllBeneficialOwner() {
  return (dispatch) => {
    dispatch(request());
    userService.getAllBeneficialOwner().then(
      (beneficialOwner) => {
        dispatch(success(beneficialOwner));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.BENEFICIALOWNER_REQUEST };
  }
  function success(beneficialOwner) {
    return { type: userConstants.BENEFICIALOWNER_SUCCESS, beneficialOwner };
  }
  function failure(error) {
    return { type: userConstants.BENEFICIALOWNER_FAILURE, error };
  }
}

function getCompanyReport(printCompany, companyCode, setIsCompanyLoaded) {
  return (dispatch) => {
    dispatch(request());
    userService.getCompanyReport(printCompany, companyCode).then(
      (companyReport) => {
        dispatch(success(companyReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsCompanyLoaded(true);
    return { type: userConstants.COMPANYREPORT_REQUEST };
  }
  function success(companyReport) {
    setIsCompanyLoaded(false);
    return {
      type: userConstants.COMPANYREPORT_SUCCESS,
      companyReport,
    };
  }
  function failure(error) {
    setIsCompanyLoaded(false);
    return { type: userConstants.COMPANYREPORT_FAILURE, error };
  }
}
function getQuickCompanyProfileReport(companyCode, setIsQuickCompanyLoaded) {
  return (dispatch) => {
    dispatch(request());
    userService.getQuickCompanyProfileReport(companyCode).then(
      (quickCompanyProfileReport) => {
        dispatch(success(quickCompanyProfileReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsQuickCompanyLoaded(true);
    return { type: userConstants.QUICKCOMPANYPROFILEREPORT_REQUEST };
  }
  function success(quickCompanyProfileReport) {
    setIsQuickCompanyLoaded(false);
    return {
      type: userConstants.QUICKCOMPANYPROFILEREPORT_SUCCESS,
      quickCompanyProfileReport,
    };
  }
  function failure(error) {
    setIsQuickCompanyLoaded(false);
    return { type: userConstants.QUICKCOMPANYPROFILEREPORT_FAILURE, error };
  }
}

function getAsicCompanyDetailReport(
  asicCompanyDetail,
  companyCode,
  setIsASICCompanyLoaded
) {
  return (dispatch) => {
    dispatch(request());
    userService.getAsicCompanyDetailReport(asicCompanyDetail, companyCode).then(
      (asicCompanyDetailReport) => {
        dispatch(success(asicCompanyDetailReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsASICCompanyLoaded(true);
    return { type: userConstants.ASICCOMPANYDETAILREPORT_REQUEST };
  }
  function success(asicCompanyDetailReport) {
    setIsASICCompanyLoaded(false);
    return {
      type: userConstants.ASICCOMPANYDETAILREPORT_SUCCESS,
      asicCompanyDetailReport,
    };
  }
  function failure(error) {
    setIsASICCompanyLoaded(false);
    return { type: userConstants.ASICCOMPANYDETAILREPORT_FAILURE, error };
  }
}

function isUnitTrustEmpty() {
  return userService.isUnitTrustEmpty();
}

function getOrderMonitorStatement() {
  return (dispatch) => {
    dispatch(request());
    userService.getOrderMonitorStatement().then(
      (orderMonitorStatement) => {
        dispatch(success(orderMonitorStatement));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.ORDERMONITORSTATEMENT_REQUEST };
  }
  function success(orderMonitorStatement) {
    return {
      type: userConstants.ORDERMONITORSTATEMENT_SUCCESS,
      orderMonitorStatement,
    };
  }
  function failure(error) {
    return { type: userConstants.ORDERMONITORSTATEMENT_FAILURE, error };
  }
}

function getDigitalSignatureStatement() {
  return (dispatch) => {
    dispatch(request());
    userService.getDigitalSignatureStatement().then(
      (digitalSignatureStatement) => {
        dispatch(success(digitalSignatureStatement));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.DIGITALSIGNATURESTATEMENT_REQUEST };
  }
  function success(digitalSignatureStatement) {
    return {
      type: userConstants.DIGITALSIGNATURESTATEMENT_SUCCESS,
      digitalSignatureStatement,
    };
  }
  function failure(error) {
    return { type: userConstants.DIGITALSIGNATURESTATEMENT_FAILURE, error };
  }
}

function getAsicDebtReport(ACN, setIsASICDeptLoaded) {
  return (dispatch) => {
    dispatch(request());
    userService.getAsicDebtReport(ACN).then(
      (asicDebtReport) => {
        dispatch(success(asicDebtReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsASICDeptLoaded(true);
    return { type: userConstants.ASICDEBTREPORT_REQUEST };
  }
  function success(asicDebtReport) {
    setIsASICDeptLoaded(false);
    return {
      type: userConstants.ASICDEBTREPORT_SUCCESS,
      asicDebtReport,
    };
  }
  function failure(error) {
    setIsASICDeptLoaded(false);
    return { type: userConstants.ASICDEBTREPORT_FAILURE, error };
  }
}

function getElectronicLodegementService() {
  return (dispatch) => {
    dispatch(request());
    userService.getElectronicLodegementService().then(
      (electronicLodgementService) => {
        dispatch(success(electronicLodgementService));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.ELECTRONICLODGEMENTSERVICE_REQUEST };
  }
  function success(electronicLodgementService) {
    return {
      type: userConstants.ELECTRONICLODGEMENTSERVICE_SUCCESS,
      electronicLodgementService,
    };
  }
  function failure(error) {
    return { type: userConstants.ELECTRONICLODGEMENTSERVICE_FAILURE, error };
  }
}

function getCompaniesDebtReport(details, setIsViewCompanyReportLoaded) {
  return (dispatch) => {
    dispatch(request());
    userService.getCompaniesDebtReport(details).then(
      (companiesDebtReport) => {
        dispatch(success(companiesDebtReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsViewCompanyReportLoaded(true);
    return { type: userConstants.COMPANIESDEBTREPORT_REQUEST };
  }
  function success(companiesDebtReport) {
    setIsViewCompanyReportLoaded(false);
    return {
      type: userConstants.COMPANIESDEBTREPORT_SUCCESS,
      companiesDebtReport,
    };
  }
  function failure(error) {
    setIsViewCompanyReportLoaded(false);
    return { type: userConstants.COMPANIESDEBTREPORT_FAILURE, error };
  }
}

function getIndividualElectronicLodegementService(companyCode) {
  return (dispatch) => {
    dispatch(request());
    userService.getIndividualElectronicLodegementService(companyCode).then(
      (individualElectronicLodgementService) => {
        dispatch(success(individualElectronicLodgementService));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.INDIVIDUALELECTRONICLODGEMENTSERVICE_REQUEST };
  }
  function success(individualElectronicLodgementService) {
    return {
      type: userConstants.INDIVIDUALELECTRONICLODGEMENTSERVICE_SUCCESS,
      individualElectronicLodgementService,
    };
  }
  function failure(error) {
    return {
      type: userConstants.INDIVIDUALELECTRONICLODGEMENTSERVICE_FAILURE,
      error,
    };
  }
}

function getCompaniesDetailList() {
  return (dispatch) => {
    dispatch(request());
    userService.getCompaniesDetailList().then(
      (companiesDetailList) => {
        dispatch(success(companiesDetailList));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.COMPANIESDETAILLIST_REQUEST };
  }
  function success(companiesDetailList) {
    return {
      type: userConstants.COMPANIESDETAILLIST_SUCCESS,
      companiesDetailList,
    };
  }
  function failure(error) {
    return { type: userConstants.COMPANIESDETAILLIST_FAILURE, error };
  }
}

function getFormsHistoryReport(Code, setIsViewFormHistoryLoaded) {
  return (dispatch) => {
    dispatch(request());
    userService.getFormsHistoryReport(Code).then(
      (formsHistoryReport) => {
        dispatch(success(formsHistoryReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsViewFormHistoryLoaded(true);
    return { type: userConstants.FORMSHISTORYREPORT_REQUEST };
  }
  function success(formsHistoryReport) {
    setIsViewFormHistoryLoaded(false);
    return {
      type: userConstants.FORMSHISTORYREPORT_SUCCESS,
      formsHistoryReport,
    };
  }
  function failure(error) {
    setIsViewFormHistoryLoaded(false);
    return { type: userConstants.FORMSHISTORYREPORT_FAILURE, error };
  }
}

function getDirectorIdReport(didList,setIsLoaded,typedoc) {
  return (dispatch) => {
    dispatch(request());
    userService.getDirectorIdReport(didList,typedoc).then(
      (directorIdReport) => {
        dispatch(success(directorIdReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsLoaded(true);
    return { type: userConstants.DIRECTORIDREPORT_REQUEST };
  }
  function success(directorIdReport) {
    setIsLoaded(false);
    return {
      type: userConstants.DIRECTORIDREPORT_SUCCESS,
      directorIdReport,
    };
  }
  function failure(error) {
    setIsLoaded(false);
    return { type: userConstants.DIRECTORIDREPORT_FAILURE, error };
  }
}

function getAsicInvoiceReport(
  companyCode,
  companyName,
  setIsASICInvoiceLoaded
) {
  return (dispatch) => {
    dispatch(request());
    userService.getAsicInvoiceReport(companyCode, companyName).then(
      (asicInvoiceReport) => {
        dispatch(success(asicInvoiceReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsASICInvoiceLoaded(true);
    return { type: userConstants.ASICINVOICEREPORT_REQUEST };
  }
  function success(asicInvoiceReport) {
    setIsASICInvoiceLoaded(false);
    return {
      type: userConstants.ASICINVOICEREPORT_SUCCESS,
      asicInvoiceReport,
    };
  }

  function failure(errorAsicInvoiceReport) {
    setIsASICInvoiceLoaded(false);
    return {
      type: userConstants.ASICINVOICEREPORT_FAILURE,
      errorAsicInvoiceReport,
    };
  }
}

function getAsicInvoiceReportLinks(companyCode) {
  return (dispatch) => {
    dispatch(request());
    userService.getAsicInvoiceReportLinks(companyCode).then(
      (asicInvoiceReportLinks) => {
        dispatch(success(asicInvoiceReportLinks));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.ASICINVOICEREPORTLINK_REQUEST };
  }
  function success(asicInvoiceReportLinks) {
    return {
      type: userConstants.ASICINVOICEREPORTLINK_SUCCESS,
      asicInvoiceReportLinks,
    };
  }

  function failure(errorAsicInvoiceReportLink) {
    return {
      type: userConstants.ASICINVOICEREPORTLINK_FAILURE,
      errorAsicInvoiceReportLink,
    };
  }
}

function getViewCurrentAnnualStatementReport(
  companyCode,
  companyName,
  setIsViewCurrentAnnualStatementLoaded
) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getViewCurrentAnnualStatementReport(companyCode, companyName)
      .then(
        (viewCurrentAnnualStatementReport) => {
          dispatch(success(viewCurrentAnnualStatementReport));
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
  };
  function request() {
    setIsViewCurrentAnnualStatementLoaded(true);
    return { type: userConstants.VIEWCURRENTANNUALSTATEMENTREPORT_REQUEST };
  }
  function success(viewCurrentAnnualStatementReport) {
    setIsViewCurrentAnnualStatementLoaded(false);
    return {
      type: userConstants.VIEWCURRENTANNUALSTATEMENTREPORT_SUCCESS,
      viewCurrentAnnualStatementReport,
    };
  }

  function failure(errorViewCurrentAnnualStatement) {
    setIsViewCurrentAnnualStatementLoaded(false);
    return {
      type: userConstants.VIEWCURRENTANNUALSTATEMENTREPORT_FAILURE,
      errorViewCurrentAnnualStatement,
    };
  }
}

function getUnitTrustUnitholdersReport(unitTrustRN) {
  return (dispatch) => {
    dispatch(request());
    userService.getUnitTrustUnitholdersReport(unitTrustRN).then(
      (unitTrustUnitholdersReport) => {
        dispatch(success(unitTrustUnitholdersReport));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.UNITTRUSTUNITHOLDERSREPORT_REQUEST };
  }
  function success(unitTrustUnitholdersReport) {
    return {
      type: userConstants.UNITTRUSTUNITHOLDERSREPORT_SUCCESS,
      unitTrustUnitholdersReport,
    };
  }
  function failure(error) {
    return { type: userConstants.UNITTRUSTUNITHOLDERSREPORT_FAILURE, error };
  }
}

function getUnitTrustApplicationAllotmentJournalReport(unitTrustRN) {
  return (dispatch) => {
    dispatch(request());
    userService.getUnitTrustApplicationAllotmentJournalReport(unitTrustRN).then(
      (unitTrustApplicationAllotmentJournalReport) => {
        dispatch(success(unitTrustApplicationAllotmentJournalReport));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: userConstants.UNITTRUSTAPPLICATIONALLOTMENTJOURNALREPORT_REQUEST,
    };
  }
  function success(unitTrustApplicationAllotmentJournalReport) {
    return {
      type: userConstants.UNITTRUSTAPPLICATIONALLOTMENTJOURNALREPORT_SUCCESS,
      unitTrustApplicationAllotmentJournalReport,
    };
  }
  function failure(error) {
    return {
      type: userConstants.UNITTRUSTAPPLICATIONALLOTMENTJOURNALREPORT_FAILURE,
      error,
    };
  }
}

function getUnitTrustTransferJournalReport(unitTrustRN) {
  return (dispatch) => {
    dispatch(request());
    userService.getUnitTrustTransferJournalReport(unitTrustRN).then(
      (unitTrustTransferJournalReport) => {
        dispatch(success(unitTrustTransferJournalReport));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.UNITTRUSTTRANSFERJOURNALREPORT_REQUEST };
  }
  function success(unitTrustTransferJournalReport) {
    return {
      type: userConstants.UNITTRUSTTRANSFERJOURNALREPORT_SUCCESS,
      unitTrustTransferJournalReport,
    };
  }
  function failure(error) {
    return {
      type: userConstants.UNITTRUSTTRANSFERJOURNALREPORT_FAILURE,
      error,
    };
  }
}

function getUnitTrustUnitholdersSummaryReport(unitTrustRN) {
  return (dispatch) => {
    dispatch(request());
    userService.getUnitTrustUnitholdersSummaryReport(unitTrustRN).then(
      (unitTrustUnitholdersSummaryReport) => {
        dispatch(success(unitTrustUnitholdersSummaryReport));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.UNITTRUSTUNITHOLDERSSUMMARYREPORT_REQUEST };
  }
  function success(unitTrustUnitholdersSummaryReport) {
    return {
      type: userConstants.UNITTRUSTUNITHOLDERSSUMMARYREPORT_SUCCESS,
      unitTrustUnitholdersSummaryReport,
    };
  }
  function failure(error) {
    return {
      type: userConstants.UNITTRUSTUNITHOLDERSSUMMARYREPORT_FAILURE,
      error,
    };
  }
}

function postCompanyPhoneNumber(RN, mobileNumber) {
  return (dispatch) => {
    dispatch(request());
    userService.postCompanyPhoneNumber(RN, mobileNumber).then(
      (CompanyPhoneNumberUpdate) => {
        dispatch(success(CompanyPhoneNumberUpdate));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.COMPANYPHONENUMBERUPDATE_REQUEST };
  }
  function success(CompanyPhoneNumberUpdate) {
    return {
      type: userConstants.COMPANYPHONENUMBERUPDATE_SUCCESS,
      CompanyPhoneNumberUpdate,
    };
  }
  function failure(error) {
    return { type: userConstants.COMPANYPHONENUMBERUPDATE_FAILURE, error };
  }
}

function postCompanyEmailID(RN, emailId) {
  return (dispatch) => {
    dispatch(request());
    userService.postCompanyEmailID(RN, emailId).then(
      (CompanyEmailIdUpdate) => {
        dispatch(success(CompanyEmailIdUpdate));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.COMPANYEMAILIDUPDATE_REQUEST };
  }
  function success(CompanyEmailIdUpdate) {
    return {
      type: userConstants.COMPANYEMAILIDUPDATE_SUCCESS,
      CompanyEmailIdUpdate,
    };
  }
  function failure(error) {
    return { type: userConstants.COMPANYEMAILIDUPDATE_FAILURE, error };
  }
}

function postIndividualPhoneNumber(RN, mobileNumber) {
  return (dispatch) => {
    dispatch(request());
    userService.postIndividualPhoneNumber(RN, mobileNumber).then(
      (individualPhoneNumberUpdate) => {
        dispatch(success(individualPhoneNumberUpdate));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.INDIVIDUALPHONENUMBERUPDATE_REQUEST };
  }
  function success(individualPhoneNumberUpdate) {
    return {
      type: userConstants.INDIVIDUALPHONENUMBERUPDATE_SUCCESS,
      individualPhoneNumberUpdate,
    };
  }
  function failure(error) {
    return { type: userConstants.INDIVIDUALPHONENUMBERUPDATE_FAILURE, error };
  }
}

function postIndividualEmailID(RN, emailId,detail) {
  return (dispatch) => {
    dispatch(request());
    userService.postIndividualEmailID(RN, emailId,detail).then(
      (individualEmailIdUpdate) => {
        dispatch(success(individualEmailIdUpdate));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.INDIVIDUALEMAILIDUPDATE_REQUEST };
  }
  function success(individualEmailIdUpdate) {
    return {
      type: userConstants.INDIVIDUALEMAILIDUPDATE_SUCCESS,
      individualEmailIdUpdate,
    };
  }
  function failure(error) {
    return { type: userConstants.INDIVIDUALEMAILIDUPDATE_FAILURE, error };
  }
}

function postForgetpasswordEmail(userName) {
  return (dispatch) => {
    dispatch(request());
    userService.postForgetpasswordEmail(userName).then(
      (forgetPasswordEmail) => {
        dispatch(success(forgetPasswordEmail));
      },
      (errorForgetPasswordEmail) => {
        dispatch(failure(errorForgetPasswordEmail.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.FORGETPASSWORDEMAIL_REQUEST };
  }
  function success(forgetPasswordEmail) {
    return {
      type: userConstants.FORGETPASSWORDEMAIL_SUCCESS,
      forgetPasswordEmail,
    };
  }
  function failure(errorForgetPasswordEmail) {
    return {
      type: userConstants.FORGETPASSWORDEMAIL_FAILURE,
      errorForgetPasswordEmail,
    };
  }
}

function postResetPassword(userName, passWord, confirmPassword, rCode) {
  return (dispatch) => {
    dispatch(request());
    userService
      .postResetPassword(userName, passWord, confirmPassword, rCode)
      .then(
        (resetPassword) => {
          dispatch(success(resetPassword));
        },
        (errorResetPassword) => {
          dispatch(failure(errorResetPassword.toString()));
        }
      );
  };

  function request() {
    return { type: userConstants.RESETPASSWORD_REQUEST };
  }
  function success(resetPassword) {
    return {
      type: userConstants.RESETPASSWORD_SUCCESS,
      resetPassword,
    };
  }
  function failure(errorResetPassword) {
    return { type: userConstants.RESETPASSWORD_FAILURE, errorResetPassword };
  }
}

function getDiv7ALoanReport(companyCode, reportParam, setIsDiv7AReportLoaded) {
  return (dispatch) => {
    dispatch(request());
    userService.getDiv7ALoanReport(companyCode, reportParam).then(
      (div7ALoanReport) => {
        dispatch(success(div7ALoanReport));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    setIsDiv7AReportLoaded(true);
    return { type: userConstants.DIV7ALOANREPORT_REQUEST };
  }
  function success(div7ALoanReport) {
    setIsDiv7AReportLoaded(false);
    return {
      type: userConstants.DIV7ALOANREPORT_SUCCESS,
      div7ALoanReport,
    };
  }

  function failure(errorDiv7ALoanReport) {
    setIsDiv7AReportLoaded(false);
    return {
      type: userConstants.DIV7ALOANREPORT_FAILURE,
      errorDiv7ALoanReport,
    };
  }
}

function getForm484Information(rN, docNo, setIsForm484Loaded) {
  return (dispatch) => {
    dispatch(request());
    userService.getForm484Information(rN, docNo).then(
      (form484Information) => {
        dispatch(success(form484Information));
      },
      (errorForm484Information) => {
        dispatch(failure(errorForm484Information.toString()));
      }
    );
  };

  function request() {
    setIsForm484Loaded(true);
    return { type: userConstants.FORM484INFORMATION_REQUEST };
  }
  function success(form484Information) {
    setIsForm484Loaded(false);
    return {
      type: userConstants.FORM484INFORMATION_SUCCESS,
      form484Information,
    };
  }
  function failure(errorForm484Information) {
    setIsForm484Loaded(false);
    return {
      type: userConstants.FORM484INFORMATION_FAILURE,
      errorForm484Information,
    };
  }
}

function getRegdInformation(ACN) {
  return (dispatch) => {
    dispatch(request());
    userService.getRegdInformation(ACN).then(
      (regdInformation) => {
        dispatch(success(regdInformation));
      },
      (errorRegdInformation) => {
        dispatch(failure(errorRegdInformation.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.REGDINFORMATION_REQUEST };
  }
  function success(regdInformation) {
    return {
      type: userConstants.REGDINFORMATION_SUCCESS,
      regdInformation,
    };
  }
  function failure(errorForm484Information) {
    return {
      type: userConstants.REGDINFORMATION_FAILURE,
      errorForm484Information,
    };
  }
}

function getIndividualEntitiesProfileReport(Code, setIsProfileReportLoaded) {
  return (dispatch) => {
    dispatch(request());
    userService.getIndividualEntitiesProfileReport(Code).then(
      (individualEntitiesProfileReport) => {
        dispatch(success(individualEntitiesProfileReport));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    setIsProfileReportLoaded(true);
    return { type: userConstants.INDIVDUALENTITIESPROFILEREPORT_REQUEST };
  }
  function success(individualEntitiesProfileReport) {
    setIsProfileReportLoaded(false);
    return {
      type: userConstants.INDIVDUALENTITIESPROFILEREPORT_SUCCESS,
      individualEntitiesProfileReport,
    };
  }
  function failure(error) {
    setIsProfileReportLoaded(false);
    return {
      type: userConstants.INDIVDUALENTITIESPROFILEREPORT_FAILURE,
      error,
    };
  }
}

function getIndividualEntitiesOffices(Code) {
  return (dispatch) => {
    dispatch(request());
    userService.getIndividualEntitiesOffices(Code).then(
      (individualEntitiesOffices) => {
        dispatch(success(individualEntitiesOffices));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.INDIVIDUALENTITIESOFFICES_REQUEST };
  }
  function success(individualEntitiesOffices) {
    return {
      type: userConstants.INDIVIDUALENTITIESOFFICES_SUCCESS,
      individualEntitiesOffices,
    };
  }
  function failure(error) {
    return { type: userConstants.INDIVIDUALENTITIESOFFICES_FAILURE, error };
  }
}

function getIndividualEntitiesShares(Code) {
  return (dispatch) => {
    dispatch(request());
    userService.getIndividualEntitiesShares(Code).then(
      (individualEntitiesShares) => {
        dispatch(success(individualEntitiesShares));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.INDIVDUALENTITIESSHARES_REQUEST };
  }
  function success(individualEntitiesShares) {
    return {
      type: userConstants.INDIVDUALENTITIESSHARES_SUCCESS,
      individualEntitiesShares,
    };
  }
  function failure(error) {
    return { type: userConstants.INDIVDUALENTITIESSHARES_FAILURE, error };
  }
}
function postIndividualEntitiesDid(RN, did) {
  return (dispatch) => {
    dispatch(request());
    userService.postIndividualEntitiesDid(RN, did).then(
      (didUpdate) => {
        dispatch(success(didUpdate));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.INDIVDUALENTITIESDID_REQUEST };
  }
  function success(didUpdate) {
    return {
      type: userConstants.INDIVDUALENTITIESDID_SUCCESS,
      didUpdate,
    };
  }
  function failure(errorDidUpdate) {
    return { type: userConstants.INDIVDUALENTITIESDID_FAILURE, errorDidUpdate };
  }
}

function postCompanyNotes(RN, notes) {
  return (dispatch) => {
    dispatch(request());
    userService.postCompanyNotes(RN, notes).then(
      (notesUpdate) => {
        dispatch(success(notesUpdate));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.COMPANYNOTES_REQUEST };
  }
  function success(notesUpdate) {
    return {
      type: userConstants.COMPANYNOTES_SUCCESS,
      notesUpdate,
    };
  }
  function failure(error) {
    return { type: userConstants.COMPANYNOTES_FAILURE, error };
  }
}

function getAllCountries() {
  return (dispatch) => {
    dispatch(request());
    userService.getAllCountries().then(
      (countries) => {
        dispatch(success(countries));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.COUNTRIESALL_REQUEST };
  }
  function success(countries) {
    return { type: userConstants.COUNTRIESALL_SUCCESS, countries };
  }
  function failure(error) {
    return { type: userConstants.COUNTRIESALL_FAILURE, error };
  }
}
function postSignup(firstName, lastName, phone, email, organisationName) {
  return (dispatch) => {
    dispatch(request());
    userService
      .postSignup(firstName, lastName, phone, email, organisationName)
      .then(
        (signUp) => {
          dispatch(success(signUp));
        },
        (errorSignup) => {
          dispatch(failure(errorSignup.toString()));
        }
      );
  };

  function request() {
    return { type: userConstants.SIGNUP_REQUEST };
  }
  function success(signUp) {
    return {
      type: userConstants.SIGNUP_SUCCESS,
      signUp,
    };
  }
  function failure(errorSignup) {
    return { type: userConstants.SIGNUP_FAILURE, errorSignup };
  }
}

function getUnitTrustTable(department) {
  return (dispatch) => {
    dispatch(request());
    userService.getUnitTrustTable(department).then(
      (unitTrustTable) => {
        dispatch(success(unitTrustTable));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.UNITTRUSTTABLE_REQUEST };
  }
  function success(unitTrustTable) {
    return { type: userConstants.UNITTRUSTTABLE_SUCCESS, unitTrustTable };
  }
  function failure(unitTrustTableError) {
    return { type: userConstants.UNITTRUSTTABLE_FAILURE, unitTrustTableError };
  }
}

function getLoggedUserInfo() {
  return (dispatch) => {
    dispatch(request());
    userService.getLoggedUserInfo().then(
      (loggedUserInfo) => {
        dispatch(success(loggedUserInfo));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.LOGINUSERINFO_REQUEST };
  }
  function success(loggedUserInfo) {
    return { type: userConstants.LOGINUSERINFO_SUCCESS, loggedUserInfo };
  }
  function failure(loggedUserInfoError) {
    return { type: userConstants.LOGINUSERINFO_FAILURE, loggedUserInfoError };
  }
}

function postChangeOrderRequest(changeOrder, setIsChangeOrder, setIsSuccess) {
  return (dispatch) => {
    dispatch(request());
    userService.postChangeOrderRequest(changeOrder).then(
      (changeOrderRequest) => {
        dispatch(success(changeOrderRequest));
      },
      (changeOrderError) => {
        dispatch(failure(changeOrderError.toString()));
      }
    );
  };

  function request() {
    setIsChangeOrder(true);
    setIsSuccess(false);
    return { type: userConstants.CHANGEORDER_REQUEST };
  }
  function success(changeOrderRequest) {
    setIsChangeOrder(false);
    setIsSuccess(true);
    return {
      type: userConstants.CHANGEORDER_SUCCESS,
      changeOrderRequest,
    };
  }
  function failure(changeOrderError) {
    setIsChangeOrder(false);
    setIsSuccess(false);
    return { type: userConstants.CHANGEORDER_FAILURE, changeOrderError };
  }
}

function getClassList(code) {
  return (dispatch) => {
    dispatch(request());
    userService.getClassList(code).then(
      (classListInfo) => {
        dispatch(success(classListInfo));
      },
      (classlistError) => {
        dispatch(failure(classlistError.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.CLASSLIST_REQUEST };
  }
  function success(classListInfo) {
    return { type: userConstants.CLASSLIST_SUCCESS, classListInfo };
  }
  function failure(classlistError) {
    return { type: userConstants.CLASSLIST_FAILURE, classlistError };
  }
}

function getCompanyListReport(companyList, companyCode, setIsCompanyList) {
  return (dispatch) => {
    dispatch(request());
    userService.getCompanyListReport(companyList, companyCode).then(
      (companyListReport) => {
        dispatch(success(companyListReport));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    setIsCompanyList(true);
    return { type: userConstants.COMPANYLISTSREPORT_REQUEST };
  }
  function success(companyListReport) {
    setIsCompanyList(false);
    return {
      type: userConstants.COMPANYLISTSREPORT_SUCCESS,
      companyListReport,
    };
  }
  function failure(error) {
    setIsCompanyList(false);
    return { type: userConstants.COMPANYLISTSREPORT_FAILURE, error };
  }
}
function postRecentCompanyList(userName, clientId, deptCode, ACN) {
  return (dispatch) => {
    dispatch(request());
    userService.postRecentCompanyList(userName, clientId, deptCode, ACN).then(
      (postRecentCompanyLIst) => {
        dispatch(success(postRecentCompanyLIst));
      },
      (errorPostRecentCompanyLIst) => {
        dispatch(failure(errorPostRecentCompanyLIst));
      }
    );
  };

  function request() {
    return { type: userConstants.POSTRECENTCOMPANYLIST_REQUEST };
  }
  function success(postRecentCompanyLIst) {
    return {
      type: userConstants.POSTRECENTCOMPANYLIST_SUCCESS,
      postRecentCompanyLIst,
    };
  }
  function failure(errorPostRecentCompanyLIst) {
    return {
      type: userConstants.POSTRECENTCOMPANYLIST_FAILURE,
      errorPostRecentCompanyLIst,
    };
  }
}

function getRecentCompanyList(userName, clientId, deptCode) {
  return (dispatch) => {
    dispatch(request());
    userService.getRecentCompanyList(clientId, userName, deptCode).then(
      (recentCompanyList) => {
        dispatch(success(recentCompanyList));
      },
      (getRecentCompanyListError) => {
        dispatch(failure(getRecentCompanyListError));
      }
    );
  };
  function request() {
    return { type: userConstants.GETRECENTCOMPANYLIST_REQUEST };
  }
  function success(recentCompanyList) {
    return {
      type: userConstants.GETRECENTCOMPANYLIST_SUCCESS,
      recentCompanyList,
    };
  }
  function failure(getRecentCompanyListError) {
    return {
      type: userConstants.GETRECENTCOMPANYLIST_FAILURE,
      getRecentCompanyListError,
    };
  }
}

function getEbinderPrimeFiles(Code) {
  return (dispatch) => {
    dispatch(request());
    userService.getEbinderPrimeFiles(Code).then(
      (ebinderPrimeFileList) => {
        dispatch(success(ebinderPrimeFileList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GETEBINDERPRIMEFILELIST_REQUEST };
  }
  function success(ebinderPrimeFileList) {
    return {
      type: userConstants.GETEBINDERPRIMEFILELIST_SUCCESS,
      ebinderPrimeFileList,
    };
  }
  function failure(error) {
    return { type: userConstants.GETEBINDERPRIMEFILELIST_FAILURE, error };
  }
}

function getManageEbinderList(Code) {
  return (dispatch) => {
    dispatch(request());
    userService.getManageEbinderList(Code).then(
      (manageEbinderList) => {
        dispatch(success(manageEbinderList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.MANAGEEBINDERLIST_REQUEST };
  }
  function success(manageEbinderList) {
    return { type: userConstants.MANAGEEBINDERLIST_SUCCESS, manageEbinderList };
  }
  function failure(error) {
    return { type: userConstants.MANAGEEBINDERLIST_FAILURE, error };
  }
}

function getEbinderLink(Code, binderId, setIsEbinderLinkLoaded) {
  return (dispatch) => {
    dispatch(request());
    userService.getEbinderLink(Code, binderId).then(
      (eBinderLink) => {
        dispatch(success(eBinderLink));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    setIsEbinderLinkLoaded(true);
    return { type: userConstants.EBINDERLINKS_REQUEST };
  }
  function success(eBinderLink) {
    setIsEbinderLinkLoaded(false);
    return { type: userConstants.EBINDERLINKS_SUCCESS, eBinderLink };
  }
  function failure(error) {
    setIsEbinderLinkLoaded(false);
    return { type: userConstants.EBINDERLINKS_FAILURE, error };
  }
}

function getEbinderCustomFileList(Code) {
  return (dispatch) => {
    dispatch(request());
    userService.getEbinderCustomFileList(Code).then(
      (ebinderCustomFileList) => {
        dispatch(success(ebinderCustomFileList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.EBINDERCUSTOMFILELIST_REQUEST };
  }
  function success(ebinderCustomFileList) {
    return {
      type: userConstants.EBINDERCUSTOMFILELIST_SUCCESS,
      ebinderCustomFileList,
    };
  }
  function failure(error) {
    return { type: userConstants.EBINDERCUSTOMFILELIST_FAILURE, error };
  }
}

function postEbinderCustomFileDelete(Code, fileName) {
  return (dispatch) => {
    dispatch(request());
    userService.postEbinderCustomFileDelete(Code, fileName).then(
      (ebinderCustomFileDelete) => {
        dispatch(success(ebinderCustomFileDelete));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.EBINDERCUSTOMFILEDELETE_REQUEST };
  }
  function success(ebinderCustomFileDelete) {
    return {
      type: userConstants.EBINDERCUSTOMFILEDELETE_SUCCESS,
      ebinderCustomFileDelete,
    };
  }
  function failure(error) {
    return { type: userConstants.EBINDERCUSTOMFILEDELETE_FAILURE, error };
  }
}

function postEbinderCustomFileAdd(Code, formdata, setErrors, setIsUploading) {
  return (dispatch) => {
    dispatch(request());
    userService.postEbinderCustomFileAdd(Code, formdata).then(
      (ebinderCustomFileAdd) => {
        dispatch(success(ebinderCustomFileAdd));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    setIsUploading(true);
    return { type: userConstants.EBINDERCUSTOMFILEADD_REQUEST };
  }
  function success(ebinderCustomFileAdd) {
    setIsUploading(false);
    setErrors(ebinderCustomFileAdd);
    return {
      type: userConstants.EBINDERCUSTOMFILEADD_SUCCESS,
      ebinderCustomFileAdd,
    };
  }
  function failure(error) {
    setIsUploading(false);
    return { type: userConstants.EBINDERCUSTOMFILEADD_FAILURE, error };
  }
}

function getEbinderCompanyList() {
  return (dispatch) => {
    dispatch(request());
    userService.getEbinderCompanyList().then(
      (ebinderCompanyList) => {
        dispatch(success(ebinderCompanyList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.EBINDERCOMPANYLIST_REQUEST };
  }
  function success(ebinderCompanyList) {
    return {
      type: userConstants.EBINDERCOMPANYLIST_SUCCESS,
      ebinderCompanyList,
    };
  }
  function failure(error) {
    return { type: userConstants.EBINDERCOMPANYLIST_FAILURE, error };
  }
}

function postCreateEbinder(
  Code,
  primeFileDocuments,
  companyRegister,
  customFileList,
  setIsCreateEBinderLoaded
) {
  return (dispatch) => {
    dispatch(request());
    userService
      .postCreateEbinder(
        Code,
        primeFileDocuments,
        companyRegister,
        customFileList
      )
      .then(
        (ebinderOrderRequest) => {
          dispatch(success(ebinderOrderRequest));
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
  };

  function request() {
    setIsCreateEBinderLoaded(true);
    return { type: userConstants.EBINDERORDER_REQUEST };
  }
  function success(ebinderOrderRequest) {
    setIsCreateEBinderLoaded(false);

    return {
      type: userConstants.EBINDERORDER_SUCCESS,
      ebinderOrderRequest,
    };
  }
  function failure(error) {
    setIsCreateEBinderLoaded(false);
    return { type: userConstants.EBINDERORDER_FAILURE, error };
  }
}

function postChangePassword(clientId, userId,oldPassword,passWord, confirmPassword) {
  return (dispatch) => {
    dispatch(request());
    userService
      .postChangePassword(clientId, userId,oldPassword,passWord, confirmPassword)
      .then(
        (changePassword) => {
          dispatch(success(changePassword));
        },
        (errorChangePassword) => {
          dispatch(failure(errorChangePassword.toString()));
        }
      );
  };

  function request() {
    return { type: userConstants.CHANGEPASSWORD_REQUEST };
  }
  function success(changePassword) {
    return {
      type: userConstants.CHANGEPASSWORD_SUCCESS,
      changePassword,
    };
  }
  function failure(errorChangePassword) {
    return { type: userConstants.CHANGEPASSWORD_FAILURE, errorChangePassword };
  }
}


function getBusinessNameDetailList() {
  return (dispatch) => {
    dispatch(request());
    userService.getBusinessNameDetailList().then(
      (businessNameDetailList) => {
        dispatch(success(businessNameDetailList));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.BUSINESSNAMEDETAILLIST_REQUEST };
  }
  function success(businessNameDetailList) {
    return {
      type: userConstants.BUSINESSNAMEDETAILLIST_SUCCESS,
      businessNameDetailList,
    };
  }
  function failure(error) {
    return { type: userConstants.BUSINESSNAMEDETAILLIST_FAILURE, error };
  }
}

function getBusinessWelcomeReport(reportParam) {
  return (dispatch) => {
    dispatch(request());
    userService.getBusinessWelcomeReport( reportParam).then(
      (businessNameWelcomeReport) => {
        dispatch(success(businessNameWelcomeReport));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
        return { type: userConstants.BUSINESSWELCOMEREPORT_REQUEST };
  }
  function success(businessNameWelcomeReport) {
      return {
      type: userConstants.BUSINESSWELCOMEREPORT_SUCCESS,
      businessNameWelcomeReport,
    };
  }

  function failure(error) {
  
    return {
      type: userConstants.BUSINESSWELCOMEREPORT_FAILURE,
      error,
    };
  }
}


function getBusinessCertificateReport(reportParam) {
  return (dispatch) => {
    dispatch(request());
    userService.getBusinessCertificateReport( reportParam).then(
      (businessCertificateReport) => {
        dispatch(success(businessCertificateReport));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
        return { type: userConstants.BUSINESSCERTIFICATEREPORT_REQUEST };
  }
  function success(businessCertificateReport) {
      return {
      type: userConstants.BUSINESSCERTIFICATEREPORT_SUCCESS,
      businessCertificateReport,
    };
  }

  function failure(error) {
  
    return {
      type: userConstants.BUSINESSCERTIFICATEREPORT_FAILURE,
      error,
    };
  }
}

function getBusinessNameSummary(rn) {
  return (dispatch) => {
    dispatch(request());
    userService.getBusinessNameSummary(rn).then(
      (businessNameSummary) => {
        dispatch(success(businessNameSummary));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.BUSINESSDETAILSUMMARY_REQUEST };
  }
  function success(businessNameSummary) {
    return {
      type: userConstants.BUSINESSDETAILSUMMARY_SUCCESS,
      businessNameSummary,
    };
  }
  function failure(error) {
    return { type: userConstants.BUSINESSDETAILSUMMARY_FAILURE, error };
  }
}


function getBusinessNameReport(reportParam) {
  return (dispatch) => {
    dispatch(request());
    userService.getBusinessNameReport( reportParam).then(
      (businessNameReport) => {
        dispatch(success(businessNameReport));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
        return { type: userConstants.BUSINESSNAMEREPORT_REQUEST };
  }
  function success(businessNameReport) {
      return {
      type: userConstants.BUSINESSNAMEREPORT_SUCCESS,
      businessNameReport,
    };
  }

  function failure(error) {
  
    return {
      type: userConstants.BUSINESSNAMEREPORT_FAILURE,
      error,
    };
  }
}


function postBusinessNotes(identifier, notes) {
  return (dispatch) => {
    dispatch(request());
    userService.postBusinessNotes(identifier, notes).then(
      (businessNotesUpdate) => {
        dispatch(success(businessNotesUpdate));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.POSTBUSINESSNOTES_REQUEST };
  }
  function success(businessNotesUpdate) {
    return {
      type: userConstants.POSTBUSINESSNOTES_SUCCESS,
      businessNotesUpdate,
    };
  }
  function failure(error) {
    return { type: userConstants.POSTBUSINESSNOTES_FAILURE, error };
  }
}

function getBusinessNotes(identifier) {
  return (dispatch) => {
    dispatch(request());
    userService.getBusinessNotes(identifier).then(
      (businessNotes) => {
        dispatch(success(businessNotes));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.GETBUSINESSNOTES_REQUEST };
  }
  function success(businessNotes) {
    return { type: userConstants.GETBUSINESSNOTES_SUCCESS, businessNotes };
  }
  function failure(error) {
    return { type: userConstants.GETBUSINESSNOTES_FAILURE, error };
  }
}

function getDirectorIdCount() {
  return (dispatch) => {
    dispatch(request());
    userService.getDirectorIdCount().then(
      (directorIdCount) => {
        dispatch(success(directorIdCount));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
 
    return { type: userConstants.DIRECTORIDCOUNT_REQUEST};
  }
  function success(directorIdCount) {
    return {
      type: userConstants.DIRECTORIDCOUNT_SUCCESS,
      directorIdCount,
    };
  }
  function failure(error) {
      return { type: userConstants.DIRECTORIDCOUNT_FAILURE, error };
  }
}

 
function getEdgeCompanyProfileReport(companyCode, setIsEdgeReportLoaded) {
  return (dispatch) => {
    dispatch(request());
    userService.getEdgeCompanyProfileReport(companyCode).then(
      (edgeCompanyProfileReport) => {
        dispatch(success(edgeCompanyProfileReport));
      },
      (error) => {
        dispatch(failure(error && error.toString()));
      }
    );
  };
  function request() {
    setIsEdgeReportLoaded(true);
    return { type: userConstants.EDGECOMPANYPROFILEREPORT_REQUEST };
  }
  function success(edgeCompanyProfileReport) {
    setIsEdgeReportLoaded(false);
    return {
      type: userConstants.EDGECOMPANYPROFILEREPORT_SUCCESS,
      edgeCompanyProfileReport,
    };
  }
  function failure(error) {
    setIsEdgeReportLoaded(false);
    return { type: userConstants.EDGECOMPANYPROFILEREPORT_FAILURE, error };
  }
}
