import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import moment from "moment";
import FullPageLoader from "../CommonPage/FullPageLoader";

const EBinderManagement = (props) => {
  //loading spinner
  const [isEbinderLinkLoaded, setIsEbinderLinkLoaded] = useState(false);
  const manageEbinderList = useSelector((state) => state.manageEbinderList);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.selectedCompanyCode) {
     
      dispatch(userActions.getManageEbinderList(props.selectedCompanyCode));
  
    }
    // eslint-disable-next-line
  }, []);

  const manageEbinderListFile =
    manageEbinderList && manageEbinderList.items ? manageEbinderList.items : [];
  const selectedSearchValue = localStorage.getItem("inputSearchValue");
  function eBinderHandleClick(e, binderId) {
    if (props.selectedCompanyCode) {
      dispatch(
        userActions.getEbinderLink(
          props.selectedCompanyCode,
          binderId,
          setIsEbinderLinkLoaded
        )
      );
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(
          userActions.getEbinderLink(
            props.selectedCompanyCode,
            binderId,
            setIsEbinderLinkLoaded
          )
        )
      );
    }
  }

  return (
    <div className="fs-6">
      <div className ="mt-5">
      {props.selectedCompanyCode && manageEbinderListFile && manageEbinderListFile.length === 0 && (    <h4>e-Binder Management</h4>)}

      {props.selectedCompanyCode && manageEbinderListFile && manageEbinderListFile.length === 0 && (<p>
          On this popup page you will find references and download links to
          previously created electronic binders.
        </p>
        )}
        <table>
          {props.selectedCompanyCode && manageEbinderListFile && manageEbinderListFile.length === 0 && (
            <p>No e-binders on record</p>
          )}
          {props.selectedCompanyCode && manageEbinderListFile && manageEbinderListFile.length > 0 && (
            <p>
              Showing {manageEbinderListFile && manageEbinderListFile.length}{" "}
              item(s) arranged by date descending.
            </p>
          )}
          {props.selectedCompanyCode && manageEbinderListFile &&
            manageEbinderListFile.map((post) => (
              <tr>
                <td>
                  <a
                    href={() => false}
                    target="_blank"
                    rel="noreferrer"
                    className="link-primary"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => eBinderHandleClick(e, post.Id)}
                  >
                    eBinder-pdf (
                    {post.CreatedAt
                      ? moment(post.CreatedAt).format("DD/MM/YYYY  , LTS")
                      : ""}{" "}
                    )
                  </a>
                </td>
              </tr>
            ))}{" "}
          {isEbinderLinkLoaded ? <FullPageLoader /> : null}
        </table>
      </div>
    </div>
  );
};
export default EBinderManagement;
