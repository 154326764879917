import { userConstants } from "../constants/login.constants";

 function regdInformation(state = {}, action) {
    switch (action.type) {
        case userConstants.REGDINFORMATION_REQUEST:
            return {
                loading: true
            };
        case userConstants.REGDINFORMATION_SUCCESS:
            return {
                items: action.regdInformation
            };
        case userConstants.REGDINFORMATION_FAILURE:
            return {
                error: action.errorRegdInformation
            };
        default:
            return state
    }
}
export default regdInformation;