import React, { useCallback } from "react";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";

const OfficeHoldersResignation = (props) => {
  const handleSearch = useCallback((call) => {
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container ">
      <form>
        <hr />
        <div className="form-group row  px-4">
          <div className="col-sm">
            <label
              htmlFor="resignationDate"
              className="col-form-label input-sm  "
            >
              Resignation Date
            </label>

            <DatePicker
              className="form-control input-sm w-25 "
              name="resignationDate"
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              /* selected={props.asicDetails.resignationDate}
              onChange={e=> props.handleChange(e, true, 'resignationDate')}

 value={props.asicDetails.resignationDate} */
              onChange={(e) => {
                const list = [...props.resignInputlist];
                list[props.resignIndex]["resignationDate"] = e;
                props.setResignInputlist(list);
                props.setAsicDetails((changeOrder) => ({
                  ...changeOrder,
                  resignInputlist: list,
                }));
              }}
              value={props.resignElements.resignationDate}
              selected={props.resignElements.resignationDate}
            />
          </div>
          <div className="col-xs-3">
            <label htmlFor="resignOfficerName" className="col-form-label">
              Officer Name{" "}
              <i
                className="bi bi-info-circle px-1"
                data-toggle="tooltip"
                data-placement="top"
                title="Full legal Name as per Passport"
              ></i>
            </label>

            <div className="col-sm-12 col-lg-7 TypeAheadDropDown  w-100 ">
              <Typeahead
                id="searchOfficer"
                labelKey={(option) =>
                  `${option.CEASED !== "" ? option.OFFICER_NAME : ""}`
                }
                options={
                  props.companyProfile && props.companyProfile.items
                    ? props.companyProfile.items.officers.filter(
                        (thing, index, self) =>
                          index ===
                          self.findIndex(
                            (t) => t.OFFICER_NAME === thing.OFFICER_NAME
                          )
                      )
                    : []
                }
                isLoading={false}
                onSearch={handleSearch}
                placeholder="Enter the Officer Name to Search"
                useCache={false}
                onChange={(selected) => {
                  const list = [...props.resignInputlist];
                  list[props.resignIndex]["resignOfficerName"] = selected;
                  list[props.resignIndex]["ceasedDirector"] =(selected.map(item=> item.POSITION_HELD === "DIRECTOR" ? true:false))
                  list[props.resignIndex]["ceasedsecretary"] =(selected.map(item=> item.POSITION_HELD === "SECRETARY" ? true:false))
                  list[props.resignIndex]["ceasedAltDirector"] =(selected.map(item=> item.POSITION_HELD === "ALTDIRECTOR" ? true:false))
                  list[props.resignIndex]["ceasedPublicOfficer"] =(selected.map(item=> item.POSITION_HELD === "PUBLICOFFICER" ? true:false))
                  props.setResignInputlist(list);
                  props.setAsicDetails((changeOrder) => ({
                    ...changeOrder,
                    resignInputlist: list,
                  }));
                 
                }}
                selected={props.resignElements.resignOfficerName}
              />
            </div>
          </div>
          <div className="col-xs-3 ">
            <label htmlFor="resignReason" className="col-form-label">
              Resign Reason
            </label>
            <input
              type="text"
              name="resignReason"
              id="resignReason"
              className="form-control "
              placeholder="Text..."
              /*value={props.asicDetails.resignReason} onChange={e=> props.handleChange(e, false, '')}*/
              value={props.resignElements.resignReason}
              onChange={(e) =>
                props.handleResignInputChange(e, props.resignIndex)
              }
              autoComplete="off"
            />
          </div>

          <div className="row px-4">
            <label
              className="form-check-label px-1 py-2 "
              for="gridCheckRolesCeasing"
            >
              Roles Ceasing
            </label>
            <div className="form-check ">
              <input
                type="checkbox"
                className="form-check-input px-2"
                id="gridCheckDirector"
                name="ceasedDirector"
                /*checked={props.asicDetails.ceasedDirector &&props.asicDetails.ceasedDirector[0]  }
                value={props.asicDetails.ceasedDirector}
                           
                onChange={e=> props.handleChange(e, false, '')}*/
                onChange={(e) => {
                  props.handleResignInputChange(e, props.resignIndex, true);
                }}
                checked={
                  props.resignElements.ceasedDirector &&
                  props.resignElements.ceasedDirector[0]
                }
                value={props.resignElements.ceasedDirector}
              />

              <label className="form-check-label" for="gridCheckDirector">
                Director
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="gridCheckSecretary"
                Name="ceasedsecretary"
                /*  value="ceasedsecretary"                
                checked={props.asicDetails.ceasedsecretary &&props.asicDetails.ceasedsecretary[0]  }
                onChange={e=> props.handleChange(e, false, '')}*/
                onChange={(e) => {
                  props.handleResignInputChange(e, props.resignIndex, true);
                }}
                checked={
                  props.resignElements.ceasedsecretary &&
                  props.resignElements.ceasedsecretary[0]
                }
                value={props.resignElements.ceasedsecretary}
              />
              <label className="form-check-label" for="gridCheckDirector">
                Secretary
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="gridCheckAltDirector"
                Name="ceasedAltDirector"
                /* value="ceasedAltDirector"
                checked={props.asicDetails.ceasedAltDirector &&props.asicDetails.ceasedAltDirector[0]  }
                onChange={e=> props.handleChange(e, false, '')}*/
                onChange={(e) => {
                  props.handleResignInputChange(e, props.resignIndex, true);
                }}
                checked={
                  props.resignElements.ceasedAltDirector &&
                  props.resignElements.ceasedAltDirector[0]
                }
                value={props.resignElements.ceasedAltDirector}
              />
              <label className="form-check-label" for="gridCheckAltDirector">
                Alt Director
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="gridCheckPublicOfficer"
                Name="ceasedPublicOfficer"
                /* value="ceasedPublicOfficer"
                checked={props.asicDetails.ceasedPublicOfficer &&props.asicDetails.ceasedPublicOfficer[0]  }
                onChange={e=> props.handleChange(e, false, '')} */
                onChange={(e) => {
                  props.handleResignInputChange(e, props.resignIndex, true);
                }}
                checked={
                  props.resignElements.ceasedPublicOfficer &&
                  props.resignElements.ceasedPublicOfficer[0]
                }
                value={props.resignElements.ceasedPublicOfficer}
              />
              <label className="form-check-label" for="gridCheckPublicOfficer">
                Public Officer
              </label>
            </div>{" "}
          </div>
        </div>
      </form>
    </div>
  );
};
export default OfficeHoldersResignation;
