import React, { useState,useCallback } from "react";
import DatePicker from "react-datepicker";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Typeahead } from "react-bootstrap-typeahead";


const OfficeHoldersChangeofAddressComponent = (props) => {


  const  shareHolderNames=  props.companyProfile &&  props.companyProfile.items &&  props.companyProfile.items.shareholders ? props.companyProfile.items.shareholders.map((value) =>  
  `${value.NAME}:  ${value.CLASS_CODE}:  ${value.CLASS_NO}:  FP=${value.FULLY_PAID ? "Yes" : "no"}:  BO=${value.BEN_OWNER ? "Yes" : "no"}: 
   Total Shares=${value.SUM_TOT_SHARES} :${value.BEN_OWNER ? "Beneficially Held" : ""}` ): "";

   const duplicatedOfficer=
   props.companyProfile &&
   props.companyProfile.items &&
   props.companyProfile.items.officers.filter(
     (thing, index, self) =>
       index === self.findIndex((t) => t.OFFICER_NAME === thing.OFFICER_NAME)
   );

    const  officerNames=   duplicatedOfficer&& duplicatedOfficer.map((value) =>     `${value.CEASED!=="" ? value.OFFICER_NAME:""}` );
  
  //const  officerNames=companyProfile && companyProfile.items &&  companyProfile.items.officers &&  companyProfile.items.officers.map((value) =>     `${value.CEASED!=="" ? value.OFFICER_NAME:""}` );



function handleBothNames()
{
if (officerNames && shareHolderNames) {
    setOfficerShareHoldersData((value) => ({ ...value, name: officerNames && shareHolderNames && officerNames.concat(shareHolderNames)} ));
} else {
  setOfficerShareHoldersData((value) => ({ ...value, name: []} ));
}
}
  
  const [officerShareHoldersData, setOfficerShareHoldersData] = useState({
    name:[]
  });
 
  const handleSearch = useCallback((call) => {
    // eslint-disable-next-line
  }, []);

  

  function handleReset(e) {
    props.setAsicDetails((value) => ({ ...value, officeHolderName: "" }));
  }

  
  return (
    <div className="container ">
      <hr />

      <form>
        <div className="form-group row px-4" >
          <div>
            <label
              htmlFor="officeHolderAddressChangeDate"
              className="col-form-label input-sm "
            >
              Change Date
              <i
                className="bi bi-info-circle px-1"
                data-toggle="tooltip"
                data-placement="top"
                title="ASIC late fees apply if form is not lodgged with in 28days of change date"
              ></i>
            </label>
            <DatePicker
              className="form-control input-sm w-25"
              name="officeHolderAddressChangeDate"
              selected={props.changeofAddressElements.ocaChangeDate}
              onChange={(e) =>{
                const list = [...props.osChangeofAddressInputlist];
                list[props.changeofAddressIndex]["ocaChangeDate"] = e;
                props.setOSChangeofAddressInputlist(list);
                props.setAsicDetails((changeOrder) => ({
                  ...changeOrder,
                  osChangeofAddressInputlist:list
                }));
              }
              }
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              value={props.changeofAddressElements.ocaChangeDate}
          
            />
          </div>
          <div className="col-xs-2">
            <br />
            <label htmlFor="changeFor" className="col-form-label  ">
              {" "}
              Change For
            </label>
            <br />
            <select
              name="changeFor"
              id="changeFor"
              className=" dropDownBox  w-25 px-2"
      
              onChange={(e) =>{ handleReset(e);props.handleChangeofAddressInputChange(e,props.changeofAddressIndex);
                handleBothNames();
               
              }
            }
            >
            <option value=""></option>
              <option value="Officer">Officer</option>
              <option value="Shareholder">Shareholder</option>
              <option value="both" >both</option>
            </select>
          </div>
          <div className="col-xs-3">
            <label
              htmlFor="officeHolderName"
              name="officeHolderName"
              id="officeHolderName"
              className="col-form-label"
            >
              Officer/Shareholder Name
            </label>
          </div>

          <div className="col-sm-12 col-lg-7 TypeAheadDropDown  w-100 ">
            {props.changeofAddressElements.changeFor &&
              props.changeofAddressElements.changeFor !== "" &&
              props.changeofAddressElements.changeFor === "Officer" &&  (
                <Typeahead
                  id="searchOfficer"
                
                  labelKey={(option) =>
                                   `${option.CEASED!=="" ? option.OFFICER_NAME:""}`
                                 
                  }
              
                  options={
                    props.companyProfile &&
                    props.companyProfile.items ?
                    props.companyProfile.items.officers.filter(
                      (thing, index, self) =>
                        index === self.findIndex((t) => t.OFFICER_NAME === thing.OFFICER_NAME)
                    ): []
                  }
                  isLoading={false}
                  onSearch={handleSearch}
                /*  onChange={(selected) => {
                    props.setAsicDetails((value) => ({ ...value, officeHolderName: selected }));
                    console.log("selectedselected officer xxxxxxxxxx",selected)
                  }}                  
                  selected={props.asicDetails.officeHolderName} */

                  onChange={(selected) => {                          
                
                   
                    const list = [...props.osChangeofAddressInputlist];
                    list[props.changeofAddressIndex]["officeHolderName"] = selected;
                    props.setOSChangeofAddressInputlist(list);
                    props.setAsicDetails((changeOrder) => ({
                      ...changeOrder,
                      osChangeofAddressInputlist:list
                    }));
              
                
              }}
                  selected={props.changeofAddressElements.officeHolderName}
                  placeholder="Enter the Officer Name to Search"
                  useCache={false}
               
                />
              )}
            
            {props.changeofAddressElements.changeFor &&
              props.changeofAddressElements.changeFor !== "" &&
              props.changeofAddressElements.changeFor === "Shareholder" && (
                <Typeahead
                className="w-100"
                  id="searchShareholder"
                  labelKey={(option) =>
                    `${option.NAME}:  ${option.CLASS_CODE}:  ${option.CLASS_NO}:  FP=${option.FULLY_PAID ? "Yes" : "no"}:  BO=${option.BEN_OWNER ? "Yes" : "no"}
                    :  Total Shares=${option.SUM_TOT_SHARES}: ${option.BEN_OWNER ? "Beneficially Held" : ""}`
                  }
                
                  options={
                    props.companyProfile &&
                    props.companyProfile.items ?
                    props.companyProfile.items.shareholders : []
                  }
                  isLoading={false}
                  onSearch={handleSearch}
                 /* onChange={(selected) => {
                    props.setAsicDetails((value) => ({ ...value, officeHolderName: selected }));
                    console.log("selectedshareholderxxxxxxxxxx",selected)
                  }}
                  selected={props.asicDetails.officeHolderName}*/
                  onChange={(selected) => {                          
                
                   
                    const list = [...props.osChangeofAddressInputlist];
                    list[props.changeofAddressIndex]["officeHolderName"] = selected;
                    props.setOSChangeofAddressInputlist(list);
                    props.setAsicDetails((changeOrder) => ({
                      ...changeOrder,
                      osChangeofAddressInputlist:list
                    }));
              
                
              }}
                  selected={props.changeofAddressElements.officeHolderName}
                  placeholder="Enter the ShareHolder Name to Search"
                  useCache={false}
                />
              )}
              {props.changeofAddressElements.changeFor &&
                props.changeofAddressElements.changeFor !== "" &&
                props.changeofAddressElements.changeFor === "both" &&  (
                  <Typeahead
                    id="both"
                  
                    labelKey={  officerShareHoldersData &&  officerShareHoldersData.name && officerShareHoldersData.name.map(
                      (value) => value.name)
                                   
                                    
                    }
                
                    options={
                      officerShareHoldersData &&  officerShareHoldersData.name ? officerShareHoldersData.name :[]
                                          }
                    isLoading={false}
                    onSearch={handleSearch}
                  /*  onChange={(selected) => {
                      props.setAsicDetails((value) => ({ ...value, officeHolderName: selected }));
                      console.log("officeHolderName selected officer xxxxxxxxxx",selected)
                    }}*/

                    onChange={(selected) => {                          
                
                      //const splittedSelected = (selected[0] && selected[0].indexOf(':') !== -1) ?  selected[0].split(':') : selected[0]
                      const list = [...props.osChangeofAddressInputlist];
                      list[props.changeofAddressIndex]["officeHolderName"] = selected;
                      props.setOSChangeofAddressInputlist(list);
                      props.setAsicDetails((changeOrder) => ({
                        ...changeOrder,
                        osChangeofAddressInputlist:list
                      }));
                
                  
                }}
                    selected={props.changeofAddressElements.officeHolderName}
                    placeholder="Enter both the  Officer or Shareholder Name to Search"
                    useCache={false}
                 
                  />
                )}
    
          </div>

          <div className="col-xs-3">
            <label
              htmlFor="officeHolderAddressChangeDateFullAddress"
              className="col-form-label"
            >
              FULL ADDRESS
            </label>
         
           
            <GooglePlacesAutocomplete
              apiKey={"AIzaSyD8pHI8_Rviq7fmkxO5TBlJmaQl7kNHpE0"}
              selectProps={{
                placeholder: "Text...",
                isClearable: true,
                predefinedPlacesAlwaysVisible: true,
                value:  props.changeofAddressElements && props.changeofAddressElements.officeholdersChangeFullAddress ? props.changeofAddressElements.officeholdersChangeFullAddress:"",
                onChange: (val) => {
                  if(props.osChangeofAddressInputlist && props.osChangeofAddressInputlist.length > 0) {
                      const list = [...props.osChangeofAddressInputlist];
                      list[props.changeofAddressIndex]["officeholdersChangeFullAddress"] = val;
                      props.setOSChangeofAddressInputlist(list);
                      props.setAsicDetails((changeOrder) => ({
                        ...changeOrder,
                        osChangeofAddressInputlist:list
                      }));
                }
              }
              }}
            />
            
          </div>
        </div>
      </form>
    </div>
  );
};
export default OfficeHoldersChangeofAddressComponent;
