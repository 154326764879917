import React, { useState, useEffect,useCallback } from "react";
//import axios from "axios";
import Pagination from "../CommonPage/Pagination.component";
import "./AnnualReviews.style.scss";
import AnnualReviewTableComponent from "./AnnualReviewTable.Component";
import AnnualStatementComponent from "./AnnualStatement.Component";
import { Switch, Route, Router, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";

const AnnualReviewsFilter = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const year = moment().format('YYYY');
  const annualStatement = useSelector((state) => state.annualStatement);
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
  const [currentYear, setCurrentYear] = useState("Current");
  const [showTable, setShowTable] = useState(true);
  const selectedSubMonth = currentMonth - 1;
  console.log(currentYear);
  const initDateValue = moment().month(selectedSubMonth).year(currentYear === "Current" ? year : currentYear === "Past" ? year-1:parseInt(year)+1).endOf("month").toDate();
  console.log('AnnualReviewsFilter.jsx - annualReviewDate', initDateValue);

  const [annualReviewDate, setAnnualReviewDate] = useState(initDateValue);

  
  const [filterOrders, setFilterOrders] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  let handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    dispatch(userActions.getAnnualStatement(currentYear, moment().month() + 1));
    dispatch(userActions.getAnnualStatementMonthStatus(currentYear));
    history.push(`/MonitorLanding/${moment().month() + 1}`);

    // eslint-disable-next-line
  }, []);

  let handleChange = (e, isDate) => {
    if (isDate) {
      setAnnualReviewDate(e);
    }
  };

  // search
  const [searchInput, setSearchInput] = useState("");
  const [selectedFilterType, setSelectedFilterType] = useState("");

  // console.log(posts);

  const indexOfLastPost = currentPage * postsPerPage;
  // console.log("indexOfLastPost: ", indexOfLastPost);

  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // console.log("indexOfFirstPost: ", indexOfFirstPost);
  console.log('filterOrders:::::::', annualReviewDate)
  const totalStatement =
    annualStatement && annualStatement.items
      ? selectedFilterType === "department"
        ? annualStatement.items.filter(
            (filteredValue) =>
              filteredValue.Department &&
              filteredValue.Department.toLowerCase().includes(
                searchInput.toLowerCase()
              )&&
              moment(filteredValue.ReviewDate).toDate() >=
              moment().month(selectedSubMonth).startOf("month").toDate() &&
            moment(filteredValue.ReviewDate).toDate() <= annualReviewDate
          )
        : selectedFilterType === "acn"
        ? annualStatement.items.filter(
            (filteredValue) =>
              filteredValue.ACN &&
              filteredValue.ACN.trim().toLowerCase().includes(
                searchInput.trim().toLowerCase()
              )&&
              moment(filteredValue.ReviewDate).toDate() >=
              moment().month(selectedSubMonth).startOf("month").toDate() &&
            moment(filteredValue.ReviewDate).toDate() <= annualReviewDate
          )
        : selectedFilterType === ""
        ? 
        annualStatement.items.filter((filteredValue) =>
            filteredValue.Name.toLowerCase().includes(searchInput.toLowerCase()) &&
            moment(filteredValue.ReviewDate).toDate() >=
            moment().month(selectedSubMonth).year(currentYear === "Current"? year : currentYear === "Past"? year-1:parseInt(year)+1).startOf("month").toDate() &&
          moment(filteredValue.ReviewDate).toDate() <= annualReviewDate
          )
        : annualStatement.items.filter((filteredValue) =>
            filteredValue.Name.toLowerCase().includes(searchInput.toLowerCase()) &&
            moment(filteredValue.ReviewDate).toDate() >=
            moment().month(selectedSubMonth).year(currentYear === "Current"? year : currentYear === "Past"? year-1:parseInt(year)+1).startOf("month").toDate() &&
          moment(filteredValue.ReviewDate).toDate() <= annualReviewDate
          )
      :
      [];

  // filter
  function handleFilterChange(e) {
    if (e && e.target.value) {
      setSelectedFilterType(e.target.value);
      setSearchInput("")
    }
  }
  function handleClick(e, selectedMonth, currentYr) {
    dispatch(userActions.getAnnualStatement(currentYr, selectedMonth));
    setCurrentMonth(selectedMonth);
    setSearchInput("");
    setFilterOrders("");
    history.push(`/MonitorLanding/${selectedMonth}`);
    setAnnualReviewDate(
      moment()
        .month(selectedMonth - 1)
        .year(currentYr === "Current"? year : currentYr === "Past"? year-1:parseInt(year)+1)
        .endOf("month")
        .toDate()
    );
  }

  //year dropdown selection
  const annualStatementMonthStatus = useSelector(
    (state) => state.annualStatementMonthStatus
  );
  function handleChangeYearDropDown(e) {
    setCurrentYear(e.target.value);
    console.log("e.target.valuee.target.value",e.target.value)
    setAnnualReviewDate(moment().month(selectedSubMonth).year(e.target.value === "Current"? year : e.target.value === "Past"? year-1:parseInt(year)+1).endOf("month").toDate())
    dispatch(userActions.getAnnualStatementMonthStatus(e.target.value));

    dispatch(userActions.getAnnualStatement(e.target.value, currentMonth));
    setCurrentMonth(currentMonth);
    setSearchInput("");
    setFilterOrders("");
  }
  // button handle click

  function buttonHandleClick(e, value) {
    e.preventDefault();
    if (value === "success") {
      setFilterOrders(
        totalStatement &&
          totalStatement.filter(
            (value) =>
              value["StatusText"] &&
              value["StatusText"]
                .toLowerCase()
                .includes("success".toLowerCase())
          )
      );
    } else if (value === "warning") {
      setFilterOrders(
        totalStatement &&
          totalStatement.filter(
            (value) =>
              value["StatusText"] &&
              value["StatusText"]
                .toLowerCase()
                .includes("warning".toLowerCase())
          )
      );
    } else if (value === "danger") {
      setFilterOrders(
        totalStatement &&
          totalStatement.filter(
            (value) =>
              value["StatusText"] &&
              value["StatusText"].toLowerCase().includes("danger".toLowerCase())
          )
      );
    } else if (value === "default") {
      setFilterOrders(
        totalStatement &&
          totalStatement.filter(
            (value) =>
              value["StatusText"] &&
              value["StatusText"]
                .toLowerCase()
                .includes("default".toLowerCase())
          )
      );
    } else {
      setFilterOrders(totalStatement);
    }
  }
  let successTotal =
    totalStatement &&
    totalStatement.filter(
      (value) =>
        value["StatusText"] &&
        value["StatusText"].toLowerCase().includes("success".toLowerCase())
    );
  let warningTotal =
    totalStatement &&
    totalStatement.filter(
      (value) =>
        value["StatusText"] &&
        value["StatusText"].toLowerCase().includes("warning".toLowerCase())
    );
  let dangerTotal =
    totalStatement &&
    totalStatement.filter(
      (value) =>
        value["StatusText"] &&
        value["StatusText"].toLowerCase().includes("danger".toLowerCase())
    );
  let defaultTotal =
    totalStatement &&
    totalStatement.filter(
      (value) =>
        value["StatusText"] &&
        value["StatusText"].toLowerCase().includes("default".toLowerCase())
    );

  function searchHandleChange(e) {
    setSearchInput(e.target.value);

    // setFilterOrders(totalStatement && totalStatement.filter((value) =>  value["Name"]  && value["Name"].toLowerCase().includes(searchInput.toLowerCase()) ))
  }

  let currentPosts = filterOrders
    ? filterOrders && filterOrders.slice(indexOfFirstPost, indexOfLastPost)
    : totalStatement && totalStatement.slice(indexOfFirstPost, indexOfLastPost);

  // refresh button
  /*function handleClickRefreshAnnualSearch(e) {
  e.preventDefault();
  setSearchInput("");
  
}*/
// calendar toggle
const [isCalendar, setIsCalendar] = useState(false)
const handleCalenderTrigger = useCallback(
  () =>{setIsCalendar(!isCalendar)},
  [isCalendar, setIsCalendar]
);
  return (
    <div className="m-4 fontfamily">
      <Router history={history}>
        <h2 className="panel-title">Annual Reviews</h2>
        <nav className="navbar navbar-light bg-light navbar-expand-md ">
          <div className="container">
            <div
              className=" navbar-collapse justify-content-start"
              id="main-nav-collapse"
            >
              <ul className="navbar-nav">
                <li className="nav-item active nav-item px-1">
                  {" "}
                  <label htmlFor="records" className="px-1 ">
                    {" "}
                    {annualStatement &&
                    annualStatement.items &&
                    annualStatement.items.length > 0
                      ? `${annualStatement.items.length} Records `
                      : " 0 Record"}
                  </label>
                </li>
                <li className="nav-item active nav-item px-1 ">
                  <input
                    type="search"
                    id="monitorsearch"
                    className="form-control-sm border border-default"
                    size="35"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                      searchHandleChange(e);
                    }}
                    autoComplete="off"
                  />
                </li>

                <li className="nav-item active nav-item px-1">
                  <select
                    name="SearchByFilter"
                    id="searchByFilter"
                    className="dropDown text-nowrap"
                    onChange={(e) => handleFilterChange(e)}
                  >
                    <option value="">searchByFilter</option>
                    <option value="companyName">Company Name</option>
                    <option value="department">Department</option>
                    <option value="acn">ACN</option>
                  </select>
                </li>

                <li className="nav-item active nav-item px-3 ">
                  <div className="row  calendarPeriod">
                    <div className="col">
                      <label className="text-white">
                        {" "}
                        {moment()
                          .month(selectedSubMonth)
                          .year(currentYear === "Current"? year : currentYear === "Past"? year-1:parseInt(year)+1)
                          .startOf("month")
                          .format("DD/MM/YYYY")}
                      </label>
                    </div>
                    <div className="col">
                      <DatePicker
                        name="annualReviewDate"
                        onChange={(e) => handleChange(e, true)}
                        selected={annualReviewDate}
                        value={annualReviewDate}
                        dateFormat="dd/MM/yyyy"
                        minDate={moment()
                          .month(selectedSubMonth)
                          .year(currentYear === "Current"? year : currentYear === "Past"? year-1:parseInt(year)+1)
                          .startOf("month")
                          .toDate()}
                        maxDate={moment()
                          .month(selectedSubMonth)
                          .year(currentYear === "Current"? year : currentYear === "Past"? year-1:parseInt(year)+1)
                          .endOf("month")
                          .toDate()}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <nav className="navbar navbar-light bg-light navbar-expand-md ">
          <div className="container">
            <div
              className=" navbar-collapse justify-content-start  fs-6"
              id="main-nav-collapse"
            >
              <ul className=" navbar-nav ">
              <li className="nav-item active nav-item list-inline-item">
              <button
              data-toggle="tooltip" html= "true" 
              data-placement="bottom" title="To Change Annual Statement Month/Year"
                type="button"
                className="btn  btn-sm px-2"
                onClick={handleCalenderTrigger}
              >
              <i className="bi bi-calendar2-check-fill"></i>
              </button>{" "}
              
            </li>
                {" "}
                <li className="nav-item active nav-item list-inline-item">
                  <button
                    type="button"
                    className="btn  btn-success btn-sm px-2"
                    onClick={(e) => buttonHandleClick(e, "success")}
                  >
                    {successTotal.length}
                  </button>{" "}
                  Received & Printed{" "}
                </li>
                <li className="nav-item active nav-item list-inline-item">
                  {" "}
                  <button
                    type="button"
                    className="btn  btn-warning btn-sm px-2"
                    onClick={(e) => buttonHandleClick(e, "warning")}
                  >
                    {warningTotal.length}
                  </button>{" "}
                  Received NOT Printed
                </li>
                <li className="nav-item active nav-item list-inline-item">
                  {" "}
                  <button
                    type="button"
                    className="btn  btn-danger  btn-sm px-2"
                    onClick={(e) => buttonHandleClick(e, "danger")}
                  >
                    {dangerTotal.length}
                  </button>{" "}
                  NOT Received
                </li>
                <li className="nav-item active nav-item list-inline-item">
                  {" "}
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm px-2"
                    onClick={(e) => buttonHandleClick(e, "default")}
                  >
                    {defaultTotal.length}
                  </button>{" "}
                  NOT Due / Co-collects
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="row">
        {isCalendar &&
          <div className="col-md-2 col-sm-2">
            <AnnualStatementComponent
              history={history}
              currentMonth={currentMonth}
              handleClick={handleClick}
              dangerTotal={dangerTotal}
              handleChange={handleChangeYearDropDown}
              annualStatementMonthStatus={annualStatementMonthStatus}
              currentYear={currentYear}
              setRecentSearch={props.setRecentSearch}
              recentSearch={props.recentSearch}
            />
          </div>
        }
        {console.log('currentPosts::::::', currentPosts)}
          <div className={isCalendar ? "col-md-9 col-sm-9" : "col-md-12 col-sm-12"}>
            <Switch>
              <Route path="/MonitorLanding/1">
                <AnnualReviewTableComponent
                  page="1"
                  history={history}
                  currentPosts={currentPosts}
                  annualStatementMonthStatus={
                    annualStatementMonthStatus &&
                    annualStatementMonthStatus.items &&
                    annualStatementMonthStatus.items[0]
                  }
                  currentYear={currentYear}
                  setRecentSearch={props.setRecentSearch}
                  recentSearch={props.recentSearch}
                  loggedUserInfo={props.loggedUserInfo}
                  userId={props.userId}
                  clientId={props.clientId}
                />
              </Route>
              <Route path="/MonitorLanding/2">
                <AnnualReviewTableComponent
                  page="2"
                  history={history}
                  currentPosts={currentPosts}
                  annualStatementMonthStatus={
                    annualStatementMonthStatus &&
                    annualStatementMonthStatus.items &&
                    annualStatementMonthStatus.items[1]
                  }
                  currentYear={currentYear}
                  setRecentSearch={props.setRecentSearch}
                  recentSearch={props.recentSearch}
                  loggedUserInfo={props.loggedUserInfo}
                  userId={props.userId}
                  clientId={props.clientId}
                />
              </Route>
              <Route path="/MonitorLanding/3">
                <AnnualReviewTableComponent
                  page="3"
                  history={history}
                  currentPosts={currentPosts}
                  annualStatementMonthStatus={
                    annualStatementMonthStatus &&
                    annualStatementMonthStatus.items &&
                    annualStatementMonthStatus.items[2]
                  }
                  currentYear={currentYear}
                  setRecentSearch={props.setRecentSearch}
                  recentSearch={props.recentSearch}
                  loggedUserInfo={props.loggedUserInfo}
                  userId={props.userId}
                  clientId={props.clientId}
                />
              </Route>
              <Route path="/MonitorLanding/4">
                <AnnualReviewTableComponent
                  page="4"
                  history={history}
                  currentPosts={currentPosts}
                  annualStatementMonthStatus={
                    annualStatementMonthStatus &&
                    annualStatementMonthStatus.items &&
                    annualStatementMonthStatus.items[3]
                  }
                  currentYear={currentYear}
                  setRecentSearch={props.setRecentSearch}
                  recentSearch={props.recentSearch}
                  loggedUserInfo={props.loggedUserInfo}
                  userId={props.userId}
                  clientId={props.clientId}
                />
              </Route>
              <Route path="/MonitorLanding/5">
                <AnnualReviewTableComponent
                  page="5"
                  history={history}
                  currentPosts={currentPosts}
                  annualStatementMonthStatus={
                    annualStatementMonthStatus &&
                    annualStatementMonthStatus.items &&
                    annualStatementMonthStatus.items[4]
                  }
                  currentYear={currentYear}
                  setRecentSearch={props.setRecentSearch}
                  recentSearch={props.recentSearch}
                  loggedUserInfo={props.loggedUserInfo}
                  userId={props.userId}
                  clientId={props.clientId}
                />
              </Route>
              <Route path="/MonitorLanding/6">
                <AnnualReviewTableComponent
                  page="6"
                  history={history}
                  currentPosts={currentPosts}
                  annualStatementMonthStatus={
                    annualStatementMonthStatus &&
                    annualStatementMonthStatus.items &&
                    annualStatementMonthStatus.items[5]
                  }
                  currentYear={currentYear}
                  setRecentSearch={props.setRecentSearch}
                  recentSearch={props.recentSearch}
                  loggedUserInfo={props.loggedUserInfo}
                  userId={props.userId}
                  clientId={props.clientId}
                />
              </Route>
              <Route path="/MonitorLanding/7">
                <AnnualReviewTableComponent
                  page="7"
                  history={history}
                  currentPosts={currentPosts}
                  annualStatementMonthStatus={
                    annualStatementMonthStatus &&
                    annualStatementMonthStatus.items &&
                    annualStatementMonthStatus.items[6]
                  }
                  currentYear={currentYear}
                  setRecentSearch={props.setRecentSearch}
                  recentSearch={props.recentSearch}
                  loggedUserInfo={props.loggedUserInfo}
                  userId={props.userId}
                  clientId={props.clientId}
                />
              </Route>
              <Route path="/MonitorLanding/8">
                <AnnualReviewTableComponent
                  page="8"
                  history={history}
                  currentPosts={currentPosts}
                  annualStatementMonthStatus={
                    annualStatementMonthStatus &&
                    annualStatementMonthStatus.items &&
                    annualStatementMonthStatus.items[7]
                  }
                  currentYear={currentYear}
                  setRecentSearch={props.setRecentSearch}
                  recentSearch={props.recentSearch}
                  loggedUserInfo={props.loggedUserInfo}
                  userId={props.userId}
                  clientId={props.clientId}
                />
              </Route>
              <Route path="/MonitorLanding/9">
                <AnnualReviewTableComponent
                  page="9"
                  history={history}
                  currentPosts={currentPosts}
                  annualStatementMonthStatus={
                    annualStatementMonthStatus &&
                    annualStatementMonthStatus.items &&
                    annualStatementMonthStatus.items[8]
                  }
                  currentYear={currentYear}
                  setRecentSearch={props.setRecentSearch}
                  recentSearch={props.recentSearch}
                  loggedUserInfo={props.loggedUserInfo}
                  userId={props.userId}
                  clientId={props.clientId}
                />
              </Route>
              <Route path="/MonitorLanding/10">
                <AnnualReviewTableComponent
                  page="10"
                  history={history}
                  currentPosts={currentPosts}
                  annualStatementMonthStatus={
                    annualStatementMonthStatus &&
                    annualStatementMonthStatus.items &&
                    annualStatementMonthStatus.items[9]
                  }
                  currentYear={currentYear}
                  setRecentSearch={props.setRecentSearch}
                  recentSearch={props.recentSearch}
                  loggedUserInfo={props.loggedUserInfo}
                  userId={props.userId}
                  clientId={props.clientId}
                />
              </Route>
              <Route path="/MonitorLanding/11">
              {console.log("isCalendar",isCalendar)}
                <AnnualReviewTableComponent
                      page="11"
                  history={history}
                  currentPosts={currentPosts}
                  annualStatementMonthStatus={
                    annualStatementMonthStatus &&
                    annualStatementMonthStatus.items &&
                    annualStatementMonthStatus.items[10]
                  }
                  currentYear={currentYear}
                  setRecentSearch={props.setRecentSearch}
                  recentSearch={props.recentSearch}
                  loggedUserInfo={props.loggedUserInfo}
                  userId={props.userId}
                  clientId={props.clientId}
                />
              </Route>
              <Route path="/MonitorLanding/12">
                <AnnualReviewTableComponent
                  page="12"
                  history={history}
                  currentPosts={currentPosts}
                  annualStatementMonthStatus={
                    annualStatementMonthStatus &&
                    annualStatementMonthStatus.items &&
                    annualStatementMonthStatus.items[11]
                  }
                  currentYear={currentYear}
                  setRecentSearch={props.setRecentSearch}
                  recentSearch={props.recentSearch}
                  loggedUserInfo={props.loggedUserInfo}
                  userId={props.userId}
                  clientId={props.clientId}
                />
              </Route>
              <Route path="/">
                <AnnualReviewTableComponent
                  page="1"
                  history={history}
                  annualStatementMonthStatus={
                    annualStatementMonthStatus &&
                    annualStatementMonthStatus.items &&
                    annualStatementMonthStatus.items[1]
                  }
                  currentYear={currentYear}
                  setRecentSearch={props.setRecentSearch}
                  recentSearch={props.recentSearch}
                  loggedUserInfo={props.loggedUserInfo}
                  userId={props.userId}
                  clientId={props.clientId}
                />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
      {totalStatement.length >= postsPerPage && (
        <footer
          id="sticky-footer"
          className="footer d-flex justify-content-end footer-body  "
        >
          <div className="container fs-6 ">
            <div className="row">
              <div className="col-md-6 ">
                Showing{" "}
                <input
                  type="text"
                  className="form-control-sm border border-default"
                  onKeyPress={handleKeyPress}
                  name="showRecord"
                  value={postsPerPage}
                  maxLength="3"
                  size="3"
                  onChange={(e) => setPostsPerPage(e.target.value)}
                ></input>{" "}
                /
                {filterOrders
                  ? filterOrders.length
                  : totalStatement && totalStatement.length}
              </div>
              <div className="col-md-6">
                {postsPerPage && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={
                      filterOrders
                        ? filterOrders.length
                        : totalStatement && totalStatement.length
                    }
                    pageSize={postsPerPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </footer>
      )}
      <br />
      <br />
    </div>
  );
};
export default AnnualReviewsFilter;
