import { userConstants } from "../constants/login.constants";

 function individualEntities(state = {}, action) {
    switch (action.type) {
        case userConstants.INDIVIDUALENTITIES_REQUEST:
            return {
                loading: true
            };
        case userConstants.INDIVIDUALENTITIES_SUCCESS:
            return {
                items: action.individualEntities
            };
        case userConstants.INDIVIDUALENTITIES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export default individualEntities;