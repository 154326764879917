import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import MultiStep from "../CommonPage/MultiStep";
import EBinderAdditionalDoc from "../EBinderAdditionalDocComponent/EBinderAdditionalDocComponent";
import EBinderCompanyComponent from "../EBinderCompanyComponent/EBinderCompanyComponent";
import EBinderComplete from "../EBinderCompleteComponent/EbinderCompleteComponent";
import EbinderGetStarted from "../EBinderGetStartedComponent/EBinderGetStartedComponent";
import EBinderPrimeCloudDocs from "../EBinderPrimeCloudDocsComponent/EBinderPrimeCloudDocsComponent";
import EBinderRegisters from "../EBinderRegistersComponent/EBinderRegistersComponent";
import EBinderReview from "../EBinderReviewComponent/EBinderReviewComponent";

const EbinderLanding = (props) => {
  const companyCode = JSON.parse(localStorage.getItem("companyCode"));
  const inputSearchValue = JSON.parse(localStorage.getItem("inputSearchValue"));
  const [primeFileDocuments, setPrimeFileDocuments] = useState([]);
  const [selectedCompanyCode, setSelectedCompanyCode] = useState();
  const [ebinderResponse, setEbinderResponse] = useState();
  const [customFileList, setCustomFileList] = useState([]);
  const validate =
    companyCode !== null ||
    (inputSearchValue !== null && inputSearchValue.length > 0);
  const dispatch = useDispatch();
  const [showEBinderMangement, setShowEBinderMangement] = useState(false);
  // selected company from company selection
  const [displaySelectedCompany, setDisplaySelectedCompany] = useState([]);
  const ebinderCustomFileList = useSelector(
    (state) => state.ebinderCustomFileList
  );
  const ebinderOrderRequest = useSelector(
    (state) => state.ebinderOrderRequest
  );
  
  const ebinderPrimeFileList = useSelector(
    (state) => state.ebinderPrimeFileList
  );
  const [isCreateEBinderLoaded, setIsCreateEBinderLoaded] = useState(false);
 

  useEffect(() => {
    setTimeout(() => {
      if (selectedCompanyCode) {
        dispatch(userActions.getEbinderPrimeFiles(selectedCompanyCode));
        dispatch(userActions.getEbinderCustomFileList(selectedCompanyCode));
        dispatch(userActions.getManageEbinderList(selectedCompanyCode));
      }
    }, 0);

    // eslint-disable-next-line
  }, [selectedCompanyCode]);

  useEffect(() => {
    setPrimeFileDocuments(
      ebinderPrimeFileList &&
        ebinderPrimeFileList.items &&
        ebinderPrimeFileList.items.map((value) =>
          Object.assign({
            type: value.Type,
            description: value.Description,
            documents:
              value.Documents &&
              value.Documents.map((value) =>
                Object.assign(value, { primeSubDocCheckBox: true })
              ),
            primeDocCheckBox: true,
          })
        )
    );
    setCustomFileList(
      ebinderCustomFileList && ebinderCustomFileList.items
        ? ebinderCustomFileList.items.map((value) =>
            Object.assign(value, { additionDocCheckBox: true })
          )
        : []
    );

    // eslint-disable-next-line
  }, [ebinderPrimeFileList, ebinderCustomFileList]);

  //Company register
  // eslint-disable-next-line
  const [companyRegister, setCompanyRegister] = useState({
    // by default this bellow to fields are true
    directors: true,
    allotment: true,
    transfer: true,
    members: true,
  });
  let totalSelected =
  primeFileDocuments &&
 primeFileDocuments.map((value) =>
    value.documents.filter((item) => item.primeSubDocCheckBox)
  );

  let register = companyRegister && Object.keys(companyRegister).filter(function (key) {
    return companyRegister[key];
  });

  let customFiles = customFileList && customFileList.filter((value)=> value.additionDocCheckBox)
  console.log("customFiles:::::", customFiles);
  function handleClick(e) {
    // Selected prime forms
    e.preventDefault();
    let selectedPrimeFileList = [];
    primeFileDocuments && Object.keys(primeFileDocuments).forEach((v) => {
      console.log(primeFileDocuments[v]);
      primeFileDocuments[v] &&
        primeFileDocuments[v].documents &&
        primeFileDocuments[v].documents
          .filter((doc) => doc.primeSubDocCheckBox)
          .forEach((f) => {
            selectedPrimeFileList.push({
              id: f.TrackingNumber,
              type: primeFileDocuments[v].type,
            });
          });
    });

    

    dispatch(
      userActions.postCreateEbinder(
        selectedCompanyCode,
        selectedPrimeFileList,
        register,
        customFiles,
        setIsCreateEBinderLoaded
      )
    );
  }

  useEffect(() => {
    console.log("ebinderOrderRequest1: ", ebinderOrderRequest);
   
    setEbinderResponse(ebinderOrderRequest)
    // eslint-disable-next-line
  }, [ebinderOrderRequest]);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        setEbinderResponse("");
      }
    }
}, []);
  const steps = [
    {
      name: "GetStarted",
      validate: validate,
      component: (
        <EbinderGetStarted
          displaySelectedCompany={displaySelectedCompany}
          setDisplaySelectedCompany={setDisplaySelectedCompany}
          primeFileDocuments={primeFileDocuments}
          setPrimeFileDocuments={setPrimeFileDocuments}
          ebinderPrimeFileList={ebinderPrimeFileList}
          customFileList={customFileList}
          setCustomFileList={setCustomFileList}
          selectedCompanyCode={selectedCompanyCode}
          setSelectedCompanyCode={setSelectedCompanyCode}
          showEBinderMangement={showEBinderMangement}
          setShowEBinderMangement={setShowEBinderMangement}
        />
      ),
    },
    {
      name: "Select Company",
      validate: validate,
      component: (
        <EBinderCompanyComponent
          displaySelectedCompany={displaySelectedCompany}
          setDisplaySelectedCompany={setDisplaySelectedCompany}
          primeFileDocuments={primeFileDocuments}
          setPrimeFileDocuments={setPrimeFileDocuments}
          ebinderPrimeFileList={ebinderPrimeFileList}
          customFileList={customFileList}
          setCustomFileList={setCustomFileList}
          selectedCompanyCode={selectedCompanyCode}
          setSelectedCompanyCode={setSelectedCompanyCode}
          showEBinderMangement={showEBinderMangement}
          setShowEBinderMangement={setShowEBinderMangement}
        />
      ),
    },
    {
      name: "Prime Cloud Docs",
      validate: validate,
      component: (
        <EBinderPrimeCloudDocs
          primeFileDocuments={primeFileDocuments}
          setPrimeFileDocuments={setPrimeFileDocuments}
          ebinderPrimeFileList={ebinderPrimeFileList}
          customFileList={customFileList}
          setCustomFileList={setCustomFileList}
          searchCompany={props.searchCompany}
          totalSelected ={totalSelected}
        />
      ),
    },
    {
      name: "Additional Docs",
      validate: validate,
      component: (
        <EBinderAdditionalDoc
          customFileList={customFileList}
          setCustomFileList={setCustomFileList}
          ebinderCustomFileList={ebinderCustomFileList}
          selectedCompanyCode={selectedCompanyCode}
        />
      ),
    },
    {
      name: "Registers",
      validate: validate,
      component: (
        <EBinderRegisters
          companyRegister={companyRegister}
          setCompanyRegister={setCompanyRegister}
        />
      ),
    },
    {
      name: "Review",
      validate: validate,
      component: (
        <EBinderReview
          displaySelectedCompany={displaySelectedCompany}
          primeFileDocuments={primeFileDocuments}
          setPrimeFileDocuments={setPrimeFileDocuments}
          customFileList={customFiles}
          companyRegister={companyRegister}
          totalSelected ={totalSelected}
        />
      ),
    },
    {
      name: "Complete",
      validate: validate,
      component: (
        <EBinderComplete
          displaySelectedCompany={displaySelectedCompany}
          primeFileDocuments={primeFileDocuments}
          setPrimeFileDocuments={setPrimeFileDocuments}
          customFileList={customFileList}
          register={register}
          ebinderResponse={ebinderResponse}
          selectedCompanyCode={selectedCompanyCode}
          totalSelected ={totalSelected}
        />
      ),
    },
  ];
  return (
    <div className=" faq-content fs-6">
      <h4 className="panel-title">EBinder Work in Progress</h4>
      <MultiStep
        activeStep={0}
        steps={steps}
        validate={validate}
        showEBinderMangement={showEBinderMangement}
        setShowEBinderMangement={setShowEBinderMangement}
        handleClick={handleClick}
        selectedCompanyCode={selectedCompanyCode}
        isCreateEBinderLoaded={isCreateEBinderLoaded}
        ebinderOrderRequest={ebinderOrderRequest}
        ebinderResponse={ebinderResponse}
        setEbinderResponse={setEbinderResponse}
      />
    </div>
  );
};
export default EbinderLanding;
