import { userConstants } from "../constants/login.constants";

 function jointHolders(state = {}, action) {
    switch (action.type) {
        case userConstants.JOINTHOLDERS_REQUEST:
            return {
                loading: true
            };
        case userConstants.JOINTHOLDERS_SUCCESS:
            return {
                items: action.jointHolders
            };
        case userConstants.JOINTHOLDERS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export default jointHolders;