import React, { useState } from "react";
import moment from "moment";


const EBinderReview = (props) => {
  const [expandState, setExpandState] = useState({});
    const [expandedRows, setExpandedRows] = useState([]);
    const handleRow = (e, code) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(code);

    let obj = {};
    isRowExpanded ? (obj[code] = false) : (obj[code] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== code)
      : currentExpandedRows.concat(code);

    setExpandedRows(newExpandedRows);
  };


  return (
    <div className="fs-6">

    <div>
    <h4><strong>Review </strong></h4>
        <p>
          Please review the content of your e-Binder before it is finalized for
          assembly.
        </p>
      </div>
      <div>
      <h5><strong>Company Overview</strong></h5>
      <p>The following is provided to confirm the selected company.</p>
    </div>

    <div >
      <ul>
        <li>
       
          <p>
          <strong>Name:</strong> {props.displaySelectedCompany &&
              props.displaySelectedCompany.Name}
          </p>
        </li>
        <li>
          <p>
            <b>ACN:</b>  {props.displaySelectedCompany &&
              props.displaySelectedCompany.ACN
                ? props.displaySelectedCompany.ACN
                : ""}
          </p>
        </li>
        <li>

          <p>
            <b>Registered Office:</b> 
            {props.displaySelectedCompany &&
              props.displaySelectedCompany.Address
                ? props.displaySelectedCompany.Address
                : ""}
          </p>
        </li>
        <li>
          <p>
            <b>Incorporation Date:</b>
            {props.displaySelectedCompany &&
              props.displaySelectedCompany.DATE_INC === null
                ? " Incorporated: N/A"
                : props.displaySelectedCompany &&
                  props.displaySelectedCompany.DATE_INC
                ? moment(
                    props.displaySelectedCompany.DATE_INC
                  ).format("DD/MM/YYYY")
                : ""}
          </p>
        </li>
      </ul>
    </div>
    
    <h5><strong>Prime Cloud Documents</strong> </h5>
    <p>The following list includes all PrimeCloud forms which will be included in the binder.</p>
    <div className="table-responsive-sm">
          {props.primeFileDocuments && props.primeFileDocuments.length > 0 && (
            <table className="table  table-bordered  w-50 fs-6">
              {props.primeFileDocuments &&
                props.primeFileDocuments.map((post, index) => (
                  <tbody>
                    <tr
                      key={
                        post.documents &&
                        post.documents.map((value) => value.ID)
                      }
                    >
                    
                      <td>
                      {props.totalSelected &&
                        props.totalSelected[index] &&
                        props.totalSelected[index].length > 0  &&
                        <div>
                        {expandState[post.type] ? (
                          <i
                            class="bi bi-caret-up-fill text-danger"
                            onClick={(e) => handleRow(e, post.type)}
                          ></i>
                        ) : (
                          <i
                            class="bi bi-caret-down-fill text-success"
                            onClick={(e) => handleRow(e, post.type)}
                          ></i>
                        )}
                        </div>
                      }
                      </td>
                           
                      <td>{post.type}</td>
                      <td>
                      {props.totalSelected &&
                        props.totalSelected[index] &&
                        props.totalSelected[index].length > 0
                        ? `${props.totalSelected[index].length} selected`
                        : " 0 selected "}
                    </td>
                      <td>{post.description}</td>
                     
                    </tr>
                    {expandedRows.includes(post.type) && (
                      <tr>
                        <td colSpan="5 ">
                          <table className="table  fs-6 table-light table-borderless">
                            <tbody>
                              {post.documents &&
                                post.documents.filter(doc =>doc.primeSubDocCheckBox).map((value) => (
                                  <tr>
                                    <td className="w-25 text-center">
                                      {" "}
                                      
                                    </td>
                                    <td>ID:{value.ID}</td>

                                    <td>
                                      Lodges:
                                      {value.LodgedAt !== -1 &&
                                        moment(value.LodgedAt).format(
                                          "DD/MMM/YYYY"
                                        )}
                                    </td>
                                    <td>ASIC:{value.ASIC_ID}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </tbody>
                ))}
            </table>
          )}
        </div>

    <h5><strong>Additional User Documents </strong></h5>
    <p>The following includes all additional documents which will be included in the binder</p>
    <ul>
          {props.customFileList &&
            props.customFileList.map((post, index) => (
            <li> {post}</li>
            
            ))}
            </ul>
          
      
    <h5><strong>Registers </strong></h5>
    <p>The following is a list of registers which has been selected for inclusion in the binder.</p>
    <p> {console.log("sssssssss",  props.companyRegister)}</p>
    {props.companyRegister &&
    <ul>
    {props.companyRegister.directors && <li>Directors & Secretaries</li>}
    {props.companyRegister.allotment && <li>Allotment Journal</li>}      
    {props.companyRegister.transfer && <li>Transfer Journal</li>}
    {props.companyRegister.members && <li>Members</li>}
  </ul>
}
    </div>
  );
};
export default EBinderReview;