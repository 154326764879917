import React from "react";
import EOrderOrderRequestComponent from "../ChangesAndOrderRequest/EorderOrderRequest.Component";
import "../CompanyDetailsComponent/CompanyDetails.styles.scss";


const EOrderComponent = (props) => {
  return (
    <div>
      <div>
        <details open>
          <summary>Changes and Order Requests</summary>
          <div className="faq-content">
            <EOrderOrderRequestComponent searchCompany={props.searchCompany}/>
          </div>
        </details>
      </div>

    
    </div>
  );
};
export default EOrderComponent;
