import React, { useState, useEffect } from "react";
import "font-awesome/css/font-awesome.min.css";
import "../MonitorsComponents/AnnualReviews.style.scss";
import Pagination from "../CommonPage/Pagination.component";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import moment from "moment";
import Popup from "../CommonPage/Popup.component";
import ViewCompanyReport from "../ViewCompanyDebtReport/ViewCompanyDebtReport";
import "../CommonPage/Action.scss";


const ASICDept = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // View Company Report
  // eslint-disable-next-line
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const [details, setDetails] = useState({
    zeroBalance: "No",
    creditBalance: "No",
    sortReport: "No",
  });

  //loading spinner
  const [isViewCompanyReportLoaded, setIsViewCompanyReportLoaded] =
    useState(false);

  let viewReporthHandleChange = (e) => {
    const { name, value } = e.target;
    setDetails((details) => ({ ...details, [name]: value }));
  };

  let viewReportHandleClick = (e) => {
    e.preventDefault();
    setDetailsSubmitted(true);
    dispatch(
      userActions.getCompaniesDebtReport(details, setIsViewCompanyReportLoaded)
    );
  
  };

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  let handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    dispatch(userActions.getAsicDebtStatement());
    // eslint-disable-next-line
  }, []);
  const asicDebtStatement = useSelector((state) => state.asicDebtStatement);

  // search
  const [searchInput, setSearchInput] = useState("");
  const asicTotalStatement =
    asicDebtStatement && asicDebtStatement.items
      ? asicDebtStatement.items.filter((filteredValue) =>
          filteredValue["CO_NAME"]
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        )
      : [];



  const indexOfLastPost = currentPage * postsPerPage;


  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  
  const currentPosts = asicTotalStatement
    ? asicTotalStatement.slice(indexOfFirstPost, indexOfLastPost)
    : [];

  const valueCompare = 0;

  // refresh button
  function handleClickRefreshASICDebt(e) {
    e.preventDefault();
    setSearchInput("");
  }

  return (
    <div className="m-4 fontfamily">
      <h2 className="panel-title">ASIC DEBTS</h2>
      <nav className="navbar navbar-light bg-light navbar-expand-md ">
        <div className="container">
          <div
            className=" navbar-collapse justify-content-start"
            id="main-nav-collapse"
          >
            {" "}
            <button className=" buttonCommon fs-6" onClick={handleShow}>
              <i class="fas fa-chart-line"></i>
              <i class="fa fa-bar-chart" aria-hidden="true"></i>View Company
              Debt Report
            </button>
          </div>
          {show && (
            <Popup
              show={show}
              handleClose={handleClose}
              handleShow={handleShow}
              body={
                <ViewCompanyReport
                  details={details}
                  viewReporthHandleChange={viewReporthHandleChange}
                />
              }
              btnName="Show Report"
              handleClick={viewReportHandleClick}
              isPopupReportLoading={isViewCompanyReportLoaded}
            />
          )}

          <div
            className=" navbar-collapse d-flex flex-row-reverse "
            id="main-nav-collapse"
          >
            <ul className="navbar-nav">
              <div className=" col form-outline">
                <input
                  type="search"
                  id="monitorsearch"
                  className="form-control-sm "
                  size="50"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  autoComplete={false}
                />
                &nbsp;
                <button
                  className="btn-sm"
                  onClick={(e) => handleClickRefreshASICDebt(e)}
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i>
                </button>
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <div className="table-responsive-sm">
        <table className="table table-hover fs-6">
          <thead>
            <tr>
              <th>Company Name</th>
              <th className="col-1">ACN</th>
              <th className="text-wrap ">Review Date</th>
              <th className="text-wrap">Now Due</th>
              <th className="text-wrap">Future Dept</th>
              <th className="text-wrap">Total Owing</th>
              <th className="text-wrap">0-30 Days</th>
              <th className="text-wrap">31-60 Days</th>
              <th className="text-wrap">61-90 Days</th>
              <th className="text-wrap">Over 90 Days</th>
                {/*  <th> Action </th>  */}
            </tr>
          </thead>

          <tbody className=" fs-6">
            {
              asicDebtStatement && currentPosts&& currentPosts.length ===0 ?              
              <tr><td colSpan="10">No matching records found</td></tr>:
              asicDebtStatement &&
              currentPosts &&
              currentPosts.map((post) => (
                <tr>
                  <td>{post.CO_NAME} </td>

                  <td class="w-20">{post.ACN}</td>

                  <td>
                    {post.REVIEW_DATE
                      ? moment(post.REVIEW_DATE).format("DD/MM/YYYY")
                      : ""}{" "}
                  </td>

                  <td>{post.DEBT_NOW_DUE}</td>

                  <td>{post.FUTURE_DEBT_DUE}</td>
                  {post.AMOUNT_OWING > valueCompare}

                  <td>
                    <button
                      className={
                        post.AMOUNT_OWING > valueCompare
                          ? '"btn  btn-sm btn-danger pe-none'
                          : "btn-success btn  btn-sm pe-none"
                      }
                    >
                      {post.AMOUNT_OWING}
                    </button>
                  </td>
                  <td>{post.DEBT_30}</td>
                  <td>{post.DEBT_31_60}</td>
                  <td>{post.DEBT_61_90}</td>
                  {post.DEBT_90 > valueCompare}
                  <td>
                    <button
                      className={
                        post.DEBT_90 > valueCompare
                          ? '"btn  btn-sm btn-danger pe-none'
                          : "btn-success btn  btn-sm pe-none "
                      }
                    >
                      {post.DEBT_90}
                    </button>
                  </td>
                    {/*
                  <td>
                    <a
                      href={() => false}
                      className="d-flex align-items-center text-white text-decoration-none "
                      id="dropdownUserDetail"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                    </a>

                    <ul className="dropdown-menu  text-dark">
                      <li className="action ">
                        <a className="dropdown-item " href={() => false}>
                          ASIC Payment Slip
                        </a>
                        <hr />
                      </li>

                      <li className="py-0 ">
                        <a className="dropdown-item " href={() => false}>
                          Email Notificaion
                        </a>
                      </li>
                    </ul>
                  </td>
                   */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {asicTotalStatement && asicTotalStatement.length >= 10 && (
        <footer
          id="sticky-footer"
          class="footer d-flex justify-content-end footer-body  "
        >
          <div class="container fs-6 ">
            <div class="row">
              <div class="col-md-6 ">
                Showing{" "}
                <input
                  type="text"
                  className="form-control-sm border border-default"
                  onKeyPress={handleKeyPress}
                  name="showRecord"
                  value={postsPerPage}
                  maxLength="3"
                  size="3"
                  onChange={(e) => setPostsPerPage(e.target.value)}
                ></input>{" "}
                /{asicTotalStatement ? asicTotalStatement.length : 0}
              </div>
              <div class="col-md-6">
                {postsPerPage && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={
                      asicTotalStatement ? asicTotalStatement.length : 0
                    }
                    pageSize={postsPerPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </footer>
      )}
      <br/>
      <br/>
    </div>
  );
};
export default ASICDept;
