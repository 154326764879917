import React from 'react';
import "../CompanyDetailsComponent/CompanyDetails.styles.scss";
import AllotmentJournal from './AllotmentJournalComponent';
import CompanyListReport from './CompanyListReportComponent';
import CorporateRegisterMembers from './CorporateRegisterMembers.Component';
import DirectorsAndSecretaries from './DirectorsAndSecretariesComponent';
import DirectorShareholdingsComponent from './DirectorsShareholdingsComponent';
import PublicOfficerComponent from './PublicOfficerComponent';
import ResponsibleManagerComponent from './ResponsibleManagerComponent';
import TransferJournalComponent from './TransferJournalComponent';



const CorporateRegisterComponent = (props) => {
  return (
   
    <div>
    {console.log('props.searchCompany:::::::::::::::::::::::::::', props.searchCompany)}
      <div>
        <details open>
          <summary>Members</summary>
          <div className="faq-content">
          
      <CorporateRegisterMembers    searchCompany={props.searchCompany} history={props.history}/>
          </div>
        </details>
      </div>
      <details>
        <summary> Directors and Secretaries  </summary>
        <div className="faq-content">
          <DirectorsAndSecretaries    searchCompany={props.searchCompany} history={props.history}/>
        </div>
      </details>

      <details>
        <summary>Allotment Journal  </summary>
        <div className="faq-content">
        <AllotmentJournal    searchCompany={props.searchCompany} history={props.history}/>
         
        </div>
      </details>
      <details>
        <summary>Transfer journal  </summary>
        <div className="faq-content">
     <TransferJournalComponent    searchCompany={props.searchCompany} history={props.history}/>
        </div>
      </details>
      <details>
        
        <summary>Responsible Manager </summary>
        <div className="faq-content">
          <ResponsibleManagerComponent searchCompany={props.searchCompany} history={props.history}/>
        </div>
      </details>
      <details>
        <summary>Public Officer</summary>
        <div className="faq-content">
        <PublicOfficerComponent searchCompany={props.searchCompany} history={props.history}/>
        </div>
      </details>
          <details>
        <summary>Directors Shareholdings</summary>
        <div className="faq-content">
          <DirectorShareholdingsComponent searchCompany={props.searchCompany} history={props.history}/>
        </div>
      </details>
      <details>
      <summary>Company List (inc Email)</summary>
      <div className="faq-content">
    <CompanyListReport searchCompany={props.searchCompany} history={props.history}/>
      </div>
    </details>
      <details>
      <summary>Address Search</summary>
      <div className="faq-content">
       Address Search Under construction
      </div>
    </details>
    <details>
    <summary>Name Search</summary>
    <div className="faq-content">
      Name Search Under construction
    </div>
  </details>
  <details>
  <summary>Others</summary>
  <div className="faq-content">
  Under construction
  </div>
</details>
    </div>
  );
};
    export default CorporateRegisterComponent;