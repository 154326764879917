import { userConstants } from "../constants/login.constants";

export default function companyProfileReport(state = {}, action) {
    switch (action.type) {
        case userConstants.COMPANYPROFILEREPORT_REQUEST:
            return {
                loading: true
            };
        case userConstants.COMPANYPROFILEREPORT_SUCCESS:
            return {
                items: action.companyProfileReport
            };
        case userConstants.COMPANYPROFILEREPORT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}