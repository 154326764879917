import { userConstants } from "../constants/login.constants";

 function individualEntitiesShares(state = {}, action) {
    switch (action.type) {
        case userConstants.INDIVDUALENTITIESSHARES_REQUEST:
            return {
                loading: true
            };
        case userConstants.INDIVDUALENTITIESSHARES_SUCCESS:
            return {
                items: action.individualEntitiesShares
            };
        case userConstants.INDIVDUALENTITIESSHARES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export default individualEntitiesShares;