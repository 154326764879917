import { userConstants } from "../constants/login.constants";

 function postRecentCompanyLIst(state = {}, action) {
    switch (action.type) {
        case userConstants.POSTRECENTCOMPANYLIST_REQUEST:
            return {
                loading: true
            };
        case userConstants.POSTRECENTCOMPANYLIST_SUCCESS:
            return {
                items: action.postRecentCompanyLIst
            };
        case userConstants.POSTRECENTCOMPANYLIST_FAILURE:
            return {
                error: action.errorPostRecentCompanyLIst
            };
        default:
            return state
    }
}
export default postRecentCompanyLIst;