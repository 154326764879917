import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";

import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

import { HashRouter } from "react-router-dom";
import { Provider} from 'react-redux';
import {store, persistor} from './redux/store/store';
import {PersistGate} from 'redux-persist/integration/react';


ReactDOM.render(

<HashRouter>
    <React.StrictMode>
    <Provider store={store}>
    <PersistGate persistor={persistor}>
            <App />
      </PersistGate>
     </Provider>
    </React.StrictMode>
    </HashRouter>,
      document.getElementById("root")
);

reportWebVitals();
