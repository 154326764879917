import { userConstants } from "../constants/login.constants";

  function eBinderLink(state = {}, action) {
     switch (action.type) {
         case userConstants.EBINDERLINKS_REQUEST:
             return {
                 loading: true
             };
         case userConstants.EBINDERLINKS_SUCCESS:
             return {
                 items: action.eBinderLink
             };
         case userConstants.EBINDERLINKS_FAILURE:
             return {
                 error: action.error
             };
         default:
             return state
     }
 }
 export default eBinderLink;