import React, { useState, useEffect,useCallback } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../redux/actions/user.actions";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";

const OfficeHolderAppointementComponent = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.getAllCountries());
    setOfficerShareHoldersData((value) => ({ ...value, name: officerNames && shareHolderNames && officerNames.concat(shareHolderNames)} ));
    // eslint-disable-next-line
  }, []);
  const countries = useSelector((state) => state.countries);


  // const PLACE_API = process.env.REACT_GOOGLE_PLACE_API;

  let handleClickClearDate = (e, isDate, name, index) => {
    if (isDate) {
      const list = [...props.appointmentInputlist];
          list[index][name] = "";
          props.setAppointmentInputlist(list);
          props.setAsicDetails((changeOrder) => ({
            ...changeOrder,
            appointmentInputlist:list
          }));
      /*setOfficeHolderAppointement((OfficeHolderAppointement) => ({
        ...OfficeHolderAppointement,
        [name]: "",
      }));*/
    }
  };



  const  shareHolderNames=  props.companyProfile && props.companyProfile.items &&  props.companyProfile.items.shareholders && props.companyProfile.items.shareholders.map((value) =>  
  `${value.NAME}:  ${value.CLASS_CODE}:  ${value.CLASS_NO}:  FP=${value.FULLY_PAID ? "Yes" : "no"}:  BO=${value.BEN_OWNER ? "Yes" : "no"}
  :  Total Shares=${value.SUM_TOT_SHARES} : ${value.BEN_OWNER ? "Beneficially Held" : ""}` );

  const duplicatedOfficer=
  props.companyProfile &&
  props.companyProfile.items &&
  props.companyProfile.items.officers.filter(
    (thing, index, self) =>
      index === self.findIndex((t) => t.OFFICER_NAME === thing.OFFICER_NAME)
  );

  const  officerNames=   duplicatedOfficer&& duplicatedOfficer.map((value) =>     `${value.CEASED!=="" ? value.OFFICER_NAME:""}` );
 


  
  const [officerShareHoldersData, setOfficerShareHoldersData] = useState({
    name:[]
  });

  const handleSearch = useCallback((q) => {

      const list = [...props.appointmentInputlist];
              list[props.appointIndex]["appointedOfficerName"] = [q];
              props.setAppointmentInputlist(list);
                props.setAsicDetails((changeOrder) => ({ ...changeOrder, appointmentInputlist: list }));
             
        //props.setAsicDetails((value) => ({ ...value, appointedOfficerName: [q] }));
    
      // eslint-disable-next-line
    }, []);

  return (
    <div class="container ">
      <form>
        <hr />
        <div class="form-group row px-4 ">
          <div class="col-xs-3">
            <label htmlFor="appointedOfficerName" className="col-form-label">
              Officer Name{" "}
              <i
                class="bi bi-info-circle px-1"
                data-toggle="tooltip"
                data-placement="top"
                title="Full legal Name as per Passport"
              ></i>
            </label>
         
  {/*    <input
              type="text"
              name="appointedOfficerName"
              id="appointedOfficerName"
              className="form-control "
              placeholder="Text..."
              value={props.appointElements.appointedOfficerName}
              onChange={(e) =>props.handleAppointmentInputChange(e,props.appointIndex)}
       autoComplete='off' 
            />
*/}
            <AsyncTypeahead
            id="appointedOfficerName"
          
            labelKey={(option) =>
                             `${option.CEASED!=="" ? option.OFFICER_NAME:""}`
                           
            }
        
            options={
              props.companyProfile &&
              props.companyProfile.items ?
              props.companyProfile.items.officers.filter(
                (thing, index, self) =>
                  index === self.findIndex((t) => t.OFFICER_NAME === thing.OFFICER_NAME)
              ):[]
            }
            isLoading={false}
            onSearch={handleSearch}
            onChange={(selected) => {
             if (selected && selected!=='undefined') {
              const list = [...props.appointmentInputlist];
              list[props.appointIndex]["appointedOfficerName"] = selected;
              props.setAppointmentInputlist(list);
                props.setAsicDetails((changeOrder) => ({ ...changeOrder, appointmentInputlist: list }));
             }
      
            }}
           // selected={props.asicDetails.signerName!=='undefined' && props.asicDetails.signerName}
           placeholder="Text..."
            useCache={false}
           //selected={props.appointElements.appointedOfficerName}
           autoFocus={false}
          />

          </div>
          <div class="col-xs-3 ">
            <label htmlFor="officerAddress" className="col-form-label">
              Officer Address
            </label>

            <GooglePlacesAutocomplete
              apiKey={"AIzaSyD8pHI8_Rviq7fmkxO5TBlJmaQl7kNHpE0"}
              selectProps={{
                placeholder: "Text...",
                isClearable: true,
                predefinedPlacesAlwaysVisible: true,
                value: props.appointElements.officerAddress,
                onChange: (val) => {
                  const list = [...props.appointmentInputlist];
          list[props.appointIndex]["officerAddress"] = val;
          props.setAppointmentInputlist(list);
          props.setAsicDetails((changeOrder) => ({
            ...changeOrder,
            appointmentInputlist:list
          }));
           
          },
        }}
      />
          
            <span className="text-secondary">
              Address required for new Officer
            </span>
          </div>

          <div class="container">
            <br />
            <div class="row">
              <div class="col-sm  d-flex justify-content-start">
                <label
                  htmlFor="position1"
                  className="col-form-label input-sm px-5 "
                >
                  Position 1
                </label>
              </div>
              <div class="col-sm d-flex justify-content-start">
                <select
                  name="position1"
                  id="position1"
                  className=" dropDownBox  w-100 "
                  onChange={(e) =>{props.handleAppointmentInputChange(e,props.appointIndex)}}

                >
                  <option value=""></option>
                  <option value="Director">Director</option>
                  <option value="Secretary">Secretary</option>
                  <option value="AlternateDirector">Alternate Director</option>
                  <option value="PublicOfficer">Public Officer</option>
                </select>
              </div>
              <div class="col-sm  d-flex justify-content-end">
                <label
                  htmlFor="changeDate"
                  className="col-form-label input-sm  "
                >
                  Change Date{" "}
                  <i
                    class="bi bi-info-circle px-1"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="ASIC late fees apply if form is not lodgged with in 28days of change date"
                  ></i>
                </label>
              </div>
              <div class="col-sm ">
                <DatePicker
                  className="form-control input-sm "
                  name="position1ChangeDate"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"

                  selected={props.appointElements.position1ChangeDate}
                  onChange={(e) =>{
                    const list = [...props.appointmentInputlist];
                    list[props.appointIndex]["position1ChangeDate"] = e;
                    props.setAppointmentInputlist(list);
                    props.setAsicDetails((changeOrder) => ({
                      ...changeOrder,
                      appointmentInputlist:list
                    }));
                  }
                  }
                  value={props.appointElements.position1ChangeDate}
                />
              </div>
              <div class="col-sm ">
                <i
                  class="bi bi-x-circle-fill text-danger hand-cursor"
                  onClick={(e) =>
                    handleClickClearDate(e, true, "position1ChangeDate",props.appointIndex)
                  }
                ></i>
              </div>
            </div>
          </div>
          <div class="container">
            <br />
            <div class="row">
              <div class="col-sm  d-flex justify-content-start">
                <label
                  htmlFor="position2"
                  className="col-form-label input-sm px-5 "
                >
                  Position 2
                </label>
              </div>
              <div class="col-sm d-flex justify-content-start">
                <select
                  name="position2"
                  id="position2"
                  className=" dropDownBox  w-100 "
                  onChange={(e) =>{props.handleAppointmentInputChange(e,props.appointIndex)}}
                >
                <option value=""></option>
                <option value="Director">Director</option>
                <option value="Secretary">Secretary</option>
                <option value="AlternateDirector">Alternate Director</option>
                <option value="PublicOfficer">Public Officer</option>
                </select>
              </div>
              <div class="col-sm  d-flex justify-content-end">
                <label
                  htmlFor="position2ChangeDate"
                  className="col-form-label input-sm  "
                >
                  Change Date{" "}
                  <i
                    class="bi bi-info-circle px-1"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="ASIC late fees apply if form is not lodgged with in 28days of change date"
                  ></i>
                </label>
              </div>
              <div class="col-sm ">
                <DatePicker
                  className="form-control input-sm "
                  name="position2ChangeDate"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"

                  selected={props.appointElements.position2ChangeDate}
              onChange={(e) =>{
                const list = [...props.appointmentInputlist];
                list[props.appointIndex]["position2ChangeDate"] = e;
                props.setAppointmentInputlist(list);
                props.setAsicDetails((changeOrder) => ({
                  ...changeOrder,
                  appointmentInputlist:list
                }));
              }
              }
              value={props.appointElements.position2ChangeDate}
                />
              </div>
              <div class="col-sm ">
                <i
                  class="bi bi-x-circle-fill text-danger hand-cursor"
                  onClick={(e) =>
                    handleClickClearDate(e, true, "position2ChangeDate",props.appointIndex)
                  }
                ></i>
              </div>
            </div>
          </div>

          <div class="container">
            <br />
            <div class="row">
              <div class="col-sm  d-flex justify-content-start">
                <label
                  htmlFor="position3"
                  className="col-form-label input-sm px-5 "
                >
                  Position 3
                </label>
              </div>
              <div class="col-sm d-flex justify-content-start">
                <select
                  name="position3"
                  id="position3"
                  className=" dropDownBox  w-100 "
                  onChange={(e) =>{props.handleAppointmentInputChange(e,props.appointIndex)}}
                >
                <option value=""></option>
                <option value="Director">Director</option>
                <option value="Secretary">Secretary</option>
                <option value="AlternateDirector">Alternate Director</option>
                <option value="PublicOfficer">Public Officer</option>
                </select>
              </div>
              <div class="col-sm  d-flex justify-content-end">
                <label
                  htmlFor="position3ChangeDate"
                  className="col-form-label input-sm  "
                >
                  Change Date{" "}
                  <i
                    class="bi bi-info-circle px-1"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="ASIC late fees apply if form is not lodgged with in 28days of change date"
                  ></i>
                </label>
              </div>
              <div class="col-sm ">
                <DatePicker
                  className="form-control input-sm "
                  name="position3ChangeDate"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"

                  selected={props.appointElements.position3ChangeDate}
                  onChange={(e) =>{
                    const list = [...props.appointmentInputlist];
                    list[props.appointIndex]["position3ChangeDate"] = e;
                    props.setAppointmentInputlist(list);
                    props.setAsicDetails((changeOrder) => ({
                      ...changeOrder,
                      appointmentInputlist:list
                    }));
                  }
                  }
                  value={props.appointElements.position3ChangeDate}
                />
              </div>
              <div class="col-sm ">
                <i
                  class="bi bi-x-circle-fill text-danger hand-cursor"
                  onClick={(e) =>
                    handleClickClearDate(e, true, "position3ChangeDate",props.appointIndex)
                  }
                ></i>
              </div>
            </div>
          </div>

          <div class="col-xs-3">
            <label htmlFor="alternateDirectorFor" className="col-form-label">
              Alternate Director For
            </label>
        

            <Typeahead
            id="alternateDirectorFor"
            labelKey={
              officerShareHoldersData &&
              officerShareHoldersData.name &&
              officerShareHoldersData.name.map((value) => value.name)
            }
            options={
              officerShareHoldersData && officerShareHoldersData.name
              ? officerShareHoldersData.name
              : []
            }
            isLoading={false}
            onSearch={handleSearch}
            autoFocus={false}
            onChange={(selected) => {
              
                const list = [...props.appointmentInputlist];

                list[props.appointIndex]["alternateDirectorFor"] = selected;
              
                props.setAppointmentInputlist(list);
                props.setAsicDetails((changeOrder) => ({
                  ...changeOrder,
                  appointmentInputlist: list,
                }));
                
            }}
            selected={props.appointElements.alternateDirectorFor}
            placeholder="Text..."
            useCache={false}
          />
         
         
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="dOB" className="col-form-label input-sm ">
                DOB
              </label>

              <DatePicker
                className="form-control input-sm w-25"
                name="dOB"              
                 dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
        selected={props.appointElements.dOB}
                             onChange={(e) =>{
                               const list = [...props.appointmentInputlist];
                               list[props.appointIndex]["dOB"] = e;
                               props.setAppointmentInputlist(list);
                               props.setAsicDetails((changeOrder) => ({
                                 ...changeOrder,
                                 appointmentInputlist:list
                               }));
                             }
                             }
                             value={props.appointElements.dOB}
                              autoComplete="off"                               
              />
            </div>
            <div className="col">
              <label
                htmlFor="placeOfBirth"
                className="col-form-label input-sm "
              >
                Place of Birth
              </label>
              <input
                type="text"
                name="placeOfBirth"
                className="form-control "
                placeholder="Text..."
                value={props.appointElements.placeOfBirth}
                onChange={(e) =>props.handleAppointmentInputChange(e,props.appointIndex)}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label
                htmlFor="stateofBrith"
                className="col-form-label input-sm "
              >
                State of Brith
              </label>
              <select
                name="stateofBrith"
                id="stateofBrith"
                className=" dropDownBox  w-100 "
                onChange={(e) =>{props.handleAppointmentInputChange(e,props.appointIndex)}}
              >
                <option value="" selected> </option>
                <option value="ACT">ACT</option>
                <option value="NSW">NSW</option>
                <option value="NT">NT</option>
                <option value="QLD">QLD</option>
                <option value="SA">SA</option>
                <option value="TAS">TAS</option>
                <option value="VIC">VIC</option>
                <option value="WA">WA</option>
              </select>
            </div>
            <div className="col">
              <label
                htmlFor="countryOfBirth"
                className="col-form-label input-sm "
              >
                Country of Brith
              </label>
              <select
                name="countryOfBirth"
                id="countryOfBirth"
                className=" dropDownBox  w-100 "
                onChange={(e) =>{props.handleAppointmentInputChange(e,props.appointIndex)}}
              >
                <opition> </opition>
             
                {countries &&
                  countries.items &&
                  countries.items.map((value) => (
                    <option
                      value={value.common}
                      selected={value.common === "Australia"}
                    >
                      {value.common}
                    </option>
                  ))}
              </select>
            </div>
          </div>

      
        </div>
      </form>
    </div>
  );
};
export default OfficeHolderAppointementComponent;
