import React, { useState, useEffect } from "react";
import "../MonitorsComponents/AnnualReviews.style.scss";
import Pagination from "../CommonPage/Pagination.component";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";

const UnitTrustRegisterComponent = (props) => {
  // shim to fix page size bug (not showing 7 records... only 5)
  const PAGE_MAX_SIZE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(PAGE_MAX_SIZE);

  let handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getUnitTrustTable("all"));
    

    // eslint-disable-next-line
  }, []);
  const unitTrustTable = useSelector((state) => state.unitTrustTable);
 
  // search
  const [searchInputUnitTrustTable, setSearchInputUnitTrustTable] =
    useState("");
  const unitTrustTableTotal =
  unitTrustTable && unitTrustTable.items
      ? unitTrustTable.items.filter(
          (filteredValue) =>
            filteredValue["CO_NAME"] &&
            filteredValue["CO_NAME"]
              .toLowerCase()
              .includes(searchInputUnitTrustTable.toLowerCase())
        )
      : [];

  // console.log(posts);

  const indexOfLastPost = currentPage * postsPerPage;
  // console.log("indexOfLastPost: ", indexOfLastPost);

  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // console.log("indexOfFirstPost: ", indexOfFirstPost);

  const currentPosts = unitTrustTableTotal
    ? unitTrustTableTotal.slice(indexOfFirstPost, indexOfLastPost)
    : [];

    function handleClickRegisterAction(e, rn) {
        dispatch(userActions.getUnitTrustUnitholdersReport(rn));
      }
    
      function handleClickAAJournalAction(e, rn) {
        dispatch(userActions.getUnitTrustApplicationAllotmentJournalReport(rn));
      }
    
      function handleClickUnitsTransferJournalAction(e, rn) {
        dispatch(userActions.getUnitTrustTransferJournalReport(rn));
      }
    
      function handleClickUnitholdersSummaryAction(e, rn) {
        dispatch(userActions.getUnitTrustUnitholdersSummaryReport(rn));
      }

  // refresh button
  function handleClickUnitTrustTable(e) {
    e.preventDefault();
    setSearchInputUnitTrustTable("");
  }

  return (
    <div className="m-4 fontfamily">
      <h2 className="panel-title">Unit Trust Register</h2>

      <nav className="navbar navbar-light bg-light navbar-expand-md ">
        <div className="container">
          <div
            className="flex-row-reverse navbar-collapse d-flex"
            id="main-nav-collapse"
          >
            <ul className="navbar-nav">
              <div className=" col form-outline">
                <input
                  type="search"
                  id="unitTrustCompaniesSearch"
                  name="unitTrustCompaniesSearch"
                  className="form-control-sm "
                  size="50"
                  placeholder="Search"
                  aria-label="Search"
                  autoComplete={false}
                  value={searchInputUnitTrustTable}
                  onChange={(e) => {
                    setSearchInputUnitTrustTable(e.target.value);
                  }}
                />
              </div>
              &nbsp;
              <button
                className="btn-sm"
                onClick={(e) => handleClickUnitTrustTable(e)}
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </ul>
          </div>
        </div>
      </nav>

      <div className="table-responsive-sm">
        <table className="table table-hover fs-6">
          <thead>
            <tr>
              <th className="text-wrap">UnitTrust Name</th>
              <th className="text-wrap w-25">	Trustee</th>

              <th> Action </th>
            </tr>
          </thead>

          <tbody className=" fs-6">
            {
              unitTrustTable &&  currentPosts&& currentPosts.length ===0 ?              
              <tr><td colSpan="3">No matching records found</td></tr>:
              unitTrustTable &&
              currentPosts &&
              currentPosts.map((post) => (
                <tr>
                  <td>{post.TRUST_NAME} </td>
                  <td>{post.CO_NAME} </td>

                  <td>
                  <a
                  href={() => false}
                  className="text-white d-flex align-items-center text-decoration-none "
                  id="dropdownUserDetail"
                  data-bs-toggle="dropdown"
                //  aria-expanded="false"
                >
                  <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                </a>

                <ul className="dropdown-menu text-dark ">
                  <li className="action">
                    <a
                      className="dropdown-item "
                      href={() => false}
                      onClick={(e) =>
                        handleClickRegisterAction(e, post.RN)
                      }
                    >
                      Register of Unitholders
                    </a>{" "}
                    <hr />
                  </li>

                  <li className="action ">
                    <a
                      className="dropdown-item "
                      href={() => false}
                      onClick={(e) =>
                        handleClickAAJournalAction(e, post.RN)
                      }
                    >
                      Units - Application and Allotment Journal
                    </a>
                    <hr />
                  </li>
                  <li className="action">
                    <a
                      className="dropdown-item"
                      href={() => false}
                      onClick={(e) =>
                        handleClickUnitsTransferJournalAction(e, post.RN)
                      }
                    >
                      Units Transfer Journal
                    </a>
                    <hr />
                  </li>
                  <li className="action ">
                    <a
                      className="dropdown-item"
                      href={() => false}
                      onClick={(e) =>
                        handleClickUnitholdersSummaryAction(e, post.RN)
                      }
                    >
                      Unitholders Summary
                    </a>
                  </li>
                </ul>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {unitTrustTableTotal && unitTrustTableTotal.length >= PAGE_MAX_SIZE && (
        <footer
          id="sticky-footer"
          class="footer d-flex justify-content-end footer-body  "
        >
          <div class="container fs-6 ">
            <div class="row">
              <div class="col-md-6 ">
                Showing{" "}
                <input
                  type="text"
                  className="border form-control-sm border-default"
                  onKeyPress={handleKeyPress}
                  name="showRecord"
                  value={postsPerPage}
                  maxLength="3"
                  size="3"
                  onChange={(e) => setPostsPerPage(e.target.value)}
                ></input>
                {""}/{unitTrustTableTotal ? unitTrustTableTotal.length : 0}
              </div>
              <div class="col-md-6">
                {postsPerPage && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={
                        unitTrustTableTotal ? unitTrustTableTotal.length : 0
                    }
                    pageSize={postsPerPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </footer>
      )}
      <br/>
      <br/>
    </div>
  );
};
export default UnitTrustRegisterComponent;
