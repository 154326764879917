import React, { useState  } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";
import FullPageLoader from "../CommonPage/FullPageLoader";


const ResponsibleManagerComponent = (props) => {
  const dispatch = useDispatch();
  //loading spinner
const [isResponsibleManagerLoaded, setIsResponsibleManagerLoaded] = useState(false);

  const [submitted, setSubmitted] = useState(false);
    const [responsibleManager, setResponsileManager] = useState({
      rmPrintDate: new Date(),
      rofficer: 'Yes'
  });
  
  let handleChange = (e, isDate) => {
    if(isDate){
        setResponsileManager(responsibleManager => ({ ...responsibleManager,rmPrintDate: e }))
    } 
    else {
        const { name, value } = e.target;
        setResponsileManager(responsibleManager => ({ ...responsibleManager, [name]: value }))
      }
     
    }

    const companyCode = JSON.parse(localStorage.getItem("companyCode"));
    const inputSearchValue = JSON.parse(localStorage.getItem("inputSearchValue"));
    const validate = companyCode !== null || (inputSearchValue !== null && inputSearchValue.length > 0) || (props.searchCompany && props.searchCompany.length > 0);
  
    function handleClick(e) {
      setSubmitted(true);
      const selectedSearchValue = localStorage.getItem("inputSearchValue");
  
      const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
      if(props.searchCompany && props.searchCompany[0]) {
        dispatch(userActions.getResponsibleManagerReport(responsibleManager, props.searchCompany[0].Code,setIsResponsibleManagerLoaded));
      } else if(selectedCompanyCode !== null) {
        dispatch(userActions.getResponsibleManagerReport(responsibleManager, selectedCompanyCode,setIsResponsibleManagerLoaded));
      } else if (selectedSearchValue !== null) {
        JSON.parse(selectedSearchValue).map((value) =>
          dispatch(userActions.getResponsibleManagerReport(responsibleManager, value.Code,setIsResponsibleManagerLoaded))
        );
      }
    }
    return (
      <div class="container">
      <h3 className="mb-4">Enter Values</h3>
         
          <table className="table table-responsive table-borderless">
          <tbody>
          <tr>
          <td  className="fw-bold w-25 " >Enter as at Date (Click on Blank for Current Date)</td>
           <td colspan="2" >
            <DatePicker
            className="w-50"
            name="printDate"
            selected={responsibleManager.rmPrintDate}
            onChange={(e) => handleChange(e, true)}
            dateFormat="dd/MM/yyyy"
            placeholderText="Enter as at Date"
            value={responsibleManager.rmPrintDate}
          />  
            </td>
          </tr>
          <tr>
          <td  className="fw-bold w-50 " >Enter Current Officers </td>
          <td >
         
            <label><input type="radio" name="rofficer"   checked={responsibleManager.rofficer  === 'Yes'} onClick={handleChange} value="Yes"/>Yes</label>
            </td>
            <td className="w-75">
      
            <label><input type="radio" name="rofficer"  checked={responsibleManager.rofficer === 'No'} onClick={handleChange} value="No"/>NO</label>

          </td>
        </tr>
          <tr><td>         {" "}
          <button
            className={
              submitted && !validate ? "btn btn-danger" : "btn btn-primary"
            }
            onClick={(e) => handleClick(e)}
          >
            Show Report 
          </button>{isResponsibleManagerLoaded ? <FullPageLoader /> :null}
          {submitted && !validate && (
            <div className="text-danger py-3">Company Name not selected.</div>
          )} </td></tr>
       
          </tbody>   </table>
        
         
        </div>
        );
      };
      
  
  export default ResponsibleManagerComponent;
  