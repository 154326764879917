import React, { useState, useEffect } from "react";
import "../MonitorsComponents/AnnualReviews.style.scss";
import "../CommonPage/Action.scss";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import moment from "moment";
import Popup from "../CommonPage/Popup.component";
import FullPageLoader from "../CommonPage/FullPageLoader";
import Form484InformationComponent from "../CommonPage/Form484Information.component";

const DocumentTrackingComponent = (props) => {
  const [selectedOption, setSelectedOption] = useState("484");

  const asicDocumentTracking = useSelector(
    (state) => state.asicDocumentTracking
  );
  const totalLength =
    asicDocumentTracking &&
    asicDocumentTracking.items && asicDocumentTracking.items[0] &&
    asicDocumentTracking.items[0].DOCUMENTS;
  const currentPosts = totalLength;

  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});

  const handleRow = (e, code) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(code);

    let obj = {};
    isRowExpanded ? (obj[code] = false) : (obj[code] = true);

    setExpandState(obj);
    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== code)
      : currentExpandedRows.concat(code);

    setExpandedRows(newExpandedRows);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));

    if (selectedCompanyCode !== null) {
      dispatch(userActions.getAsicDocumentTracking(selectedCompanyCode));
    } else {
      const selectedSearchValue = localStorage.getItem("inputSearchValue");
      if (selectedSearchValue !== null) {
        const companyCode = JSON.parse(selectedSearchValue).map((value) => {
          return value.Code;
        });
        if (companyCode) {
          dispatch(userActions.getAsicDocumentTracking(companyCode));
        }
      }
    }
    handle484DocTrackPopupClose();
    handleDocPopupClose();
    // eslint-disable-next-line
  }, []);

  //loading spinner
  const [isDocLoaded, setIsDocLoaded] = useState(false);
  //Document Tracking particular document  report

  const documentHistoryDocDetails = useSelector(
    (state) => state.documentHistoryDocDetails
  );
  let docHandleClick = (e, rN, formType, docNo) => {
    e.preventDefault();
    dispatch(
      userActions.getDocumentHistoryDocReport(
        rN,
        formType,
        docNo,
        setIsDocLoaded
      )
    );
    handleDocPopupShow(e, docNo);
  };

  const [popupDocShow, setPopupDocShow] = useState(0);
  const handleDocPopupClose = () => setPopupDocShow(false);
  const handleDocPopupShow = (e, index) => {
    e.preventDefault();
    setPopupDocShow(index);
  };

  let handleDocPopupClick = (e) => {
    e.preventDefault();
    handleDocPopupClose();
  };

  // Form 484 information Popup.
  const form484Information = useSelector((state) => state.form484Information);

  //loading spinner
  const [isForm484DocTrackLoaded, setIsForm484DocTrackLoaded] = useState(false);
  // eslint-disable-next-line
  const [submitted, setSubmitted] = useState(false);
  function form484DocTrackHandleClick(e, rN, docNo) {
    setSubmitted(true);
    dispatch(
      userActions.getForm484Information(rN, docNo, setIsForm484DocTrackLoaded)
    );
    handle484DocTrackPopupShow(e, docNo);
  }

  const [popup484DocTrackShow, setPopup484DocTrackShow] = useState(0);
  const handle484DocTrackPopupClose = () => setPopup484DocTrackShow(false);
  const handle484DocTrackPopupShow = (e, index) => {
    e.preventDefault();
    setPopup484DocTrackShow(index);
  };
  let handle484DocTrackPopupClick = (e) => {
    e.preventDefault();
    handle484DocTrackPopupClose();
  };

  return (
    <div className="m-4 fontfamily">
    
      <h3>DocumentTracking</h3>

      <table className="table  table-responsive-sm   fs-6 table-light table-striped">
        <thead>
          <tr>
            <th className="text-wrap "> </th>
            <th className="text-wrap w-25 ">Company </th>
            <th className="text-wrap w-50">Company Name</th>
            <th className="text-wrap w-50">ACN </th>
            <th>Action </th>
          </tr>
        </thead>
        {asicDocumentTracking.items &&
          asicDocumentTracking.items.map((value) => (
            <tbody>
              <tr key={value.CO_CODE}>
                <td>
                  {expandState[value.CO_CODE] ? (
                    <i
                      class="bi bi-caret-up-fill text-danger"
                      onClick={(e) => handleRow(e, value.CO_CODE)}
                    ></i>
                  ) : (
                    <i
                      class="bi bi-caret-down-fill text-success"
                      onClick={(e) => handleRow(e, value.CO_CODE)}
                    ></i>
                  )}
                </td>
                <td>{value.CO_CODE}</td>
                <td>{value.CO_NAME}</td>
                <td>{value.ACN}</td>
                <td>
                  <a
                    href={() => false}
                    className="d-flex align-items-center text-white text-decoration-none "
                    id="dropdownUserDetail"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                  </a>

                  <ul className="dropdown-menu  text-dark">
                    <li className="action ">
                      <a className="dropdown-item " href={() => false}>
                        Print Document History
                      </a>
                    </li>
                  </ul>
                </td>
              </tr>
              {!expandedRows.includes(value.CO_CODE) && (
                <tr>
                  <td colSpan="5">
                    <table className="table  fs-6 table-light ">
                      <thead>
                        <tr>
                          <th className="w-25">
                            <select
                              name="allform"
                              id="allform"
                              className="dropDownBox text-nowrap "
                              onChange={(e) => {
                                setSelectedOption(e.target.value);
   setPopup484DocTrackShow("");

                              }}
                            >
                              <option value="">AllForms</option>
                              {Array.from(
                                new Set(
                                  currentPosts &&
                                    currentPosts.map((obj) => obj.FORM_TYPE)
                                )
                              ).map((value) => {
                                return (
                                  <option
                                    value={value}
                                    selected={value === "484"}
                                  >
                                    {value}
                                  </option>
                                );
                              })}
                            </select>
                          </th>
                          <th className="w-50">Form Title</th>
                          <th className="text-wrap ">Form Date</th>
                          <th className="text-wrap">Lodged Date</th>
                          <th>ELS</th>
                          <th className="text-wrap">Doc No.</th>
                          <th className="text-wrap">ASIC Doc No.</th>
                          <th className="text-wrap">Emailed</th>
                          <th className="text-wrap">Order No.</th>
                         {/*  <th>Action</th>*/}
                          <th>
                            <i class="bi bi-info-circle-fill"></i>
                          </th>
                        </tr>
                      </thead>

                      <tbody className=" fs-6">
                        {currentPosts &&
                          currentPosts
                            .filter((name) =>
                              name.FORM_TYPE.includes(selectedOption)
                            )
                            .map((value, index) => (
                              <tr>
                                <td>{value.FORM_TYPE}</td>
                                <td> {value.FORM_TYPE_DESC}</td>

                                <td class="w-20">
                                  {value.FORM_DATE
                                    ? moment(value.FORM_DATE).format(
                                        "DD/MM/YYYY"
                                      )
                                    : ""}
                                </td>
                                <td>
                                  {value.LODGE_DATE
                                    ? moment(value.LODGE_DATE).format(
                                        "DD/MM/YYYY"
                                      )
                                    : ""}
                                </td>

                                <td> {value.ELS_TRANSMIT ? "Y" : "N"}</td>

                                <td>
                                  {" "}
                                  <button
                                    type="button"
                                    className="btn btn-link  "
                                    onClick={(e) =>
                                      docHandleClick(
                                        e,
                                        value.RN,
                                        value.FORM_TYPE,
                                        value.DOC_NO
                                      )
                                    }
                                  >
                                    {" "}
                                    {value.DOC_NO}
                                  </button>
                                  {popupDocShow === value.DOC_NO &&
                                  documentHistoryDocDetails &&
                                  documentHistoryDocDetails.error &&
                                  documentHistoryDocDetails.error.indexOf(
                                    "400"
                                  ) !== -1 ? (
                                    <Popup
                                      show={popupDocShow === value.DOC_NO}
                                      heading={
                                        "Error occured in Document package"
                                      }
                                      handleClose={handleDocPopupClose}
                                      handleShow={(e) =>
                                        handleDocPopupShow(e, value.DOC_NO)
                                      }
                                      body={
                                        " No Documents Package on File -" +
                                        value.DOC_NO
                                      }
                                      handleClick={handleDocPopupClick}
                                    />
                                  ) : popupDocShow === value.DOC_NO &&
                                    isDocLoaded ? (
                                    <FullPageLoader />
                                  ) : null}
                                </td>

                                <td>{value.ASIC_DOC_NO}</td>
                                <td>
                                  {value.EMAILED
                                    ? moment(value.EMAILED).format("DD/MM/YYYY")
                                    : ""}
                                </td>
                                <td>{value.ORDER_ID}</td>
                                  {/* 
                                <td>
                                  <a
                                    href={() => false}
                                    className="d-flex align-items-center text-white text-decoration-none "
                                    id="dropdownUserDetail"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                                  </a>

                                  <ul className="dropdown-menu  text-dark">
                                    <li className="action ">
                                      <a
                                        className="dropdown-item "
                                        href={() => false}
                                      >
                                        View document Package
                                      </a>
                                      <hr />
                                    </li>
                                    <li className="action ">
                                      <a
                                        className="dropdown-item "
                                        href={() => false}
                                      >
                                        Summary
                                      </a>
                                      <hr />
                                    </li>
                                    <li className="py-0 ">
                                      <a
                                        className="dropdown-item "
                                        href={() => false}
                                      >
                                        Folder
                                      </a>
                                    </li>
                                  </ul>
                                </td>
                                */}
                                <td>
                                  {value.FORM_TYPE === "484" ? (
                                    <a
                                      href={() => false}
                                      role="button"
                                      onClick={(e) =>
                                        form484DocTrackHandleClick(
                                          e,
                                          value.RN,
                                          value.DOC_NO
                                        )
                                      }
                                    >
                                      <i class="bi bi-info-circle-fill  hand-cursor text-primary"></i>
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </td>

                                {popup484DocTrackShow === value.DOC_NO ? (
                                  <Popup
                                    show={popup484DocTrackShow === value.DOC_NO}
                                    heading={"Summary"}
                                    handleClose={handle484DocTrackPopupClose}
                                    handleShow={(e) =>
                                      handle484DocTrackPopupShow(
                                        e,
                                        value.DOC_NO
                                      )
                                    }
                                    body={
                                      <Form484InformationComponent
                                        form484Information={form484Information}
                                      />
                                    }
                                    handleClick={handle484DocTrackPopupClick}
                                  />
                                ) : popup484DocTrackShow === value.DOC_NO &&
                                  isForm484DocTrackLoaded ? (
                                  <FullPageLoader />
                                ) : null}
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </tbody>
          ))}
      </table>
    </div>
  );
};
export default DocumentTrackingComponent;
