import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";
import FullPageLoader from "../CommonPage/FullPageLoader";

const ASICCompanyDetail = (props) => {
  const dispatch = useDispatch();
  const [asicSubmitted, setAsicSubmitted] = useState(false);
  //loading spinner
  const [isASICCompanyLoaded, setIsASICCompanyLoaded] = useState(false);

  const [asicCompanyDetail, setAsicCompanyDetail] = useState({
    nameField: "No",
  });

  let handleChange = (e) => {
    const { name, value } = e.target;
    setAsicCompanyDetail((asicCompanyDetail) => ({
      ...asicCompanyDetail,
      [name]: value,
    }));
  };

  const companyCode = JSON.parse(localStorage.getItem("companyCode"));
  const inputSearchValue = JSON.parse(localStorage.getItem("inputSearchValue"));
  const validate = companyCode !== null || (inputSearchValue !== null && inputSearchValue.length > 0) || (props.searchCompany && props.searchCompany.length > 0);

  function handleClick(e) {
    setAsicSubmitted(true);
    const selectedSearchValue = localStorage.getItem("inputSearchValue");

    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if(props.searchCompany && props.searchCompany[0]) {
     
      dispatch(
        userActions.getAsicCompanyDetailReport(
          asicCompanyDetail,
          props.searchCompany[0].Code,
          setIsASICCompanyLoaded
        )
      )
     
    } else if (selectedCompanyCode !== null) {
      dispatch(
        userActions.getAsicCompanyDetailReport(
          asicCompanyDetail,
          selectedCompanyCode,
          setIsASICCompanyLoaded
        )
      );
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(
          userActions.getAsicCompanyDetailReport(
            asicCompanyDetail,
            value.Code,
            setIsASICCompanyLoaded
          )
        )
      );
    }
  }

  return (
    <div class="container">
      <h3 className="mb-4">Enter Values</h3>

      <table className="table table-responsive table-borderless">
        <tbody>
          <tr>
            <td className="fw-bold w-50 ">Show All Given Name Fields?</td>
            <td>
              <label>
                <input
                  type="radio"
                  name="nameField"
                  checked={asicCompanyDetail.nameField === "Yes"}
                  onClick={handleChange}
                  value="Yes"
                />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
                <input
                  type="radio"
                  name="nameField"
                  checked={asicCompanyDetail.nameField === "No"}
                  onClick={handleChange}
                  value="No"
                />
                NO
              </label>
            </td>
          </tr>

          <tr>
            <th>
              {" "}
              <button
                className={
                  asicSubmitted && !validate
                    ? "btn btn-danger"
                    : "btn btn-primary"
                }
                onClick={(e) => handleClick(e)}
              >
                Show Report
              </button>
              {isASICCompanyLoaded ? <FullPageLoader /> : null}
              {asicSubmitted && !validate && (
                <div className="text-danger py-3">
                Company Name not selected
                </div>
              )}
            </th>
          </tr>
        </tbody>{" "}
      </table>
    </div>
  );
};

export default ASICCompanyDetail;
