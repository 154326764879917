import React, { useEffect, useCallback } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
//import Popup from '../CommonPage/Popup.component';
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import "./Search.scss";
import { useHistory } from "react-router-dom";
import "../CommonPage/Action.scss";
import Cookies from "universal-cookie";

const CommonSearch = (props) => {
  const history = useHistory();
  const handleSearch = useCallback((call) => {
    // eslint-disable-next-line
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.getAllCompany(props.searchToken));
    dispatch(userActions.getAllDepartement());
    // eslint-disable-next-line
  }, []);
  const companyCode = JSON.parse(localStorage.getItem("companyCode"));
  const inputSearchValue = JSON.parse(localStorage.getItem("inputSearchValue"));
  const companyName = JSON.parse(localStorage.getItem("companyName"));
  const companyACN = JSON.parse(localStorage.getItem("companyACN"));

  const cookies = new Cookies();

  const handleSignOut = () => {
    cookies.remove("fullName");
    cookies.remove("userName");
    cookies.remove("password");
    cookies.remove("token");
    cookies.remove("roleId");

    props.history.push("/login");
  };

  useEffect(() => {
    if (companyName !== null) {
      props.setSearchCompany([
        { Code: companyCode, Name: companyName, ACN: companyACN },
      ]);
    } else if (inputSearchValue) {
    } else if (inputSearchValue) {
      props.setSearchCompany(inputSearchValue);
    } else {
      if (
        props.recentCompanyList &&
        props.recentCompanyList.items &&
        props.recentCompanyList.items[0]
      ) {
        props.setSearchCompany([
          {
            Code: props.recentCompanyList.items[0].CO_CODE,
            Name: props.recentCompanyList.items[0].CO_NAME,
            ACN: props.recentCompanyList.items[0].CO_ACN,
          },
        ]);
      }
    }

    // if(!companyCode && !inputSearchValue) {
    // handleIistCompanyPopupShow()
    //}
    // eslint-disable-next-line
  }, [props.setSearchCompany, props.recentCompanyList]);

  const companyList = useSelector((state) => state.companyList);

  //const [popupIistCompanyShow, setPopupIistCompanyShow] = useState(false);
  //const handleIistCompanyPopupClose = () => setPopupIistCompanyShow(false);
  // const handleIistCompanyPopupShow = () => setPopupIistCompanyShow(true);
  const userName = localStorage.getItem("userName");
  // popupmessage to select the company
  /*{(!companyCode && !inputSearchValue) &&<Popup show={popupIistCompanyShow} handleClose={handleIistCompanyPopupClose}
//handleShow={()=> handleIistCompanyPopupShow()} body={"Please Select the Company in a Search Bar"}   handleClick={handleIPopupClick}  />}
let handleIPopupClick = (e) => {
   e.preventDefault();
      handleIistCompanyPopupClose();
}
*/
  const recentSearchedCompanies = localStorage.getItem("recentCompanySearch");
  let handleClickCompany = (e) => {
    e.preventDefault();
    const code = e.target.value;
    const splitValue = code && code.split(",");
    if (splitValue) {
      localStorage.setItem("companyCode", JSON.stringify(splitValue[0]));
      localStorage.setItem("companyName", JSON.stringify(splitValue[1]));
      localStorage.setItem("companyACN", JSON.stringify(splitValue[2]));
      props.setSearchCompany([
        {
          Code: JSON.parse(JSON.stringify(splitValue[0])),
          Name: JSON.parse(JSON.stringify(splitValue[1])),
          ACN: JSON.parse(JSON.stringify(splitValue[2])),
        },
      ]);
      dispatch(userActions.getCompanyProfileByCode(splitValue[0]));
      history.push("/CompanyMenuDetails");
    }
  };
  const oldRepeatedCompanySearch =
    recentSearchedCompanies &&
    JSON.parse(recentSearchedCompanies) &&
    Array.from(
      new Set(
        recentSearchedCompanies &&
          JSON.parse(recentSearchedCompanies).map((obj) =>
            obj.map((value) => value)
          )
      )
    );
  const recentSearchHistory =
    props.recentCompanyList &&
    props.recentCompanyList.items &&
    props.recentCompanyList.items.map((value) => [
      { Code: value.CO_CODE, Name: value.CO_NAME },
    ]);
  const repeatedCompanySearch =
    oldRepeatedCompanySearch &&
    Array.prototype.concat.apply(
      [],
      recentSearchHistory
        ? oldRepeatedCompanySearch.concat(recentSearchHistory)
        : oldRepeatedCompanySearch
    );
  useEffect(() => {
    // eslint-disable-next-line
  }, [repeatedCompanySearch]);
  return (
    <div className="row ">
      {console.log("props.recentCompanyList ===", props.recentCompanyList)}
      {console.log(
        "======repeatedCompanySearch========",
        repeatedCompanySearch
      )}
      <div className="col-sm-12 col-lg-7 TypeAheadDropDown   ">
        <AsyncTypeahead
          className={
            !props.searchCompany ||
            (props.searchCompany && props.searchCompany.length === 0)
              ? "form-control is-invalid"
              : ""
          }
          id="searchCompany"
          labelKey="Name"
          options={companyList.items}
          isLoading={false}
          onSearch={handleSearch}
          onChange={(selected) => {
            localStorage.removeItem("companyName");
            localStorage.removeItem("companyCode");
            localStorage.removeItem("recentCompanySearch");
            localStorage.setItem("inputSearchValue", JSON.stringify(selected));
            props.setSearchCompany(selected);
            const companyCode = JSON.parse(JSON.stringify(selected)).map(
              (value) => {
                return value.Code;
              }
            );
            const acn = JSON.parse(JSON.stringify(selected)).map((value) => {
              return value.ACN;
            });
            if (selected.length > 0) {
              props.recentSearch.push(selected);
              localStorage.setItem(
                "recentCompanySearch",
                JSON.stringify(props.recentSearch)
              );
            }
            if (companyCode && companyCode.length > 0) {
              dispatch(userActions.getCompanyProfileByCode(companyCode));
              dispatch(userActions.getUnitTrustByCode(companyCode, "summary"));
              dispatch(userActions.getAsicDocumentTracking(companyCode));
            }
            if (acn && acn.length > 0) {
              dispatch(userActions.getRegdInformation(acn));
              dispatch(
                userActions.postRecentCompanyList(
                  props.userId,
                  props.clientId,
                  "",
                  acn[0]
                )
              );
              console.log(
                "postRecentCompanyList",
                props.userId,
                props.clientId,
                acn
              );
            }
          }}
          selected={props.searchCompany}
          placeholder="Enter the Company to Search"
          useCache={false}
        />
      </div>
      <div className="col-sm-3 col-lg-3 lineSpacer">
        <div className="dropdown">
          <select
            name="allform"
            id="allform"
            className="dropDownBox text-nowrap"
            onChange={(e) => handleClickCompany(e)}
          >
            <option value="">Recent CompanyList </option>
            {repeatedCompanySearch
              ? repeatedCompanySearch
                  .filter(
                    (thing, index, self) =>
                      index === self.findIndex((t) => t.Code === thing.Code)
                  )
                  .map((value) => (
                    <option
                      value={value.Code + "," + value.Name + "," + value.ACN}
                    >
                      {value.Name}
                    </option>
                  ))
              : props.recentCompanyList &&
                props.recentCompanyList.items &&
                props.recentCompanyList.items
                  .filter(
                    (thing, index, self) =>
                      index ===
                      self.findIndex((t) => t.CO_CODE === thing.CO_CODE)
                  )
                  .map((value) => (
                    <option
                      value={
                        value.CO_CODE + "," + value.CO_NAME + "," + value.CO_ACN
                      }
                    >
                      {value.CO_NAME}
                    </option>
                  ))}
          </select>
        </div>
      </div>
      <div className="col-sm-1 col-lg-auto lineSpacer hand-cursor">
        {/* <i className="bi bi-bell-fill btn-lg"></i> */}
      </div>
      <div className="col-sm-1 col-lg-auto lineSpacer hand-cursor">
        <a
          href={() => false}
          className="d-flex align-items-center text-white text-decoration-none "
          id="dropdownStaticlinks"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="text-dark ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-question"
              viewBox="0 0 16 16"
            >
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
            </svg>
          </span>
        </a>
        <ul
          className="dropdown-menu  text-small"
          aria-labelledby="dropdownStaticlinks"
        >
          <li>
            <a
              className="dropdown-item"
              href="https://www.primeconsulting.com.au/software/downloads/PrimeCloud/Documents/DirectorIDIntroduction.pdf"
              target="_blank"
              rel="noreferrer"
            >
              New Director ID
            </a>
            <hr />
          </li>
          <li>
            <a
              className="dropdown-item"
              href="https://auspost.com.au/postcode"
              target="_blank"
              rel="noreferrer"
            >
              AusPOST Postcode Check
            </a>
          </li>
        </ul>
      </div>
      <div className="col-sm-auto col-lg-auto lineSpacer">
        <a
          href={() => false}
          className="d-flex align-items-center text-white text-decoration-none "
          id="dropdownUserDetail"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="dm-change hand-cursor">
            <p>{userName && userName.charAt(0).toUpperCase()}</p>
          </div>
        </a>
        <ul
          className="dropdown-menu  text-small"
          aria-labelledby="dropdownUserDetail"
        >
          {/* 
         <li>
            <a className="dropdown-item" href={() => false}>
              Edit Profile
            </a>
            <hr />
          </li>
*/}
          <li>
            <a
              className="dropdown-item"
              href={() => false}
              onClick={() => props.history.push("/ProfileSettings")}
            >
              Profile Settings
            </a>
            <hr />
          </li>
          <li>
            <a
              className="dropdown-item"
              href={() => false}
              onClick={handleSignOut}
            >
              Sign out
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default CommonSearch;
