import React from "react";


const ViewCompanyReport = (props) => {
  return (
    <div>
      <h3 className="mb-4">Enter Values</h3>

      <table className="table table-responsive table-borderless">
        <tbody>
          <tr>
            <td className="fw-bold w-50 ">Show Zero Balances?</td>{" "}
            <td>
              {" "}
              <label>
                <input
                  type="radio"
                  name="zeroBalance"
                  checked={props.details.zeroBalance === "Yes"}
                  onClick={props.viewReporthHandleChange}
                  value="Yes"
                />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
                <input
                  type="radio"
                  name="zeroBalance"
                  checked={props.details.zeroBalance === "No"}
                  onClick={props.viewReporthHandleChange}
                  value="No"
                />
                NO
              </label>
            </td>
          </tr>
          <tr>
            <td className="fw-bold w-50 ">Enter Show Credit Balances? </td>{" "}
            <td>
              {" "}
              <label>
                <input
                  type="radio"
                  name="creditBalance"
                  checked={props.details.creditBalance === "Yes"}
                  onClick={props.viewReporthHandleChange}
                  value="Yes"
                />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
                <input
                  type="radio"
                  name="creditBalance"
                  checked={props.details.creditBalance === "No"}
                  onClick={props.viewReporthHandleChange}
                  value="No"
                />
                NO
              </label>
            </td>
          </tr>
          <tr>
            <td className="fw-bold w-50 ">Enter Sort Alphabetically?</td>{" "}
            <td>
              {" "}
              <label>
                <input
                  type="radio"
                  name="sortReport"
                  checked={props.details.sortReport === "Yes"}
                  onClick={props.viewReporthHandleChange}
                  value="Yes"
                />
                Yes
              </label>
            </td>
            <td className="w-75">
              <label>
                <input
                  type="radio"
                  name="sortReport"
                  checked={props.details.sortReport === "No"}
                  onClick={props.viewReporthHandleChange}
                  value="No"
                />
                NO
              </label>
            </td>
          </tr>
        </tbody>{" "}
      </table>
    </div>
  );
};

export default ViewCompanyReport;
