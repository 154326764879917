import React, { useCallback, useState } from "react";
import DatePicker from "react-datepicker";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import NumberFormat from "react-number-format";
import { AsyncTypeahead,Typeahead } from "react-bootstrap-typeahead";

const ShareIssue = (props) => {

  function handleReset(e) {
    if (props.elements.shareIssueFullyPaid) {
      props.setAsicDetails((value) => ({
        ...value,
        shareIssueUnpaidPerShare: "",
        shareIssueTotalUnpaid: "",
        shareIssueBenName: "",
      }));
    }
    if (props.elements.shareIssueBeneficiallyOwned) {
      props.setAsicDetails((value) => ({ ...value, shareIssueBenName: "" }));
    }
  }

  const  shareHolderNames=  props.companyProfile &&  props.companyProfile.items &&  props.companyProfile.items.shareholders && props.companyProfile.items.shareholders.map((value) =>  
  `${value.NAME}:  ${value.CLASS_CODE}:  ${value.CLASS_NO}:  FP=${value.FULLY_PAID ? "Yes" : "no"}:  BO=${value.BEN_OWNER ? "Yes" : "no"}: 
   Total Shares=${value.SUM_TOT_SHARES} :${value.BEN_OWNER ? "Beneficially Held" : ""}` );

  const duplicatedOfficer=
  props.companyProfile &&
  props.companyProfile.items &&
  props.companyProfile.items.officers.filter(
    (thing, index, self) =>
      index === self.findIndex((t) => t.OFFICER_NAME === thing.OFFICER_NAME)
  );

    const  officerNames=   duplicatedOfficer&& duplicatedOfficer.map((value) =>     `${value.CEASED!=="" ? value.OFFICER_NAME:""}` );

  //const  officerNames=props.companyProfile && props.companyProfile.items &&  props.companyProfile.items.officers &&  props.companyProfile.items.officers.map((value) =>     `${value.CEASED!=="" ? value.OFFICER_NAME:""}` );
  function handleNames() {
    setOfficerShareHoldersData((value) => ({
      ...value,
      name:
        officerNames &&
        shareHolderNames &&
        officerNames.concat(shareHolderNames),
    }));
  }
  const [officerShareHoldersData, setOfficerShareHoldersData] = useState({
    name: [],
  });

  const handleSearch = useCallback((q) => {
    

    const list = [...props.inputList];
    list[props.index]["shareissueShareholderName"] = [q];
    props.setInputList(list);
     props.setAsicDetails((order) => ({
     ...order,
     inputList: list,
       }));
       // eslint-disable-next-line
  }, []);


  return (
    <div className="container " onClick={handleNames} onFocus={handleNames} onKeyPress={handleNames}>

      <form>
        <hr />
        <div className="form-group row px-4 ">
          <div className="col-xs-3">
            <label for="shareissueShareholderName" className="form-label">
              Shareholder Name
              <i
                className="bi bi-info-circle px-1"
                data-toggle="tooltip"
                data-placement="top"
                title="Full legal Name as per Passport"
              ></i>
            </label>

            <AsyncTypeahead
              id="shareissueShareholderName"
              labelKey={
                officerShareHoldersData &&
                officerShareHoldersData.name &&
                officerShareHoldersData.name.map((value) => value.name)
              }
              options={
                officerShareHoldersData && officerShareHoldersData.name
                ? officerShareHoldersData.name
                : []
              }
              isLoading={false}
              onSearch={handleSearch}
              autoFocus={false}
              onChange={(selected) => {
                
                  const list = [...props.inputList];

                  list[props.index]["shareissueShareholderName"] = selected;
                
                  props.setInputList(list);
                  props.setAsicDetails((changeOrder) => ({
                    ...changeOrder,
                    inputList: list,
                  }));
                  
              }}
              selected={props.elements.shareissueShareholderName}
              placeholder="Shareholder Name"
              useCache={false}
            />

            <span className="text-secondary">
              For Joint holder, combine with 'AND' operator
            </span>
          </div>
          <div className="row py-2">
            <div className="col">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="shareIssueFullyPaid"
                  name="shareIssueFullyPaid"
                  onChange={(e) => {
                    props.handleInputChange(e, props.index, true);
                    handleReset(e);
                  }}
                  checked={props.elements.shareIssueFullyPaid}
                  value={props.elements.shareIssueFullyPaid}
                />
                <label className="form-check-label" for="shareIssueFullyPaid">
                  Fully Paid
                </label>
              </div>
            </div>
            <div className="col">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="shareIssueBeneficiallyOwned"
                  name="shareIssueBeneficiallyOwned"
                  onChange={(e) => {
                    props.handleInputChange(e, props.index);
                    handleReset(e);
                  }}
                  checked={props.elements.shareIssueBeneficiallyOwned}
                  value={props.elements.shareIssueBeneficiallyOwned}
                />
                <label
                  className="form-check-label"
                  for="shareIssueBeneficiallyOwned"
                >
                  Beneficially Owned
                </label>
              </div>
            </div>
          </div>
          {!props.elements.shareIssueBeneficiallyOwned && (
            <div className="col-xs-3">
              <label for="shareIssueBenName" className="form-label">
                BEN NAME
              </label>
              <input
                type="text"
                className="form-control"
                id="shareIssueBenName"
                name="shareIssueBenName"
                value={props.elements.shareIssueBenName}
                onChange={(e) => props.handleInputChange(e, props.index)}
                autoComplete="off"
              />
            </div>
          )}
          <div className="col-xs-3">
            <label htmlFor="shareholderAddress" className="col-form-label">
              Shareholder Address
            </label>
            <GooglePlacesAutocomplete
              apiKey={"AIzaSyD8pHI8_Rviq7fmkxO5TBlJmaQl7kNHpE0"}
              selectProps={{
                placeholder: "Text...",
                isClearable: true,
                predefinedPlacesAlwaysVisible: true,
                value: props.elements.shareholdersFullAddress,
                onChange: (val) => {
                  const list = [...props.inputList];
                  list[props.index]["shareholdersFullAddress"] = val;
                  props.setInputList(list);
                  props.setAsicDetails((changeOrder) => ({
                    ...changeOrder,
                    inputList: list,
                  }));
                  /*props.setAsicDetails((shareholderAddress) => ({
                    ...shareholderAddress,
                    shareholdersFullAddress: val,
                  }));*/
                },
              }}
            />
            <span className="text-secondary">
              Address required for new Shareholder
            </span>
            <div className="col-xs-3 py-2">
              <label for="shareIssueClasscode" className="form-label">
                CLASS CODE
              </label>
              
  
              <Typeahead
              id="shareIssueClasscode"
            
              labelKey={(option) =>
                               `${option.CLASS_CODE}`
                             
              }
      
              options={
                props.classListInfo  &&
                props.classListInfo.items ?
                props.classListInfo.items.Result.filter(
                  (thing, index, self) =>
                    index === self.findIndex((t) => t.CLASS_CODE === thing.CLASS_CODE)
                ): []
              }
              isLoading={false}
              onSearch={handleSearch}
           
              onChange={(selected) => {                          
            
               
                const list = [...props.inputList];
                list[props.index]["shareIssueClasscode"] = selected;
                props.setInputList(list);
                props.setAsicDetails((changeOrder) => ({
                  ...changeOrder,
                  inputList:list
                }));
          
          }}
              selected={props.elements.shareIssueClasscode}
              placeholder="Enter the class Code"
              useCache={false}
           
            />
            </div>
            <div>
              <label
                htmlFor="shareIssueAllotmentDate"
                className="col-form-label input-sm "
              >
                Allotment Date{" "}
                <i
                  className="bi bi-info-circle px-1"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="ASIC late fees apply if form is not lodgged with in 28days of change date"
                ></i>
              </label>
              <DatePicker
                className="form-control input-sm w-25"
                name="shareIssueAllotmentDate"
                selected={props.elements.shareIssueAllotmentDate}
                onChange={(e) => {
                  const list = [...props.inputList];
                  list[props.index]["shareIssueAllotmentDate"] = e;
                  props.setInputList(list);
                  props.setAsicDetails((changeOrder) => ({
                    ...changeOrder,
                    inputList: list,
                  }));
                }}
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
                value={props.elements.shareIssueAllotmentDate}
              />
            </div>
          </div>
          <div className="col-xs-3 py-2">
            <label for="inputTotalShares4" className="form-label">
              Total Shares
            </label>
            <NumberFormat
              type="text"
              data-filter="[0-9]"
              name="shareIssueTotalShares"
              className="form-control w-100"
              id="shareIssueTotalShares"
              value={props.elements.shareIssueTotalShares}
              onChange={(e) => props.handleInputChange(e, props.index)}
              thousandSeparator={true}
              placeholder="0"
              decimalSeparator="."
              displayType="input"
              allowNegative={false}
              autoComplete="off"
            />
          </div>
          
          <div className="row">
            <div className="col">
              <label
                htmlFor="paidPerShare"
                className="col-form-label input-sm "
              >
                Paid per share
              </label>
              <NumberFormat
                type="text"
                data-filter="[0-9]"
                name="shareIssuePaidPerShare"
                className="form-control w-100"
                value={props.elements.shareIssuePaidPerShare}
                onChange={(e) => props.handleInputChange(e, props.index)}
                placeholder="0.00"
                decimalScale={4}
                thousandSeparator={true}
                prefix="$"
                decimalSeparator="."
                displayType="input"
                allowNegative={false}
                autoComplete="off"
              />
            </div>
            <div className="col">
              <label htmlFor="totalPaid" className="col-form-label input-sm ">
                Total Paid
              </label>
              <NumberFormat
                type="text"
                data-filter="[0-9]"
                name="shareIssueTotalPaid"
                className="form-control w-100"
                value={props.elements.shareIssueTotalPaid}
                onChange={(e) => props.handleInputChange(e, props.index)}
                placeholder="0.00"
                decimalScale={2}
                thousandSeparator={true}
                prefix="$"
                decimalSeparator="."
                displayType="input"
                allowNegative={false}
                autoComplete="off"
              />
            </div>
          </div>
          {!props.elements.shareIssueFullyPaid && (
            <div className="row py-2">
              <div className="col">
                <label
                  htmlFor="unpaidPerShare"
                  className="col-form-label input-sm "
                >
                  UnPaid per share
                </label>
                <NumberFormat
                  type="text"
                  data-filter="[0-9]"
                  name="shareIssueUnpaidPerShare"
                  className="form-control w-100"
                  value={props.elements.shareIssueUnpaidPerShare}
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  placeholder="0.00"
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  allowNegative={false}
                  autoComplete="off"
                />
              </div>
              <div className="col">
                <label
                  htmlFor="totalUnPaid"
                  className="col-form-label input-sm "
                >
                  Total UnPaid
                </label>

                <NumberFormat
                  type="text"
                  data-filter="[0-9]"
                  name="shareIssueTotalUnpaid"
                  className="form-control w-100"
                  value={props.elements.shareIssueTotalUnpaid}
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  placeholder="0.00"
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  allowNegative={false}
                  autoComplete="off"
                />
              </div>
            </div>
          )}
        </div>

      </form>
    
    </div>
  );
};
export default ShareIssue;
