import React, { useState, useEffect } from "react";
import { css, styled, setup } from "goober";
import FullPageLoader from "./FullPageLoader";

setup(React.createElement);

const Ol = styled("ol")`
  margin: 0;
  padding-bottom: 2.2rem;
  list-style-type: none;
`;
const Li = styled("li")`
  display: inline-block;
  text-align: center;
  line-height: 4.8rem;
  padding: 0 0.7rem;
  cursor: pointer;

  color: silver;
  border-bottom: 2px solid silver;

  &:hover,
  &:before {
    color: #0fa0ce;
  }
  &:after {
    content: "\\00a0\\00a0";
  }
  span {
    padding: 0 1.5rem;
  }
  &:before {
    position: relative;
    float: left;
    left: 50%;
    width: 1.2em;
    line-height: 1.4em;
    border-radius: 0;
    bottom: -3.99rem;
  }
`;
const Todo = (props) => css`
  &:before {
    content: "${props.key}";
    color: silver;
    background-color: white;
  }
`;
const Doing = (props) => css`
  &:before {
    content: "${props.key}";
    color: white;
    background-color: #33c3f0;
  }
`;
const Done = (props) => css`
  &:before {
    content: "${props.key}";
    color: white;
    background-color: #33c3f0;
  }
`;

const getStep = (defaultIndex, newIndex, length) => {
  if (newIndex <= length) {
    return newIndex;
  }
  return defaultIndex;
};

const getTopNavStyles = (indx, length) => {
  const styles = [];
  for (let i = 0; i < length; i++) {
    if (i < indx) {
      styles.push("done");
    } else if (i === indx) {
      styles.push("doing");
    } else {
      styles.push("todo");
    }
  }
  return styles;
};

const getButtonsState = (indx, length) => {
  if (indx > 0 && indx < length - 1) {
    return {
      showPreviousBtn: true,
      showNextBtn: true,
    };
  } else if (indx === 0) {
    return {
      showPreviousBtn: false,
      showNextBtn: true,
    };
  } else {
    return {
      showPreviousBtn: true,
      showNextBtn: false,
    };
  }
};

export default function MultiStep(props) {
  const { activeComponentClassName, inactiveComponentClassName } = props;
  const showNav =
    typeof props.showNavigation === "undefined" ? true : props.showNavigation;

  const [activeStep] = useState(
    getStep(0, props.activeStep, props.steps.length)
  );
  const [count, setCount] = useState(0);
  const [stylesState, setStyles] = useState(
    getTopNavStyles(activeStep, props.steps.length)
  );
  const [compState, setComp] = useState(activeStep);
  const [buttonsState, setButtons] = useState(
    getButtonsState(activeStep, props.steps.length)
  );

  useEffect(() => {
    console.log("Index changed: ", props.activeStep);
    setStepState(props.activeStep);
    // eslint-disable-next-line
  }, [props.activeStep]);
  useEffect(() => {
    console.log("ebinderOrderRequest2: ", props.ebinderResponse);
  
    props.ebinderResponse &&
      props.ebinderResponse.items &&
      setStepState(compState + 1);

    // eslint-disable-next-line
  }, [props.ebinderResponse]);

  const setStepState = (indx) => {
    console.log("Index changed: ", props.activeStep);
    setStyles(getTopNavStyles(indx, props.steps.length));
    setComp(indx < props.steps.length ? indx : compState);
    setButtons(getButtonsState(indx, props.steps.length));
  };

  const next = () => {
    setStepState(compState + 1);
    setCount(compState + 1);
    props.setEbinderResponse("");
    props.setShowEBinderMangement(false);
  };

  const previous = () => {
    setStepState(compState > 0 ? compState - 1 : compState);
    setCount(compState > 0 ? compState - 1 : compState);
    props.setEbinderResponse("");
  };

  const handleOnClick = (evt) => {
    if (
      evt.currentTarget.value === props.steps.length - 1 &&
      compState === props.steps.length - 1
    ) {
      setStepState(props.steps.length);
    } else {
      setStepState(evt.currentTarget.value);
    }
  };

  const renderSteps = () =>
    props.steps.map((s, i) => {
      if (stylesState[i] === "todo") {
        return (
          <Li
            className={Todo({ key: i + 1 })}
            onClick={handleOnClick}
            key={i}
            value={i}
          >
            <span>{s.name}</span>
            <p hidden>{i + 1}</p>
          </Li>
        );
      } else if (stylesState[i] === "doing") {
        return (
          <Li
            className={Doing({ key: i + 1 })}
            onClick={handleOnClick}
            key={i}
            value={i}
            style={{
              borderBottom:
                "Complete" !== s.name ? "2px solid red" : "2px solid green",
            }}
          >
            <span>{s.name}</span>
            <p hidden>{i + 1}</p>
          </Li>
        );
      } else {
        return (
          <Li
            className={Done({ key: i + 1 })}
            onClick={handleOnClick}
            key={i}
            value={i}
            style={{ borderBottom: "2px solid green" }}
          >
            <span>{s.name}</span>
            <p hidden>{i + 1}</p>
          </Li>
        );
      }
    });

  const renderNav = (show) =>
    show && (
      <div>
        <table>
          <tr>
            <td>
              <br />
              {console.log('compState:::', compState)}
              {compState <= 5 && (
                <button
                  className={"buttonCommon fs-6"}
                  style={
                    buttonsState.showPreviousBtn
                      ? props.prevStyle
                      : { display: "none" }
                  }
                  onClick={previous}
                >
                  Prev
                </button>
              )}
            </td>
            <td> </td>
            <td> </td>
            <td>
              {" "}
              <br />
              {count < 5 && (
                <button
                  className={
                    count === 1 && !props.selectedCompanyCode
                      ? "btn btn-secondary buttonCommon"
                      : "buttonCommon fs-6"
                  }
                  disabled={count === 1 && !props.selectedCompanyCode}
                  style={
                    buttonsState.showNextBtn
                      ? props.nextStyle
                      : { display: "none" }
                  }
                  onClick={next}
                >
                  Next
                </button>
              )}
              {console.log(" activeStep activeStep", count)}
              {count === 5 && (
                <button
                  className={"buttonCommon fs-6"}
                  // disabled={ !props.isCreateEBinderLoaded}
                  style={
                    buttonsState.showNextBtn
                      ? props.nextStyle
                      : { display: "none" }
                  }
                  onClick={(e) => {
                    props.handleClick(e);
                  }}
                >
                  Create Ebinder
                </button>
              )}
            </td>
          </tr>
        </table>
        {count === 5 &&
        props.ebinderOrderRequest &&
        props.ebinderOrderRequest.loading ? (
          <FullPageLoader />
        ) : null}
        {count === 1 && !props.selectedCompanyCode && (
          <div className="text-danger  py-1 ">
            Company Name not selected! please select the Company Name to
            proceed.
          </div>
        )}
        {count === 5 &&
          props.ebinderResponse &&
          props.ebinderResponse.error && (
            <div className="text-danger  py-1 ">
              An unknown error occurred. Please contact
              support@primeconsulting.com.au if the error persists.
            </div>
          )}
        <br />
        <br />
        <br />
      </div>
    );

  return (
    <div>
      <Ol>{renderSteps()}</Ol>
      {inactiveComponentClassName ? (
        props.steps.map((step, index) => {
          const className =
            index === compState
              ? activeComponentClassName
              : inactiveComponentClassName;
          return (
            <div className={className} key={index}>
              {step.component}
            </div>
          );
        })
      ) : (
        <div>{props.steps[compState].component}</div>
      )}
      <div>{renderNav(showNav)}</div>
    </div>
  );
}
