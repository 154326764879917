import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import Popup from "../CommonPage/Popup.component";
import FullPageLoader from "../CommonPage/FullPageLoader";
import Form484InformationComponent from "../CommonPage/Form484Information.component";

const DocumentHistoryComponent = (props) => {
  useEffect(() => {
    setPopupDocShow(false);
    setPopupAsicDocShow(false);
    setPopup484DocShow(false);
    // eslint-disable-next-line
  }, []);
  const companyProfile = useSelector((state) => state.companyProfile);
  const documentHistoryDocDetails = useSelector(
    (state) => state.documentHistoryDocDetails
  );
  const documentHistoryAsicDocDetails = useSelector(
    (state) => state.documentHistoryAsicDocDetails
  );

  const [selectedOption, setSelectedOption] = useState("484");
  const dispatch = useDispatch();
  //loading spinner
const [isDocLoaded, setIsDocLoaded] = useState(false);

  let docHandleClick = (e, rN, formType, docNo) => {
    e.preventDefault();
    dispatch(userActions.getDocumentHistoryDocReport(rN, formType, docNo,setIsDocLoaded));
    handleDocPopupShow(e, docNo);
  };
  let asicDocHandleClick = (e, asicDocNo) => {
    e.preventDefault();
    
    dispatch(userActions.getDocumentHistoryAsicReport(asicDocNo));
    handleAsicDocPopupShow(e,asicDocNo);
  };

  const [popupDocShow, setPopupDocShow] = useState(false);
  const handleDocPopupClose = () => setPopupDocShow(false);
  const handleDocPopupShow = (e, index) => {
    e.preventDefault();
    setPopupDocShow(index);
  };

  let handleDocPopupClick = (e) => {
    e.preventDefault();
    handleDocPopupClose();
  };

  const [popupAsicDocShow, setPopupAsicDocShow] = useState(0);
  const handleAsicDocPopupClose = () => setPopupAsicDocShow(false);
  const handleAsicDocPopupShow = (e, index) => {
    e.preventDefault();
    setPopupAsicDocShow(index);
  };

  let handleAsicDocPopupClick = (e) => {
    e.preventDefault();
    handleAsicDocPopupClose();
  };

// Form 484 information Popup.
const form484Information = useSelector(
  (state) => state.form484Information
);

 //loading spinner
 const [isForm484Loaded, setIsForm484Loaded] = useState(false);
 // eslint-disable-next-line
const [submitted, setSubmitted] = useState(false);
function form484HandleClick(e,rN,docNo) {
  setSubmitted(true);
        dispatch(userActions.getForm484Information(rN,docNo,setIsForm484Loaded));
        handle484DocPopupShow(e, docNo);
 }

 const [popup484DocShow, setPopup484DocShow] = useState(0);
 const handle484DocPopupClose = () => setPopup484DocShow(false);
 const handle484DocPopupShow = (e, index) => {
   e.preventDefault();
   setPopup484DocShow(index);
 };
 let handle484DocPopupClick = (e) => {
  e.preventDefault();
  handle484DocPopupClose();
};



  return (
    <div> <div className="d-flex  fs-6  fw-bold "><label> Sort by <span  className="px-2"></span>    </label>
    <select
    name="allform"
    id="allform"
    className="dropDownBox w-25 px-6"
    onChange={(e) => {
      setSelectedOption(e.target.value);
    }}
  >
    <option value="">AllForms</option>
    {Array.from(
      new Set(
        companyProfile.items &&
          companyProfile.items.documents.map((obj) => obj.FORM_TYPE)
      )
    ).map((value) => {
      return (
        <option value={value} selected={value === "484"}>
          {value}
        </option>
      );
    })}
  </select>
  </div>
  
    <table className="table table-light">
    
      <thead className="fw-bold">
        <tr className="border-0">
          <th className="border-0 ">
            Form Code
          </th>
          <th className="border-0">Form Date</th>
          <th className="border-0">ELS</th>
          <th className="border-0">Doc No.</th>
          <th className="border-0">ASIC Doc No.</th>
          <th className="border-0"><i class="bi bi-info-circle-fill"></i></th>
        </tr>
      </thead>

      {companyProfile.items &&
        companyProfile.items.documents && companyProfile.items.documents.length>0? 
        companyProfile.items.documents.filter((name) => name.FORM_TYPE.includes(selectedOption))
          .map((value, index) => (
            <tbody className="fs-6 fw-normal">
              <tr className={!value.ASIC_DOC_NO ? "table-danger" : ""}>
                <td>{value.FORM_TYPE}</td>
                <td>{value.FORM_DATE && moment(value.FORM_DATE).format("DD/MM/YYYY")}</td>
                <td>{value.ELS_TRANSMIT ? "Y" : "N"}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-link  "
                    onClick={(e) =>
                      docHandleClick(e, value.RN, value.FORM_TYPE, value.DOC_NO)
                    }
                  >
                    {" "}
                    {value.DOC_NO}   
                      
                  </button>
                  

                  {popupDocShow === value.DOC_NO &&
                    documentHistoryDocDetails &&
                    documentHistoryDocDetails.error &&
                    documentHistoryDocDetails.error.indexOf("400") !== -1 ? (
                      <Popup
                        show={popupDocShow === value.DOC_NO}
                        heading={"Error occured in Document package"}
                        handleClose={handleDocPopupClose}
                        handleShow={(e) => handleDocPopupShow(e, value.DOC_NO)}
                        body={
                          value.DOC_NO +
                          " Document number doesn't exist on the system."
                        }
                        handleClick={handleDocPopupClick}
                 
                       
                      />
                    )
                    :  popupDocShow === value.DOC_NO && isDocLoaded ? <FullPageLoader /> :null }  
                  
                </td>
                <td>
     {value.ASIC_DOC_NO===null ? "  n/a":
                  <button
                    type="button"
                    className="btn btn-link  "
                    onClick={(e) => asicDocHandleClick(e, value.ASIC_DOC_NO)}
                  >
                
                    {value.ASIC_DOC_NO}
                  </button>
                }
                  {popupAsicDocShow === value.ASIC_DOC_NO &&
                    documentHistoryAsicDocDetails &&
                    documentHistoryAsicDocDetails.error &&
                    documentHistoryAsicDocDetails.error.indexOf("400") !==
                      -1 && (
                      <Popup
                        show={popupAsicDocShow === value.ASIC_DOC_NO}
                        heading={"Error occured in ASIC Document package"}
                        handleClose={handleAsicDocPopupClose}
                        handleShow={(e) =>
                          handleAsicDocPopupShow(e, value.ASIC_DOC_NO)
                        }
                        body={
                          value.ASIC_DOC_NO +
                          " Document number doesn't exist on the system."
                        }
                        handleClick={handleAsicDocPopupClick}
                      />
                    )}
                </td>
                <td >{value.FORM_TYPE === "484" ?  <a                  
href={() => false }
role="button"
onClick={(e) =>form484HandleClick(e,value.RN,value.DOC_NO)}><i class="bi bi-info-circle-fill  hand-cursor text-primary"></i></a>: "" }</td>


{popup484DocShow === value.DOC_NO  ? (
    <Popup
      show={popup484DocShow === value.DOC_NO}
      heading={"Summary"} 
      handleClose={handle484DocPopupClose}
      handleShow={(e) => handle484DocPopupShow(e, value.DOC_NO)}
      body={
        <Form484InformationComponent
        form484Information={form484Information}
                 />
      }
      handleClick={handle484DocPopupClick}

     
    />
  )
  :  popup484DocShow === value.DOC_NO && isForm484Loaded ? <FullPageLoader /> :null }  
     </tr>
            </tbody>
          )):<tr><td colspan ={6}>No data exist in the Document History</td></tr>}
    </table>
    </div>
  );
};
export default DocumentHistoryComponent;
