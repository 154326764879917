import React from "react";
import "./AnnualReviews.style.scss";
import moment from "moment";

const AnnualStatementComponent = (props) => {
  const year = moment().format('YYYY');
  //console.log('AnnualStatementComponent', props, year);

  return (
    <div className="fs-6 fw-normal fontfamily">
   
      <table className="w-100">
        <tbody>
          <tr height="10px"></tr>
          <tr>
            <td>
              {" "}
              <select
                name="yearfilter"
                id="yearfilter"
                className=" yearfilter text-nowrap "
                       onChange={(e) => props.handleChange(e)}
                      
              >
                <option value="Future">{parseInt(year)+1}</option>
                <option value="Current" selected>{year}</option>
                <option value="Past">{year-1}</option>
              </select>
            </td>
          </tr>
          <tr height="10px"></tr>
          <tr>
            <table className="table table-bordered w-100 table-hover">
              <tbody>
                <tr
                  className={
                    props.history.location.pathname === "/MonitorLanding/1" ||
                    props.history.location.pathname === "/MonitorLanding" ||
                    props.currentMonth === 1
                      ? "bg-info"
                      : ""
                  }
                >
                  <td onClick={(e) => props.handleClick(e, 1, props.currentYear)}>
                  {console.log("props.currentYearprops.currentYear",props.currentYear)}
                  {(props.annualStatementMonthStatus && props.annualStatementMonthStatus.items&&props.annualStatementMonthStatus.items[1]=== 'danger') ? <i className="bi bi-circle-fill text-danger"></i> :  <i className="bi bi-circle"></i>} January
                  </td>
                </tr>

                <tr
                  className={
                    props.history.location.pathname === "/MonitorLanding/2" ||
                    props.currentMonth === 2
                      ? "bg-info"
                      : ""
                  }
                >
                  <td onClick={(e) => props.handleClick(e, 2, props.currentYear)}>
                  {(props.annualStatementMonthStatus && props.annualStatementMonthStatus.items&&props.annualStatementMonthStatus.items[1]=== 'danger') ? <i className="bi bi-circle-fill text-danger"></i> :  <i className="bi bi-circle"></i>} February
                  </td>
                </tr>

                <tr
                  className={
                    props.history.location.pathname === "/MonitorLanding/3" ||
                    props.currentMonth === 3
                      ? "bg-info"
                      : ""
                  }
                >
                  <td onClick={(e) => props.handleClick(e, 3, props.currentYear)}>
                  {(props.annualStatementMonthStatus &&props.annualStatementMonthStatus.items&& props.annualStatementMonthStatus.items[2]=== 'danger') ? <i className="bi bi-circle-fill text-danger"></i> :  <i className="bi bi-circle"></i>} March
                  </td>
                </tr>

                <tr
                  className={
                    props.history.location.pathname === "/MonitorLanding/4" ||
                    props.currentMonth === 4
                      ? "bg-info"
                      : ""
                  }
                >
                  <td onClick={(e) => props.handleClick(e, 4, props.currentYear)}>
                  {(props.annualStatementMonthStatus && props.annualStatementMonthStatus.items&&props.annualStatementMonthStatus.items[3]=== 'danger') ? <i className="bi bi-circle-fill text-danger"></i> :  <i className="bi bi-circle"></i>} April
                  </td>
                </tr>

                <tr
                  className={
                    props.history.location.pathname === "/MonitorLanding/5" ||
                    props.currentMonth === 5
                      ? "bg-info"
                      : ""
                  }
                >
                  <td onClick={(e) => props.handleClick(e, 5, props.currentYear)}>
                  {(props.annualStatementMonthStatus && props.annualStatementMonthStatus.items&&props.annualStatementMonthStatus.items[4]=== 'danger') ? <i className="bi bi-circle-fill text-danger"></i> :  <i className="bi bi-circle"></i>} May
                  </td>
                </tr>

                <tr
                  className={
                    props.history.location.pathname === "/MonitorLanding/6" ||
                    props.currentMonth === 6
                      ? "bg-info"
                      : ""
                  }
                >
                  <td onClick={(e) => props.handleClick(e, 6, props.currentYear)}>
                  {(props.annualStatementMonthStatus && props.annualStatementMonthStatus.items&&props.annualStatementMonthStatus.items[5]=== 'danger') ? <i className="bi bi-circle-fill text-danger"></i> :  <i className="bi bi-circle"></i>} June
                  </td>
                </tr>

                <tr
                  className={
                    props.history.location.pathname === "/MonitorLanding/7" ||
                    props.currentMonth === 7
                      ? "bg-info"
                      : ""
                  }
                >
                  <td onClick={(e) => props.handleClick(e, 7, props.currentYear)}>
                  {(props.annualStatementMonthStatus && props.annualStatementMonthStatus.items&&props.annualStatementMonthStatus.items[6]=== 'danger') ? <i className="bi bi-circle-fill text-danger"></i> :  <i className="bi bi-circle"></i>} July
                  </td>
                </tr>

                <tr
                  className={
                    props.history.location.pathname === "/MonitorLanding/8" ||
                    props.currentMonth === 8
                      ? "bg-info"
                      : ""
                  }
                >
                  <td onClick={(e) => props.handleClick(e, 8, props.currentYear)}>
                  {(props.annualStatementMonthStatus &&props.annualStatementMonthStatus.items&& props.annualStatementMonthStatus.items[7]=== 'danger') ? <i className="bi bi-circle-fill text-danger"></i> :  <i className="bi bi-circle"></i>} August
                  </td>
                </tr>

                <tr
                  className={
                    props.history.location.pathname === "/MonitorLanding/9" ||
                    props.currentMonth === 9
                      ? "bg-info"
                      : ""
                  }
                >
                  <td onClick={(e) => props.handleClick(e, 9, props.currentYear)}>
                  {(props.annualStatementMonthStatus && props.annualStatementMonthStatus.items&&props.annualStatementMonthStatus.items[8]=== 'danger') ? <i className="bi bi-circle-fill text-danger"></i> :  <i className="bi bi-circle"></i>} September
                  </td>
                </tr>

                <tr
                  className={
                    props.history.location.pathname === "/MonitorLanding/10" ||
                    props.currentMonth === 10
                      ? "bg-info"
                      : ""
                  }
                >
                  <td onClick={(e) => props.handleClick(e, 10, props.currentYear)}>
                  {(props.annualStatementMonthStatus && props.annualStatementMonthStatus.items&&props.annualStatementMonthStatus.items[9]=== 'danger') ? <i className="bi bi-circle-fill text-danger"></i> :  <i className="bi bi-circle"></i>} October
                  </td>
                </tr>

                <tr
                  className={
                    props.history.location.pathname === "/MonitorLanding/11" ||
                    props.currentMonth === 11
                      ? "bg-info"
                      : ""
                  }
                >
                  <td onClick={(e) => props.handleClick(e, 11, props.currentYear)}>
                  {(props.annualStatementMonthStatus &&props.annualStatementMonthStatus.items&& props.annualStatementMonthStatus.items[10]=== 'danger') ? <i className="bi bi-circle-fill text-danger"></i> :  <i className="bi bi-circle"></i>} November
                  </td>
                </tr>

                <tr
                  className={
                    props.history.location.pathname === "/MonitorLanding/12" ||
                    props.currentMonth === 12
                      ? "bg-info"
                      : ""
                  }
                >
                  <td onClick={(e) => props.handleClick(e, 12, props.currentYear)}>
                  {(props.annualStatementMonthStatus && props.annualStatementMonthStatus.items&& props.annualStatementMonthStatus.items[11]=== 'danger') ? <i className="bi bi-circle-fill text-danger"></i> :  <i className="bi bi-circle"></i>} December
                  </td>
                </tr>
              </tbody>
            </table>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default AnnualStatementComponent;
