import React from "react";
import {Modal, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Popup.style.scss'
import FullPageLoader from "../CommonPage/FullPageLoader";

export default function Popup(props) {

  return (
    <>

      <Modal show={props.show} onHide={props.handleClose} centered backdropClassName="modal-backdrop"   size= { props.isLargeSize ? 'lg' :'' }>
        {props.heading && <Modal.Header  className="border-0">
          <Modal.Title>{props.heading}</Modal.Title>
         <Button type="button" className="btn-close btn btn-light" aria-label="Close" onClick={props.handleClose}></Button>
        </Modal.Header>}
        <Modal.Body>{props.body}</Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
         {props.btnName &&<Button variant="primary" onClick={props.handleClick}>
          {props.btnName}
          </Button>
         
            
              }
              { props.isPopupReportLoading
                ? <FullPageLoader  /> :null }
        </Modal.Footer>
      </Modal>
    </>
  );
}