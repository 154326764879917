import { userConstants } from "../constants/login.constants";

 function div7ALoanReport(state = {}, action) {
    switch (action.type) {
        case userConstants.DIV7ALOANREPORT_REQUEST:
            return {
                loading: true
            };
        case userConstants.DIV7ALOANREPORT_SUCCESS:
            return {
                items: action.div7ALoanReport
            };
        case userConstants.DIV7ALOANREPORT_FAILURE:
            return {
                error: action.errorDiv7ALoanReport
            };
        default:
            return state
    }
}
export default div7ALoanReport;