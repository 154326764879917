import { userConstants } from "../constants/login.constants";

 function changeOrderRequest(state = {}, action) {
    switch (action.type) {
        case userConstants.CHANGEORDER_REQUEST:
            return {
                loading: true
            };
        case userConstants.CHANGEORDER_SUCCESS:
            return {
                items: action.changeOrderRequest
            };
        case userConstants.CHANGEORDER_FAILURE:
            return {
                error: action.changeOrderError
            };
        default:
            return state
    }
}
export default changeOrderRequest;