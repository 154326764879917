import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import Popup from "../CommonPage/Popup.component";
import EBinderDeleteCustomfileComponent from "./EBinderDeleteCustomfileComponent";
import { useDropzone } from "react-dropzone";
import FullPageLoader from "../CommonPage/FullPageLoader";

const EBinderAdditionalDoc = (props) => {
  const ebinderCustomFileList = useSelector(
    (state) => state.ebinderCustomFileList
  );
    // eslint-disable-next-line
  const [isFinishedUploading, setIsFinishedUploading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
    // eslint-disable-next-line
  const [isError, setIsError] = useState(false);
  const [errors, setErrors] = useState({});

  const onDrop = useCallback((acceptedFiles) => {
    console.log("acceptedFiles", acceptedFiles);
    

    // Build POST data
    let formdata = new FormData();
    acceptedFiles.forEach((file) => {
      formdata.append(file.name, file);
    });

    console.log('formdata=====', formdata)
 
    dispatch(userActions.postEbinderCustomFileAdd(props.selectedCompanyCode,formdata,setErrors,setIsUploading));
       setTimeout(() => {
      dispatch(userActions.getEbinderCustomFileList(props.selectedCompanyCode));
    }, 2000);
      // eslint-disable-next-line
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const dispatch = useDispatch();

  // Create user generated upload error list
  let fileUploadErrors = null;
  if (Object.keys(errors).length > 0) {
    console.log("fileUploadErrors")
    fileUploadErrors = Object.keys(errors).map((key) => {
      switch (errors[key]) {
        case "INVALID_NAME":
          return (
            <p className="text-danger">
              File '{key}' has an invalid name. File must be a PDF and only
              contain alphanumeric and spaces.
            </p>
          );
        case "INVALID_CONTENT_TYPE":
          return (
            <p className="text-danger">File '{key}' is not an accepted format. Files must be PDF.</p>
          );
        case "ALREADY_EXISTS":
          return (
            <p className="text-danger">
              File '{key}' already exists. Either rename your upload or delete
              the existing file.
            </p>
          );
        default:
          return <p className="text-danger">File '{key}' was rejected by server (unknown).</p>;
      }
    });
    console.log("fileUploadErrors",fileUploadErrors)
  }



 
  //  postEbinderCustomFileDelete v
  const [popupCustomfileDeleteShow, setPopupCustomfileDeleteShow] = useState(0);
  const handleCustomfileDeleteClose = () => setPopupCustomfileDeleteShow(false);
  const handleCustomfileDeleteShow = (e, index) => {
    e.preventDefault();
    setPopupCustomfileDeleteShow(index);
  };

  let handleDeletePopupClick = (e, fileName) => {
    e.preventDefault();
    dispatch(
      userActions.postEbinderCustomFileDelete(props.selectedCompanyCode, fileName)
    );
    handleCustomfileDeleteClose();
    setTimeout(() => {
      dispatch(userActions.getEbinderCustomFileList(props.selectedCompanyCode));
    }, 1000);
  };

  function handleClickSelectedItems(e, value) {
    props.setCustomFileList((prevState) =>
      prevState.map((item) =>
        item === value
          ? Object.assign(item, { additionDocCheckBox: e.target.checked })
          : Object.assign(item)
      )
    );
  }

  function handleClickAll(e) {
    props.setCustomFileList((prevState) =>
      prevState.map((item) =>
        Object.assign(item, { additionDocCheckBox: true })
      )
    );
  }

  function handleClickNone(e) {
    props.setCustomFileList((prevState) =>
      prevState.map((item) =>
        Object.assign(item, { additionDocCheckBox: false })
      )
    );
  }

  useEffect(() => {
    // eslint-disable-next-line
  }, [
    props.ebinderCustomFileList,
    ebinderCustomFileList,
    props.customFileList,
  ]);


  // Upload recently finished
  if (isFinishedUploading) {
    return (
      <div>
        <div>
          <p>Upload complete!</p>
        </div>
        <div>{fileUploadErrors}</div>
      </div>
    );
  }

  // If there was a server error in the upload process
  if (isError) {
    return (
      <div>
        <p>Failed to upload due to unexpected error.</p>
      </div>
    );
  }

  return (
    <div className="fs-6">
      <div>
        <h4>Additional User Documents</h4>
        <p>
          Select any additional documents which should be included in the
          binder.
        </p>
        <p>
          You may also upload any additional documents (pdf only) which you
          would like included and saved for future use.
        </p>
      </div>
      <div>
        <button onClick={(e) => handleClickAll(e)}>All</button>
        <button onClick={(e) => handleClickNone(e)}>None</button>
      </div>
      <br />
      <br />
      <table>
        <tbody>
          {props.customFileList &&
            props.customFileList.map((post, index) => (
              <tr>
                <td>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="additionDocCheckBox"
                    onChange={(e) => handleClickSelectedItems(e, post)}
                    checked={post.additionDocCheckBox}
                    value={post.additionDocCheckBox}
                  />
                </td>
                <td>
                  <button
                    class="btn btn-light"
                    onClick={(e) => handleCustomfileDeleteShow(e, post)}
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                  {popupCustomfileDeleteShow === post && (
                    <Popup
                      show={popupCustomfileDeleteShow === post}
                      handleClose={handleCustomfileDeleteClose}
                      handleShow={handleCustomfileDeleteShow}
                      body={
                        <EBinderDeleteCustomfileComponent fileName={post} />
                      }
                      btnName="Delete"
                      handleClick={(e) => handleDeletePopupClick(e, post)}
                    />
                  )}
                </td>
                <td>{post}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="form-group">
      <section className="container w-50" style={{
        border: 'dashed grey 1px',
        backgroundColor: 'lightgray',
        top: '50%',
        textAlign: 'center',
        lineHeight: '75px'
      }}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop files here to upload...</p>
          ) : (
            <p>Drag and drop PDF files here, or click to upload.</p>
          )}
        </div>
              </section>
        <div>{fileUploadErrors}</div>
      </div>
      {isUploading ? <FullPageLoader/> : null}
    </div>
  );
};
export default EBinderAdditionalDoc;
