import React,{useState} from 'react';
import Landingpage from '../LandingPagecomponent/LandingPage.Component'
import { withRouter } from "react-router-dom";
import IndividualEntitiesComponent from './IndividualEntities.component';
import CommonSearch from '../CommonPage/CommonSearch.component';



const IndividualEntitiesLanding = (props) => {
    const [recentSearch, setRecentSearch] = useState([])
const [searchCompany, setSearchCompany] = useState([]);

    return (
       
        <Landingpage  isFileCollapse={true} isIndividualEntitiesPage={true} 
        commonSearch={ <CommonSearch history={props.history} setRecentSearch={setRecentSearch} recentSearch={recentSearch} setSearchCompany={setSearchCompany} searchCompany={searchCompany}/>}content={
<IndividualEntitiesComponent />
        }/>
        
    )
        
    }
    export default withRouter(IndividualEntitiesLanding);