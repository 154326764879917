import React from "react";
import { useHistory } from "react-router-dom";

const IndividualEntitiesSharesPopup = (props) => {
  const history = useHistory();
  const Sharevalues =
    props.individualEntitiesShares && props.individualEntitiesShares.items;
    let handleClick = (e, companyCode, companyName) => {
      localStorage.setItem("companyCode", JSON.stringify(companyCode));
      localStorage.setItem("companyName", JSON.stringify(companyName));
      history.push("/CompanyMenuDetails/details");
    };
  return (
    <div className="py-3">
      <h5 className="panel-title">CORPORATE REGISTER</h5>

      <table className="table table-hover  table-xs fs-6">
        <thead>
          <tr>
            <th> Name</th>
            <th>Share Class</th>
            <th>Total Shares</th>
            <th>FullyPaid</th>
            <th>Beneficially Owned</th>
          </tr>
        </thead>

        <tbody>
        {Sharevalues && Sharevalues.length === 0 ? (
          <tr>
            <td colSpan="5">No matching records found</td>
          </tr>
        ) : (
                 Sharevalues &&
            Sharevalues.map((post) => (
              
              <tr>
                <td>    <a
                href={() => false}
                className="hand-cursor text-decoration-none"
                onClick={(e) =>
                  handleClick(e, post.value.CO_CODE, post.key)
                }
              >{post.key}</a></td>
                
                <td>{post.value.CLASS_CODE}</td>

                <td>{post.value.SUM_TOT_SHARES}</td>
             
                <td>
                <i    
      className={ (post.value.FULLY_PAID)? ' bi bi-check  fa-lg text-success ': '  bi bi-x  fa-lg  text-danger' }></i>
                </td>
                <td>
                <i    
                className={ (post.value.BEN_OWNER)? ' bi bi-check  fa-lg  text-success ': '  bi bi-x  fa-lg  text-danger' }></i>
                </td>
              </tr>
            )
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default IndividualEntitiesSharesPopup;
