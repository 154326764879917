import React,{useState}from 'react';
import Landingpage from '../LandingPagecomponent/LandingPage.Component'
import { withRouter } from "react-router-dom";
import CompaniesComponent from './Companies.component';
import CommonSearch from '../CommonPage/CommonSearch.component';

const CompaniesLanding = (props) => {
   
    const [searchCompany, setSearchCompany] = useState([]);
    return (
       
        <Landingpage  isFileCollapse={true} isCompaniesPage={true}
        commonSearch={ <CommonSearch history={props.history} setRecentSearch={props.setRecentSearch} recentSearch={props.recentSearch} setSearchCompany={setSearchCompany} searchCompany={searchCompany}/>} content={
<CompaniesComponent setRecentSearch={props.setRecentSearch} recentSearch={props.recentSearch} loggedUserInfo={props.loggedUserInfo} userId={props.userId} clientId={props.clientId}  />
        }/>
        
    )
        
    }
    export default withRouter(CompaniesLanding);