import React, { useState } from "react";

const EmailId = (props) => {
  const [usersEmail, setUsersEmail] = useState({
    emailAddress: props.emailId,
  });
// eslint-disable-next-line
  const [emailIdSubmitted, setEmailIdSubmitted] = useState(false);

  let handleChange = (e) => {
    setUsersEmail((usersEmail) => ({
      ...usersEmail,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div>
      <h3 className="mb-4">Email Address</h3>

      <table className="table table-responsive table-borderless">
        <tbody>
          <tr>
            <td className="fw-bold w-25 ">Email Address</td>{" "}
            <td className="w-50">
              <input
                type="email"
                name="emailAddress"
                id="emailAddress"
                placeholder="Email...."
                value={usersEmail.emailAddress}
                onChange={(e) => {
                  handleChange(e);
                  props.handlePersonDetailsChange(e);
                }}
                className={
                  "form-control input-sm " +
                  (emailIdSubmitted && !usersEmail.emailAddress
                    ? "is-invalid"
                    : "")
                }
                autoComplete="off"
              
              />
              {emailIdSubmitted && !usersEmail.emailAddress && (
                <div className="invalid-feedback">EmailAddress Required</div>
              )}
            </td>
          </tr>
        </tbody>{" "}
      </table>
    </div>
  );
};

export default EmailId;
