import React from "react";
import MainScreen from "../CommonPage/MainScreen.component";

const PasswordChangeSuccess = (props) => {
    
    return (
      <MainScreen
        content={
          <div className="container ">
            <form >
              <div className="form-group ">
              <h5 className="text-center">
              Password changed successfully
                </h5>
              </div>
            
                       <br />
              <div className="d-grid">
                <div className="col-auto col-md-12 text-center">
                <button className="buttonCommon" type="submit" id="backToLogin" onClick={() => props.history.push("/login")}>
                Back to login
                </button>
                </div>
              </div>
             
            </form>
          </div>
        }
      />
    );
  };
  
export default PasswordChangeSuccess;
