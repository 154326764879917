import React,{useState} from "react";
import Landingpage from "../LandingPagecomponent/LandingPage.Component";
import { withRouter } from "react-router-dom";
import BenificialOwnerComponent from "./BeneficialOwner.component";
import CommonSearch from "../CommonPage/CommonSearch.component";

const BenificialOwnerLanding = (props) => {
  const [recentSearch, setRecentSearch] = useState([])
  const [searchCompany, setSearchCompany] = useState([]);
  return (
    <Landingpage
      isFileCollapse={true}
      isBenificalOwnerPage={true}
  

commonSearch={ <CommonSearch history={props.history} setRecentSearch={setRecentSearch} recentSearch={recentSearch} setSearchCompany={setSearchCompany} searchCompany={searchCompany}/>}
      content={<BenificialOwnerComponent />}
    />
  );
};
export default withRouter(BenificialOwnerLanding);
