import React,{useState}from "react";
import { withRouter } from "react-router-dom";
import CommonSearch from "../CommonPage/CommonSearch.component";

import LandingPageComponent from "../LandingPagecomponent/LandingPage.Component";
import ASICDept from "./ASICDept.Component";

const ASICDeptLanding = (props) => {
  const [recentSearch, setRecentSearch] = useState([])
  const [searchCompany, setSearchCompany] = useState([]);
  return (
    <LandingPageComponent
      isMonitorCollapse={true}
      isAsicDeptPage={true}
  commonSearch={ <CommonSearch history={props.history} setRecentSearch={setRecentSearch} recentSearch={recentSearch} setSearchCompany={setSearchCompany} searchCompany={searchCompany}/>}
      content={<ASICDept />}
    />
  );
};
export default withRouter(ASICDeptLanding);
