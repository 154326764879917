import React, { useState } from "react";
import DatePicker from "react-datepicker";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const RegisteredOfficeAddressComponent = (props) => {
  const [premisses, setPremisses] = useState(true);
  let handleClickPremisses = (e) => {
    setPremisses(e.target.checked);
    props.setAsicDetails((changeOrder) => ({
      ...changeOrder,
      companyOccupiesPremises:e.target.checked
    }));
  };

  return (
    <div className="container">
    {console.log("companyOccupiesPremises",props.asicDetails.companyOccupiesPremises)}
      <form>
        <div className="form-group row">
          <div>
            <label htmlFor="changeDate" className="col-form-label input-sm ">
              Change Date
              <i
                className="bi bi-info-circle px-1"
                data-toggle="tooltip"
                data-placement="top"
                title="ASIC late fees apply if form is not lodgged with in 28days of change date"
              ></i>
            </label>

            <DatePicker
              className="form-control input-sm w-25"
              name="raChangeDate"
              selected={props.asicDetails.raChangeDate}
              onChange={(e) => props.handleChange(e, true, "raChangeDate")}
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              value={props.asicDetails.raChangeDate}
            />
          </div>
          <div className="col-xs-3">
            <label htmlFor="fullAddress" className="col-form-label">
              FULL ADDRESS
            </label>
            <GooglePlacesAutocomplete
              apiKey={"AIzaSyD8pHI8_Rviq7fmkxO5TBlJmaQl7kNHpE0"}
              selectProps={{
                placeholder: "Text...",
                isClearable: true,
                predefinedPlacesAlwaysVisible: true,
                value: props.asicDetails.registeredFullAddress,
                onChange: (val) => {
                  props.setAsicDetails((registeredAddress) => ({
                    ...registeredAddress,
                    registeredFullAddress: val,
                  }));
                },
              }}
            />
          </div>

          <div className="col-xs-3">
            <br />
            <input
              className="form-check-input"
              type="checkbox"
              value={props.asicDetails.companyOccupiesPremises}
              name="companyOccupiesPremises"
              id="companyOccupiesPremises"
              onClick={(e) => {
                handleClickPremisses(e);
                props.handleChange(e, false, "");
              }}
              checked={props.asicDetails.companyOccupiesPremises}
            />
            <label
              className="form-check-label"
              for="defaultCheckCompanyOccupiesPremises"
            >
              Company Occupies Premises
            </label>
          </div>

          <div className="col-xs-3">
            <br />
            <label htmlFor="occupierCompanyName" className="col-form-label">
              Occupier's Company Name
            </label>

            <input
              type="text"
              name="occupierCompanyName"
              className="form-control input-sm "
              placeholder="Text..."
              disabled={premisses}
              value={props.asicDetails.occupierCompanyName}
              onChange={(e) => props.handleChange(e, false, "")}
              autoComplete="off"
            />
          </div>
        </div>
      </form>
    </div>
  );
};
export default RegisteredOfficeAddressComponent;
