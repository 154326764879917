import React, { useState, useCallback } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";

const OfficerShareholdersNameChange = (props) => {
  const  shareHolderNames=  props.companyProfile &&  props.companyProfile.items &&  props.companyProfile.items.shareholders && props.companyProfile.items.shareholders.map((value) =>  
  `${value.NAME}:  ${value.CLASS_CODE}:  ${value.CLASS_NO}:  FP=${value.FULLY_PAID ? "Yes" : "no"}:  BO=${value.BEN_OWNER ? "Yes" : "no"}: 
   Total Shares=${value.SUM_TOT_SHARES} :${value.BEN_OWNER ? "Beneficially Held" : ""}` );

  const duplicatedOfficer=
  props.companyProfile &&
  props.companyProfile.items &&
  props.companyProfile.items.officers.filter(
    (thing, index, self) =>
      index === self.findIndex((t) => t.OFFICER_NAME === thing.OFFICER_NAME)
  );

    const  officerNames=   duplicatedOfficer&& duplicatedOfficer.map((value) =>     `${value.CEASED!=="" ? value.OFFICER_NAME:""}` );

  //const  officerNames=props.companyProfile && props.companyProfile.items &&  props.companyProfile.items.officers &&  props.companyProfile.items.officers.map((value) =>     `${value.CEASED!=="" ? value.OFFICER_NAME:""}` );
  function handleBothNames() {
    setOfficerShareHoldersData((value) => ({
      ...value,
      name:
        officerNames &&
        shareHolderNames &&
        officerNames.concat(shareHolderNames),
    }));
  }
  const [officerShareHoldersData, setOfficerShareHoldersData] = useState({
    name: [],
  });
  const handleSearch = useCallback((call) => {
    // eslint-disable-next-line
  }, []);
  function handleResetOfficerNameChange(e) {
    props.setAsicDetails((value) => ({ ...value, fromName: "" }));
  }

  return (
    <div className="container ">
      <hr />
      <form>
        <div className="form-group row">
          <div>
            <label
              htmlFor="officerShareholdersNameChangeDate"
              className="col-form-label input-sm "
            >
              Change Date
              <i
                className="bi bi-info-circle px-1"
                data-toggle="tooltip"
                data-placement="top"
                title="ASIC late fees apply if form is not lodgged with in 28days of change date"
              ></i>
            </label>
            <DatePicker
              className="form-control input-sm w-25"
              name="changeDate"
              /* selected={props.asicDetails.ohChangeDate}
              onChange={(e) => props.handleChange(e, true, "ohChangeDate")}
              value={props.asicDetails.ohChangeDate}
*/
              onChange={(e) => {
                const list = [...props.osNameChangeInputlist];
                list[props.nameChangeIndex]["ohChangeDate"] = e;
                props.setOSNameChangeInputlist(list);
                props.setAsicDetails((changeOrder) => ({
                  ...changeOrder,
                  osNameChangeInputlist: list,
                }));
              }}
              value={props.nameChangeElements.ohChangeDate}
              selected={props.nameChangeElements.ohChangeDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
            />
          </div>
          <div>
            <br />
            <label htmlFor="changeForNameChange" className="col-form-label  ">
              {" "}
              Change For
            </label>
            <br />
            <select
              name="changeForNameChange"
              id="changeForNameChange"
              className=" dropDownBox  w-25  "
              /*  onChange={(e) => {
                handleResetOfficerNameChange(e);
                props.handleChange(e, false, "");
                handleBothNames();
              }}*/
              onChange={(e) => {
                props.handleNameChangeChange(e, props.nameChangeIndex);
                handleBothNames();
                handleResetOfficerNameChange();
              }}
            >
              <option value=""></option>
              <option value="Officer">Officer</option>
              <option value="Shareholder">Shareholder</option>
              <option value="both">both</option>
            </select>
          </div>
          <div className="col-xs-3">
            <label htmlFor="fromName" className="col-form-label">
              From Name
            </label>

            <div className="col-sm-12 col-lg-7 TypeAheadDropDown  w-100 ">
              {props.nameChangeElements.changeForNameChange &&
                props.nameChangeElements.changeForNameChange !== "" &&
                props.nameChangeElements.changeForNameChange === "Officer" && (
                  <Typeahead
                    id="searchOfficer"
                    labelKey={(option) =>
                      `${option.CEASED !== "" ? option.OFFICER_NAME : ""}`
                    }
                    options={
                      props.companyProfile && props.companyProfile.items
                        ? props.companyProfile.items.officers.filter(
                            (thing, index, self) =>
                              index ===
                              self.findIndex(
                                (t) => t.OFFICER_NAME === thing.OFFICER_NAME
                              )
                          )
                        : []
                    }
                    isLoading={false}
                    onSearch={handleSearch}
                    onChange={(selected) => {
                     
                      const list = [...props.osNameChangeInputlist];
                      list[props.nameChangeIndex]["fromName"] = selected;
                      props.setOSNameChangeInputlist(list);
                      props.setAsicDetails((changeOrder) => ({
                        ...changeOrder,
                        osNameChangeInputlist: list,
                      }));
                    }}
                    selected={props.nameChangeElements.fromName}
                    placeholder="From Name"
                    useCache={false}
                  />
                )}

              {props.nameChangeElements.changeForNameChange &&
                props.nameChangeElements.changeForNameChange !== "" &&
                props.nameChangeElements.changeForNameChange === "Shareholder" && (
                 
                  <Typeahead
                    className="w-100"
                    id="searchShareholder"
                    labelKey={(option) =>
                      `${option.NAME}:  ${option.CLASS_CODE}:  ${option.CLASS_NO}:  FP=${option.FULLY_PAID ? "Yes" : "no"}:  BO=${option.BEN_OWNER ? "Yes" : "no"}
                      :  Total Shares=${option.SUM_TOT_SHARES}: ${option.BEN_OWNER ? "Beneficially Held" : ""}`
                    }
                  
                    options={
                      props.companyProfile &&
                      props.companyProfile.items ?
                      props.companyProfile.items.shareholders : []
                    }
                    isLoading={false}
                    onSearch={handleSearch}
                    onChange={(selected) => {
                     
                      const list = [...props.osNameChangeInputlist];
                      list[props.nameChangeIndex]["fromName"] = selected;
                      props.setOSNameChangeInputlist(list);
                      props.setAsicDetails((changeOrder) => ({
                        ...changeOrder,
                        osNameChangeInputlist: list,
                      }));
                    }}
                    selected={props.nameChangeElements.fromName}
                    placeholder="From Name"
                    useCache={false}
                  />
                )}
              {props.nameChangeElements.changeForNameChange &&
                props.nameChangeElements.changeForNameChange !== "" &&
                props.nameChangeElements.changeForNameChange === "both" && (
                  <Typeahead
                    id="both"
                    labelKey={
                      officerShareHoldersData &&
                      officerShareHoldersData.name &&
                      officerShareHoldersData.name.map((value) => value.name)
                    }
                    options={
                      officerShareHoldersData && officerShareHoldersData.name
                        ? officerShareHoldersData.name
                        : []
                    }
                    isLoading={false}
                    onSearch={handleSearch}
                    /*  onChange={(selected) => {
                      props.setAsicDetails((value) => ({ ...value, fromName: selected }));
                      console.log("officeHolderName selected officer xxxxxxxxxx",selected)
                    }}
                    selected={props.asicDetails.fromName}*/

                    onChange={(selected) => {
                      
                      const list = [...props.osNameChangeInputlist];
                      list[props.nameChangeIndex]["fromName"] = selected;
                      props.setOSNameChangeInputlist(list);
                      props.setAsicDetails((changeOrder) => ({
                        ...changeOrder,
                        osNameChangeInputlist: list,
                      }));
                    }}
                    selected={props.nameChangeElements.fromName}
                    placeholder="Name"
                    useCache={false}
                  />
                )}
            </div>
          </div>
          <div className="col-xs-3">
            <label htmlFor="toName" className="col-form-label">
              To Name{" "}
              <i
                className="bi bi-info-circle px-1"
                data-toggle="tooltip"
                data-placement="top"
                title="Full legal Name as per Passport"
              ></i>
            </label>
            <input
              type="text"
              name="toName"
              className="form-control "
              placeholder="To Name"
              value={props.nameChangeElements.toName}
              /*
              onChange={(e) => props.handleChange(e, false, "")}*/
              onChange={(e) =>
                props.handleNameChangeChange(e, props.nameChangeIndex)
              }
              autoComplete="off"
            />
          </div>
        </div>
      </form>
    </div>
  );
};
export default OfficerShareholdersNameChange;
