import { userConstants } from "../constants/login.constants";

export default function membersReport(state = {}, action) {
    switch (action.type) {
        case userConstants.MEMBERSREPORT_REQUEST:
            return {
                loading: true
            };
        case userConstants.MEMBERSREPORT_SUCCESS:
            return {
                items: action.membersReport
            };
        case userConstants.MEMBERSREPORT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}