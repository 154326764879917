import React, { useState } from "react";
import NumberFormat from 'react-number-format';

const DidComponent = (props) => {
  const [users, setUsers] = useState({
    didNumber: props.did,
  });
// eslint-disable-next-line
  const [submitted, setSubmitted] = useState(false);

  let handleChange = (e) => {
    setUsers((users) => ({
      ...users,
      [e.target.name]: e.target.value,
    }));
  };

  
  return (
    <div>
      <h3 className="mb-4">DID</h3>
      <table className="table table-responsive table-borderless">
        <tbody>
          <tr>
            <td className="fw-bold w-50 ">DID</td>{" "}
            <td>
              <NumberFormat
                type="text" data-filter='[0-9]'
                name="didNumber"
                format="### ##### ##### ##"
                onChange={e=> {handleChange(e);props.handlePersonDetailsChange(e)} }
     allowEmptyFormatting
                mask="_"
            
              
                value={ users.didNumber }
               
              />
              
              {submitted && !users.didNumber && (
                <div className="invalid-feedback">DID Number Required</div>
              )}
            </td>
          </tr>
        </tbody>{" "}
      </table>
    </div>
  );
};

export default DidComponent;
