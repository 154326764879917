import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";
import FullPageLoader from "../CommonPage/FullPageLoader";

const Div7aLoanAgreement = (props) => {

  
  const [reportParam, setReportParam] = useState({
loanBalance: 0,
    associateName: "",
   associateAddress:"",
   stateLocation: "",
     });

     //const { loanBalance, associateName,associateAddress} = reportParam;
     const [submitted, setSubmitted] = useState(false);
     let handleChange = (e) => {
      
      const { name, value } = e.target;
      setReportParam((reportParam) => ({ ...reportParam, [name]: value }));
     };



  // div 7a loan Lodgement Report
  const dispatch = useDispatch();

  //loading spinner
const [isDiv7AReportLoaded, setIsDiv7AReportLoaded] = useState(false);

  const selectedSearchValue = localStorage.getItem("inputSearchValue");
  
  const companyCode = JSON.parse(localStorage.getItem("companyCode"));
  const inputSearchValue = JSON.parse(localStorage.getItem("inputSearchValue"));
  const validate = companyCode !== null || (inputSearchValue !== null && inputSearchValue.length > 0) || (props.searchCompany && props.searchCompany.length > 0);
  function handleClick(e) {
    setSubmitted(true);
    
    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if(props.searchCompany && props.searchCompany[0]) {
      dispatch(userActions.getDiv7ALoanReport(props.searchCompany[0].Code,reportParam,setIsDiv7AReportLoaded));
    } else if  (selectedCompanyCode !== null) {
   
      dispatch(userActions.getDiv7ALoanReport(selectedCompanyCode,reportParam,setIsDiv7AReportLoaded));
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(userActions.getDiv7ALoanReport(value.Code,reportParam,setIsDiv7AReportLoaded))
      );
    }
  }



  
  return (
    <div class="container">
  
      <h3 className="mb-4">Enter Values</h3>

      <table className="table table-responsive table-borderless">
        <tbody>
          <tr>
          <td  className="fw-bold w-25 " >Enter Loan Balance (or leave Bank)</td>
          <td colspan="2" className="w-75">
          <input type="text" name="loanBalance" placeholder="0" size="55"  onChange={handleChange}   value={reportParam.loanBalance}/>
                      </td>
                   </tr>
          <tr>  <td  className="fw-bold w-25" > Enter Associate Name (Or Blank)
       
         
          </td>
         
          <td colspan="2" className="w-75">
          <input type="text"  name="associateName"  placeholder=" The Maximum length for this field is 200." size="55"  onChange={handleChange} value={reportParam.associateName} />
                      </td>
          </tr>  
          <tr>  <td  className="fw-bold  w-25" > Enter Associate Address (Or Blank)
       
         
          </td>
         
          <td colspan="2" className="w-75 ">
          <input type="text"  name="associateAddress"  placeholder=" The Maximum length for this field is 200." size="55"  onChange={handleChange}  value={reportParam.associateAddress} />
                      </td>
          </tr> 
          <tr>
          <td  className="fw-bold w-25 " >State of Jurisdiction</td>
          <td colspan="2"  className= "w-75" >
          <select
          name="stateLocation"
          id="stateLocation"
          className={' dropDownBox w-50 form-select ' + (submitted && !reportParam.stateLocation ? 'is-invalid' : '')}
          onChange={(e) => {
        
                        setReportParam(() => ({ ...reportParam, [e.target.name]: e.target.value }))
          }
        }
        required
        >
          <option value="" selected> </option>
          <option value="ACT">Australian Capital Territory</option>
          <option value="NSW">New South Wales</option>
          <option value="NT">Northern Territory</option>
          <option value="QLD">Queensland</option>
          <option value="SA">South Australia</option>
          <option value="VIC">Victoria</option>
          <option value="WA">Western Australia</option>
          <option value="TAS">Tasmania</option>
        </select>
        {
          submitted && !reportParam.stateLocation &&
          <div className="invalid-feedback">Please Select the State
          </div>
          
      }
                      </td>
                   </tr>  
          <tr>
            <th>
              {" "}
              <button   className={
                submitted && !validate ? "btn btn-danger" : "btn btn-primary"
              }  onClick={(e) => handleClick(e)}>
                Show Report
              </button>
              {isDiv7AReportLoaded? <FullPageLoader /> :null}
              {submitted && !validate && (
                <div className="text-danger py-3">Make sure Company Name  selected / Report doesn't exist.</div>
              )}
            </th>
          </tr>
        </tbody>{" "}
      </table>
    </div>
  );
};

export default Div7aLoanAgreement;
