import { userConstants } from "../constants/login.constants";

  function manageEbinderList(state = {}, action) {
     switch (action.type) {
         case userConstants.MANAGEEBINDERLIST_REQUEST:
             return {
                 loading: true
             };
         case userConstants.MANAGEEBINDERLIST_SUCCESS:
             return {
                 items: action.manageEbinderList
             };
         case userConstants.MANAGEEBINDERLIST_FAILURE:
             return {
                 error: action.error
             };
         default:
             return state
     }
 }
 export default manageEbinderList;