import React, { useState,useEffect } from "react";
import "./CompanyDetails.styles.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import UnitTrust from "./unitTrust.Component";
import ShareCapitalComponent from "./ShareCapitalComponent";
import DocumentHistoryComponent from "../CompanySnapshotComponent/DocumentHistoryComponent";
import Popup from "../CommonPage/Popup.component";
import PrintCompanyProfileComponent from "../PrintCompanyProfileComponents/PrintCompanyProfile.Component";
import ToggleSwitch from "../CommonPage/ToggleSwitch.component";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";
import FullPageLoader from "../CommonPage/FullPageLoader";
import ShareHolderDetailsComponent from "../ShareHolderDetailsComponent/ShareHolderDetails.component";
import { useHistory } from "react-router-dom";
import OfficerDetailsComponent from "../OfficerDetailsComponents/OfficerDetails.Component";
import CompanySnapshotComponent from "../CompanySnapshotComponent/CompanySnapshotComponent.component";
import EmailId from "../EmailIdComponent/EmailId.Component";
import DidComponent from "../Did.Component/Did.Component";

const Details = (props) => {
  const companyProfile = useSelector((state) => state.companyProfile);
  const regdInformation = useSelector((state) => state.regdInformation);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedOfficerToggle, setSelectedOfficerToggle] = useState("Current");
  const [selectedCompanyViewToggle, setSelectedCompanyViewToggle] =
    useState("DETAILED VIEW");
  //company Profile Report
  // eslint-disable-next-line
  const [companyProfilesubmitted, setCompanyProfileSubmitted] = useState(false);
  const [isCompanyLoaded, setIsCompanyLoaded] = useState(false);
  const [printCompany, setPrintCompany] = useState({
    docLodge: "Yes",
    officeHolders: "Yes",
    asDate: moment().format('DD/MM/yyyy')
  });

  let handleChange = (e) => {
    const { name, value } = e.target;
    const newCompany = { ...printCompany, [name]: value };
    setPrintCompany((printCompany) => (newCompany));

    console.log('PrintCompanyProfile.Component.jsx - newCompany', newCompany);
  };

  let printCompanyProfileHandleClick = (e) => {
    e.preventDefault();
    setCompanyProfileSubmitted(true);
    const selectedSearchValue = localStorage.getItem("inputSearchValue");

    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));

    if(props.searchCompany && props.searchCompany[0]) {
      dispatch(
        userActions.getCompanyReport(
          printCompany,
          props.searchCompany[0].Code,
          setIsCompanyLoaded
        )
      );
    } else if (selectedCompanyCode !== null) {
      dispatch(
        userActions.getCompanyReport(
          printCompany,
          selectedCompanyCode,
          setIsCompanyLoaded
        )
      );
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) => dispatchAPIs(value));
    }
  };

  function dispatchAPIs(value) {
    dispatch(
      userActions.getCompanyReport(printCompany, value.Code, setIsCompanyLoaded)
    );
  }

  function handleOfficerChange(e) {
    console.log("eeeeeeeeeee",e);
    setSelectedOfficerToggle(e);
  }
  // Company View Toggled Switch
  function handleCompanyViewChange(e) {
    setSelectedCompanyViewToggle(e);
  }
  // quickcompany Profile Report
  const dispatch = useDispatch();
  
  //loading spinner
  const [isQuickCompanyLoaded, setIsQuickCompanyLoaded] = useState(false);
  // eslint-disable-next-line
  const [submitted, setSubmitted] = useState(false);
  const selectedSearchValue = localStorage.getItem("inputSearchValue");
  const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
 
  useEffect(() => {
    if (props.searchCompany) {
     
        dispatch(userActions.getRegdInformation(props.searchCompany && props.searchCompany[0] && props.searchCompany[0].ACN));
    }
    // eslint-disable-next-line
  }, []);
  function handleClick(e) {
    setSubmitted(true);
    if(props.searchCompany && props.searchCompany[0]) {
      dispatch(
        userActions.getQuickCompanyProfileReport(
          props.searchCompany[0].Code,
          setIsQuickCompanyLoaded
        )
      );

    } else if (selectedCompanyCode !== null) {
      dispatch(
        userActions.getQuickCompanyProfileReport(
          selectedCompanyCode,
          setIsQuickCompanyLoaded
        )
      );
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(
          userActions.getQuickCompanyProfileReport(
            value.Code,
            setIsQuickCompanyLoaded
          )
        )
      );
    }
  }

  const history = useHistory();
  // officer details popup
  const [popupSecretariesShow, setPopupSecretariesShow] = useState(0);
  const [selectedoOfficerName, setSelectedoOfficerName] = useState("");
  const secretariesDetailHandleClose = () => setPopupSecretariesShow(false);
  const secretariesDetailHandleShow = (e, index, officerCode, officerName) => {
    e.preventDefault();

    setPopupSecretariesShow(index);
    setSelectedoOfficerName(officerName);
    dispatch(userActions.getOfficerDetailsReport(officerCode));
  };

  let officerDetailHandleClick = (e) => {
    e.preventDefault();
    secretariesDetailHandleClose();
  };

  //shareholders Popup

  const [popupShareHolderShow, setPopupShareHolderShow] = useState(0);
  const [selectedShareholdersName, setSelectedShareholdersName] = useState("");
  const shareHolderDetailHandleClose = () => setPopupShareHolderShow(false);
  const shareHolderDetailHandleShow = (
    e,
    index,
    code,
    holderType,
    OfficerName
  ) => {
    e.preventDefault();

    setPopupShareHolderShow(index);
    setSelectedShareholdersName(OfficerName);
    dispatch(userActions.getShareHolderDetailsReport(code, holderType));
  };
  let shareHolderDetailHandleClick = (e) => {
    e.preventDefault();
    shareHolderDetailHandleClose();
  };

  //email Id
  const [officerPersonal, setOfficerPersonal] = useState({
    officerEmailAddress: "",
    OfficerDidNumber: "",
  });
  const [popupEmailShow, setPopupEmailShow] = useState(false);
  const handlePopupEmailClose = () => setPopupEmailShow(false);
const handlePopupEmailShow = (e, index) => {
  e.preventDefault();

  setPopupEmailShow(index);
};

let handleEmailPopupClick = (e, rn,detail) => {
  e.preventDefault();
 //if (officerPersonal.emailAddress) {
    dispatch(userActions.postIndividualEmailID(rn, officerPersonal.emailAddress,detail));
    setTimeout(() => {
      dispatch(userActions.getCompanyProfileByCode(props.searchCompany  && props.searchCompany[0].Code));
    }, 0);
    // props.history.push(props.history.location);
    handlePopupEmailClose();
  //}
};

 let handleOfficerPersonalDetailsChange = (e) => {
  e.preventDefault();
  setOfficerPersonal((officerPersonal) => ({
    ...officerPersonal,
    [e.target.name]: e.target.value,
  }));
};
//officers did 
const [popupOfficersDidErrorShow, setPopupOfficersDidErrorShow] = useState(false);
const [rn, setRn] = useState("");
const [popupOfficersDidShow, setPopupOfficersDidShow] = useState(false);
const handleOfficersDidPopupClose = () => setPopupOfficersDidShow(false);
const handleOfficersDidPopupShow = (e, index) => {
  e.preventDefault();
  setPopupOfficersDidShow(index);
};


let handleDidPopupClick = (e, rn) => {
  e.preventDefault();

  //if (person.didNumber) {
    dispatch(userActions.postIndividualEntitiesDid(rn, officerPersonal.didNumber));
    setRn(rn)
    setPopupOfficersDidErrorShow(officerPersonal.didNumber);
    setTimeout(() => {
      
      dispatch(userActions.getCompanyProfileByCode(props.searchCompany  && props.searchCompany[0].Code));
    }, 0);
  
    //props.history.push(props.history.location);
 
    handleOfficersDidPopupClose();
 // }
};

const didUpdate = useSelector((state) => state.didUpdate);

const handleErrorOfficersDidPopupClose = () => setPopupOfficersDidErrorShow(false);
const handleErrorOfficersDidPopupShow = (e, index) => {
  e.preventDefault();
  setPopupOfficersDidErrorShow(index);
};

// share Holder email Id
const [shareholderPersonal, setShareholderPersonal] = useState({
  shareholderEmailAddress: "",
 
});
const [popupShareholderEmailShow, setPopupShareholderEmailShow] = useState(false);
const handlePopupShareholderEmailClose = () => setPopupShareholderEmailShow(false);
const handlePopupShareholderEmailShow = (e, index) => {
e.preventDefault();
console.log("indexemail",index);
setPopupShareholderEmailShow(index);
};

let handleShareholderEmailPopupClick = (e, rn) => {
e.preventDefault();
//if (shareholderPersonal.emailAddress) {
  dispatch(userActions.postIndividualEmailID(rn, shareholderPersonal.emailAddress));
  setTimeout(() => {
    dispatch(userActions.getCompanyProfileByCode(props.searchCompany  && props.searchCompany[0].Code));
  }, 0);
  // props.history.push(props.history.location);
  handlePopupShareholderEmailClose();
//}
};

let handleShareholderPersonalDetailsChange = (e) => {
e.preventDefault();
setShareholderPersonal((shareholderPersonal) => ({
  ...shareholderPersonal,
  [e.target.name]: e.target.value,
}));
};
useEffect(() => {
  setPopupShareholderEmailShow(false);
  setPopupEmailShow(false);
  
  // eslint-disable-next-line
}, []);
  return (
    <div>
      {console.log("unitrustRecordaaaaa:::::::", props.searchCompany)}
      <div className="py-2"></div>
      <ToggleSwitch
      name="companyViewToggle"
 
      checked={selectedCompanyViewToggle}
      onChange={(e) => handleCompanyViewChange(e)}
      yesLabel={"DETAILED VIEW"}
      noLabel={"SNAP SHOT VIEW"}
      toggleWidth={"160px"}
    />
    {selectedCompanyViewToggle ?
      <div>
     
      <div>
    

        <details open>
          <summary>Company Details</summary>
          <div className="d-flex flex-row-reverse fs-6  fw-bold ">
            <table>
   {/*  <tr>
                <td>
                  <a
                    href={() => false}
                    className="d-flex align-items-center text-white text-decoration-none "
                    id="dropdownUserDetail"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                  </a>
                  <ul className="dropdown-menu text-dark">
                    <li className="action ">
                      <a
                        className="dropdown-item "
                        role="button"
                        href={() => false}
                        onClick={(e) => handleClick(e)}
                      >
                        Download Quick Company Profile
                      </a>{" "}
                      <hr />
                    </li>

                    <li className="py-0 ">
                      <a
                        className="dropdown-item"
                        role="button"
                        href={() => false}
                        onClick={handleShow}
                      >
                        Download Full Company Profile
                      </a>
                      {show && (
                        <Popup
                          show={show}
                          handleClose={handleClose}
                          handleShow={handleShow}
                          body={
                            <PrintCompanyProfileComponent
                              printCompany={printCompany}
                              handleChange={handleChange}
                            />
                          }
                          btnName="Show Report"
                          handleClick={printCompanyProfileHandleClick}
                          isPopupReportLoading={isCompanyLoaded}
                        />
                      )}
                    </li>
                  </ul>
                </td>
              </tr>
              {isQuickCompanyLoaded ? <FullPageLoader /> : null}
               */}
             <tr>
                <td>
                  <i class="bi bi-printer"></i>

                  <a
                    className="text-decoration-none text-reset"
                    role="button"
                    href={() => false}
                    onClick={(e) => handleClick(e)}
                  >
                    Quick Company Profile
                  </a>
                </td>
                <td> </td>
                <td>
                  <i class="bi bi-printer"></i>
                  <a
                    className="text-decoration-none text-reset"
                    role="button"
                    href={() => false}
                    onClick={handleShow}
                  >
                    Company Profile
                  </a>
                  {show && (
                    <Popup
                      show={show}
                      handleClose={handleClose}
                      handleShow={handleShow}
                      body={
                        <PrintCompanyProfileComponent
                          printCompany={printCompany}
                          handleChange={handleChange}
                        />
                      }
                      btnName="Show Report"
                      handleClick={printCompanyProfileHandleClick}
                      isPopupReportLoading={isCompanyLoaded}
                    />
                  )}
                </td>
              </tr>
              {isQuickCompanyLoaded ? <FullPageLoader /> : null}
                 
            </table>
          </div>
          <div className="faq-content">
            {companyProfile && companyProfile.items && (
              <table className="table table-responsive table-borderless ">
                <tbody>
                  <tr>
                    <td className="fw-bold w-50">Company Name</td>
                    <td>
                      <label htmlFor="CompanyName" className="text-nowrap ">
                        {console.log("regdInformation:::::::", regdInformation)}
                        {companyProfile.items.company.CO_NAME} {"  " && <br />}{" "}
                        {regdInformation &&
                        regdInformation.items &&
                        regdInformation.items.Result.Desc === "REGD" ? (
                          <button  class="text-white btn btn-success rounded-pill disabled " style={{cursor:'default'}} >
                        
                            {" "}
                            REGD -Registered{" "}
                            </button>
                        ) : regdInformation &&
                          regdInformation.items &&
                          regdInformation.items.Result.Desc === "SOFF" ? (
                          <button class="text-white btn btn-warning rounded-pill"  style={{cursor:'default'}} >
                            {" "}
                            SOFF – registered, strike-off action in progress{" "}
                          </button>
                        ) : regdInformation &&
                          regdInformation.items &&
                          regdInformation.items.Result.Desc === "EXAD" ? (
                          <button class="text-white  btn btn-danger rounded-pill"  style={{cursor:'default'}} >
                            {" "}
                            EXAD - registered, under external administration{" "}
                          </button>
                        ) : regdInformation &&
                          regdInformation.items &&
                          regdInformation.items.Result.Desc === "NOAC" ? (
                          <button class="text-white btn btn-danger rounded-pill"  style={{cursor:'default'}} >
                            {" "}
                            NOAC - registered, not active{" "}
                          </button>
                        ) : regdInformation &&
                          regdInformation.items &&
                          regdInformation.items.Result.Desc === "DRGD" ? (
                          <button class="text-white btn btn-danger rounded-pill"  style={{cursor:'default'}} >
                            {" "}
                            DRGD -deregistered{" "}
                          </button>
                        ) : (
                          ""
                        )}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold w-50">ACN</td>{" "}
                    <td>
                      <label htmlFor="ACN">
                        {companyProfile.items.company.ACN}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold w-50">
                      {" "}
                      Date and Place of Incorporation
                    </td>
                    <td>
                      <label htmlFor="DateAndPlace" className="text-nowrap">
                        {companyProfile.items.company.DATE_INC
                          ? moment(
                              companyProfile.items.company.DATE_INC
                            ).format("DD/MM/YYYY")
                          : ""}{" "}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold w-50"> Review Date</td>
                    <td>
                      <label htmlFor="ReviewDate" className=" text-nowrap">
                        {companyProfile.items.company.REVIEW_DATE
                          ? moment(
                              companyProfile.items.company.REVIEW_DATE
                            ).format("DD/MM/YYYY")
                          : ""}{" "}
                      </label>
                    </td>
                  </tr>
                  <tr><td className="fw-bold w-50"> ASIC Debt (as at 
                    {companyProfile.items.company.DEBT_DATE
                      ? 
                           "   "+companyProfile.items.company.DEBT_DATE
                     
                      : " "}{" "}
                     ) </td>
                     <td>
                      <label htmlFor="DebtAmount" className=" text-nowrap">
                        {companyProfile.items.company.DEBT_AMOUNT
                          ? companyProfile.items.company.DEBT_AMOUNT
                          : "$0.00"}{" "}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold w-50">Department</td>
                    <td>
                      <label htmlFor="Department" className="text-nowrap">
                        {companyProfile.items.company.AGENT_ID
                          ? companyProfile.items.company.AGENT_ID
                          : "n/a"}
                      </label>
                    </td>
                  </tr>

                  <tr>
                    <td className="fw-bold w-50">Registered Office</td>
                    {companyProfile.items.company.RO_STREET !== null ? (
                      <td>
                        {`
                        ${
                          companyProfile.items.company.RO_CARE_OF !== null
                            ? companyProfile.items.company.RO_CARE_OF
                            : ""
                        }
                        ${
                          companyProfile.items.company.RO_BUILDING !== null
                            ? companyProfile.items.company.RO_BUILDING
                            : ""
                        }
                        ${companyProfile.items.company.RO_STREET},
                  ${companyProfile.items.company.RO_SUBURB},
                  ${companyProfile.items.company.RO_STATE}
                  ${companyProfile.items.company.RO_PCODE}`}
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                  <tr>
                    <td className="fw-bold w-50">
                      Principal Place of Business
                    </td>
                    {companyProfile.items.company.PB_STREET !== null ? (
                      <td className="text-wrap">
                        {`
                        ${
                          companyProfile.items.company.PB_CARE_OF !== null
                            ? companyProfile.items.company.PB_CARE_OF
                            : ""
                        }
                        ${
                          companyProfile.items.company.PB_BUILDING !== null
                            ? companyProfile.items.company.PB_BUILDING
                            : ""
                        }
                        ${companyProfile.items.company.PB_STREET},
                ${companyProfile.items.company.PB_SUBURB},
                ${companyProfile.items.company.PB_STATE}
                ${companyProfile.items.company.PB_PCODE}`}
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                  <tr>
                    <td className="fw-bold w-50">Postal Address</td>

                    <td className="text-wrap">
                      {`${
                        companyProfile.items.company.POSTAL1
                          ? companyProfile.items.company.POSTAL1 + ","
                          : ""
                      }
                    ${
                      companyProfile.items.company.POSTAL2
                        ? companyProfile.items.company.POSTAL2 + ","
                        : ""
                    }
                    ${
                      companyProfile.items.company.POSTAL3
                        ? companyProfile.items.company.POSTAL3 + ","
                        : ""
                    }
                    ${
                      companyProfile.items.company.POST_STATE
                        ? companyProfile.items.company.POST_STATE + "  "
                        : ""
                    }
                    ${
                      companyProfile.items.company.POST_PCODE
                        ? companyProfile.items.company.POST_PCODE
                        : ""
                    }`}

                      {!companyProfile.items.company.POSTAL1 &&
                        !companyProfile.items.company.POSTAL2 &&
                        !companyProfile.items.company.POSTAL3 &&
                        !companyProfile.items.company.POST_STATE &&
                        !companyProfile.items.company.POST_PCODE &&
                        "n/a"}
                    </td>
                  </tr>
                  <tr>
                  <td className="fw-bold w-50">Email Address</td>
                  <td>
                
                    <a
                    href={() => false}
                    onClick={(e) => handlePopupEmailShow(e,companyProfile.items.company.RN)}
                    className="hand-cursor"
                  >
                    <i   data-toggle="tooltip" html= "true" 
                    data-placement="bottom" title="Add/Modify email"
                      class="bi bi-pencil-square text-dark px-2"
                    ></i>
                  </a>
                  <span htmlFor="EmailAddress">
                  {companyProfile.items.company.EMAIL_ADDRESS
                    ? companyProfile.items.company.EMAIL_ADDRESS.indexOf(';')!== -1
                    ? 
                    companyProfile.items.company.EMAIL_ADDRESS.split(';').join("\n")       
                    : companyProfile.items.company.EMAIL_ADDRESS
                    : "Add/edit email" }
                </span>
                  {popupEmailShow === companyProfile.items.company.RN && (
                    <Popup
                      show={popupEmailShow === companyProfile.items.company.RN}
                      handleClose={handlePopupEmailClose}
                      handleShow={(e) =>
                        handlePopupEmailShow(e, companyProfile.items.company.EMAIL_ADDRESS)
                      }
                      body={
                        <EmailId
                          emailId={companyProfile.items.company.EMAIL_ADDRESS}
                          handlePersonDetailsChange={
                            handleOfficerPersonalDetailsChange
                          }
                        />
                      }
                      btnName="Save"
                      handleClick={(e) => handleEmailPopupClick(e, companyProfile.items.company.RN,"C")}
                    />
                  )}
                  </td>
                </tr>

                </tbody>
              </table>
            )}
          </div>
        </details>
      </div>
      <details open>
        <summary>Entity Details</summary>

        <div className="faq-content">
          {companyProfile && companyProfile.items && (
            <table className="table table-responsive table-borderless">
              <tbody>
                <tr>
                  <td className="fw-bold w-50">ASIC Company Class</td>
                  <td className="">
                    {companyProfile.items.company.ASC_CLASS_DESC
                      ? companyProfile.items.company.ASC_CLASS_DESC
                      : "n/a"}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold w-50">ASIC Company Sub-Class</td>
                  <td>
                    {companyProfile.items.company.ASC_CO_TYP_DESC
                      ? companyProfile.items.company.ASC_CO_TYP_DESC
                      : "n/a"}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold w-50">ASIC Company type</td>
                  <td>
                    {companyProfile.items.company.ASC_S_CLAS_DESC
                      ? companyProfile.items.company.ASC_S_CLAS_DESC
                      : "n/a"}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold w-50">Principal Acitivity</td>
                  <td>
                    {companyProfile.items.company.ACTIVITY
                      ? companyProfile.items.company.ACTIVITY
                      : "n/a"}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold w-50">Principal Acitivity Line 2</td>
                  <td>
                    {companyProfile.items.company.ACTIVITY2
                      ? companyProfile.items.company.ACTIVITY2
                      : "n/a"}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold w-50">Client Account Code</td>
                  <td>
                    {companyProfile.items.company.CLIENT_ACCOUNT_CODE
                      ? companyProfile.items.company.CLIENT_ACCOUNT_CODE
                      : "n/a"}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold w-50">Client Billing Code</td>
                  <td>
                    {companyProfile.items.company.CLIENT_BILLING_CODE
                      ? companyProfile.items.company.CLIENT_BILLING_CODE
                      : "n/a"}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </details>
      <details open>
        <summary>Officers</summary>
        <div className="faq-content">
          <div className="col">
            <ToggleSwitch
              name="officerToggle"
              checked={selectedOfficerToggle}
              onChange={(e) => handleOfficerChange(e)}
              yesLabel={"Current"}
              noLabel={"All"}
            />
          </div>
          <table className="table table-borderless">
            <thead>
              <tr>
                <th> Position Held</th> <th> Office Holder Name</th>
                <th> Appointment Date</th>
                <th className="text-wrap"> Default signature </th>
                <th> Email ID</th>
                <th >DID</th>
                {!selectedOfficerToggle && <th> Ceased Date</th>}
              </tr>
            </thead>
            
            {companyProfile &&
              companyProfile.items &&
              companyProfile.items.officers &&  companyProfile.items.officers.length > 0 ? companyProfile.items.officers.filter((value)=> selectedOfficerToggle ?  !value.CEASED: value).map((value, index) => (
                <tbody>
               
                  <tr>
                    <td className="col-1">{value.POSITION_HELD}</td>
                    <td className="fs-6 fw-normal col-4">
                      <a
                        href="{()=> false}"
                        className="text-decoration-none table-data "
                        onClick={(e) =>
                          secretariesDetailHandleShow(
                            e,
                            companyProfile.items.company.RN,
                            value.CODE,
                            value.OFFICER_NAME
                          )
                        }
                      >
                        {value.OFFICER_NAME}
                      </a>
                    </td>
                    {popupSecretariesShow ===
                      companyProfile.items.company.RN && (
                      <Popup
                        show={
                          popupSecretariesShow ===
                          companyProfile.items.company.RN
                        }
                        handleClose={secretariesDetailHandleClose}
                        handleShow={(e) =>
                          secretariesDetailHandleShow(
                            e,
                            companyProfile.items.company.RN,
                            value.CODE,
                            value.OFFICER_NAME
                          )
                        }
                        body={
                          <OfficerDetailsComponent
                            officerName={selectedoOfficerName}
                            history={history}
                            setPopupShow={setPopupSecretariesShow}
                            setSearchCompany={props.setSearchCompany}
                            recentSearch={props.recentSearch}
                            loggedUserInfo={props.loggedUserInfo}
                            userId={props.userId}
                            clientId={props.clientId}
                          />
                        }
                        handleClick={officerDetailHandleClick}
                      />
                    )}
                    <td>{moment(value.APPOINTED).format("DD/MM/YYYY")} </td>
                    <td><input
                    type="checkbox"
                    className="form-check-input"
                    id="defaultSignature"
                    name="defaultSignature"
                    
                  checked={value.DEF_SIGN}
                 value={value.DEF_SIGN}
                  /></td>
                  
                  <td>
          
                    <a
                      href={() => false}
                      onClick={(e) => handlePopupEmailShow(e,value.Entity_RN)}
                      className="hand-cursor"
                    >
                      <i
                      data-toggle="tooltip" html= "true" 
                      data-placement="bottom" title="Add/Modify email"
                        class="bi bi-pencil-square text-dark px-2"
                      ></i>
                    </a>
                    {!value.EMAIL?"Add/edit email" : value.EMAIL.indexOf(';')!== -1? value.EMAIL.split(';').join("\n")
       
                  :  value.EMAIL}
                    {popupEmailShow === value.Entity_RN && (
                      <Popup
                        show={popupEmailShow === value.Entity_RN}
                        handleClose={handlePopupEmailClose}
                        handleShow={(e) =>
                          handlePopupEmailShow(e, value.EMAIL)
                        }
                        body={
                          <EmailId
                            emailId={value.EMAIL}
                            handlePersonDetailsChange={
                              handleOfficerPersonalDetailsChange
                            }
                          />
                        }
                        btnName="Save"
                        handleClick={(e) => handleEmailPopupClick(e, value.Entity_RN)}
                      />
                    )}
                  </td>
                 
                  <td >
                
                  
                      <a
                      className={
                        value.DID
                          ? "text-success text-decoration-none hand-cursor"
                          : " text-danger text-decoration-none hand-cursor"
                      }
                        href={() => false}
                        onClick={(e) => handleOfficersDidPopupShow(e, value.Entity_RN)}
                      >
                        DID
                      </a>
                      
                      {popupOfficersDidShow === value.Entity_RN && (
                        <Popup
                          show={popupOfficersDidShow === value.Entity_RN}
                          handleClose={handleOfficersDidPopupClose}
                          handleShow={(e) => handleOfficersDidPopupShow(e, value.Entity_RN)}
                          body={
                            <DidComponent
                          
                              did={value.DID}
                              handlePersonDetailsChange={
                                handleOfficerPersonalDetailsChange
                              }
                            />
                          }
                          btnName="Save"
                          handleClick={(e) =>
                            handleDidPopupClick(e, value.Entity_RN)
                          }
                        
                          />
                      )}
                      
                  {didUpdate &&
                    didUpdate.error &&
                    didUpdate.error.indexOf("400") !== -1 ? (
                      <Popup
                        show={popupOfficersDidErrorShow}
                        heading={"Invalid DID " + popupOfficersDidErrorShow}
                        handleClose={handleErrorOfficersDidPopupClose}
                        handleShow={(e) =>
                          handleErrorOfficersDidPopupShow(e, value.DID)
                        }
                        body={<DidComponent
                          //did={post.DID}
                          handlePersonDetailsChange={
                            handleOfficerPersonalDetailsChange
                          }
                        />
                        
                      }
                      btnName="Save"
                      handleClick={(e) =>
                        handleDidPopupClick(e, rn)
                      }
                      //  handleClick={handleErrorDidPopupClick}
                      />
                    ) : (
                      ""
                    )}
                 </td>
            
                    {!selectedOfficerToggle && (
                      <td>
                     
                        {value.CEASED &&
                          moment(value.CEASED).format("DD/MM/YYYY")}{" "}
                      </td>
                    )}
                  </tr>
                </tbody>
              )):  <tbody><tr><td colSpan={6}>No data exist in the officer</td></tr></tbody>}
          </table>
        </div>
      </details>

      <details open>
        <summary>Share Capital</summary>
        <div className="faq-content">
          <ShareCapitalComponent />
        </div>
        </details>
      <details open>
        <summary>Shareholders</summary>
        <div className="faq-content">
          {companyProfile && companyProfile.items && (
            <table class="table table-responsive  table-hover">
              <thead className="fw-bold">
                <tr>
                  <th>Share Holders Name </th>
                  <th>Class Code</th>
                  <th>Total Shares</th>
                  <th>Ben-Owned</th>
                  <th>FullyPaid</th>
                  <th>Email ID</th>
                </tr>
              </thead>
              <tbody>
                {companyProfile.items.shareholders &&companyProfile.items.shareholders.length>0?companyProfile.items.shareholders.map((value, index) => (
                  <tr>
                    <td class=" w-25 ">
                      <a
                        href="{()=> false}"
                        className="text-decoration-none table-data "
                        onClick={(e) =>
                          shareHolderDetailHandleShow(
                            e,
                            value.CODE,
                            value.CODE,
                            value.HOLDERTYPE,
                            value.NAME
                          )
                        }
                      >
                        {value.NAME}
                      </a>
                    </td>
                    {popupShareHolderShow === value.CODE && (
                      <Popup
                        show={popupShareHolderShow === value.CODE}
                        handleClose={shareHolderDetailHandleClose}
                        handleShow={(e) =>
                          shareHolderDetailHandleShow(
                            e,
                            value.CODE,
                            value.CODE,
                            value.HOLDERTYPE,
                            value.NAME
                          )
                        }
                        body={
                          <ShareHolderDetailsComponent
                            NAME={selectedShareholdersName}
                            history={history}
                            setPopupShareHolderShow={setPopupShareHolderShow}
                            setSearchCompany={props.setSearchCompany}
                            recentSearch={props.recentSearch}
                            loggedUserInfo={props.loggedUserInfo}
                            userId={props.userId}
                            clientId={props.clientId}
                          searchCompany={props.searchCompany}
                          />
                        }
                        handleClick={shareHolderDetailHandleClick}
                      />
                    )}

                    <td class="  w-25 ">{value.CLASS_CODE}</td>
                    <td class="  w-25">
                      {Intl.NumberFormat("en-US").format(value.SUM_TOT_SHARES)}
                    </td>
                    <td class="  w-25 ">{value.BEN_OWNER ? "Yes" : "No"}</td>

                    <td class="  w-25 ">{value.FULLY_PAID ? "FP" : ""}</td>
              
                  <td>
 {value.HOLDERTYPE ==="I" ?(
                    <a
                      href={() => false}
                      onClick={(e) => handlePopupShareholderEmailShow(e,value.Entity_RN)}
                      className="hand-cursor"
                    >  <i
                    data-toggle="tooltip" html= "true" 
                    data-placement="bottom" title="Add/Modify email"
                    class={ "bi bi-pencil-square text-dark px-2" }
                  ></i>
                  
                    </a>
                  
					):("n/a" )     }
               {  value.EMAIL_ADDRESS || value.HOLDERTYPE ==="G" ? value.EMAIL_ADDRESS: "Add/edit email" }
                    {popupShareholderEmailShow === value.Entity_RN && (
                      <Popup
                        show={popupShareholderEmailShow === value.Entity_RN}
                        handleClose={handlePopupShareholderEmailClose}
                        handleShow={(e) =>
                          handlePopupShareholderEmailShow(e, value.EMAIL_ADDRESS)
                        }
                        body={
                          <EmailId
                            emailId={value.EMAIL_ADDRESS}
                            handlePersonDetailsChange={
                              handleShareholderPersonalDetailsChange
                            }
                          />
                        }
                        btnName="Save"
                        handleClick={(e) => handleShareholderEmailPopupClick(e, value.Entity_RN)}
                      />
                    )}
                  </td>
                 
                  </tr>
                )):<tr><td colspan ={6}>No data exist in the Shareholders</td></tr>}
              </tbody>
            </table>
          )}
        </div>
      </details>
      {userActions.isUnitTrustEmpty() !== "" && (
        <details open>
          <summary>Unit Trusts</summary>
          <div className="faq-content">
            <UnitTrust />
          </div>
        </details>
      )}
      <details open>
        <summary>Document History</summary>
        <div className="faq-content">
          <DocumentHistoryComponent />
        </div>
      </details>
      </div>:
    
      <CompanySnapshotComponent
      history={props.history}
      setRecentSearch={props.setRecentSearch}
      recentSearch={props.recentSearch}
      setSearchCompany={props.setSearchCompany}
      searchCompany={props.searchCompany}
      loggedUserInfo={props.loggedUserInfo}
      userId={props.userId} clientId={props.clientId}
    />
 
    }
  
    </div>
  );
};
export default Details;
