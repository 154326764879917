import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import "./LandingPage.style.scss";
import { withRouter } from "react-router-dom";
import { TimeoutLogic } from "../../timeoutComponent/TimeoutLogic";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import { isLocalhost } from "../../util/helpers";

const LandingPage = (props) => {
  // quickcompany Profile Report
  const userName = localStorage.getItem("userName");
  const API_URL = process.env.REACT_APP_PRIME_API_URL;

  const [isMobile, setMobile] = useState(document.body.clientWidth <= 498);

  const dispatch = useDispatch();
  const loggedUserInfo = useSelector((state) => state.loggedUserInfo);
  const imageURL =
    loggedUserInfo && loggedUserInfo.items && loggedUserInfo.items.ClientLogo;
  const alternateLogo =
    loggedUserInfo && loggedUserInfo.items && loggedUserInfo.items.ClientID;
  useEffect(() => {
    dispatch(userActions.getLoggedUserInfo());
    dispatch(userActions.getDirectorIdCount());
    // eslint-disable-next-line
  }, [isMobile]);
  console.log("isMobile --------", isMobile);
  //message card
  const [cardShow, setCardShow] = useState(true);
  const handleCardClose = () => setCardShow(false);
  // did count
  const directorIdCount = useSelector((state) => state.directorIdCount);

  function splitDid(did) {
    const splitDid = did.indexOf(" ") !== -1 ? did.split(" ") : did;
    return did.indexOf(" ") !== -1
      ? `${splitDid[0]} (${splitDid[1]})`
      : splitDid;
  }

  const cookies = new Cookies();
  const history = useHistory();

  useEffect(() => {
    const token = cookies.get("token");

    if (!token) {
      dispatch(userActions.logout());
      history.push("/login");
    }
  });

  const environment = isLocalhost()
    ? "http://kyc.localhost.com:3010"
    : process.env.REACT_APP_NEW_HOST;

  return (
    <div className="container-fluid">
      <TimeoutLogic history={props.history} />
      <div className="row flex-nowrap">
        <div className="col-auto px-4 py-3 col-md-3 col-xl-2 px-sm-4 leftNavSideMenu">
          <div className="text-white d-flex flex-column align-items-center align-items-sm-start min-vh-100">
            <div>
              <img src="/prime_compliance_logo.jpg" width="180" />
            </div>
            <br />
            <a
              href={() => false}
              className="pb-3 text-white d-flex align-items-center mb-md-0 me-md-auto text-decoration-none"
            >
              <div className="rcorners1">
                <span className="companyInitial">
                  {loggedUserInfo &&
                    loggedUserInfo.items &&
                    loggedUserInfo.items.ClientName.charAt(0).toUpperCase()}
                </span>
              </div>
              <span className="px-2 pb-1 fs-7 d-none d-sm-inline">
                {loggedUserInfo &&
                  loggedUserInfo.items &&
                  loggedUserInfo.items.ClientID}{" "}
                <br />
              </span>
            </a>
            <span className="px-0 pb-1 fs-7 d-none d-sm-inline text-secondary">
              Discover
            </span>
            <ul className="mb-0 nav flex-column w-100 mb-sm-auto" id="menu">
              <li className="nav-item ">
                <a
                  href={() => false}
                  className={
                    "nav-link align-middle px-2 " +
                    (props.isCompany ? "selected" : "")
                  }
                  onClick={() => props.history.push("/CompanyMenuDetails")}
                >
                  <i className="bi bi-building text-secondary"></i>

                  <span className="ms-1 d-none d-sm-inline">Company</span>
                </a>
              </li>

              <li className="nav-item">
                <a
                  href={() => true}
                  className={"nav-link align-middle px-2 "}
                  onClick={() =>
                    (window.location.href = `${environment}/#/know-your-customer`)
                  }
                >
                  <i class="bi bi-person-check-fill text-secondary"></i>{" "}
                  <span class="ms-1 d-none d-sm-inline">KYC</span>
                </a>
              </li>

              {loggedUserInfo &&
                loggedUserInfo.items &&
                !loggedUserInfo.items.ExcludeDeparmentalActions && (
                  <li className="nav-item ">
                    <a
                      href="#submenu2"
                      data-bs-toggle="collapse"
                      className="px-0 px-2 align-middle nav-link"
                    >
                      <i className="fs-6 bi-files text-secondary"></i>{" "}
                      <span className="ms-1 d-none d-sm-inline">Files</span>
                    </a>
                    <ul
                      className={
                        props.isFileCollapse
                          ? "nav flex-column ms-1"
                          : "collapse nav flex-column ms-1"
                      }
                      id="submenu2"
                      data-bs-parent="#menu"
                    >
                      <li className="w-100">
                        <a
                          href={() => false}
                          className={
                            "nav-link px-4 " +
                            (props.isCompaniesPage ? "selected" : "")
                          }
                          onClick={() =>
                            props.history.push("/CompaniesLanding")
                          }
                        >
                          <span className="d-none d-sm-inline">Companies</span>
                        </a>
                      </li>

                      <li>
                        <a
                          href={() => false}
                          className={
                            "nav-link px-4 " +
                            (props.isEbinderPage ? "selected" : "")
                          }
                          onClick={() =>
                            props.history.push("/EbinderMainLanding")
                          }
                        >
                          <span className="d-none d-sm-inline">eBinders</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={() => false}
                          className={
                            "nav-link px-4 " +
                            (props.isIndividualEntitiesPage ? "selected" : "")
                          }
                          onClick={() =>
                            props.history.push("/IndividualEntitiesLanding")
                          }
                        >
                          <span className="d-none d-sm-inline">
                            Individuals & Entities
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={() => false}
                          className={
                            "nav-link px-4 " +
                            (props.isUnitTrustRegisterPage ? "selected" : "")
                          }
                          onClick={() =>
                            props.history.push("/UnitTrustRegisterLanding")
                          }
                        >
                          <span className="d-none d-sm-inline">
                            Unit Trusts
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={() => false}
                          className={
                            "nav-link px-4 " +
                            (props.isJointHolderPage ? "selected" : "")
                          }
                          onClick={() =>
                            props.history.push("/JointHolderLanding")
                          }
                        >
                          <span className="d-none d-sm-inline">
                            Joint Holders
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          href={() => false}
                          className={
                            "nav-link px-4 " +
                            (props.isBusinessPage ? "selected" : "")
                          }
                          onClick={() =>
                            props.history.push("/BusinessNameLanding")
                          }
                        >
                          <span className="d-none d-sm-inline">
                            Business Names
                          </span>
                        </a>
                      </li>
                      {/*  <li>
                    <a
                      href={() => false}
                      className={
                        "nav-link px-4 " +
                        (props.isBenificalOwnerPage ? "selected" : "")
                      }
                      onClick={() =>
                        props.history.push("/BeneficialOwnerLanding")
                      }
                    >
                      <i
                        className={
                          props.isBenificalOwnerPage
                            ? "bi bi-heart-fill text-dark"
                            : "bi bi-heart-fill"
                        }
                      ></i>{" "}
                      <span
                        className={
                          props.isBenificalOwnerPage
                            ? "d-none d-sm-inline text-dark"
                            : "d-none d-sm-inline"
                        }
                      >
                        {" "}
                        Beneficial Owners & Trusts
                      </span>
                    </a>
                      </li>
                      */}
                      <li>
                        <a
                          href={() => false}
                          className={
                            "nav-link px-4 " +
                            (props.isDidListPage ? "selected" : "")
                          }
                          onClick={() => props.history.push("/DIDListLanding")}
                        >
                          <span className="d-none d-sm-inline">
                            Director ID
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
              {loggedUserInfo &&
                loggedUserInfo.items &&
                !loggedUserInfo.items.ExcludeDeparmentalActions && (
                  <li className="nav-item ">
                    <a
                      href="#submenu3"
                      data-bs-toggle="collapse"
                      className="px-0 px-2 align-middle nav-link"
                    >
                      <i className="bi bi-laptop text-secondary"></i>{" "}
                      <span className="ms-1 d-none d-sm-inline ">Monitors</span>
                    </a>
                    <ul
                      className={
                        props.isMonitorCollapse
                          ? "nav flex-column ms-1"
                          : "collapse nav flex-column ms-1"
                      }
                      id="submenu3"
                      data-bs-parent="#menu"
                    >
                      <li className="w-100">
                        <a
                          href={() => false}
                          className={
                            "nav-link px-4 " +
                            (props.isMonitorPage ? "selected" : "")
                          }
                          onClick={() => props.history.push("/MonitorLanding")}
                        >
                          <span className="d-none d-sm-inline">
                            Annual Statements
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={() => false}
                          className={
                            "nav-link px-4 " +
                            (props.isAsicDeptPage ? "selected" : "")
                          }
                          onClick={() => props.history.push("/ASICDeptLanding")}
                        >
                          <span className="d-none d-sm-inline">ASIC Debts</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={() => false}
                          className={
                            "nav-link px-4 " +
                            (props.isOrderMonitorPage ? "selected" : "")
                          }
                          onClick={() =>
                            props.history.push("/OrderMonitorLanding")
                          }
                        >
                          <i></i>{" "}
                          <span className="d-none d-sm-inline">Orders</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={() => false}
                          className={
                            "nav-link px-4 " +
                            (props.isDigitalSignaturePage ? "selected" : "")
                          }
                          onClick={() =>
                            props.history.push("/DigitalSignatureLanding")
                          }
                        >
                          <span className="d-none d-sm-inline">
                            Digital Signatures
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          href={() => false}
                          className={
                            "nav-link px-4 " +
                            (props.isFormsAndDocumentPage ? "selected" : "")
                          }
                          onClick={() =>
                            props.history.push("/FormsAndDocumentsLanding")
                          }
                        >
                          <span className="d-none d-sm-inline">
                            ASIC Forms Portal
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
            </ul>
            <div className="py-5 dropdown flex-nowrap">
              <div>
                {" "}
                <hr size="5" />
              </div>

              <footer className=" fixed-bottom text-nowrap">
                {!isMobile && cardShow && (
                  <div
                    class="card text-dark d-none d-sm-none d-md-none d-md-block d-lg-none d-xl-block fixed-bottom"
                    style={{ background: "#D8D8D8", width: "16.6%" }}
                  >
                    <div className="px-2 py-2 row">
                      <div className="col">
                        <h5 class="card-title text-align-right fw-bold">
                          DID Summary
                        </h5>
                      </div>

                      <div className="flex-row-reverse col d-flex">
                        <button
                          type="button"
                          className="btn-close btn btn-light "
                          aria-label="Close"
                          onClick={handleCardClose}
                        ></button>
                      </div>
                    </div>
                    <div class="card-body ">
                      {/* <span class="btn btn-danger rounded-pill"      style={{ width:"100%" }} ><i class="bi bi-exclamation-circle"></i> {directorIdCount&&
                directorIdCount.items &&directorIdCount.items.DAYS_TO_GO}  Days to go</span>
                */}
                      <table className="text-wrap">
                        <tr>
                          <td>DeadLine</td>{" "}
                          <td className="text-danger"> 30th Nov 2022</td>
                        </tr>
                        <tr>
                          {" "}
                          <td>Summary</td>{" "}
                          <td>
                            #{" "}
                            {directorIdCount &&
                              directorIdCount.items &&
                              directorIdCount.items.TOTAL_COUNT}
                          </td>
                        </tr>
                        <tr className="text-success">
                          <td> Total With DID</td>{" "}
                          <td>
                            #
                            {directorIdCount &&
                              directorIdCount.items &&
                              splitDid(directorIdCount.items.WITH_DID)}
                            %
                          </td>
                        </tr>
                        <tr className="text-danger">
                          <td>Total Without DID</td>{" "}
                          <td>
                            #
                            {directorIdCount &&
                              directorIdCount.items &&
                              splitDid(directorIdCount.items.WITHOUT_DID)}
                            %
                          </td>
                        </tr>
                        <tr className="">
                          <td>
                            <button
                              className="buttonCommon fs-6"
                              onClick={() =>
                                props.history.push("/DIDListLanding")
                              }
                            >
                              DID Report
                            </button>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                )}

                {/* {imageURL ?
                <img
                  src={API_URL+imageURL}
                  alt="alternateLogo"
                  width="30"
                  height="30"
                  className=" footerfixed"
                />: <p>{alternateLogo}</p>} */}
                <a
                  href={() => false}
                  className="text-white d-flex align-items-center text-decoration-none "
                  id="dropdownUserDetail"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="px-2 mx-2 d-none d-sm-inline footerfixed hand-cursor">
                    {userName}
                  </span>
                </a>
                <ul
                  className="dropdown-menu text-small"
                  aria-labelledby="dropdownUserDetail"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      href={() => false}
                      onClick={() => props.history.push("/login")}
                    >
                      Sign out
                    </a>
                  </li>
                </ul>
              </footer>
            </div>
          </div>
        </div>
        <div className="py-3 col">
          {props.commonSearch}

          {<hr />}
          <div className="row">
            <span className="lead">{props.content}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(LandingPage);
