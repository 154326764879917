import React, { useState } from "react";

import DatePicker from "react-datepicker";
import AddOrRemoveAsicAgentListComponent from "./AddOrRemoveAsicAgentList.component";
import Popup from "../CommonPage/Popup.component";
import ClassListComponent from "../ClassListComponent/ClassList.component";
import NumberFormat from "react-number-format";

const DividendStatementOrderComponent = (props) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);  

  let handleClickClassList = (e, index,classCode, classDesc, classNo) => {
    e.preventDefault();
    props.setAsicDetails((classList) => ({
      ...classList,
      dividendClassCode: classCode,classCodeDetails:classDesc, classCodeNo:classNo
    }));
    handleClose();

  };
 

  function handleReset(e) {
    if (props.asicDetails.dividendClassCode) {
      props.setAsicDetails((value) => ({
        ...value,
        dividendClassCode: "",
        classCodeDetails: "",
        classCodeNo: "",
       
      }));
    }
    
  }
  
  function handleCalculate(e) {
    if (e.target.value) {
      props.setAsicDetails((value) => ({
        ...value,
        unFrankedAmountPercentage:100.00-e.target.value
       
      }));
    }
    
  }

  let frantAmt = 0;
  let unfrankAmt = 0;
  let total = 0;
  let frankPercentage = 0;
  let  unfrankPercentage = 0;

  function handleFrankedAmountCalculate(e) {
    if (e.target.value) {
      frantAmt = parseFloat(e.target.value)
      unfrankAmt = props.asicDetails.unFrankedAmount ? props.asicDetails.unFrankedAmount:0;
      total = parseFloat(frantAmt) + parseFloat(unfrankAmt);
      if (total !== 0) {
       
        frankPercentage = frantAmt / total * 100
        unfrankPercentage = 100 - frankPercentage
        props.setAsicDetails((value) => ({
          ...value,
          frankedAmountPercentage:frankPercentage,
       
          unFrankedAmountPercentage:unfrankPercentage,
        }));
      }
     
    }
    
  }
  
  function handleUnFrankedAmountCalculate(e) {

    if (e.target.value) {
      frantAmt = props.asicDetails.frankedAmount ? props.asicDetails.frankedAmount:0;
      unfrankAmt = e.target.value
      total = parseFloat(frantAmt) + parseFloat(unfrankAmt);
      if (total !== 0) {
       
        unfrankPercentage = unfrankAmt / total * 100
        frankPercentage = 100 - unfrankPercentage
        props.setAsicDetails((value) => ({
          ...value,
          frankedAmountPercentage:frankPercentage,
       
          unFrankedAmountPercentage:unfrankPercentage,
          unFrankedAmount:e.target.value
        }));
      }
     
    }

    
  }
  

  return (
   
    <div className="card">
      <form>
        <div className="card-header">Dividend</div>

        <div className="row py-2">
          <div className="col-sm-5">
            <div className="input-group ">
              <label htmlFor="dividendClassCode" className="col-form-label px-4">
                Class Code
              </label>
              <input
                type="text"
                name="dividendClassCode"
                id="dividendClassCode"
                className="form-control input-sm "
                placeholder="Text..."
                value={props.asicDetails.dividendClassCode} onChange={e=>{ props.handleChange(e, false, ''); handleReset(e)}}
                autoComplete='off' 
              />
              <div className="input-group-append">
              <i className="bi bi-list btn btn-primary"  onClick={(e) => handleShow(e)}>
              <i className="bi bi-chevron-down"></i>
            </i>
     
              </div>
            </div>
          </div>
          {show && (
            <Popup
              show={show}
              handleClose={handleClose}
              handleShow={handleShow}
              body={
                <ClassListComponent 
                handleClickClassList={handleClickClassList}
                />
              }
             
            />
          )}

          <div className="col-auto">
            <input
              type="text"
              name="classCodeDetails"
              className="form-control"
              value={props.asicDetails.classCodeDetails} onChange={e=> props.handleChange(e, false, '')}
              disabled
            ></input>
          </div>
          <div className="col-auto">
            <input
              type="text"
              name="classCodeNo"
              className="form-control"
              value={props.asicDetails.classCodeNo} onChange={e=> props.handleChange(e, false, '')}
              disabled
            ></input>
          </div>
        </div>

        <div className="row py-3 px-4">
          <div className="col-sm-3">
            <label htmlFor="booksCloseDate" className="col-form-label ">
              Declaration /Books Close Date
            </label>
            <DatePicker
              className="form-control input-sm"
              name="booksCloseDate"
              selected={props.asicDetails.booksCloseDate}
              onChange={e=> props.handleChange(e, true, 'booksCloseDate')}
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              value={props.asicDetails.booksCloseDate}
            />
          </div>

          <div className="col-auto">
            <label htmlFor="financialYearEndDate" className="col-form-label">
              Financial Year End Date
            </label>
            <DatePicker
              className="form-control input-sm"
              name="financialYearEndDate"
              selected={props.asicDetails.financialYearEndDate}
              onChange={e=> props.handleChange(e, true, 'financialYearEndDate')}
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              value={props.asicDetails.financialYearEndDate}
            />
          </div>
        </div>
        <div className="row py-3 px-4">
          <div className="col-sm-3 ">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="interimDividend"
              name="interimDividend"
              onChange={e=> props.handleChange(e, false, '')} checked={props.asicDetails.interimDividend}
            />
            <label className="form-check-label " for="interimDividend">
              Interim Dividend
            </label>
          </div>
          <div className="col-sm-3">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="finalDividend"
              name="finalDividend"
              onChange={e=> props.handleChange(e, false, '')} checked={props.asicDetails.finalDividend}
            />
            <label className="form-check-label" for="finalDividend">
              Final Dividend
            </label>
          </div>
          <div className="col-sm-3">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="shortMeetingNotice"
             name="shortMeetingNotice"
              onChange={e=> props.handleChange(e, false, '')} checked={props.asicDetails.shortMeetingNotice}
            />
            <label className="form-check-label" for="shortMeetingNotice">
              Short Meeting Notice
            </label>
          </div>
          <div className="col-sm-3">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="shareholdersResolutions"
              name="shareholdersResolutions"
              onChange={e=> props.handleChange(e, false, '')} checked={props.asicDetails.shareholdersResolutions}
            />
            <label className="form-check-label" for="shareholdersResolutions">
              Include Shareholders Resolutions
            </label>
          </div>
          <div className="col-sm-3">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="inputFrankedDollar"
              name="inputFrankedDollar"
              onChange={e=> props.handleChange(e, false, '')} checked={props.asicDetails.inputFrankedDollar}
            />
            <label className="form-check-label" for="inputFrankedDollar">
              Input Franked Dollar
            </label>
          </div>
        </div>
        <div className="row py-3 px-1">
          <div className="col-sm-3">
            <div className="input-group ">
              <label htmlFor="dividendPerShare" className="col-form-label px-4">
                Dividend Per Share
              </label>
              <input
                type="text"
                name="dividendPerShare"
                id="dividendPerShare"
                className="form-control input-sm "
                placeholder="$0.00"
                value={props.asicDetails.dividendPerShare} onChange={e=> props.handleChange(e, false, '')}
                autoComplete='off' 
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="input-group ">
              <label htmlFor="or" className="col-form-label px-4">
                Or
              </label>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="input-group ">
              <label htmlFor="totalDividend" className="col-form-label px-4">
                Total Dividend $'s
              </label>
              <input
                type="text"
                name="totalDividend"
                id="totalDividend"
                className="form-control input-sm "
                placeholder="$0.00"
                value={props.asicDetails.totalDividend} onChange={e=> props.handleChange(e, false, '')}
                autoComplete='off' 
              />
            </div>
          </div>
        </div>

        <div className="row py-3 px-1">
          <div className="col-sm-3">
            <div className="input-group ">
              <label
                htmlFor="frankedAmountPercentage"
                className="col-form-label px-4"
              >
                Franked Amount (%)
              </label>

              <NumberFormat
              type="text"
              data-filter="[0-9]"
              name="frankedAmountPercentage"
              className="form-control input-sm"
              disabled={props.asicDetails.inputFrankedDollar}
              value={props.asicDetails.frankedAmountPercentage} onChange={e=> {props.handleChange(e, false, ''); handleCalculate(e);}}
              placeholder="0.00"
              decimalScale={4}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalSeparator="."
              displayType="input"
              allowNegative={false}
              autoComplete="off"
            />

             
            </div>
          </div>
          <div className="col-sm-3">
            <div className="input-group ">
              <label
                htmlFor="unFrankedAmountPercentage"
                className="col-form-label px-4"
              >
                Unfranked Amount (%)
              </label>
          

              <NumberFormat
              type="text"
              data-filter="[0-9]"
              name="unFrankedAmountPercentage"
              className="form-control input-sm "
              value={props.asicDetails.unFrankedAmountPercentage} onChange={e=> props.handleChange(e, false, '')}
              disabled={props.asicDetails.inputFrankedDollar}
              placeholder="0.00"
              decimalScale={4}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalSeparator="."
              displayType="input"
              allowNegative={false}
              autoComplete="off"
            />

            </div>
          </div>
          <div className="col-sm-3">
            <div className="input-group ">
              <label htmlFor="companyTaxRate" className="col-form-label px-4">
                Company Tax Rate%
              </label>
              <select
                name="companyTaxRate"
                id="companyTaxRate"
                className="dropDownBox  w-25"
                onChange={e=> props.handleChange(e, false,"")}
              >
                <option></option>

                <option  Value ="25.00" >25.00</option>
                <option Value ="  26.00"  >
                  26.00
                </option>
                <option value="27.50" >
                  27.50
                </option>
                <option value="30.00" > 30.00</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row py-3 px-1">
          <div className="col-sm-3">
            <div className="input-group ">
              <label htmlFor="frankedAmount" className="col-form-label px-4">
                Franked Amount ($)
              </label>
              <NumberFormat
              type="text"
              data-filter="[0-9]"
              name="frankedAmount"
              className="form-control input-sm "
               value={props.asicDetails.frankedAmount} onChange={e=> {props.handleChange(e, false, '');handleFrankedAmountCalculate(e)}}
                disabled={!props.asicDetails.inputFrankedDollar}
              placeholder="$0.00"
              decimalScale={2}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalSeparator="."
              displayType="input"
              allowNegative={false}
              autoComplete="off"
            />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="input-group ">
              <label htmlFor="unFrankedAmount" className="col-form-label px-4">
                Unfranked Amount ($)
              </label>
              <NumberFormat
              type="text"
              data-filter="[0-9]"
           name="unFrankedAmount"
              className="form-control input-sm "
               value={props.asicDetails.unFrankedAmount} onChange={e=>{ props.handleChange(e, false, '');handleUnFrankedAmountCalculate(e);}}
                disabled={!props.asicDetails.inputFrankedDollar}
              placeholder="$0.00"
              decimalScale={2}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalSeparator="."
              displayType="input"
              allowNegative={false}
              autoComplete="off"
            />
            </div>
          </div>
        </div>
      </form>
      <hr/>
      <AddOrRemoveAsicAgentListComponent handleChange={props.handleChange} asicDetails={props.asicDetails} setAsicDetails={props.setAsicDetails}  isCardHeader={false}     companyProfile={props.companyProfile}/>
    </div>
  
  );
};
export default DividendStatementOrderComponent;
