import { userConstants } from "../constants/login.constants";

 function electronicLodgementService(state = {}, action) {
    switch (action.type) {
        case userConstants.ELECTRONICLODGEMENTSERVICE_REQUEST:
            return {
                loading: true
            };
        case userConstants.ELECTRONICLODGEMENTSERVICE_SUCCESS:
            return {
                items: action.electronicLodgementService
            };
        case userConstants.ELECTRONICLODGEMENTSERVICE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export default electronicLodgementService;