import React, { useState, useEffect } from "react";
import "../CompanyDetailsComponent/CompanyDetails.styles.scss";
import { useSelector } from "react-redux";
import ASICCompanyDetail from "../ASICCompanyDetailComponent/ASICCompanyDetail";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";
import FullPageLoader from "../CommonPage/FullPageLoader";

const ASICComponent = (props) => {
  const companyProfile = useSelector((state) => state.companyProfile);
  const asicInvoiceReportLinks = useSelector(
    (state) => state.asicInvoiceReportLinks
  );

  // View ASIC Debt Report
  const dispatch = useDispatch();
  //loading spinner
  const [isASICDeptLoaded, setIsASICDeptLoaded] = useState(false);
// eslint-disable-next-line
  const [submitted, setSubmitted] = useState(false);
  const selectedSearchValue = localStorage.getItem("inputSearchValue");
  function handleClick(e) {
    setSubmitted(true);

    const selectedAcn = companyProfile && companyProfile.items&& companyProfile.items.company&&companyProfile.items.company.ACN

    if (selectedAcn !== null) {
      dispatch(userActions.getAsicDebtReport(selectedAcn, setIsASICDeptLoaded));
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(userActions.getAsicDebtReport(value.ACN, setIsASICDeptLoaded))
      );
    }
  }
  ///API Reports link

  useEffect(() => {
    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if (selectedCompanyCode !== null) {
      dispatch(userActions.getAsicInvoiceReportLinks(selectedCompanyCode));
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) => dispatchAPIs(value));
    }

    // eslint-disable-next-line
  }, []);

  function dispatchAPIs(value) {
    dispatch(userActions.getAsicInvoiceReportLinks(value.Code));
  }

  const API_URL = process.env.REACT_APP_PRIME_API_URL;
  //edge report spinner
  const [isEdgeReportLoaded, setIsEdgeReportLoaded] = useState(false);
  function handleClickEdgeReport(e) {
 
    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if(props.searchCompany && props.searchCompany[0]) {
      dispatch(
        userActions.getEdgeCompanyProfileReport(
          props.searchCompany[0].Code,
          setIsEdgeReportLoaded
        )
      );
 
    } else if (selectedCompanyCode !== null) {
      dispatch(
        userActions.getEdgeCompanyProfileReport(
          selectedCompanyCode,
          setIsEdgeReportLoaded
        )
      );
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(
          userActions.getEdgeCompanyProfileReport(
            value.Code,
            setIsEdgeReportLoaded
          )
        )
      );
    }
  }
 
 

  return (
    <div>
      <details open>
        <summary>View ASIC Debt Report</summary>
        <div className="faq-content">
          <a
            className="text-decoration-none "
            role="button"
            href={() => false}
            onClick={(e) => handleClick(e)}
          >
            View ASIC Debt Report
          </a>
          {isASICDeptLoaded ? <FullPageLoader /> : null}
        </div>
      </details>
      <details>
        <summary>ASIC Payment BPay Details</summary>
        <div className="faq-content">
          <a
            className="collapsed"
            role="button"
            target="_blank"
            rel="noreferrer"
            href={
              companyProfile &&
              companyProfile.items &&
              companyProfile.items.company &&
              `https://connectonline.asic.gov.au/RegistrySearch/faces/bPayPostBillPayDetailSearch.jspx?searchOrgNumber=${companyProfile.items.company.ACN}`
            }
          >
            ASIC Payment BPay Details
          </a>
        </div>
      </details>
      <details>
        <summary>ASIC Company Details (Free ASIC Search)</summary>
        <div className="faq-content">
          <ASICCompanyDetail  searchCompany={props.searchCompany} history={props.history}/>
        </div>
      </details>
      <details>
        <summary>ASIC Invoice</summary>
        <div className="faq-content">
          {asicInvoiceReportLinks &&
            asicInvoiceReportLinks.items &&
            asicInvoiceReportLinks.items !== 'No Record(s) Found.' ?
            asicInvoiceReportLinks.items.map((value) => (
              <a
                className="text-decoration-none "
                role="button"
                href={`${API_URL}${value.File_Path}`}
                target="_blank"
                rel="noreferrer"
              >
                <li style={{ listStyle: "none" }}> {value.File_Name}</li>
              </a>
            ))
          : asicInvoiceReportLinks &&
          asicInvoiceReportLinks.items 
        }
        </div>
      </details>
    


     <details>
     <summary>ASIC Company Profile</summary>
     <div className="faq-content">
     <a
       className="text-decoration-none "
       role="button"
       href={() => false}
     onClick={(e) => handleClickEdgeReport(e)}
   >
    ASIC Company Profile Report</a> {isEdgeReportLoaded ? <FullPageLoader /> : null}
     </div>
   </details>

    </div>
  );
};
export default ASICComponent;
