//correct

import React, { useState, useEffect } from "react";
import MainScreen from "../CommonPage/MainScreen.component";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { userActions } from "../../redux/actions/user.actions";
import "../CommonPage/newStyles.scss";

const Login = (props) => {
  const domainpath = window.location.hostname;
  const [loginUser, setLoginUser] = useState({
    clientId: "",
    userName: "",
    password: "",
  });

  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  // const {clientId, userName, password} = loginUser;
  const { userName, password } = loginUser;
  const location = useLocation();
  let handleChange = (e) => {
    setLoginUser((users) => ({
      ...loginUser,
      [e.target.name]: e.target.value,
    }));
  };
  useEffect(() => {
    dispatch(userActions.logout());
    // eslint-disable-next-line
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (userName && password) {
      const { from } = location.state || {
        from: { pathname: "/CompanyMenuDetails" },
      };
      dispatch(userActions.login(userName, password, from, props.history));
      //props.history.push("/landingPage");
    }
  }
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  // after a month   change contentdiv py-2 to  py-4

  return (
    <MainScreen
      content={
        <div className="container  py-2">
          {console.log("window.location.pathname", props.history)}

          {console.log("domainpath", domainpath)}
          {console.log(
            "window.location.pathname",
            props.history.location.pathname
          )}
          {domainpath === "portal.primecloud.com.au" && (
            <script>
              function redirect(){" "}
              {(window.location = "https://compliance.primecc.com.au/")}
              redirect();
            </script>
          )}

          <form>
            <table>
              <tr>
                <td>
                  <div className="form-group ">
                    <input
                      type="text"
                      name="userName"
                      style={{
                        fontSize: "16px",
                        color: "#012040",
                        lineHeight: "19px",
                        fontWeight: "400",
                      }}
                      value={loginUser.userName}
                      onChange={handleChange}
                      size="50"
                      className={
                        "form-control  form-control-lg " +
                        (submitted && !loginUser.userName ? "is-invalid" : "")
                      }
                      placeholder="Username"
                    />
                    {submitted && !loginUser.userName && (
                      <div className="invalid-feedback">User Name required</div>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="py-2"></td>
              </tr>{" "}
              <tr>
                <td>
                  {" "}
                  <div className="form-group ">
                    <div
                      className={
                        " form-group d-flex flex-row align-items-center border rounded" +
                        (submitted && !loginUser.password
                          ? "border border-danger border rounded"
                          : "")
                      }
                    >
                      <input
                        type={passwordType}
                        style={{
                          fontSize: "16px",
                          color: "#012040",
                          lineHeight: "24px",
                          fontWeight: "400",
                        }}
                        name="password"
                        value={loginUser.password}
                        onChange={handleChange}
                        className={
                          "form-control border-0  form-control-lg" +
                          (submitted && !loginUser.password ? "is-invalid" : "")
                        }
                        placeholder="Password"
                        maxLength="20"
                        size="50"
                      />

                      {passwordType === "password" ? (
                        <i
                          className="bi bi-eye-slash  mx-2"
                          onClick={togglePassword}
                        ></i>
                      ) : (
                        <i
                          className="bi bi-eye mx-2"
                          onClick={togglePassword}
                        ></i>
                      )}
                    </div>
                    <div></div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  {" "}
                  {submitted && !loginUser.password && (
                    <div className="text-danger" style={{ fontSize: "14px" }}>
                      Password required
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <div className="col-auto">
                    <a
                      href={() => false}
                      className="hand-cursor  text-decoration-none"
                      style={{
                        fontSize: "14px",
                        color: "#012040",
                        lineHeight: "20 px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        letterspacing: "0.25px",
                      }}
                      onClick={() => props.history.push("/forgetPassword")}
                    >
                      Forgot your password?
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="py-3"></td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <div className="form-group">
                    <button
                      className={
                        (loginUser.userName && loginUser.password) === ""
                          ? "buttonDisable  w-100"
                          : "buttonActive w-100"
                      }
                      type="submit"
                      id="loginToPrime"
                      onClick={handleSubmit}
                    >
                      LOGIN
                    </button>
                  </div>
                </td>
              </tr>
            </table>

            <br />
            <hr className="my-12" />
            <br />
            <div className="form-group">
              <div className="col-auto col-md-12 text-center ">
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "19.36 px",
                    color: "#A6A6A6",
                    fontStyle: "normal",
                    fontWeight: "400",
                    letterspacing: "0.25px",
                  }}
                >
                  {" "}
                  Don't have an account?
                </span>

                <a
                  href={() => false}
                  style={{
                    fontSize: "16px",
                    lineHeight: "19.36 px",
                    color: " #012040",
                    fontStyle: "normal",
                    fontWeight: "550",
                    letterspacing: "0.25px",
                  }}
                  className="hand-cursor   text-decoration-none"
                  onClick={() => props.history.push("/Signup")}
                >
                  {" "}
                  Register for free
                </a>
              </div>
            </div>
          </form>
        </div>
      }
    />
  );
};
export default Login;
