import React, { useState, useEffect } from "react";
import "../CompanyDetailsComponent/CompanyDetails.styles.scss";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";
import FullPageLoader from "../CommonPage/FullPageLoader";


const DIDListComponent = (props) => {
  
  // dropdown selection
  const [didList, setDidList] = useState({
    didType: "",
     });

  // View ASIC Debt Report
  const dispatch = useDispatch();

  const selectedSearchValue = localStorage.getItem("inputSearchValue");

  ///API Reports link

  useEffect(() => {
    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if (selectedCompanyCode !== null) {
      dispatch(userActions.getAsicInvoiceReportLinks(selectedCompanyCode));
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) => dispatchAPIs(value));
    }

    // eslint-disable-next-line
  }, []);

  function dispatchAPIs(value) {
    dispatch(userActions.getAsicInvoiceReportLinks(value.Code));
  }

  // Director ID List Report pdf format
  const [isLoaded, setIsLoaded] = useState(false);
  const [isExcelLoaded, setIsExcelLoaded] = useState(false);
  function handleClickDidList(e,didList,value,setIsLoaded) {
    console.log("didList",didList)
    dispatch(userActions.getDirectorIdReport(didList,setIsLoaded,value));
  }


  
  return (
    <div>
      <details open>
        <summary>Director ID</summary>
        <div className="faq-content">
  {/*  <a
            href={() => false}
            className="nav-link px-4"
            role="button"
            onClick={(e) => handleClickDidList(e)}
          >
            Director ID List {isLoaded ? <FullPageLoader /> : null}
  </a>*/}
  <table>
  <tr ><td  colSpan={5}>
  <select
  name="didType"
  id="didType"
  className="dropDownBox w-100"
 style={{width:"100%"}}
 onChange={(e) => {
  setDidList(() => ({
    ...didList,
    [e.target.name]: e.target.value,
  }));
}}
required

 
>
  <option value="all" selected>
  Select DID Report Type 
  </option>
 

  <option value="all">All DID records</option>
  <option value="yes">With DID</option>
  <option value="no">Without DID</option>
</select>
  </td></tr>
  <tr><td><br/></td></tr>
  <tr><td >  <button className="buttonCommon fs-6"  
       
  onClick={(e) => handleClickDidList(e,didList,'pdf',setIsLoaded)}
  >Download PDF</button>  </td><td className="px-1">  {isLoaded ? <FullPageLoader /> : null}</td> 
  
  <td colSpan={5}><button className="buttonCommon fs-6"  onClick={(e) => handleClickDidList(e,didList,'xls',setIsExcelLoaded)}>Download Excel</button>
  </td><td >
  {isExcelLoaded ? <FullPageLoader /> : null}</td></tr></table>
        
          
        </div>
      </details>
      <details open>
        <summary> DID help & information</summary>
        <div className="faq-content">
        <li>
        <a
          href="https://registry.primecc.com.au/Content/static-media/DirectorIDIntroduction.pdf"
          target="_blank"
          rel="noreferrer"
        >
          DID Information Sheet{" "}
        </a>
      </li>
      <li>
        <a
          href="https://www.abrs.gov.au/director-identification-number/about-director-id"
          target="_blank"
          rel="noreferrer"
        >
          About DID{" "}
        </a>
      </li>
      <li>
        <a
          href="https://www.abrs.gov.au/director-identification-number/apply-director-identification-number"
          target="_blank"
          rel="noreferrer"
        >
          Steps to apply for DID{" "}
        </a>
      </li>
      <li>
        <a
          href="https://www.abrs.gov.au/director-identification-number/apply-director-identification-number/verify-your-identity"
          target="_blank"
          rel="noreferrer"
        >
          Verifying Your Identity for DID
        </a>
      </li>
      <li>
        <a
          href="https://registry.primecc.com.au/Content/static-media/Application_for_a_director_identification_number.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Paper DID Application Form
        </a>
      </li>
</div>
      </details>
      
    </div>
  );
};
export default DIDListComponent;