import React, { useState } from "react";
import MobileNumber from "../MobileNumberComponent/MobileNumber.Component";
import Popup from "../CommonPage/Popup.component";
import EmailId from "../EmailIdComponent/EmailId.Component";
import moment from "moment";
import "../CommonPage/Action.scss";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import { useLocation } from "react-router-dom";
import NotesComponent from "../Notes.Components/NotesComponent";

const AnnualReviewTableComponent = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [popupShow, setPopupShow] = useState(0);
  const handlePopupClose = () => setPopupShow(false);
  const handlePopupShow = (e, index) => {
    e.preventDefault();

    setPopupShow(index);
  };

  const [popupMobileShow, setPopupMobileShow] = useState(0);
  const handleMobilePopupClose = () => setPopupMobileShow(false);
  const handleMobilePopupShow = (e, index) => {
    e.preventDefault();
    setPopupMobileShow(index);
  };

  const [popupNotesShow, setPopupNotesShow] = useState(0);
  const handleNotesPopupClose = () => setPopupNotesShow(false);
  const handleNotesPopupShow = (e, index) => {
    e.preventDefault();
    setPopupNotesShow(index);
  };

  let handleNotesPopupClick = (e, rn) => {
    e.preventDefault();
    if (person.notes) {
      dispatch(userActions.postCompanyNotes(rn, person.notes));
      handleNotesPopupClose();
      dispatch(
        userActions.getAnnualStatement(
          props.currentYear,
          pathName && pathName[2]
        )
      );
      props.history.push(props.history.location.pathname);
    }
  };

  let handleEmailPopupClick = (e, rn) => {
    e.preventDefault();

    if (person.emailAddress) {
      dispatch(userActions.postCompanyEmailID(rn, person.emailAddress));
     
      setTimeout(() => {
        dispatch(
          userActions.getAnnualStatement(
            props.currentYear,
            pathName && pathName[2]
          )
        );
      }, 0);
      handlePopupClose();
      props.history.push(props.history.location.pathname);
    }
  };
  const pathName = location.pathname.split("/");

  let handleMobilePopupClick = (e, rn) => {
    e.preventDefault();

    if (person.mobileNumber) {
      dispatch(userActions.postCompanyPhoneNumber(rn, person.mobileNumber));
     
      setTimeout(() => {
        dispatch(
          userActions.getAnnualStatement(
            props.currentYear,
            pathName && pathName[2]
          )
        );
      }, 0);
      handleMobilePopupClose();
      props.history.push(props.history.location.pathname);
    }
  };

  let handleClick = (e, companyCode, companyName,acn) => {
    localStorage.setItem("companyCode", JSON.stringify(companyCode));
    localStorage.setItem("companyName", JSON.stringify(companyName));
    props.recentSearch.push([{ Code: companyCode, Name: companyName }]);
    dispatch(userActions.postRecentCompanyList(props.userId,props.clientId,"",acn))
    console.log("postRecentCompanyList From Annual Review table",props.userId,props.clientId,acn)
localStorage.setItem(
  "recentCompanySearch",
  JSON.stringify(props.recentSearch)
);
    props.history.push("/CompanyMenuDetails/details");
  };

  const [person, setPerson] = useState({
    mobileNumber: "",
    emailAddress: "",
    notes: '"',
    companyName: "",
  });

  let handlePersonDetailsChange = (e) => {
    e.preventDefault();
    setPerson((person) => ({
      ...person,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="">
      <table className="table table-hover  table-xs fs-6">
        <thead>
          <tr>
            <th></th>
            <th >Name</th>
            <th >Department</th>
            <th  >ACN</th>
            <th>Review</th>
            <th>ASIC link</th>
            <th>DocuSign</th>
            <th>
              Email
            </th>
            <th>
              SMS
            </th>
           
            <th>
            Notes
         
            </th>
            {/*
            <th>Action</th>
             */}
            <th></th>
          </tr>
        </thead>

        <tbody>
        
          {props.currentPosts && props.currentPosts.length === 0 ? (
            <tr>
              <td colSpan="11">No matching records found</td>
            </tr>
          ) : (
            props.currentPosts &&
            props.currentPosts.map((post) => (
              <tr>
                <td>
                  <i
                    className={
                      post.StatusText === "danger"
                        ? "bi bi-circle-fill fa-xs text-danger"
                        : post.StatusText === "success"
                        ? "bi bi-circle-fill fa-xs text-success"
                        : post.StatusText === "warning"
                        ? "bi bi-circle-fill fa-xs text-warning"
                        : "bi bi-circle fa-xs"
                    }
                  ></i>
                </td>
                <td className="col-md-5  fs-6 ">
                  {" "}
                  <a
                    href={() => false}
                    className="hand-cursor text-decoration-none"
                    onClick={(e) => handleClick(e, post.Code, post.Name,post.ACN)}
                  >
                    <u>{post.Name} </u>
                  </a>
                </td>

                <td className="fs-6 text-nowrap">{post.Department}</td>

                <td className="col-md-2 fs-6 text-nowrap ">{post.ACN}</td>

                <td className="fs-6">
                  {post.ReviewDate
                    ? moment(post.ReviewDate).format("DD/MM")
                    : ""}{" "}
                </td>

                <td className="fs-6">
                  <i
                    class={
                      post.Linked
                        ? "bi bi-check fa-lg text-success"
                        : "bi bi-x  fa-lg text-danger"
                    }
                  ></i>{" "}
                </td>

                <td className="fs-6">{post.DebtOwing}</td>

                <td className="fs-6">
              
                  <a
                    href={() => false}
                    onClick={(e) => handlePopupShow(e, post.RN)}
                    className="hand-cursor"
                  >    
                    <i
                    data-toggle="tooltip" html= "true" 
                    data-placement="bottom" title="Add/edit email"
                      class={
                        "bi bi-pencil-square text-dark px-2"
                       }
                    ></i>
                  </a>{" "}
                  {!post.EmailAddress?"Add/edit email" : post.EmailAddress.indexOf(';')!== -1? post.EmailAddress.split(';').join("\n")
       
                  :  post.EmailAddress}
           
                  {popupShow === post.RN && (
                    <Popup
                      show={popupShow === post.RN}
                      handleClose={handlePopupClose}
                      handleShow={(e) => handlePopupShow(e, post.EmailAddress)}
                      body={
                        <EmailId
                          emailId={post.EmailAddress}
                          handlePersonDetailsChange={handlePersonDetailsChange}
                        />
                      }
                      btnName="Save"
                      handleClick={(e) => handleEmailPopupClick(e, post.RN)}
                    />
                  )}
                </td>
                <td className="fs-6">
                  <a
                    href={() => false}
                    className="hand-cursor"
                    onClick={(e) => handleMobilePopupShow(e, post.RN)}
                  >
                    <i
                      class={
                        post.Phone
                          ? "fa  fa-mobile fa-lg text-success"
                          : "fa  fa-mobile fa-lg text-danger"
                      }
                    ></i>{" "}
                  </a>
                  {popupMobileShow === post.RN && (
                    <Popup
                      show={popupMobileShow === post.RN}
                      handleClose={handleMobilePopupClose}
                      handleShow={handleMobilePopupShow}
                      body={
                        <MobileNumber
                          phone={post.Phone}
                          handlePersonDetailsChange={handlePersonDetailsChange}
                        />
                      }
                      btnName="Save"
                      handleClick={(e) => handleMobilePopupClick(e, post.RN)}
                    />
                  )}
                </td>

                <td className="fs-6">
                  <a
                    href={() => false}
                    className="hand-cursor"
                    onClick={(e) => handleNotesPopupShow(e, post.RN)}
                  >

                  <i
                  class={
                    post.Notes
                      ? "bi bi-chat-square-text text-success"
                      : "bi bi-chat-square-text text-danger"
                  }
                 ></i>
           
                  </a>
                  {popupNotesShow === post.RN && (
                    <Popup
                      show={popupNotesShow === post.RN}
                      handleClose={handleNotesPopupClose}
                      handleShow={handleNotesPopupShow}
                      body={
                        <NotesComponent
                          notes={post.Notes}
                          companyName={post.Name}
                          departementNotes={post.DepartmentNotes}
                          handlePersonDetailsChange={handlePersonDetailsChange}
                        />
                      }
                      btnName="Save"
                      handleClick={(e) => handleNotesPopupClick(e, post.RN)}
                    />
                  )}
                </td>
               
               {/* <td>
                  <a
                    href={() => false}
                    className="d-flex align-items-center text-white text-decoration-none "
                    id="dropdownUserDetail"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                  </a>

                  <ul className="dropdown-menu text-dark ">
                    <li className="action">
                      <a className="dropdown-item " href={() => false}>
                     Notes
                      </a>{" "}
                      <hr />
                    </li>

                    <li className=" py-0 ">
                      <a className="dropdown-item " href={() => false}>
                      View Statements
                      </a>
                    
                    </li>
                  </ul>
                </td>
                    */}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};
export default AnnualReviewTableComponent;