import { userConstants } from "../constants/login.constants";

 function orderMonitorStatement(state = {}, action) {
    switch (action.type) {
        case userConstants.ORDERMONITORSTATEMENT_REQUEST:
            return {
                loading: true
            };
        case userConstants.ORDERMONITORSTATEMENT_SUCCESS:
            return {
                items: action.orderMonitorStatement
            };
        case userConstants.ORDERMONITORSTATEMENT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export default orderMonitorStatement;