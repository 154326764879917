import React, { useEffect } from "react";
import Landingpage from "../LandingPagecomponent/LandingPage.Component";
import "./CompanyMenuDetails.style.scss";
import {
  Switch,
  Route,
  Router,
  withRouter,
  useHistory,
} from "react-router-dom";
import Details from "../CompanyDetailsComponent/CompanyDetails.component";
import CompanySnapshot from "../CompanySnapshotComponent/CompanySnapshotComponent.component";
import CorporateRegister from "../CorporateRegistersComponents/CorporateRegistersComponents";
import ASIC from "../ASICComponents/ASICComponent";
import ASICForms from "../ASICFormsComponents/ASICFormsComponents";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import EmailHistoryComponent from "../EmailHistoryComponent/EmailHistory.component";
import CommonSearch from "../CommonPage/CommonSearch.component";
import EOrderComponent from "../EOrderComponents/EOrderComponents";
import ChangeOrderStatus from "../ChangesAndOrderRequest/ChangeOrderStatusComponent/ChangeOrderStatus.component";


const CompanyDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedSearchValue = localStorage.getItem("inputSearchValue");
  
  const recentCompanyList = useSelector((state) => state.recentCompanyList);
  useEffect(() => {
  setTimeout(() => {
    if(props.searchCompany && props.searchCompany[0]) {

    dispatch(userActions.getCompanyProfileByCode(props.searchCompany[0].Code));
   } 
  }, 0)
    // eslint-disable-next-line
}, [props.searchCompany]);
  useEffect(() => {

    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    const selectedCompanyName = JSON.parse(localStorage.getItem("companyName"));
  

   if (selectedCompanyCode !== null) {
      props.setSearchCompany([
        { Code: selectedCompanyCode, Name: selectedCompanyName },
      ]);
      dispatch(userActions.getCompanyProfileByCode(selectedCompanyCode));
    } else  if (selectedSearchValue !== null) {

        const companyCode = JSON.parse(selectedSearchValue).map((value) => {
          return value.Code;
        });
        const companyACN = JSON.parse(selectedSearchValue).map((value) => {
          return value.ACN;
        });
        props.setSearchCompany(JSON.parse(selectedSearchValue));
        if (companyCode) {
          dispatchToServices(companyCode, companyACN);
          dispatch(userActions.getCompanyProfileByCode(companyCode));
        }
      
    }
     
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.userId && props.clientId) {
    dispatch(userActions.getRecentCompanyList( props.clientId,props.userId,"all"))
    }
     // eslint-disable-next-line
  }, [props.userId, props.clientId]);

  function dispatchToServices(companyCode, companyACN) {
    dispatch(userActions.getCompanyProfileByCode(companyCode));
    dispatch(userActions.getUnitTrustByCode(companyCode, "summary"));
    dispatch(userActions.getRegdInformation(companyACN));
    
  }

  
  

  return (
    <Landingpage
      isCompany={true}
      commonSearch={
        <CommonSearch
          history={props.history}
          setRecentSearch={props.setRecentSearch}
          recentSearch={props.recentSearch}
          setSearchCompany={props.setSearchCompany}
          searchCompany={props.searchCompany}
          searchToken={props.searchToken}
          loggedUserInfo={props.loggedUserInfo}
          userId={props.userId} clientId={props.clientId}
          recentCompanyList={recentCompanyList}
        />
      }
      content={
        <Router history={history}>
          <nav className="navbar navbar-light bg-light navbar-expand-md ">
            <hr />
            <div className="container">
              <div
                className=" navbar-collapse justify-content-start"
                id="main-nav-collapse"
              >
                <ul className="navbar-nav">
                  <li
                    className={
                      props.history.location.pathname ===
                        "/CompanyMenuDetails/details" ||
                      props.history.location.pathname === "/CompanyMenuDetails"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <a
                      className="nav-link"
                      href={() => false}
                      onClick={() =>
                        props.history.push("/CompanyMenuDetails/details")
                      }
                    >
                      Company Details
                    </a>
                  </li>
                  {/*
                  <li
                    className={
                      props.history.location.pathname ===
                      "/CompanyMenuDetails/CompanySnapshot"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <a
                      className="nav-link"
                      href={() => false}
                      onClick={() =>
                        props.history.push(
                          "/CompanyMenuDetails/CompanySnapshot"
                        )
                      }
                    >
                      Company SnapShot
                    </a>
                  </li>
                   */}
                  <li
                    className={
                      props.history.location.pathname ===
                      "/CompanyMenuDetails/CorporateRegister"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <a
                      className="nav-link"
                      href={() => false}
                      onClick={() =>
                        props.history.push(
                          "/CompanyMenuDetails/CorporateRegister"
                        )
                      }
                    >
                      Registers and Reports
                    </a>
                  </li>
                  <li
                    className={
                      props.history.location.pathname ===
                      "/CompanyMenuDetails/ASIC"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <a
                      className="nav-link"
                      href={() => false}
                      onClick={() =>
                        props.history.push("/CompanyMenuDetails/ASIC")
                      }
                    >
                      ASIC
                    </a>
                  </li>
                  <li
                    className={
                      props.history.location.pathname ===
                      "/CompanyMenuDetails/ASICFormDocuments"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <a
                      className="nav-link"
                      href={() => false}
                      onClick={() =>
                        props.history.push(
                          "/CompanyMenuDetails/ASICFormDocuments"
                        )
                      }
                    >
                      Forms and Documents
                    </a>
                  </li>
                  {console.log("ExcludeDeparmentalActions",props.loggedUserInfo  && props.loggedUserInfo.items && props.loggedUserInfo.items.ExcludeDeparmentalActions)}
                  {props.loggedUserInfo  && props.loggedUserInfo.items && !props.loggedUserInfo.items.ExcludeDeparmentalActions && 
                  <li
                    className={
                      props.history.location.pathname ===
                      "/CompanyMenuDetails/EOrders"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                
                    <a
                      className="nav-link"
                      href={() => false}
                      onClick={() =>
                        props.history.push("/CompanyMenuDetails/EOrders")
                      }
                    >
                      eOrders
                    </a>
                  </li>
                     }
                  <li
                    className={
                      props.history.location.pathname ===
                      "/CompanyMenuDetails/EmailHistory"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <a
                      className="nav-link"
                      href={() => false}
                      onClick={() =>
                        props.history.push("/CompanyMenuDetails/EmailHistory")
                      }
                    >
                      Email History
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <Switch>
            <Route path="/CompanyMenuDetails/CompanySnapshot">
            
              <CompanySnapshot
                history={props.history}
                setRecentSearch={props.setRecentSearch}
                recentSearch={props.recentSearch}
                setSearchCompany={props.setSearchCompany}
                searchCompany={props.searchCompany}
                loggedUserInfo={props.loggedUserInfo}
                userId={props.userId} clientId={props.clientId}
              />
            </Route>
            <Route
              path="/CompanyMenuDetails/EmailHistory"
              component={EmailHistoryComponent}
            />
            <Route
              path="/CompanyMenuDetails/CorporateRegister"
              component={CorporateRegister}
              
            ><CorporateRegister searchCompany={props.searchCompany} history={props.history}/></Route>
            <Route path="/CompanyMenuDetails/ASIC">
            <ASIC searchCompany={props.searchCompany} history={props.history} />
            </Route>
      
            <Route path="/CompanyMenuDetails/ASICFormDocuments">
              <ASICForms
                history={props.history}
                setRecentSearch={props.setRecentSearch}
                recentSearch={props.recentSearch}
                setSearchCompany={props.setSearchCompany}
                searchCompany={props.searchCompany}
              />
            </Route>
            <Route path="/CompanyMenuDetails/EOrders">
              <EOrderComponent searchCompany={props.searchCompany} history={props.history}/>
            </Route>
            <Route path="/CompanyMenuDetails/EOrders/ChangeOrderStatus">
              <ChangeOrderStatus />
            </Route>
            
            <Route path="/CompanyMenuDetails/details">
              {" "}
              <Details
                history={props.history}
                setRecentSearch={props.setRecentSearch}
                recentSearch={props.recentSearch}
                setSearchCompany={props.setSearchCompany}
                searchCompany={props.searchCompany}
                loggedUserInfo={props.loggedUserInfo} userId={props.userId} clientId={props.clientId}
              />
            </Route>
           
            <Route path="/"  loggedUserInfo={props.loggedUserInfo}
            userId={props.userId} clientId={props.clientId}>
              {" "}
              <Details
                history={props.history}
                setRecentSearch={props.setRecentSearch}
                recentSearch={props.recentSearch}
                setSearchCompany={props.setSearchCompany}
                searchCompany={props.searchCompany}
                loggedUserInfo={props.loggedUserInfo} userId={props.userId} clientId={props.clientId}
              />
            </Route>
            
          </Switch>
        </Router>
      }
    />
  );
};
export default withRouter(CompanyDetails);
