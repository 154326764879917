import React from "react";
import AdditionalInformationComponent from "./AdditionalInformation.component";

const AddOrRemoveAsicAgentListComponent = (props) => {
  return (
    <div className={props.isCardHeader ? "card" : "px-3"}>
      <form>
        <div
          className={
            props.isCardHeader
              ? "card-header accordion py-2"
              : " accordion py-2"
          }
          id="myAccordionAdditional "
        >
          <input
            className="form-check-input"
            type="checkbox"
            data-bs-toggle="collapse"
            data-bs-target="#collapseAdditionalInfo"
            id="gridAdditionalInfo"
            Name="additionalInfo"
            value="additionalInfo"
            onChange={(e) => props.handleChange(e, false, "")}
          />
          <label
            className={props.isCardHeader ? "form-check-label px-3" : "px-3"}
            for="gridAdditionalInfo"
          >
            Additional Info (Inc Partner / Manager / Client / Billing
            Information)
          </label>
        </div>
        <div
          id="collapseAdditionalInfo"
          className="accordion-collapse collapse"
        >
          <AdditionalInformationComponent
            handleChange={props.handleChange}
            asicDetails={props.asicDetails}
            setAsicDetails={props.setAsicDetails}
            companyProfile={props.companyProfile}
          />
        </div>
      </form>
    </div>
  );
};
export default AddOrRemoveAsicAgentListComponent;
