import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import "../MonitorsComponents/AnnualReviews.style.scss";
import Pagination from "../CommonPage/Pagination.component";
import "../CommonPage/Action.scss";
import "../CommonPage/colourCodeEntries.scss";

const ClassListComponent = (props) => {
  // connect to API
  const dispatch = useDispatch();
  const classListInfo = useSelector((state) => state.classListInfo);

  useEffect(() => {
    const selectedSearchValue = localStorage.getItem("inputSearchValue");

    const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if (selectedCompanyCode !== null) {
      dispatch(userActions.getClassList(selectedCompanyCode));
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(userActions.getClassList(value.Code))
      );
    }
    // eslint-disable-next-line
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  let handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  // search
  const [searchInput, setSearchInput] = useState("");

  const classListTotal =
    classListInfo && classListInfo.items && classListInfo.items.Result
      ? classListInfo.items.Result.sort((a, b) =>
          a.CLASS_DESC > b.CLASS_DESC ? 1 : -1
        ).filter((filteredValue) =>
          filteredValue["CLASS_DESC"]
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        )
      : [];

  const indexOfLastPost = currentPage * postsPerPage;
  // console.log("indexOfLastPost: ", indexOfLastPost);

  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // console.log("indexOfFirstPost: ", indexOfFirstPost);
  const currentPosts = classListTotal
    ? classListTotal.slice(indexOfFirstPost, indexOfLastPost)
    : [];

  // refresh button
  function handleClickRefresh(e) {
    e.preventDefault();
    setSearchInput("");
  }
  // if we select company from Company list push to company details tab

  return (
    <div className="m-4 fontfamily">
      <h3 className="panel-title">Class List</h3>

      <nav className="navbar navbar-light bg-light navbar-expand-md ">
        <div className="container">
          <div
            className=" navbar-collapse d-flex flex-row-reverse "
            id="main-nav-collapse"
          >
            <ul className="navbar-nav">
              <div className=" col form-outline">
                <input
                  type="search"
                  id="classSearch"
                  className="form-control-sm "
                  size="50"
                  placeholder="Search Code details"
                  aria-label="Search"
                  autoComplete={false}
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                />
                &nbsp;
                <button
                  className="btn-sm"
                  onClick={(e) => handleClickRefresh(e)}
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i>
                </button>
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <div className="table-responsive-sm">
        <table className="table table-hover fs-6">
          <thead>
            <tr>
              <th>
                <i class="bi bi-person"></i>
              </th>
              <th className="text-wrap w-25">Code</th>
              <th className="text-wrap">Code Details</th>
              <th className="text-wrap">Class No</th>
            </tr>
          </thead>

          <tbody className=" fs-6">
            {
             currentPosts&& currentPosts.length ===0 ?              
              <tr><td colSpan="4">No matching records found</td></tr>:
              
              currentPosts &&
              currentPosts.map((post) => (
                <tr       className="hand-cursor text-decoration-none"
                onClick={(e) =>
                  props.handleClickClassList(
                    e,
                    post.RN,
                    post.CLASS_CODE,
                    post.CLASS_DESC,
                    post.CLASS_NO
                  )
                }>
                  <td>
                    {" "}
                    <a
                      href={() => false}
                      className="hand-cursor text-decoration-none"
                      onClick={(e) =>
                        props.handleClickClassList(
                          e,
                          post.RN,
                          post.CLASS_CODE,
                          post.CLASS_DESC,
                          post.CLASS_NO
                        )
                      }
                    >
                      {" "}
                      <i class="bi bi-person"></i>
                    </a>
                  </td>
                  <td> {post.CLASS_CODE}</td>
                  <td>{post.CLASS_DESC}</td>
                  <td>{post.CLASS_NO}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {classListTotal && classListTotal.length >= 10 && (
        <footer
          id="sticky-footer"
          class="footer d-flex justify-content-end footer-body  "
        >
          <div class="container fs-6 ">
            <div class="row">
              <div class="col-md-6 ">
                Showing{" "}
                <input
                  type="text"
                  className="form-control-sm border border-default"
                  onKeyPress={handleKeyPress}
                  name="showRecord"
                  value={postsPerPage}
                  maxLength="3"
                  size="3"
                  onChange={(e) => setPostsPerPage(e.target.value)}
                ></input>{" "}
                /{classListTotal ? classListTotal.length : 0}
              </div>
              <div class="col-md-6">
                {postsPerPage && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={classListTotal ? classListTotal.length : 0}
                    pageSize={postsPerPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};
export default ClassListComponent;
