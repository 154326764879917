export const isLocalhost = () => {
  const hostname = window.location.hostname;

  return (
    hostname.includes("localhost") ||
    hostname === "[::1]" ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
    hostname.endsWith(".localhost")
  );
};
