import { userConstants } from "../constants/login.constants";

 function countries(state = {}, action) {
    switch (action.type) {
        case userConstants.COUNTRIESALL_REQUEST:
            return {
                loading: true
            };
        case userConstants.COUNTRIESALL_SUCCESS:
            return {
                items: action.countries
            };
        case userConstants.COUNTRIESALL_FAILURE:
            return {
                error: action.errorCountries
            };
        default:
            return state
    }
}
export default countries;