import React, { useState } from "react";
import { useSelector } from "react-redux";
import ASICFormPortalComponent from "../ASICFormPortalComponents/ASICFormPortal.Components";
import DocumentTrackingComponent from "../ASICFormsAndDocumentComponent/DocumentTrackingComponent";
import { userActions } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";
import Popup from "../CommonPage/Popup.component";
import Div7aLoanAgreement from "../Div7ALoanAgreementComponent/Div7aLoanAgreement";
import FullPageLoader from "../CommonPage/FullPageLoader";

const ASICFormComponent = (props) => {
  //loading spinner
  const [isViewFormHistoryLoaded, setIsViewFormHistoryLoaded] = useState(false);

  // View Forms History Report
  const dispatch = useDispatch();
// eslint-disable-next-line
  const [submitted, setSubmitted] = useState(false);
  const selectedSearchValue = localStorage.getItem("inputSearchValue");
  function viewFormsHistoryHandleClick(e) {
    setSubmitted(true);

    const selectedCode =
      props.searchCompany && props.searchCompany.map((value) => value.Code);

    if (selectedCode !== null) {
      dispatch(
        userActions.getFormsHistoryReport(
          selectedCode,
          setIsViewFormHistoryLoaded
        )
      );
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(
          userActions.getFormsHistoryReport(
            value.Code,
            setIsViewFormHistoryLoaded
          )
        )
      );
    }
  }

  //loading spinner
  const [
    isViewCurrentAnnualStatementLoaded,
    setIsViewCurrentAnnualStatementLoaded,
  ] = useState(false);

  function viewCurrentAnnualStatementHandleClick(e) {
    setSubmitted(true);
    const companyCode =
      props.searchCompany && props.searchCompany.map((value) => value.Code);

    const companyName =
      props.searchCompany && props.searchCompany.map((value) => value.Name);

    if (companyCode !== null) {
      dispatch(
        userActions.getViewCurrentAnnualStatementReport(
          companyCode,
          companyName,
          setIsViewCurrentAnnualStatementLoaded
        )
      );
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(
          userActions.getViewCurrentAnnualStatementReport(
            value.Code,
            value.Name,
            setIsViewCurrentAnnualStatementLoaded
          )
        )
      );
    }
    handleAnnualStatementDocPopupShow(e);
  }

  // popup error message for View Current Annual Statement
  const viewCurrentAnnualStatementReport = useSelector(
    (state) => state.viewCurrentAnnualStatementReport
  );

  const [popupAnnualStatementDocShow, setPopupAnnualStatementDocShow] =
    useState(false);
  const handleAnnualStatementDocPopupClose = () =>
    setPopupAnnualStatementDocShow(false);
  const handleAnnualStatementDocPopupShow = (e) => {
    e.preventDefault();
    setPopupAnnualStatementDocShow(true);
  };

  let handleAnnualStatementDocPopupClick = (e) => {
    e.preventDefault();
    handleAnnualStatementDocPopupClose();
  };

  return (
    <div>
      <div>
        <details open>
          <summary>View latest Annual Statement</summary>
          <div className="faq-content">
            <a
              className="text-decoration-none "
              role="button"
              href={() => false}
              onClick={(e) => viewCurrentAnnualStatementHandleClick(e)}
            >
              View latest Annual Statement
            </a>
            {isViewCurrentAnnualStatementLoaded ? <FullPageLoader /> : null}

            {popupAnnualStatementDocShow &&
              viewCurrentAnnualStatementReport &&
              viewCurrentAnnualStatementReport.error &&
              viewCurrentAnnualStatementReport.error.indexOf("400") !== -1 && (
                <Popup
                  show={popupAnnualStatementDocShow}
                  handleClose={handleAnnualStatementDocPopupClose}
                  handleShow={(e) => handleAnnualStatementDocPopupShow(e)}
                  body={"  No Annual Statement on Files."}
                  handleClick={handleAnnualStatementDocPopupClick}
                />
              )}
          </div>
        </details>
        <details>
          <summary>View Forms and Documents</summary>
          <div className="faq-content">
            <DocumentTrackingComponent
              history={props.history}
              setSearchCompany={props.setSearchCompany}
              searchCompany={props.searchCompany}
            />
          </div>
        </details>
        <details>
          <summary>View Forms History</summary>
          <div className="faq-content">
            <a
              className="text-decoration-none"
              role="button"
              href={() => false}
              onClick={(e) => viewFormsHistoryHandleClick(e)}
            >
              View Forms History Report
            </a>
            {isViewFormHistoryLoaded ? <FullPageLoader /> : null}
          </div>
        </details>
        <details>
          <summary>ASIC Forms Portal</summary>
          <div className="faq-content">
            <ASICFormPortalComponent />
          </div>
        </details>
        <details>
          <summary>Div 7A Loan Agreement</summary>
          <div className="faq-content">
            <Div7aLoanAgreement   searchCompany={props.searchCompany} history={props.history} />
          </div>
        </details>
      </div>
    </div>
  );
};
export default ASICFormComponent;
