// correct code
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import "../MonitorsComponents/AnnualReviews.style.scss";
import Pagination from "../CommonPage/Pagination.component";
import MobileNumber from "../MobileNumberComponent/MobileNumber.Component";
import Popup from "../CommonPage/Popup.component";
import EmailId from "../EmailIdComponent/EmailId.Component";
import "../CommonPage/Action.scss";
import moment from "moment";
import "../CommonPage/colourCodeEntries.scss";
import { useHistory } from "react-router-dom";
import FullPageLoader from "../CommonPage/FullPageLoader";
import NotesComponent from "../Notes.Components/NotesComponent";

const CompaniesComponent = (props) => {
  const companiesDetailList = useSelector((state) => state.companiesDetailList);
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  let handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  // connect to API
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.getCompaniesDetailList());
    // eslint-disable-next-line
  }, []);

  // search
  const [searchInput, setSearchInput] = useState("");
  const companiesDetailListTotal =
    companiesDetailList && companiesDetailList.items
      ? companiesDetailList.items
          .sort((a, b) => (a.Name > b.Name ? 1 : -1))
          .filter((filteredValue) =>
            filteredValue["Name"]
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          )
      : [];

  // console.log(posts);

  const indexOfLastPost = currentPage * postsPerPage;
  // console.log("indexOfLastPost: ", indexOfLastPost);

  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // console.log("indexOfFirstPost: ", indexOfFirstPost);
  const currentPosts = companiesDetailListTotal
    ? companiesDetailListTotal.slice(indexOfFirstPost, indexOfLastPost)
    : [];

  const [popupShow, setPopupShow] = useState(0);
  const handlePopupClose = () => setPopupShow(false);
  const handlePopupShow = (e, index) => {
    e.preventDefault();

    setPopupShow(index);
  };

  const [popupMobileShow, setPopupMobileShow] = useState(0);
  const handleMobilePopupClose = () => setPopupMobileShow(false);
  const handleMobilePopupShow = (e, index) => {
    e.preventDefault();
    setPopupMobileShow(index);
  };

  let handleEmailPopupClick = (e, rn) => {
    e.preventDefault();
console.log("person.emailAddress",person.emailAddress)
   
      dispatch(userActions.postCompanyEmailID(rn, person.emailAddress));
      setTimeout(() => {
        dispatch(userActions.getCompaniesDetailList());
      }, 0);
      // props.history.push(props.history.location);
      handlePopupClose();
   
  };

  let handleMobilePopupClick = (e, rn) => {
    e.preventDefault();

   // if (person.mobileNumber) {
      dispatch(userActions.postCompanyPhoneNumber(rn, person.mobileNumber));
      setTimeout(() => {
        dispatch(userActions.getCompaniesDetailList());
      }, 0);
      // props.history.push(props.history.location);
      handleMobilePopupClose();
   // }
  };

  const [person, setPerson] = useState({
    mobileNumber: "",
    emailAddress: "",
  });

  let handlePersonDetailsChange = (e) => {
    e.preventDefault();
    setPerson((person) => ({
      ...person,
      [e.target.name]: e.target.value,
    }));
  };

  // refresh button
  function handleClickRefresh(e) {
    e.preventDefault();
    setSearchInput("");
  }
  // if we select company from Company list push to company details tab

  let handleClickCompany = (e, companyCode, companyName,acn) => {
    localStorage.setItem("companyCode", JSON.stringify(companyCode));
    localStorage.setItem("companyName", JSON.stringify(companyName));
    props.recentSearch.push([{ Code: companyCode, Name: companyName }]);
    dispatch(userActions.postRecentCompanyList(props.userId,props.clientId,"",acn))
    console.log("postRecentCompanyList from companieslist",props.userId,props.clientId,acn)
    localStorage.setItem(
      "recentCompanySearch",
      JSON.stringify(props.recentSearch)
    );
    history.push("/CompanyMenuDetails/details");
  };
  // quickcompany Profile Report

  //loading spinner
  const [isQuickCompanyLoaded, setIsQuickCompanyLoaded] = useState(false);
// eslint-disable-next-line
  const [submitted, setSubmitted] = useState(false);
  const selectedSearchValue = localStorage.getItem("inputSearchValue");
  function companyHandleClick(e, selectedCompanyCode) {
    setSubmitted(true);

    //const selectedCompanyCode = JSON.parse(localStorage.getItem("companyCode"));
    if (selectedCompanyCode !== null) {
      dispatch(
        userActions.getQuickCompanyProfileReport(
          selectedCompanyCode,
          setIsQuickCompanyLoaded
        )
      );
    } else if (selectedSearchValue !== null) {
      JSON.parse(selectedSearchValue).map((value) =>
        dispatch(
          userActions.getQuickCompanyProfileReport(
            value.Code,
            setIsQuickCompanyLoaded
          )
        )
      );
    }
  }
// current annual statement
  //loading spinner
  const [
    isViewCurrentAnnualStatementLoaded,
    setIsViewCurrentAnnualStatementLoaded,
  ] = useState(false);

function viewCurrentAnnualStatementHandleClick(e, status,code,coName) {
      if (status === 0) {
      
          setSubmitted(true);
       
          if (code !== null) {
            dispatch(
              userActions.getViewCurrentAnnualStatementReport(
                code,
                coName,
                setIsViewCurrentAnnualStatementLoaded
              )
            );
          } 
          handleAnnualStatementDocPopupShow(e);
    }
}

// popup error message for View Current Annual Statement
const viewCurrentAnnualStatementReport = useSelector(
  (state) => state.viewCurrentAnnualStatementReport
);

const [popupAnnualStatementDocShow, setPopupAnnualStatementDocShow] =
  useState(false);
const handleAnnualStatementDocPopupClose = () =>
  setPopupAnnualStatementDocShow(false);
const handleAnnualStatementDocPopupShow = (e) => {
  e.preventDefault();
  setPopupAnnualStatementDocShow(true);
};

let handleAnnualStatementDocPopupClick = (e) => {
  e.preventDefault();
  handleAnnualStatementDocPopupClose();
};

//notes popup
const [popupNotesShow, setPopupNotesShow] = useState(0);
  const handleNotesPopupClose = () => setPopupNotesShow(false);
  const handleNotesPopupShow = (e, index) => {
    e.preventDefault();
    setPopupNotesShow(index);
  };

  let handleNotesPopupClick = (e, rn) => {
    e.preventDefault();

      dispatch(userActions.postCompanyNotes(rn, person.notes));
      setTimeout(() => {
        dispatch(userActions.getCompaniesDetailList());
      }, 0);
      handleNotesPopupClose();
     
  
  };

  return (
    <div className="m-4 fontfamily">
      <h3 className="panel-title">Companies List</h3>

      <nav className="navbar navbar-light bg-light navbar-expand-md ">
        <div className="container">
          <div
            className=" navbar-collapse d-flex flex-row-reverse "
            id="main-nav-collapse"
          >
            <ul className="navbar-nav">
              <div className=" col form-outline">
                <input
                  type="search"
                  id="CompaniesSearch"
                  className="form-control-sm "
                  size="50"
                  placeholder="Search"
                  aria-label="Search"
                  autoComplete={false}
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                />
                &nbsp;
                <button
                  className="btn-sm"
                  onClick={(e) => handleClickRefresh(e)}
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i>
                </button>
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <div className="table-responsive-sm">
        <table className="table table-hover fs-6">
          <thead>
            <tr>
              <th className="text-wrap w-50">Company Name</th>
              <th className="text-wrap w-25">ACN</th>
              <th className="text-wrap">Review</th>
              <th className="text-wrap">Month</th>
              <th className="text-wrap">Notes</th>
              <th className="text-wrap">Agent</th>
              <th className="text-wrap w-25">Status</th>
              <th className="text-wrap">Email</th>
              <th className="text-wrap">SMS</th>
              <th>Notes</th>
              <th> Action </th>
            </tr>
          </thead>

          <tbody className=" fs-6">
            {
              companiesDetailList && currentPosts&& currentPosts.length ===0 ?              
              <tr><td colSpan="11">No matching records found</td></tr>:
              
              companiesDetailList &&
              currentPosts &&
              currentPosts.map((post) => (
                <tr>
                  <td>
                    {" "}
                    <a
                      href={() => false}
                      className="hand-cursor text-decoration-none"
                      onClick={(e) =>
                        handleClickCompany(e, post.Code, post.Name,post.ACN)
                      }
                    >
                      {post.Name}
                    </a>
                  </td>

                  <td className="text-nowrap">{post.ACN}</td>

                  <td>
                    {post.ReviewDate &&
                      moment(post.ReviewDate).format("DD/MM/YYYY")}
                  </td>
                  <td>
                    {post.ReviewDate && moment(post.ReviewDate).format("M")}
                  </td>

                  <td>{post.Notes ? "Y" : "N"}</td>

                  <td>{post.Agent}</td>

                  <td>
                    <i
                      class={
                        post.HasForm362
                          ? " btn-sm bi-check   bg-success "
                          : " bi-x btn-sm bg-danger"
                      }
                    ></i>
                    &nbsp;
                    <button
                      type="button"
                      className={
                        post.Status === 0
                          ? "btn btn-primary btn-sm btn-success  "
                          : post.Status === 1
                          ? "btn btn-primary btn-sm btn-warning "
                          : post.Status === 2
                          ? " btn btn-primary btn-sm btn-danger "
                          : post.Status === 3
                          ? "btn btn-secondary btn-sm"
                          : ""
                      }
                      
                      onClick={ 
                       (e) =>   viewCurrentAnnualStatementHandleClick(e, post.Status,post.Code, post.Name)}
                    >
                      AS
                    </button>
                 
                    {popupAnnualStatementDocShow &&
                      viewCurrentAnnualStatementReport &&
                      viewCurrentAnnualStatementReport.error &&
                      viewCurrentAnnualStatementReport.error.indexOf("400") !== -1 && (
                        <Popup
                          show={popupAnnualStatementDocShow}
                          handleClose={handleAnnualStatementDocPopupClose}
                          handleShow={(e) => handleAnnualStatementDocPopupShow(e)}
                          body={"  No Annual Statement on Files."}
                          handleClick={handleAnnualStatementDocPopupClick}
                        />
                      )}
                  </td>

                  <td className="fs-6">
                
                    <a
                      href={() => false}
                      onClick={(e) => handlePopupShow(e, post.RN)}
                      className="hand-cursor"
                    >
                      <i
                      data-toggle="tooltip" html= "true" 
data-placement="bottom" title="Add/Modify email"
                        class={
                         "bi bi-pencil-square text-dark px-2"
                        }
                      ></i>
                    </a>
                    {!post.Email?"Add/edit email" : post.Email.indexOf(';')!== -1? post.Email.split(';').join("\n")
                     :  post.Email}
                 
                    {popupShow === post.RN && (
                      <Popup
                        show={popupShow === post.RN}
                        handleClose={handlePopupClose}
                        handleShow={(e) =>
                          handlePopupShow(e, post.EmailAddress)
                        }
                        body={
                          <EmailId
                            emailId={post.Email}
                            handlePersonDetailsChange={
                              handlePersonDetailsChange
                            }
                          />
                        }
                        btnName="Save"
                        handleClick={(e) => handleEmailPopupClick(e, post.RN)}
                      />
             
                    )}
                   { console.log("emailaddress",post.EmailAddress)}
                  </td>
                  <td className="fs-6">
                    <a
                      href={() => false}
                      className="hand-cursor"
                      onClick={(e) => handleMobilePopupShow(e, post.RN)}
                    >
                      <i
                        class={
                          post.SMS
                            ? "fa  fa-mobile fa-lg text-success"
                            : "fa  fa-mobile fa-lg text-danger"
                        }
                      ></i>{" "}
                    </a>
                    {popupMobileShow === post.RN && (
                      <Popup
                        show={popupMobileShow === post.RN}
                        handleClose={handleMobilePopupClose}
                        handleShow={handleMobilePopupShow}
                        body={
                          <MobileNumber
                            phone={post.SMS}
                            handlePersonDetailsChange={
                              handlePersonDetailsChange
                            }
                          />
                        }
                        btnName="Save"
                        handleClick={(e) => handleMobilePopupClick(e, post.RN)}
                      />
                    )}
                  </td>
                  <td>
                  <a
                    href={() => false}
                    className="hand-cursor"
                    onClick={(e) => handleNotesPopupShow(e, post.RN)}
                  >
                  <i
                  class={post.Notes
                  ? "bi bi-chat-square-text   text-success"
                  :"bi bi-chat-square-text   text-danger"
              }        ></i>
          

                  </a>
                  {popupNotesShow === post.RN && (
                    <Popup
                      show={popupNotesShow === post.RN}
                      handleClose={handleNotesPopupClose}
                      handleShow={handleNotesPopupShow}
                      body={
                        <NotesComponent
                          notes={post.Notes}
                          companyName={post.Name}
                          departementNotes={post.DepartmentNotes}
                          handlePersonDetailsChange={handlePersonDetailsChange}
                        />
                      }
                      btnName="Save"
                      handleClick={(e) => handleNotesPopupClick(e, post.RN)}
                    />
                  )}
                </td>
               

                  <td className="w-25">
                    <a
                      href={() => false}
                      className="d-flex align-items-center text-white text-decoration-none "
                      id="dropdownUserDetail"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bi bi-three-dots-vertical  link-dark hand-cursor "></i>
                    </a>
                    <ul className="dropdown-menu text-dark">
                      <li className="py-0 ">
                        <a
                          className="dropdown-item "
                          href={() => false}
                          onClick={(e) => companyHandleClick(e, post.Code)}
                        >
                          {console.log("company code", post.Code)}
                          Quick Profile
                        </a>
                      </li>
                      {isQuickCompanyLoaded ? <FullPageLoader /> : null}
                    </ul>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {companiesDetailListTotal && companiesDetailListTotal.length >= 10 && (
        <footer
          id="sticky-footer"
          class="footer d-flex justify-content-end footer-body py-5  "
        >
          <div class="container fs-6 ">
            <div class="row">
              <div class="col-md-6 ">
                Showing{" "}
                <input
                  type="text"
                  className="form-control-sm border border-default"
                  onKeyPress={handleKeyPress}
                  name="showRecord"
                  value={postsPerPage}
                  maxLength="3"
                  size="3"
                  onChange={(e) => setPostsPerPage(e.target.value)}
                ></input>{" "}
                /
                {companiesDetailListTotal ? companiesDetailListTotal.length : 0}
              </div>
              <div class="col-md-6">
                {postsPerPage && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={
                      companiesDetailListTotal
                        ? companiesDetailListTotal.length
                        : 0
                    }
                    pageSize={postsPerPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};
export default CompaniesComponent;
