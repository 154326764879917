import React from "react";

const Form484InformationComponent = (props) => {
  const information =
    props.form484Information && props.form484Information.items;

  return (
    <div className="py-3">
      {information &&
        information.split("\n").map((str) => (
          <table className="fs-5">
            <tbody className="fs-6 fst-italic">
              {" "}
              {str.startsWith("Change") ||
              str.startsWith("Transfer") ||
              str.startsWith("Share") ||
              str.startsWith("Officeholder") ? (
                <tr>
                  <td>
                    <br />
                  </td>
                </tr>
              ) : (
                ""
              )}
              <tr>
                <td>
                  <strong>{str}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        ))}
    </div>
  );
};
export default Form484InformationComponent;
