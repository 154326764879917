import React, { useState } from "react";

const MobileNumber = (props) => {
  const [users, setUsers] = useState({
    mobileNumber: props.phone,
  });
// eslint-disable-next-line
  const [submitted, setSubmitted] = useState(false);

  let handleChange = (e) => {
    setUsers((users) => ({
      ...users,
      [e.target.name]: e.target.value,
    }));
  };

  
  return (
    <div>
      <h3 className="mb-4">Mobile Number</h3>
      <table className="table table-responsive table-borderless">
        <tbody>
          <tr>
            <td className="fw-bold w-50 ">Mobile Number</td>{" "}
            <td>
              <input
                type="text" data-filter='[0-9|,]*'
                name="mobileNumber"
                onChange={e=> {handleChange(e); props.handlePersonDetailsChange(e)} }
                
                className={
                  "form-control input-sm " +
                  (submitted && !users.mobileNumber ? "is-invalid" : "") 
                }
                placeholder="SMS....." value={ users.mobileNumber }
                autoComplete="off"
              />
              {submitted && !users.mobileNumber && (
                <div className="invalid-feedback">Mobile Number Required</div>
              )}
            </td>
          </tr>
        </tbody>{" "}
      </table>
    </div>
  );
};

export default MobileNumber;
